<app-avatar [name]="userName" [size]="size" [textSizeRatio]="textSizeRatio" *ngIf="!hideAvatar"></app-avatar>

<div class="name-part">
  <truncated-text [text]="userName">
    <a [routerLink]="['/dashboard-individual', { user: userId }]" class="dashboard-link" suffix
      (click)="$event.stopPropagation()" [matTooltip]="'common.viewUsersDashboard' | translate"
      *ngIf="!hideLink && ((role$ | async) !== 'guest')" matTooltipPosition="above"
      [trackClick]="{ selectedoption: { value: userName } }" trackType="no-link">
      <mat-icon>open_in_new</mat-icon>
    </a>
  </truncated-text>

  <ng-content></ng-content>
</div>
