import { AfterContentInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * How to use this directive?
 *
 * ```
 * <div hasScrollbar="has-scrollbar">
 *     If this element has a vertical scrollbar, it will have a class 'has-scrollbar'
 * </div>
 *
 * <div hasScrollbar hasScrollbarHorizontal="hs-horizontal">
 *     If this element has a horizontal scrollbar, it will have a class 'hs-horizontal'
 * </div>
 * ```
 */
@Directive({
  selector: '[hasScrollbar]',
})
export class HasScrollbarDirective implements OnDestroy, AfterContentInit {
  private static readonly defaultClass = 'has-scrollbar';
  private static readonly defaultHorizontalClass = 'has-scrollbar-horizontal';
  private verticalClass = new BehaviorSubject(HasScrollbarDirective.defaultClass);
  private horizontalClass = new BehaviorSubject(HasScrollbarDirective.defaultHorizontalClass);
  private onResize = new Subject();
  private mutationObserver: MutationObserver;
  private resizeObserver: ResizeObserver;
  private watchSubscription: Subscription;

  private onResizeEvent = () => this.onResize.next(null);

  @Input()
  set hasScrollbar(newClass: string) {
    this.verticalClass.next(newClass || HasScrollbarDirective.defaultClass);
  }

  @Input()
  set hasScrollbarHorizontal(newClass: string) {
    this.horizontalClass.next(newClass || HasScrollbarDirective.defaultHorizontalClass);
  }

  constructor(private el: ElementRef<HTMLElement>) {
    const hasScrollbars = this.onResize.pipe(map(() =>
      [el.nativeElement.scrollWidth > el.nativeElement.offsetWidth,
      el.nativeElement.scrollHeight > el.nativeElement.offsetHeight]));

    this.watchSubscription = combineLatest([hasScrollbars, this.verticalClass, this.horizontalClass])
      .subscribe(([[hasHorizontal, hasVertical], verticalClass, horizontalClass]) => {
        el.nativeElement.classList.toggle(verticalClass, hasVertical);
        el.nativeElement.classList.toggle(horizontalClass, hasHorizontal);
      });
  }


  ngOnDestroy() {
    window.removeEventListener('resize', this.onResizeEvent);
    this.mutationObserver?.disconnect();
    this.resizeObserver?.disconnect();
    this.watchSubscription?.unsubscribe();
  }

  ngAfterContentInit() {
    window.addEventListener('resize', this.onResizeEvent);

    // Listen to changes on the elements in the page that affect layout
    if (window.MutationObserver) {
      this.mutationObserver = new MutationObserver(this.onResizeEvent);
      this.mutationObserver.observe(this.el.nativeElement, {
        attributes: true,
        childList: true,
        characterData: true,
        subtree: true,
      });
    }

    if (window.ResizeObserver) {
      this.resizeObserver = new window.ResizeObserver(this.onResizeEvent);
      this.resizeObserver.observe(this.el.nativeElement);
    }
  }
}
