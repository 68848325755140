import { Injectable } from '@angular/core';
import { LayoutGapParent, LayoutGapStyleBuilder, StyleDefinition } from '@angular/flex-layout';

@Injectable()
export class CustomLayoutGapStyleBuilder extends LayoutGapStyleBuilder {
  buildStyles(gapValue: string, parent: LayoutGapParent): StyleDefinition {
    return super.buildStyles(gapValue || '0 px', parent);
  }

  sideEffect(gapValue, styles, parent) {
    return super.sideEffect(gapValue || '0 px', styles, parent);
  }
}
