import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-back-header',
  templateUrl: './back-header.component.html',
  styleUrls: ['./back-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackHeaderComponent {
  @Input() text: string;

  @Output() back = new EventEmitter();
}
