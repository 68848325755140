import saveAs from 'file-saver';

export function downloadBashTemplate(version: string, appName: string) {
    const hostName = (appName.indexOf('-') > -1) ? appName.substring(appName.indexOf('-') + 1) : '';
    const shCode = `
#!/bin/bash

UBUNTU="https://download.timedoctor.com/${version}/linux/ubuntu-18.04/interactive/timedoctor2-setup-${version}-linux-x86_64.run"

wget -O /tmp/timedoctor-$$.run $UBUNTU

if [ -e /tmp/timedoctor-$$.run ] ; then 
  chmod a+x /tmp/timedoctor-$$.run 
  /tmp/timedoctor-$$.run --nox11 -- ${hostName === '' ? '' : `--install-host=${hostName}`}; retval=$?
  rm -fv /tmp/timedoctor-$$.run
  exit $retval
fi
exit 255`;

    const blob = new Blob([shCode], {
        type: 'text/plain;charset=utf-8;',
    });
    saveAs(blob, `td2-ubuntu-interactive-v${version}.sh`);
}

export function shouldUseUbuntuShellScript(version: string) {
    const versions = version.trim().split('.').map(x => parseInt(x));
    return versions[0] >= 3 && versions[1] >= 9 && versions[2] >= 44;
}

export interface DownloadShellScriptInfo {
    isScript?: boolean;
    version?: string;
    appName?: string;
}
