import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, Subject, timer } from 'rxjs';
import { catchError, filter, map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { AppStateRootModel } from '../store/models';
import { API_URL, DEFAULT_API_VERSION, HTTP_TIMEOUT, SOCKET_API_ROOT, USE_WEB_SOCKET } from './app.constants';
import { ErrorMonitoringService } from './error-monitoring.service';
import { SOCKET_INTERCEPTORS, SocketInterceptor } from './socket-interceptor';
import { SocketApiParams, SocketChunkParam, SocketService } from './socket.service';


export interface ApiLimitParams {
  limit?: number;
  page?: number;
}

export interface HttpRequestOptions {
  body?: any;
  headers?: HttpHeaders | {
    [header: string]: string | string[];
  };
  observe?: 'body';
  params?: HttpParams | {
    [param: string]: string | string[];
  };
  responseType?: 'json';
  reportProgress?: boolean;
  withCredentials?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private currentCallId = 0;
  private calls: Subject<{ res?: any, status: 'update' | 'done' }>[] = [];
  private chunks: Record<number, any[]> = {};

  constructor(
    private socket: SocketService,
    private http: HttpClient,
    private store: Store,
    @Inject(HTTP_TIMEOUT) private httpTimeout: number,
    @Inject(SOCKET_API_ROOT) private socketApiPath: string,
    @Inject(API_URL) private apiUrl: string,
    @Inject(USE_WEB_SOCKET) private useWebSocket: boolean,
    @Inject(DEFAULT_API_VERSION) private defaultApiVersion: string,
    @Inject(SOCKET_INTERCEPTORS) @Optional() private socketInterceptor: SocketInterceptor,
    errorMonitoring: ErrorMonitoringService,
  ) {
    this.socket.socketApiResult.subscribe((res) => {
      const call = this.calls[res.id];

      if (call) {
        if (res.more) {
          const chunk = this.chunks[res.id] ??= [];
          chunk.push(...res.data);

          if (!call.hasError) {
            call.next({ status: 'update' });
            this.socket.emit('apiResume', res.id);
          }
        }
        else if (res.data && res.data.error) {
          call.error(res.data);
        } else if (![200, 204].includes(Number(res.status))) {
          call.error(res);
        } else {
          const chunk = this.chunks[res.id];
          if (chunk) {
            const data = { ...res.data };
            data.data = chunk;
            call.next({ status: 'done', res: data });
            call.complete();
          } else {
            call.next({ status: 'done', res: res.data });
            call.complete();
          }
        }
      } else {
        console.error('Received Socket API response without making a call.', res);
        errorMonitoring.notify(new Error('Received Socket API response without making a call.'), { res }, ['websocket']);
      }
    });
  }

  private deepenParameters(parameters: HttpParams | { [param: string]: string | string[] }) {
    if (parameters instanceof HttpParams) { return parameters; }

    const result = {};

    for (const key in parameters) {
      if (Object.prototype.hasOwnProperty.call(parameters, key)) {
        const value = typeof parameters[key] === 'boolean' || typeof parameters[key] === 'number' ?
          parameters[key].toString() : parameters[key];

        if (key.includes('[')) {
          const [keyProp, keyDeep] = key.split('[');

          result[keyProp] = result[keyProp] || {};

          result[keyProp][keyDeep.replace(']', '')] = value;
        } else {
          result[key] = value;
        }
      }
    }

    return result;
  }

  private socketApi<T>(method, endpoint, options: HttpRequestOptions, version: string = null, chunk?: SocketChunkParam) {
    const subject = new Subject<{ res?: T, status: 'update' | 'done' }>();
    this.calls[this.currentCallId] = subject;

    const subjectUpdate = subject.pipe(filter(x => x.status === 'update'), startWith({ status: 'update' }));
    const subjectDone = subject.pipe(filter(x => x.status === 'done'), map(x => x.res));

    subjectUpdate.pipe(switchMap(() => timer(this.httpTimeout)), takeUntil(subjectDone))
      .subscribe({
        next: () => subject.error({ error: 'wsTimeout', message: 'Websocket request timed out', method, endpoint, options }),
        error: () => { /*NOOP*/ },
      });

    const company = this.store.selectSnapshot((st: AppStateRootModel) => st.auth && st.auth.company);
    const queryBase = {} as any;
    if (company) {
      queryBase.company = company && company.id;
    }

    let requestParams: SocketApiParams = {
      id: this.currentCallId,
      path: `${this.getFullSocketApiPath(version)}/${endpoint}`,
      method,
      ...chunk && { chunk },
      body: options.body,
      headers: {
        ...options.headers,
      },
      query: {
        ...queryBase,
        ...this.deepenParameters(options.params),
      },
    };

    if (this.socketInterceptor) {
      requestParams = this.socketInterceptor.intercept(requestParams);
    }

    this.socket.emit('api', requestParams);


    this.socket.disconnect.pipe(takeUntil(subjectDone))
      .subscribe({
        next: () => subject.error({ error: 'wsDisconnect', message: 'Websocket disconnected', method, endpoint, options }),
        error: () => { /*NOOP*/ },
      });

    this.currentCallId++;
    return subjectDone;
  }

  request<T>(
    method: 'get' | 'post' | 'put' | 'delete',
    endpoint: string,
    bodyOrParams = {},
    extraOptions: HttpRequestOptions = {},
    skipWebsocket: boolean = false,
    version: string = null,
    chunk?: SocketChunkParam,
  ): Observable<T> {
    let params = {};
    let body = {};

    if (method === 'get') {
      params = bodyOrParams || {};
    } else {
      body = bodyOrParams || {};
    }

    const userId = this.store.selectSnapshot((st: AppStateRootModel) => st.auth && st.auth.user && st.auth.user.id);
    const options = { body, params };
    const absoluteEndpoint = endpoint.startsWith('http');
    const httpEndpoint = absoluteEndpoint ? endpoint : `${this.getFullApiUrl(version)}/${endpoint}`;

    const requestOptions = { ...options, ...extraOptions };
    if (!skipWebsocket && !absoluteEndpoint && ((this.socket.connected && this.useWebSocket) || chunk) && userId) {
      const res = this.socketApi<T>(method, endpoint, requestOptions, version, chunk)
        .pipe(catchError(error => {
          const newUserId = this.store.selectSnapshot((st: AppStateRootModel) => st.auth && st.auth.user && st.auth.user.id);
          const online = this.store.selectSnapshot((st: AppStateRootModel) => st.shared.online);

          if (userId !== newUserId) {
            // eslint-disable-next-line no-throw-literal
            throw { error: 'userChanged', message: 'User changed when trying to switch from websocket to HTTP.', wsError: error };
          }

          if (!online) {
            // eslint-disable-next-line no-throw-literal
            throw { error: 'offline', message: 'App is offline when trying to switch from websocket to HTTP.', wsError: error };
          }

          console.warn('Websocket request failed, trying HTTP.', error);

          return this.http.request<T>(method, httpEndpoint, requestOptions);
        }));
      return res;
    } else {
      return this.http.request<T>(method, httpEndpoint, requestOptions);
    }
  }

  getFullApiUrl(version: string = null) {
    return this.apiUrl.replace('{version}', version || this.defaultApiVersion);
  }

  getFullSocketApiPath(version: string = null) {
    return this.socketApiPath.replace('{version}', version || this.defaultApiVersion);
  }
}
