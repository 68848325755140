<app-shared-banner *ngIf="showBanner$ | async" #sharedTopBanner [svgIcon]="banner.svgIcon" [icon]="banner.icon"
  [attr.aria-label]="banner.ariaLabel" [attr.data-type]="banner.type" [attr.data-icon-color]="banner.iconColor">
  <span banner-header [innerHTML]="banner.message.replace('\n', '<br/>')"></span>

  <div banner-buttons fxLayout="row" *ngIf="banner.primaryButton">
    <button mat-button mat-stroked-button (click)="banner.primaryButton.action(sharedTopBanner)"
      [trackClick]="banner.trackingInfo">
      {{ banner.primaryButton.text | translate }}
    </button>
  </div>
</app-shared-banner>
