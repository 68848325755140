import { CdkVirtualScrollViewport, VIRTUAL_SCROLL_STRATEGY, VirtualScrollStrategy } from '@angular/cdk/scrolling';
import { Directive, ElementRef, Injectable, Input, forwardRef } from '@angular/core';
import { Observable, Subject, distinctUntilChanged } from 'rxjs';


interface ItemHeight {
  value: number;
  source: 'predicted' | 'actual';
}

interface VariableItem {
  id: string;
}

@Injectable()
class VariableVirtualScrollStrategy implements VirtualScrollStrategy {
  items: VariableItem[] = [];
  elements: VariableVirtualScrollItemDirective[];
  viewport: CdkVirtualScrollViewport;
  heightCache = new Map<string, ItemHeight>();
  above = 10;
  below = 10;

  scrolledIndexChange$ = new Subject<number>();
  scrolledIndexChange: Observable<number> = this.scrolledIndexChange$.pipe(distinctUntilChanged());

  constructor(public predictor: ItemSizePredictor) {}

  attach(viewport: CdkVirtualScrollViewport): void {
    this.viewport = viewport;

    if (this.items) {
      this.viewport.setTotalContentSize(this.getTotalHeight());
      this.updateRenderedRange();
    }
  }

  detach(): void {
    this.viewport = null;
  }

  onContentScrolled(): void {
    if (this.viewport) {
      this.updateRenderedRange();
    }
  }

  onDataLengthChanged(): void {
    if (!this.viewport) {
      return;
    }

    this.viewport.setTotalContentSize(this.getTotalHeight());
    this.updateRenderedRange();
  }

  onContentRendered(): void {
     /** no-op */
  }

  onRenderedOffsetChanged(): void {
     /** no-op */
  }

  scrollToIndex(index: number, behavior: ScrollBehavior): void {
    if (!this.viewport) {
      return;
    }

    const offset = this.getOffsetByItemId(index);
    this.viewport.scrollToOffset(offset, behavior);
  }

  updateItems(items: VariableItem[]) {
    this.items = items.map((item, index) => ({ ...item, id: item?.id || index.toString() }));

    if (this.viewport) {
      this.viewport.checkViewportSize();
    }
  }

  getItemHeight(item: VariableItem) {
    let height = 0;
    const cachedHeight = this.heightCache.get(item.id);
    if (!cachedHeight) {
      height = this.predictor.getItemHeight(item, this.viewport);
      this.heightCache.set(item.id, { value: height, source: 'predicted' });
    } else {

      height = cachedHeight.value;
    }

    return height;
  }

  measureItemsHeight(items: any[]): number {
    return items.map((item) => this.getItemHeight(item)).reduce((a, c) => a + c, 0);
  }

  getTotalHeight(): number {
    return this.measureItemsHeight(this.items);
  }

  getOffsetByItemId(id: number): number {
    return this.measureItemsHeight(this.items.slice(0, id));
  }

  getItemIndexByOffset(offset: number): number {
    let accumOffset = 0;

    for (let i = 0; i < this.items.length; i++) {
      accumOffset += this.getItemHeight(this.items[i]);;

      if (accumOffset >= offset) {
        return i;
      }
    }

    return 0;
  }

  getItemCountInViewport(startIndex: number): number {
    if (!this.viewport) {
      return 0;
    }

    let totalSize = this.predictor.getHeaderHeight?.() || 0;
    const viewportSize = this.viewport.getViewportSize();

    for (let i = startIndex; i < this.items.length; i++) {
      totalSize += this.getItemHeight(this.items[i]);

      if (totalSize >= viewportSize) {
        return i - startIndex + 1;
      }
    }

    return 0;
  }

  updateHeightCache() {
    if (!this.viewport) {
      return;
    }

    let cacheUpdated = false;
    for (const element of this.elements) {
      const id = element.id;

      if (element?.id) {
        const cachedHeight = this.heightCache.get(id);

        if (!cachedHeight || cachedHeight.source !== 'actual') {
          const height = element.getHeight();

          this.heightCache.set(id, { value: height, source: 'actual' });
          cacheUpdated = true;
        }
      }
    }

    if (cacheUpdated) {
      this.viewport.setTotalContentSize(this.getTotalHeight());
    }
  }

  updateRenderedRange() {
    if (!this.viewport) {
      return;
    }

    const scrollOffset = this.viewport.measureScrollOffset();
    const scrollIndex = this.getItemIndexByOffset(scrollOffset);
    const dataLength = this.viewport.getDataLength();
    const renderedRange = this.viewport.getRenderedRange();
    const range = {
      start: renderedRange.start,
      end: renderedRange.end,
    };

    range.start = Math.max(0, scrollIndex - this.above);
    range.end = Math.min(
      dataLength,
      scrollIndex + this.getItemCountInViewport(scrollIndex) + this.below,
    );

    this.viewport.setRenderedRange(range);
    this.viewport.setRenderedContentOffset(
      this.getOffsetByItemId(range.start),
    );

    this.scrolledIndexChange$.next(scrollIndex);
    this.updateHeightCache();
  }
}

/**
 * @description The interface to predict the height of an item by using its data.
 * This is useful when the height of an item is not fixed and needs to be calculated dynamically.
 * The implementation of this interface should be provided to the `variable-strategy` directive.
 * @template T The type of the item data.
 * @function getItemHeight The function to predicts the height of an item.
 * @param item The item data that can be use to predict the height.
 * @param viewport The viewport of the virtual scroll component.
 * @returns The predicted height of the item in pixels.
 * @example
 * ```typescript
 * class FooTableItemSizePredictor implements ItemSizePredictor<Bar> {
 *  getItemHeight(itemData: Bar): number {
 *    const yPaddings = 32;
 *    const titleHeight = 30;
 *    const buttonHeight = 24;
 *    const buttonCount = itemData.baz.length; // assume that baz is an array of data represents the buttons on UI.
 *    const totalButtonHeight = buttonCount * buttonHeight; // in this case, one button is for one line.
 *
 *    return itemYPaddings + itemTitleHeight + totalButtonHeight;
 *  }
 * }
 * ```
 * @see `VariableVirtualScrollDirective`
 */
export interface ItemSizePredictor<T = any> {
  getItemHeight(item: T, viewport: CdkVirtualScrollViewport): number;
  getHeaderHeight?(): number;
}

class DefaultItemSizePredictor implements ItemSizePredictor {
  getItemHeight(): number {
    return 40;
  }
}

/**
 * @description The directive to provide the actual height of the item to the `VariableVirtualScrollStrategy` strategy.
 * This directive should be used with the `variable-strategy` directive to provide the actual height of each item.
 * @see `VariableVirtualScrollDirective`
 */
@Directive({
  selector: '[variable-item]',
})
export class VariableVirtualScrollItemDirective {
  // The id of the item will be used for managing and caching the height of the item.
  @Input('variable-item') id: string;

  constructor(private el: ElementRef) {}

  public getHeight() {
    return this.el.nativeElement.getBoundingClientRect().height;
  }
}

/**
 * @description The directive to provide variable virtual scroll strategy for the `CdkVirtualScrollViewport` component.
 * This directive should be used with the `variable-item` directive to provide the ids of the item and calculates each item's
 * actual height later.
 * @see `VariableVirtualScrollItemDirective`
 */
@Directive({
  selector: '[variable-strategy]',
  providers: [
    {
      provide: VIRTUAL_SCROLL_STRATEGY,

      useFactory: (d: VariableVirtualScrollDirective) => d.scrollStrategy,
      deps: [forwardRef(() => VariableVirtualScrollDirective)],
    },
  ],
})
export class VariableVirtualScrollDirective {
  /**
   * The list of the `VariableVirtualScrollItemDirective` that will be used to calculate the actual height of each item.
   */
  @Input() set elements(elements: VariableVirtualScrollItemDirective[]) {
    this.scrollStrategy.elements = elements;
  };

  /**
   * The data list of the items that will be used to calculate the predicted heights of the items.
   */
  @Input() set items(items: any[]) {
    this.scrollStrategy.updateItems(items);
  };

  /**
   * The number of items that will be rendered above the current scroll position.
   */
  @Input() set above(value: number) {
    this.scrollStrategy.above = value;
  };

  /**
   * The number of items that will be rendered below the current scroll position.
   */
  @Input() set below(value: number) {
    this.scrollStrategy.below = value;
  };

  /**
   * The predictor to predict the height of an item.
   */
  @Input() set predictor(predictor: ItemSizePredictor) {
    this.scrollStrategy.predictor = predictor;
  };

  scrollStrategy: VariableVirtualScrollStrategy = new VariableVirtualScrollStrategy(new DefaultItemSizePredictor());
}
