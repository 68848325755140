import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ValueAccessorBase } from 'src/app/util';

@Component({
  selector: 'app-role-selector',
  templateUrl: './role-selector.component.html',
  styleUrls: ['./role-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RoleSelectorComponent),
      multi: true,
    },
  ],
})
export class RoleSelectorComponent extends ValueAccessorBase<string[]> {
  readonly roles = ['admin_owner', 'manager', 'user', 'guest'];
  readonly roleNamesMap: { [key: string]: string } = { admin_owner: 'admin', manager: 'manager', user: 'user', guest: 'client' };

  isAllSelected() {
    const numSelected = this.value?.length || 0;
    const numOptions = this.roles.length;
    return numSelected >= numOptions;
  }

  masterToggle() {
    const allSelected = this.isAllSelected();
    if (!allSelected) {
      this.value = [...this.roles];
    } else {
      this.value = [];
    }
  }

  setSelected(selected: boolean, item: string) {

    if (!this.value) {
      this.value = [];
    }
    if (!selected && this.value.includes(item)) {
      this.value = this.value.filter(x => x !== item);
    } else if (selected && !this.value.includes(item)) {
      this.value = [...this.value, item];
    }
  }
  roleSelectedFn(options: string[]) {
    const displayNameMap = {
      admin_owner: 'Admin',
      manager: 'manager',
      user: 'Regular user',
      guest: 'Client',
      '': 'All Regular Users',
    };

    const transformRoles = options.length === 4 ? ['All Access Levels'] : options.map(role => displayNameMap[role]);
    return transformRoles;
  }
}
