import { BehaviorSubject, filter, from, Observable, of, shareReplay, startWith, Subject, switchMap } from 'rxjs';
import { shallowEquals } from 'src/app/util/object-helpers';
import { Approval, Break, IdName, Project, Task, User, UserGroup } from 'src/models';
import { GetApprovalsOptions } from '../approvals/approvals.service';
import { GetBreaksOptions } from '../break/break.service';
import { GroupApiParams } from '../group/group.service';
import { PagedResult } from '../paging/paging.service';
import { GetProjectsOptions, GetTasksOptions } from '../project/project.service';
import type { Modification } from '../resolve.service';
import { UserApiParams } from '../user/user.service';

export interface LazyService<TModel, TOptions = {}> {
  all: (options?: TOptions, skipCache?: boolean) => PagedResult<TModel>;
  search: (search: string, options?: TOptions) => PagedResult<TModel>;
  getDefault?: (options?: TOptions) => Promise<IdName>;
  resolve?: (id: string, options?: TOptions) => Observable<TModel>;
  modify?: (id: string, action: (item: TModel) => TModel) => boolean;
  reset?: () => void;
}

export abstract class BaseLazyService<TModel, TOptions = {}> implements LazyService<TModel, TOptions> {
  protected loadedItems: TModel[];
  protected allItems: PagedResult<TModel>;
  protected cachedOptions: TOptions;
  protected modified$ = new BehaviorSubject<string>(null);
  protected modifications = new Subject<[string, Modification<TModel>]>();

  protected get defaultOptions(): TOptions {
    return {} as TOptions;
  }

  protected abstract getItems(options: TOptions): PagedResult<TModel>;
  protected abstract getSearchedItems: (search: string, options?: TOptions) => PagedResult<TModel>;
  abstract getDefault?: (options?: TOptions) => Promise<IdName>;
  protected abstract resolveInternal: (id: string, options?: TOptions) => Promise<TModel>;

  resolve = (id: string, options?: TOptions): Observable<TModel> => {
    if (!id) return of(null);

    return this.modified$.pipe(
      filter(x => x === id),
      startWith(null),
      switchMap(() => from(this.resolveInternal(id, options))),
      shareReplay(),
    );
  };

  all(options?: TOptions, skipCache = false) {
    if (skipCache || !this.allItems || !shallowEquals(this.cachedOptions, options)) {
      const res = this.getItems(options);
      if (skipCache) { return res; }

      this.loadedItems = null;
      this.allItems = res;

      this.allItems.preallocated().results.subscribe(x => this.loadedItems = x);

      this.cachedOptions = options;
    }

    return this.allItems;
  }

  search(keyword: string, options?: TOptions) {
    const res = this.getSearchedItems(keyword, options);

    this.modifications.subscribe(md => {
      res.preallocated().modify(x => x?.['id'] === md[0], md[1]);
    });
    return res;
  }

  reset = () => {
    this.loadedItems = null;
    this.allItems = null;
    this.cachedOptions = null;
  };

  modify(id: string, action: Modification<TModel>) {
    if (this.loadedItems) {
      const ind = this.loadedItems.findIndex(x => x?.['id'] === id);
      if (ind >= 0) {
        this.loadedItems[ind] = action(this.loadedItems[ind]);
      }
    }

    const modified = !this.allItems ? false : this.allItems.preallocated().modify(x => x?.['id'] === id, action);

    this.modifications.next([id, action]);
    this.modified$.next(id);

    return modified;
  }
}

export interface LazyTaskService extends LazyService<Task, GetTasksOptions> { }
export interface LazyProjectService extends LazyService<Project, GetProjectsOptions> { }
export interface LazyUserService extends LazyService<User, UserApiParams> { }
export interface LazyGroupService extends LazyService<UserGroup, GroupApiParams> { }
export interface LazyBreakService extends LazyService<Break, GetBreaksOptions> { }
export interface LazyApprovalsService extends LazyService<Approval, GetApprovalsOptions> { }
