<ng-container *ngIf="!singleColor">
  <div class="legend-item computer-time">
    <span fxHide.lt-sm>{{ 'timeline.computerTime' | translate }}</span>
    <span fxHide.gt-xs>{{ 'timeline.computer' | translate }}</span>
  </div>

  <div class="legend-item manual-time">
    <span fxHide.lt-sm>{{ 'timeline.manualTime' | translate }}</span>
    <span fxHide.gt-xs>{{ 'timeline.manual' | translate }}</span>
  </div>

  <div class="legend-item mobile-time">
    <span fxHide.lt-sm>{{ 'timeline.mobileTime' | translate }}</span>
    <span fxHide.gt-xs>{{ 'timeline.mobile' | translate }}</span>
  </div>

  <div class="legend-item break-time">
    <span fxHide.lt-sm>{{ 'timeline.breakTime' | translate }}</span>
    <span fxHide.gt-xs>{{ 'timeline.break' | translate }}</span>
  </div>

  <div class="legend-item leave-time" *ngIfFeature="'leave-tracking'"
    [matTooltip]="'timeline.paidLeaveLegendTooltip' | translate" [matTooltipDisabled]="!showOnlyPaidLeaves">
    <span fxHide.lt-sm *ngIf="!showOnlyPaidLeaves">{{ 'timeline.leaveTime' | translate }}</span>
    <span fxHide.gt-xs *ngIf="!showOnlyPaidLeaves">{{ 'timeline.leave' | translate }}</span>
    <span fxHide.lt-sm *ngIf="showOnlyPaidLeaves">{{
      'timeline.paidLeaveTime' | translate }}</span>
    <span fxHide.gt-xs *ngIf="showOnlyPaidLeaves">{{ 'timeline.paidLeave' |
      translate }}</span>
  </div>
</ng-container>

<ng-container *ngIf="singleColor">
  <div class="legend-item computer-time">
    <span fxHide.lt-sm>{{ 'timeline.totalTime' | translate }}</span>
    <span fxHide.gt-xs>{{ 'timeline.total' | translate }}</span>
  </div>
</ng-container>
