<div fxLayout="column" fxLayoutAlign="center center" style="height: 100%">
  <div fxFlex="1 1 auto"></div>

  <div class="icon" *ngIf="icon || svgIcon" [style.width.px]="size" [style.height.px]="size"
    [style.line-height.px]="size">
    <ng-container *ngIf="icon && !svgIcon">
      <mat-icon [style.font-size.px]="iconSize" [style.line-height.px]="iconSize" [style.width.px]="iconSize"
        [style.height.px]="iconSize">{{icon}}</mat-icon>
    </ng-container>
    <ng-container *ngIf="svgIcon">
      <mat-icon [style.font-size.px]="iconSize" [style.line-height.px]="iconSize" [style.width.px]="iconSize"
        [style.height.px]="iconSize" [svgIcon]="svgIcon"></mat-icon>
    </ng-container>
  </div>

  <div class="title">
    <ng-content select="[title]"></ng-content>
  </div>
  <div class="description">
    <ng-content select="[description]"></ng-content>
  </div>
  <div class="action">
    <ng-content select="[action]"></ng-content>
  </div>

  <div fxFlex="2 1 auto"></div>
</div>
