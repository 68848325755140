import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';

import { TopBanner } from 'src/app/pages/new-billing/models/top-banner.model';

@UntilDestroy()
@Component({
  selector: 'app-top-banner',
  templateUrl: './top-banner.component.html',
  styleUrls: ['./top-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopBannerComponent implements OnInit {
  @Input() banner: TopBanner;
  showBanner$ = new BehaviorSubject(false);

  ngOnInit(): void {
    this.initBanner();
  }

  initBanner() {
    const showCondition = this.banner.showCondition();
    if (showCondition instanceof Observable) {
      showCondition.pipe(
        untilDestroyed(this),
      ).subscribe((show) => {
        this.showBanner$.next(show);
      });
    } else {
      this.showBanner$.next(showCondition);
    }
  }

}
