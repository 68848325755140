import { ChangeDetectorRef, Component, ElementRef, HostBinding, HostListener, Inject } from '@angular/core';
import { ITEMS_INJECTION_TOKEN, itemsProvider } from 'src/app/components/nav-sidebar/items-provider';
import { ParentNavSidebarItem } from 'src/app/components/nav-sidebar/models';
import { SegmentService } from 'src/app/services/segment/segment.service';
import { TrialWidgetService } from 'src/app/services/trial-widget/trial-widget.service';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs';


export const noSideBarUrls = [
  '/logout',
  '/new-subscribe-plans',
  '/subscribe-plans',
];

const SM_BREAKPOINT = 960;

@UntilDestroy()
@Component({
  selector: 'app-nav-sidebar',
  templateUrl: './nav-sidebar.component.html',
  styleUrls: ['./nav-sidebar.component.scss'],
  providers: [
    itemsProvider,
  ],
})
export class NavSidebarComponent {
  private _isDesktopCollapsed = false;
  private _isMobileCollapsed = true;

  get canShowTrialWidget() {
    return this.trialWidgetService.canShowTrialWidget();
  }

  @HostBinding('class.collapsed') get collapsed() {
    if (window.innerWidth < SM_BREAKPOINT) return this._isMobileCollapsed;

    return this._isDesktopCollapsed;
  }

  @HostBinding('class.absolute') get absolute() {
    return window.innerWidth < SM_BREAKPOINT;
  }

  @HostListener('window:resize', ['$event']) onResize(): void {
    if (window.innerWidth >= SM_BREAKPOINT) this._isMobileCollapsed = true;
  }

  @HostListener('click', ['$event']) onClick(event: PointerEvent): void {
    if (event.target === this.eRef.nativeElement) {
      this._isMobileCollapsed = true;
    }
  }

  get isDesktopCollapsed(): boolean {
    return this._isDesktopCollapsed;
  }


  constructor(
    @Inject(ITEMS_INJECTION_TOKEN) public items: ParentNavSidebarItem[],
    private eRef: ElementRef<HTMLElement>,
    private trialWidgetService: TrialWidgetService,
    private segmentService: SegmentService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    router.events.pipe(
      filter((event) => event instanceof NavigationEnd
        && !noSideBarUrls.some((url) => event.urlAfterRedirects.includes(url)),
      ),
      // Track sidebar viewed event when the sidebar is not collapsed
      filter(() => !this._isDesktopCollapsed),
      untilDestroyed(this),
    ).subscribe(({ urlAfterRedirects }: NavigationEnd) => {
      segmentService.trackInAppEvents('Viewed Nav Sidebar', {
        path: urlAfterRedirects,
      });
    });
  }

  toggleCollapse(): void {
    this.segmentService.trackInAppEvents('Clicked On the Burger Menu', {
      action: this._isDesktopCollapsed ? 'open sidebar' : 'close sidebar',
    });

    if (window.innerWidth < SM_BREAKPOINT) {
      this._isMobileCollapsed = !this._isMobileCollapsed;
      return;
    }

    this._isDesktopCollapsed = !this._isDesktopCollapsed;
    this.changeDetectorRef.detectChanges();
  }
}
