<div class="search-div" fxLayout>
  <mat-icon>search</mat-icon>
  <input #inputElement fxFlex type="text" [(ngModel)]="text"
    [placeholder]="placeholder || ('common.search' | translate)" (keyup.enter)="enterDown()" (keyup.escape)="clear()"
    [autofocus]="autofocus">
  <button mat-icon-button class="clear-button" *ngIf="(text && showClear) || alwaysShowClear" (click)="clear()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>

