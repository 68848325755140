import { Inject, Injectable, Pipe, PipeTransform } from '@angular/core';
import { Duration, DurationUnit } from 'luxon';
import { GET_CLOCK_FORMAT } from '../../services/app.constants';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'clock' })
export class ClockPipe implements PipeTransform {
  constructor(
    @Inject(GET_CLOCK_FORMAT) private getClockFormat: () => (12 | 24),
  ) { }

  transform(value: number, unit: DurationUnit = 'seconds', format: string = 'hh:mm AA'): string {
    if (!Number.isFinite(value)) {
      return '';
    }

    let dur = Duration.fromObject({ [unit]: value });
    const hours = dur.as('hours');
    const meridianFormat = (this.getClockFormat() === 12) &&
      (hours <= 24 && (format.includes('AA') || format.includes('aa')));
    let pmFormat = false;

    if (meridianFormat) {
      if (hours >= 12) {
        if (hours !== 24) {
          pmFormat = true;
        }
        if (hours >= 13) {
          dur = dur.minus({ hours: 12 });
        }
      } else if (hours < 1) {
        pmFormat = false;
        dur = dur.plus({ hours: 12 });
      }
    }

    const replacedFormat = meridianFormat ?
      format.replace('AA', pmFormat ? `'PM'` : `'AM'`).replace('aa', pmFormat ? `'pm'` : `'am'`) :
      format.replace('AA', '').replace('aa', '');

    return dur.toFormat(replacedFormat).trim();
  }
}
