import { NgModule } from '@angular/core';
import { TopBannerComponent } from 'src/app/components/top-banner/top-banner.component';
import { SharedModule } from 'src/app/modules/shared.module';


@NgModule({
  declarations: [
    TopBannerComponent,
  ],
  imports: [
    SharedModule,
  ],
  exports: [
    TopBannerComponent,
  ],
})
export class TopBannerModule {}
