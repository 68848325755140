import { User } from 'src/models';
import { StatusPayload } from '../auth/auth.actions';


export class ResetManagedUsers {
  static type = '[Manager] Reset Managed Users';
}

export class LoadManagedUsers {
  static type = '[Manager] Load Managed Users';

  constructor(public skipIfAlreadyLoaded?: boolean) { }
}

export class LoadTimezones {
  static type = '[Manager] Load Timezones';

  constructor(public skipIfAlreadyLoaded?: boolean) { }
}

export class LoadUserGroups {
  static type = '[Manager] Load User Groups';
}

export class LoadProjects {
  static type = '[Manager] Load Projects';

  constructor(public skipIfAlreadyLoaded?: boolean) { }
}

export class AddManagedUser {
  static type = '[Manager] Add Managed User';

  constructor(public userId: string) { }
}

export class ManagedUserStatusChanged {
  static readonly type = '[Manager] User Status Changed';
  constructor(public data: StatusPayload) { }
}

export class AddGroupManager {
  static type = '[Manager] Add Group Manager';

  constructor(public groupId: string, public userId: string) { }
}

export class RemoveGroupManager {
  static type = '[Manager] Remove Group Manager';

  constructor(public groupId: string, public userId: string) { }
}

export class AddGroupMember {
  static type = '[Manager] Add Group Member';

  constructor(public groupId: string, public userId: string) { }
}

export class RemoveGroupMember {
  static type = '[Manager] Remove Group Member';

  constructor(public groupId: string, public userId: string) { }
}

export class ManagedUserSettingsChanged {
  static readonly type = '[Manager] User Settings Changed';
  constructor(
    public userId: string,
    public changes: Partial<User>,
  ) { }
}

export class EditUserProperties {
  static readonly type = '[ManageUsers] Edit User Properties';
  constructor(public userId: string, public properties: Partial<User>) { }
}
