import { RouterLink, UrlCreationOptions } from '@angular/router';
import { Injector } from '@angular/core';

export interface NavSidebarItem extends UrlCreationOptions {
  label: string;
  routerLink: RouterLink['routerLink'];
  isVisible: boolean;
  icon?: string;
  isParent?: boolean;
}

type ParentConfig = UrlCreationOptions & {
  label: string;
  icon: string;
  isVisible?: (injector: Injector) => boolean;
};

type ChildConfig = UrlCreationOptions & {
  parent: string;
  label: string;
  isVisible?: (injector: Injector) => boolean;
};

export abstract class ParentNavSidebarItem implements NavSidebarItem {
  label: string;
  routerLink: string;
  isVisible: boolean;
  icon?: string;
  isParent = true;

  abstract get children(): NavSidebarItem[];

  constructor(routerLink: string, protected injector: Injector, {
    label,
    icon,
    isVisible = () => true,
  }: ParentConfig) {
    this.routerLink = routerLink;
    this.label = label;
    this.isVisible = isVisible(injector);
    this.icon = icon;
    this.isParent = true;
  }
}

export class ChildNavSidebarItem implements NavSidebarItem {
  label: string;
  routerLink: RouterLink['routerLink'];
  isVisible: boolean;
  isParent = false;

  constructor(routerLink: string, injector: Injector, {
    parent,
    label,
    isVisible = () => true,
  }: ChildConfig) {
    this.label = label;
    this.routerLink = [parent, routerLink];
    this.isVisible = isVisible(injector);
  }
}
