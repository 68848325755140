import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateDefaultParser, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MessageFormatConfig } from 'ngx-translate-messageformat-compiler';
import { of } from 'rxjs';
import enTranslation from 'src/assets/i18n/en.json';

export function createTranslateLoader(http: HttpClient, baseHref: string): TranslateLoader {
  const loader = {
    getTranslation: (lang: string) => {
      if (lang === 'en') { return of(enTranslation); }
      return httpLoader.getTranslation(lang);
    },
  };

  const httpLoader = new TranslateHttpLoader(http, `${baseHref}assets/i18n/`, '.json');

  return loader;
}

function escapeHtml(unsafe: string) {
  return unsafe
    // .replace(/"/g, '&quot;')
    // .replace(/'/g, '&#x27;')
    // .replace(/\//g, '&#x2F;')
    // .replace(/&/g, '&amp;')
    .replace(/>/g, '&gt;')
    .replace(/</g, '&lt;');
}

@Injectable()
export class CustomTranslateParser extends TranslateDefaultParser {
  constructor() {
    super();
  }

  override interpolate(expr: string | Function, params?: any): string {
    if (params) {
      params = { ...params };
      for (const key in params) {
        if (Object.prototype.hasOwnProperty.call(params, key)) {
          const value = params[key];
          if (typeof value === 'string') params[key] = escapeHtml(value);

        }
      }
    }

    return super.interpolate(expr, params);
  }
}

export function createMessageFormatConfig() {
  return {
    formatters: { sanitize: v => escapeHtml(String(v)) },
  } as MessageFormatConfig;
}
