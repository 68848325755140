import { Directive, forwardRef, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, Validators } from '@angular/forms';

@Directive({
  selector: 'input[min][formControlName],input[min][formControl],input[min][ngModel]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => MinValidator),
    multi: true,
  }],
})
export class MinValidator implements Validator {
  @Input() min: number;

  validate(control: AbstractControl) {
    return Validators.min(this.min)(control);
  }
}

@Directive({
  selector: 'input[max][formControlName],input[max][formControl],input[max][ngModel]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => MaxValidator),
    multi: true,
  }],
})
export class MaxValidator implements Validator {
  @Input() max: number;

  validate(control: AbstractControl) {
    return Validators.max(this.max)(control);
  }
}
