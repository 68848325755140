import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { Duration, DurationUnit } from 'luxon';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'duration' })
export class DurationPipe implements PipeTransform {
  transform(value: number, unit: DurationUnit = 'seconds', format: string = 'hh:mm:ss'): string {
    if (!Number.isFinite(value)) {
      return '';
    }
    return Duration.fromObject({ [unit]: value }).toFormat(format);
  }
}
