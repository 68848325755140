import { Injectable } from '@angular/core';
import { SidebarItem } from '../sidebar-item';


@Injectable()
export class SetupGroupsStep implements SidebarItem {
  id = 'setupGroups';
  completed = false;
  link = '/manage-user-groups';
}
