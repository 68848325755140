import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ClipboardService } from 'src/app/services/clipboard/clipboard.service';


type Mode = {
  icon: string;
  btnLabel: string;
  successMsg: string;
  errorMsg: string;
};

const modes: Record<'link' | 'terminal' | 'instructions', Mode> = {
  link: {
    icon: 'link',
    btnLabel: 'common.copyLink',
    successMsg: 'common.linkCopied.success',
    errorMsg: 'common.linkCopied.error',
  },
  terminal: {
    icon: 'terminal',
    btnLabel: 'common.copy',
    successMsg: 'downloads.codeCopied.success',
    errorMsg: 'downloads.codeCopied.error',
  },
  instructions: {
    icon: 'content_copy',
    btnLabel: 'invite.inviteByLink.copyInstructionsBtn',
    successMsg: 'invite.inviteByLink.instructionsCopied.success',
    errorMsg: 'invite.inviteByLink.instructionsCopied.error',
  },
};

@Component({
  selector: 'app-copy-to-clipboard-field',
  templateUrl: './copy-to-clipboard-field.component.html',
  styleUrls: ['./copy-to-clipboard-field.component.scss'],
})
export class CopyToClipboardFieldComponent implements OnInit {
  @ViewChild('instructionsList', { static: false }) instructionsList: ElementRef<HTMLOListElement>;

  /** The icon to display in the field. Defaults to `link` */
  @Input() icon = 'link';

  /** The label to display in the button. Defaults to `common.copyLink` */
  @Input() btnLabel = 'common.copyLink';

  /**
   * The value that goes in the field and will be copied to the clipboard.
   *
   * When {@link mode} is set to `instructions`, the value will be the instructions to copy and
   * this value generally takes a valid HTML content.
   * */
  @Input() value: string;
  @Input() successMsg: string;
  @Input() errorMsg: string;

  /**
   * The mode to display the field in.
   * It will determine the icon, button label, success message and error message.
   *
   * When set, it will override the `icon`, `btnLabel`, `successMsg` and `errorMsg` inputs.
   * When `mode` is set to `link`, the `icon` will be `link`, the `btnLabel` will be "Copy link".
   * When `mode` is set to `terminal`, the `icon` will be `terminal`, the `btnLabel` will be "Copy".
   */
  @Input()
  set mode(value: keyof typeof modes) {
    this._mode = value;
    const { icon, btnLabel, successMsg, errorMsg } = modes[value];

    this.icon = icon;
    this.btnLabel = btnLabel;
    this.successMsg = successMsg;
    this.errorMsg = errorMsg;
  }

  get mode() {
    return this._mode;
  }

  private _mode: keyof typeof modes;


  constructor(
    private clipboardService: ClipboardService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
  }

  public async handleCopyLink() {
    const { success } = await this.clipboardService.copyToClipboard(this.value);
    const message = success
      ? this.translateService.instant(this.successMsg)
      : this.translateService.instant(this.errorMsg);

    this.openSnackBar(message);
  }

  public async handleCopyInstructions() {
    const htmlToCopy = this.instructionsList.nativeElement.outerHTML;
    const textToCopy = this.instructionsList.nativeElement.outerText;
    const { success } = await this.clipboardService.copyToClipboard({
      'text/plain': new Blob([textToCopy], { type: 'text/plain' }),
      'text/html': new Blob([htmlToCopy], { type: 'text/html' }),
    });

    const message = success
      ? this.translateService.instant(this.successMsg)
      : this.translateService.instant(this.errorMsg);

    this.openSnackBar(message);
  }

  private openSnackBar(message: string) {
    this.snackBar.open(message, null, {
      duration: 3000,
      horizontalPosition: 'start',
      verticalPosition: 'bottom',
    });
  }
}
