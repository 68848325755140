import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { EMPTY, Subject, timer } from 'rxjs';
import { delay, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { isTrialOfferBannerVisible } from 'src/app/components/trial-promotion-offer/banner/banner.component';
import { isTrialOfferPromotionOpen } from 'src/app/components/trial-promotion-offer/offer-popup/offer-popup.component';
import { CountdownStateModel, noPromoUrls, TrialPromotionOfferService } from '../service/trial-promotion-offer.service';
import {
  CheckTrackedTimeUser, HidePromoBanner, HidePromoPopup, ShowPromoBanner, ShowPromoPopup
} from './trial-offer-promotion.actions';

@State<CountdownStateModel>({
  name: 'countdown',
  defaults: {
    userCount: 0,
    usersWhoTrackedTime: 0,
    startTime: 0,
  },
})

@UntilDestroy()
@Injectable()
export class CountdownState implements OnDestroy {
  private stopOffer$ = new Subject<void>();
  constructor(
    private store: Store,
    private router: Router,
    private trialPromotionOfferService: TrialPromotionOfferService,
  ) {
    this.showOfferOnRouteChanges();
  }

  // Listen to route changes and restart the timer
  private showOfferOnRouteChanges() {
    this.router.events.pipe(
      delay(3000),
      filter(event => event instanceof NavigationEnd && !noPromoUrls.some((url) => event.urlAfterRedirects.includes(url))),
      untilDestroyed(this),
    ).subscribe((event) => {
      this.store.dispatch(new ShowPromoPopup());
    });
  }

  @Selector()
  static userCount(state: CountdownStateModel): boolean {
    return state.userCount >= 2;
  }

  @Selector()
  static usersTrackingTime(state: CountdownStateModel): boolean {
    return state.usersWhoTrackedTime >= 2;
  }

  @Action(CheckTrackedTimeUser)
  async checkTrackedTimeUser(ctx: StateContext<CountdownStateModel>) {
    const usersTrackingTime  = await this.trialPromotionOfferService.usersTrackingTime();
    ctx.patchState({
      usersWhoTrackedTime: usersTrackingTime,
      userCount: this.trialPromotionOfferService.company?.userCount,
    });
  }

  @Action(ShowPromoPopup)
startOfferCheck(ctx: StateContext<CountdownStateModel>, action: ShowPromoPopup) {
  const INTERVAL_MS = 1000; // Define the interval time as a constant

  this.trialPromotionOfferService.expFeatureSubject$.pipe(
    filter((expFeature) => expFeature?.variationid === 'B'),
    tap(() => this.trialPromotionOfferService.addTimeLimitedPromotionSettings()),
    switchMap(() => {
      const countdownTimer = this.trialPromotionOfferService.expFeatureSettings?.countdownTimerTime || 0;
      let elapsedTime = 0; // Reset elapsed time initially
      return timer(0, INTERVAL_MS).pipe(
        // eslint-disable-next-line rxjs/no-unsafe-takeuntil
        takeUntil(this.stopOffer$),
        tap(() => {
          const currentRoute = this.router.url.split('?')[0].replace('/', '');
          const isInNoPromoUrls = noPromoUrls.includes(currentRoute);

          // Increment elapsed time only if not in noPromoUrls
          if (!isInNoPromoUrls) {
            elapsedTime += INTERVAL_MS;
          } else {
            // Reset elapsed time if in noPromoUrls
            elapsedTime = 0;
          }
        }),
        switchMap(() => {
          const currentRoute = this.router.url.split('?')[0].replace('/', '');
          const isInNoPromoUrls = noPromoUrls.includes(currentRoute);

          if (!isInNoPromoUrls) {
            return this.trialPromotionOfferService.shouldShowTimeLimitedPromotionOffer('isTimeLimitedPromotionPopUpVisible').pipe(
              filter(isEligible => isEligible && elapsedTime >= countdownTimer),
              tap(() => {
                this.store.dispatch(new CheckTrackedTimeUser());
                this.displayPromoPopup();
                this.stopOffer$.next();
              }),
            );
          }

          return EMPTY;
        }),
      );
    }),
    untilDestroyed(this),
  ).subscribe();
}




  // Action to stop the timer
  @Action(HidePromoPopup)
  stopOfferCheck() {
    this.stopOffer$.next();
    this.trialPromotionOfferService.updateTrialPromotionSettings(false, 'isTimeLimitedPromotionPopUpVisible');
  }

  ngOnDestroy() {
    this.stopOffer$.next();
    this.stopOffer$.complete();
  }

  @Action(ShowPromoBanner)
  ShowPromoBanner() {
    isTrialOfferBannerVisible.next(this.trialPromotionOfferService.isTrialPromoEnabled());
  }

  // Banner actions
  @Action(HidePromoBanner)
  hidePromoBanner() {
    isTrialOfferBannerVisible.next(false);
    this.trialPromotionOfferService.updateTrialPromotionSettings(false, 'isTimeLimitedPromotionBannerVisible');
  }

  // Helper method to display the promo popup
  private displayPromoPopup() {
    isTrialOfferPromotionOpen.next({ isOpen: true, source: 'timer' });
    isTrialOfferBannerVisible.next(this.trialPromotionOfferService.isTrialPromoEnabled());
    this.stopOffer$.next();
  }
}
