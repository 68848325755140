import { Directive, ElementRef, Input, OnDestroy } from '@angular/core';

@Directive({
  selector: '[skipNavigation][routerLink]',
})
export class SkipNavigationDirective implements OnDestroy {

  @Input() public skipNavigation = false;

  onClick = (event: MouseEvent) => {
    if (this.skipNavigation) {
      event.stopPropagation();
      event.stopImmediatePropagation();
      event.preventDefault();
      return false;
    }
  };

  constructor(private ref: ElementRef<HTMLElement>) {
    ref.nativeElement.addEventListener('click', this.onClick, true);
  }

  public ngOnDestroy(): void {
    this.ref.nativeElement.removeEventListener('click', this.onClick, true);
  }
}
