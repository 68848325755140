import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SetCheckoutSuccessInfo } from './actions';
import { NewSubscribePlansStateModel } from './model';

@Injectable()
@State<NewSubscribePlansStateModel>({
  name: 'newSubscribePlans',
})
export class NewSubscribePlansState {
  @Selector()
  static checkoutSuccessInfo(state: NewSubscribePlansStateModel) {
    return state.checkoutSuccessInfo;
  }

  @Action(SetCheckoutSuccessInfo)
  setCheckoutSuccessInfo(ctx: StateContext<NewSubscribePlansStateModel>, { data }: SetCheckoutSuccessInfo) {
    ctx.patchState({ checkoutSuccessInfo: data });
  }
}
