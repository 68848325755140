export const onboardingPages = {
  addCardStep: 'Special Offer',
  companyInfoStep: 'CompanyName and Size',
  companyInfo2Step: 'Pain Point and Number',
  trackingModeStep: 'Account Setup Visibility',
  featuresStep: 'Account Setup features',
  enableExtensionStep: 'Account Setup Integrations',
  inviteCountStep: 'Invite Employees part 1',
  sendInvitesStep: 'Invite Employees part 2',
};
