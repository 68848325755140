import { AfterViewInit, Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[cdkFixedFooter]',
})

export class FixedFooterDirective implements AfterViewInit {
  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngAfterViewInit() {
    const cdkViewport: HTMLElement = this.el.nativeElement.closest('cdk-virtual-scroll-viewport');
    const wrapper: HTMLElement = cdkViewport.querySelector('.cdk-virtual-scroll-content-wrapper');
    const spacer: HTMLElement = cdkViewport.querySelector('.cdk-virtual-scroll-spacer');

    const spacerDiv = this.renderer.createElement('div');
    this.renderer.setStyle(spacerDiv, 'flex', '0 0 100%');
    spacer.append(spacerDiv);

    const ntv = this.el.nativeElement;
    this.renderer.setStyle(ntv, 'bottom', '0px');
    this.renderer.setStyle(ntv, 'position', 'sticky');
    this.renderer.setStyle(ntv, 'position', '-webkit-sticky');
    this.renderer.setStyle(ntv, 'z-index', '1000');
    this.renderer.setStyle(ntv, 'pointer-events', 'all');
    this.renderer.setStyle(ntv, 'flex', '0 0 auto');
    spacer.append(ntv);

    this.renderer.setStyle(wrapper, 'position', 'relative');
    this.renderer.setStyle(wrapper, 'contain', 'initial');
  }
}
