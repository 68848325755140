import { ChangeDetectorRef, Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';


/**
 * How to use this directive?
 *
 * ```
 * <div *ngIfMediaQuery="'(min-width: 500px)'">
 *     Div element will exist only when media query matches, and created/destroyed when the viewport size changes.
 * </div>
 * ```
 */
@Directive({
  selector: '[ngIfMediaQuery]',
  // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
  inputs: ['ngIfMediaQuery'],
})
export class NgIfMediaQueryDirective implements OnDestroy {
  private prevCondition: boolean = null;

  private mql: MediaQueryList;
  private mqlListener: (mql: MediaQueryListEvent) => void;   // reference kept for cleaning up in ngOnDestroy()

  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<object>,
    private cd: ChangeDetectorRef,
  ) { }

  /**
   * Called whenever the media query input value changes.
   */
  set ngIfMediaQuery(newMediaQuery: string) {
    if (!this.mql) {
      this.mql = window.matchMedia(newMediaQuery);

      /* Register for future events */
      this.mqlListener = (mq) => {
        this.onMediaMatchChange(mq.matches);
      };
      this.mql.addEventListener('change', this.mqlListener);
    }

    this.onMediaMatchChange(this.mql.matches);
  }

  elseTemplate: TemplateRef<any>;
  @Input() set ngIfMediaQueryElse(val: TemplateRef<any>) {
    if (this.elseTemplate !== val) {
      this.prevCondition = null;
      this.elseTemplate = val;
      this.onMediaMatchChange(this.mql.matches);
    }
  }

  ngOnDestroy() {
    this.mql.removeEventListener('change', this.mqlListener);
    this.mql = this.mqlListener = null;
  }

  private onMediaMatchChange(matches: boolean) {
    // this has been taken verbatim from NgIf implementation
    if (matches && this.prevCondition !== true) {
      this.prevCondition = true;
      this.viewContainer.clear();
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.cd.detectChanges();
    } else if (!matches && this.prevCondition !== false) {
      this.prevCondition = false;

      this.viewContainer.clear();
      if (this.elseTemplate) {
        this.viewContainer.createEmbeddedView(this.elseTemplate);
      }
      this.cd.detectChanges();
    }
  }
}
