
/**
 *  This selection model has ability to represent a list where all items are selected without knowing what the items are
 *  It is also immutable. Meaning every time you make a selection, a new instance will be created.
 * If allItems are set, the selection will validate itself and the class will provide other usefull helpers.
 */
export class LazySelectionModel {
  static readonly all = new LazySelectionModel([], true);
  static readonly none = new LazySelectionModel([]);

  private selectionMap: Set<string>;
  private allSelected = false;
  private allItems: Set<string>;
  private allCount: number;

  static diff(oldSelection: LazySelectionModel, newSelection: LazySelectionModel) {
    const added: string[] = [];
    const removed: string[] = [];

    for (const item of newSelection.list) {
      if (!oldSelection.isSelected(item)) {
        added.push(item);
      }
    }

    for (const item of oldSelection.list) {
      if (!newSelection.isSelected(item)) {
        removed.push(item);
      }
    }

    return { added, removed };
  }

  static same(oldSelection: LazySelectionModel, newSelection: LazySelectionModel) {
    if (!(oldSelection && newSelection)) { return false; }
    if (oldSelection.all !== newSelection.all) { return false; }

    for (const item of newSelection.list) {
      if (!oldSelection.isSelected(item)) {
        return false;
      }
    }

    for (const item of oldSelection.list) {
      if (!newSelection.isSelected(item)) {
        return false;
      }
    }

    return true;
  }

  constructor(items: string[], all?: boolean, allItems?: string[] | Set<string>, allCount?: number) {
    this.allSelected = all;

    if (allItems) {
      this.allItems = allItems instanceof Set ? allItems : new Set(allItems);

      if (this.allSelected) {
        this.selectionMap = new Set(allItems);
      } else {
        this.selectionMap = new Set(items.filter(x => this.allItems.has(x)));
        const arr = Array.from(this.allItems);
        this.allSelected = all || (arr.length > 0 && arr.every(x => this.selectionMap.has(x)));
      }

      this.allCount = this.allItems.size;
    } else {
      this.selectionMap = new Set(items);
      this.allCount = allCount;
    }
  }

  setAllItems(allItems: string[] | Set<string>, allCount?: number) {
    return new LazySelectionModel(this.list, this.all, allItems, allCount);
  }

  select(...ids: string[]) {
    if (this.all && ids.length) {
      throw new Error('Individual items cannot be selected when all items are selected');
    }
    return new LazySelectionModel([...this.list, ...ids], this.all, this.allItems, this.allCount);
  }

  deselect(...ids: string[]) {
    if (this.all && ids.length && !this.allItems) {
      throw new Error('Individual items cannot be deselected when all are selected and allItems are not assigned');
    }
    const idMap = new Set(ids);
    return new LazySelectionModel(this.list.filter(x => !idMap.has(x)), false, this.allItems, this.allCount);
  }

  toggle(id: string) {
    if (this.isSelected(id)) {
      return this.deselect(id);
    } else {
      return this.select(id);
    }
  }

  set(selected: boolean, ...ids: string[]) {
    return selected ? this.select(...ids) : this.deselect(...ids);
  }

  isSelected(id: string): boolean {
    return this.allSelected || this.selectionMap.has(id) || false;
  }

  clear() {
    return new LazySelectionModel([], false, this.allItems, this.allCount);
  }

  selectAll() {
    return new LazySelectionModel([], true, this.allItems, this.allCount);
  }

  get list() {
    if (this.all && this.allItems) {
      return Array.from(this.allItems);
    }
    return Array.from(this.selectionMap);
  }

  get count() {
    return this.all ? this.countAll : this.selectionMap.size;
  }

  get countAll() {
    return this.allItems?.size ?? this.allCount;
  }

  get any() {
    return this.all || this.count > 0;
  }

  get all() {
    return this.allSelected;
  }

  get indeterminate() {
    return this.any && !this.all;
  }

  toString() {
    return this.list.join(',');
  }
}
