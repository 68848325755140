import { DecimalPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'large-number-text',
  templateUrl: './large-number-text.component.html',
  styleUrls: ['./large-number-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LargeNumberTextComponent {
  @Input() value: number;
  @Input() tooltip: string;
  @Input() suffix = '';
  @Input() prefix = '';
  thousandDivision = 1000;
  millionDivision = 1000000;

  constructor(private decimalPipe: DecimalPipe) { }

  get isInThousands() {
    return this.value >= this.thousandDivision;
  }

  get isInMillions() {
    return this.value >= this.millionDivision;
  }

  getFormattedValue(value: number) {
    if (this.isInMillions) {
      return this.decimalPipe.transform(value / this.millionDivision, '1.0-1') + 'M';
    }

    if (this.isInThousands) {
      return this.decimalPipe.transform(value / this.thousandDivision, '1.0-1') + 'K';
    }

    return value;
  }
}
