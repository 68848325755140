// Temporary fix defined in: https://github.com/angular/components/issues/10117#issuecomment-447373986
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AfterViewInit, Directive, HostListener, Inject, OnDestroy, Self } from '@angular/core';
import ResizeSensor from 'css-element-queries/src/ResizeSensor';
import { animationFrameScheduler, Subject } from 'rxjs';
import { debounceTime, sampleTime, takeUntil } from 'rxjs/operators';


@Directive({
  selector: 'cdk-virtual-scroll-viewport:not([noresize])',
})
export class CdkVirtualScrollResizePatchDirective implements OnDestroy, AfterViewInit {
  protected readonly destroy$ = new Subject();
  private sensor: ResizeSensor;

  constructor(
    @Self() @Inject(CdkVirtualScrollViewport) private readonly viewportComponent: CdkVirtualScrollViewport,
  ) { }

  // Rerender when the virtual scroll is first added to DOM hack - https://stackoverflow.com/a/24095217/2346893
  @HostListener('animationstart', ['$event'])
  animationStart(ev: AnimationEvent) {
    if (ev.animationName === 'cdkVirtualScrollStart' && ev.target === this.viewportComponent.elementRef.nativeElement) {
      this.viewportComponent.checkViewportSize();
    }
  }

  ngAfterViewInit() {
    const resizeSubject = new Subject();
    this.sensor = new ResizeSensor(this.viewportComponent.elementRef.nativeElement, () => resizeSubject.next(null));

    resizeSubject.pipe(
      debounceTime(100),
      sampleTime(0, animationFrameScheduler),
      takeUntil(this.destroy$),
    ).subscribe(() => this.viewportComponent.checkViewportSize());
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
    if (this.sensor) {
      this.sensor.detach();
    }
  }
}
