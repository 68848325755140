import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-export-doc-button',
  templateUrl: './component.html',
  styleUrls: ['./component.scss'],
})
export class ExportDocButtonComponent {
  @Output() export = new EventEmitter<'pdf' | 'png'>();
}
