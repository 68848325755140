export const EMAIL_REGEXP = new RegExp(
  '^(([^<>()[\\]\\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\\.,;:\\s@"]+)*)'
  + '|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.'
  + '[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
  'i',
);

export const NAME_REGEXP = new RegExp('^[\u00c0-\u01ffa-zA-Z\\d\'. -]{1,32}$', 'i');

export const PASSWORD_REGEXP =
  new RegExp('^(?=\\S*[a-z])(?=\\S*[A-Z])(?=\\S*[0-9])(?=\\S*[\-`:!"£$%^&*()\[_+{}=\-@~#\';\\]<>?/.,|\\\\])(?=.{8,})\\S*$');

export const OTP_REGEXP = new RegExp('^[A-Za-z0-9]{3}-[A-Za-z0-9]{3}$');

export function cleanInvisibleCharacters(value: string) {
  return (value || '').replace(/[\u200B-\u200D\uFEFF\u2028\u2029]/g, '').trim();
}
