<button class="ellipsis" mat-button [matMenuTriggerFor]="menu" fxFlex (menuOpened)="menuOpened()"
  [disabled]="disabledForUpsell" [ngClass]="{'disabled-for-upsell': disabledForUpsell}"
  [matTooltip]="'common.upsellTooltip' | translate" [matTooltipDisabled]="!disabledForUpsell">
  <span fxLayout fxLayoutAlign="space-between center" fxFlex>
    <span fxFlex class="ellipsis">
      {{
      (showSingleUser && (hasSingleUser$ | async)) ? (hasSingleUser$ | async)?.name :
      (tempValue?.type === 'groups' ?
      (tempValue?.noTag ? 'controls.membersOfNoGroup' : (tempValue?.multipleTag ?
      'controls.membersOfMultipleGroups' :
      'controls.groupsSelected')) :
      'controls.usersSelected'
      ) | translate:{count: tempValue?.count || 0, all: tempValue?.all}
      }}
    </span>
    <mat-icon style="margin-left: 6px;" fxFlex="0 0 auto">keyboard_arrow_down</mat-icon>
  </span>
</button>


<mat-menu #menu="matMenu" class="dropdown-menu" (closed)="menuClosed()">
  <ng-container *ngIf="showGroups && showUsers; else flatView">
    <mat-tab-group mat-align-tabs="center" [selectedIndex]="tempValue?.type === 'groups' ? 1 : 0" animationDuration="0"
      (selectedTabChange)="typeChanged($event)" (click)="$event.stopPropagation()">
      <mat-tab [label]="'common.users' | translate"></mat-tab>
      <mat-tab [label]="'common.groups' | translate"></mat-tab>
    </mat-tab-group>

    <ng-container *ngIf="tempValue?.type === 'groups'; else userTab">
      <ng-container *ngTemplateOutlet="groupTab"></ng-container>
    </ng-container>
  </ng-container>
</mat-menu>

<ng-template #flatView>
  <ng-container *ngTemplateOutlet="showUsers ? userTab : groupTab"></ng-container>
</ng-template>

<ng-template #userTab>
  <app-search [loading]="searchLoading$ | async" [ngModel]="filter$" rxModel stopPropagation></app-search>

  <ng-container *ngLet="(usersToShow$ | async) as users">
    <ng-container *ngIf="users?.length !== 0; else noUsers">

      <ng-container *ngIfFeature="'soft-delete-user'">
        <mat-checkbox class="column-checkbox all-checkbox" (click)="$event.stopPropagation()" color="primary"
          (change)="setIncludeArchivedUsers($event.checked, users)" [checked]="tempValue.includeArchivedUsers" *ngIf="showArchivedUsers">
          <truncated-text [text]="'controls.includeArchivedUsers' | translate"></truncated-text>
        </mat-checkbox>
      </ng-container>

      <mat-checkbox class="column-checkbox all-checkbox" (click)="$event.stopPropagation()" color="primary"
        (change)="masterToggle($event.checked)" [checked]="tempValue.users.all"
        [indeterminate]="tempValue.users.indeterminate">
        <strong>{{ 'controls.allUsers' | translate }}</strong>
      </mat-checkbox>

      <ng-container *ngIf="!tempValue.users.all">
        <mat-divider></mat-divider>

        <cdk-virtual-scroll-viewport #userVs [itemSize]="40" [style.height.px]="(users?.length || 0) * 40"
          minBufferPx="200" maxBufferPx="400">
          <mat-checkbox class="column-checkbox" color="primary" *cdkVirtualFor="let user of (users || [])"
            (click)="$event.stopPropagation()" (change)="user && setSelected($event.checked, user.id)"
            [checked]="tempValue.users.all || (user && tempValue.users.isSelected(user.id))">

            <ng-container *ngIf="user; else skeleton">
              <truncated-text [text]="user.name"></truncated-text>
            </ng-container>
          </mat-checkbox>
        </cdk-virtual-scroll-viewport>
      </ng-container>
    </ng-container>

    <ng-template #noUsers>
      <div class="no-groups">
        {{ 'common.noUsersFound' | translate }}
      </div>
    </ng-template>
  </ng-container>
</ng-template>


<ng-template #groupTab>
  <app-search [loading]="searchLoading$ | async" [ngModel]="filter$" rxModel stopPropagation></app-search>

  <ng-container *ngIf="(groupsToShow$ | async) as groups">
    <ng-container *ngIf="groups.length; else noGroups">

      <ng-container *ngIfFeature="'soft-delete-user'">
        <mat-checkbox class="column-checkbox all-checkbox" (click)="$event.stopPropagation()" color="primary"
          (change)="setIncludeArchivedUsers($event.checked)" [checked]="tempValue.includeArchivedUsers" *ngIf="showArchivedUsers">
          <truncated-text [text]="'controls.includeArchivedUsers' | translate"></truncated-text>
        </mat-checkbox>
      </ng-container>

      <mat-checkbox class="column-checkbox all-checkbox" (click)="$event.stopPropagation()" color="primary"
        (change)="masterToggle($event.checked)" [checked]="tempValue.groups.all"
        [trackClick]="{ name: 'Pulldown User Selection', selectedoption: { value: 'All Groups' }}"
        [trackingDisabled]="tempValue.groups.all">
        <strong>{{ 'controls.allGroups' | translate }}</strong>
      </mat-checkbox>

      <mat-checkbox class="column-checkbox all-checkbox" (click)="$event.stopPropagation()" color="primary"
        (change)="setNoTag($event.checked)" [checked]="tempValue.noTag" *ngIf="showMembersOfNoGroup"
        [trackClick]="{ name: 'Pulldown User Selection', selectedoption: { value: 'Members of no group' }}"
        [trackingDisabled]="tempValue.noTag">
        <truncated-text [text]="'controls.membersOfNoGroup' | translate"></truncated-text>
      </mat-checkbox>

      <mat-checkbox class="column-checkbox all-checkbox" (click)="$event.stopPropagation()" color="primary"
        (change)="setMultipleTag($event.checked)" [checked]="tempValue.multipleTag" *ngIf="showMembersOfMultipleGroups"
        [trackClick]="{ name: 'Pulldown User Selection', selectedoption: { value: 'Members of >1 group' }}"
        [trackingDisabled]="tempValue.multipleTag">
        <truncated-text [text]="'controls.membersOfMultipleGroups' | translate"></truncated-text>
      </mat-checkbox>

      <mat-divider></mat-divider>

      <cdk-virtual-scroll-viewport #groupVs [itemSize]="40" minBufferPx="200" maxBufferPx="400"
        [style.height.px]="groups.length * 40">
        <mat-checkbox *cdkVirtualFor="let group of groups" class="column-checkbox" (click)="$event.stopPropagation()"
          color="primary" (change)="setSelected($event.checked, group.id)"
          [checked]="tempValue.groups.isSelected(group.id)"
          [trackClick]="{ name: 'Pulldown User Selection', selectedoption: { value: group?.name }}"
          [trackingDisabled]="tempValue.groups.isSelected(group.id)">
          <truncated-text [text]="group.name"></truncated-text>
        </mat-checkbox>
      </cdk-virtual-scroll-viewport>
    </ng-container>

    <ng-template #noGroups>
      <div class="no-groups">
        {{ ((filter$ | async) ? 'common.noGroupsFound' : noGroupsTranslate) | translate }}
      </div>
    </ng-template>
  </ng-container>
</ng-template>

<ng-template #skeleton>
  <div class="skeleton">Placeholder</div>
</ng-template>
