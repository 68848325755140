import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

interface ProgressData {
  value: number;
  type: 'export' | 'import';
}

@Component({
  templateUrl: './index.html',
  styleUrls: ['./index.scss'],
})
export class ExportProgressDialogComponent {
  data: ProgressData;
  constructor(@Inject(MAT_DIALOG_DATA) data: ProgressData) {
    this.data = data;
  }
}
