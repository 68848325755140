import { Injectable } from '@angular/core';
import { State } from '@ngxs/store';

export interface PreferencesStateModel { }

@State<PreferencesStateModel>({
  name: 'preferences',
  defaults: {},
})
@Injectable()
export class PreferencesState { }
