import { Component, ContentChild, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ValueAccessorBase } from 'src/app/util';

@Component({
  selector: 'app-threshold-menu',
  templateUrl: './threshold-menu.component.html',
  styleUrls: ['./threshold-menu.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ThresholdMenuComponent),
      multi: true,
    },
  ],
})
export class ThresholdMenuComponent extends ValueAccessorBase<string> {

  @ContentChild('triggerTemplate') triggerTemplate;

  @Input() label: string;
  @Input() triggerText: string;
  @Input() triggerClass = '';
  @Input() min?: number;
  @Input() max?: number;
  @Input() step?: number;
  @Input() type?: string;

  setValue(value: string) {
    this.value = this.type === 'number' ? this.getClampedValue(value) : value;
  }

  getClampedValue(value) {
    if (typeof this.min !== 'undefined' && value < this.min) {
      return this.min;
    }

    if (typeof this.max !== 'undefined' && value > this.max) {
      return this.max;
    }

    return value;
  }
}
