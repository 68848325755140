import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';

export const gzipHeader = { 'Content-Encoding': 'gzip' };

@Injectable()
export class HttpGzipInterceptorService implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isGzip = req.headers.get('Content-Encoding') === 'gzip';

    if (isGzip) {
      return from(import('pako')).pipe(concatMap(pako => {
        const compressedBody = pako.gzip(JSON.stringify(req.body));
        const newReq = req.clone({
          body: compressedBody.buffer,
          setHeaders: { 'Content-Type': 'application/octet-stream' },
        });

        return next.handle(newReq);
      }));
    } else {
      return next.handle(req);
    }
  }
}

export const httpGzipInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: HttpGzipInterceptorService,
  multi: true,
};
