import { Injectable, Injector } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { AuthState } from 'src/app/store/auth/auth.state';
import { ManagerState } from 'src/app/store/manager/manager.state';
import { CompleteWelcomeStep } from 'src/app/store/onboarding/onboarding.actions';
import { SidebarItem } from '../sidebar-item';
import { AuthSelectors } from 'src/app/store/auth/auth.selectors';


@Injectable()
export class AddEmployeeStep implements SidebarItem {
  id = 'addEmployee';
  completed = false;
  link: string;

  private isSilent: boolean;
  private store: Store;

  subscription: Subscription;

  constructor(injector: Injector) {
    this.store = injector.get(Store);
    const company = this.store.selectSnapshot(AuthState.company);
    this.isSilent = company && company.companySettings.trackingMode === 'silent';

    this.link = this.isSilent ? '/downloads' : '/invite';
  }

  clear() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  async start() {
    this.subscription = this.store.select(AuthSelectors.company).subscribe((company) => {
      const thereAreUsers = company.userCount > 1;
      if (thereAreUsers) {
        this.store.dispatch(new CompleteWelcomeStep(this.id));
        return;
      }
    });
  }
}
