<mat-dialog-content fxLayout="column" fxLayoutAlign="start stretch" fxLayout.lt-md="column" class="dialog-content">
  <h3>
    <span fxFlex></span>
    <button mat-icon-button mat-dialog-close class="compact">
      <mat-icon>close</mat-icon>
    </button>
  </h3>

  <div class="progress-section" fxLayout="column" fxLayoutAlign="center center">
    <div class="progress-text">
      {{ (data?.type === 'export' ? 'export.exportingProgressMessage' : 'export.importingProgressMessage') |
      translate}}
    </div>

    <mat-progress-bar class="progress-bar" [mode]="data?.value === 0 ? 'indeterminate' : 'determinate'"
      [value]="data?.value">
    </mat-progress-bar>
  </div>

</mat-dialog-content>
