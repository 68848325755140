import { DateTime, Duration } from 'luxon';
import { DailySummary, User, Worklog } from 'src/models';
import { DateRange } from '../common/daterange';
import { TimeService } from '../services/time';
import { getIntegrationProjectName } from './integration-helpers';

const emptyWlTemplate: WorklogExtended = {
  mode: 'offline',
  projectId: null,
  taskId: null,
  projectName: null,
  taskName: null,
  deviceId: '',
  end: '',
  start: '',
  offset: '',
  reason: '',
  timezone: null,
  time: 0,
};

export enum TimelineStatusCodes {
  Tracking = 0,
  Break = 1,
  Offline = 2,
  NeverTracked = 3,
  NotActive = 4,
  Leave = 9,
  UnpaidLeave = 10,
}

export interface WorklogDetails {
  start: Date;
  end: Date;
  total: number;
  worked: number;
}

export interface WorklogUserDetails {
  status: TimelineStatusCodes;
  duration: Duration;
  task: string;
  taskId: string;
  projectId: string;
  projectName: string;
}

export interface TimelineData {
  user: User;
  timezone: string;
  offset: string;
  userDetails: WorklogUserDetails;
  worklog: Worklog[];
  details: WorklogDetails;
}

export interface WorklogExtended extends Worklog {
  end: string;
  offset: string;
  timezone: string;
}

export interface EditTimeData {
  start: number;
  end: number;
  project: { id: string, name: string };
  task: { id: string, name: string, project: { id: string, name: string } };
  min: number;
  max: number;
  reason?: string;
}

export interface TimelineDataExtended extends TimelineData {
  worklog: WorklogExtended[];
}

export interface WeeklyTimelineData {
  user: User;
  timezone: string;
  userDetails: WorklogUserDetails;
  weeklySummary: DailySummary[];
  details: { worked: number };
}

export function getWorklogDetails(wls: Worklog[]): WorklogDetails {
  wls = wls.filter(x => x.mode !== 'unpaidLeave');
  const start = wls.map(x => DateTime.fromISO(x.start)).reduce((acc, x) => x > acc ? acc : x, undefined);
  const end = wls.map(x => DateTime.fromISO(x.start).plus({ second: x.time })).reduce((acc, x) => x < acc ? acc : x, undefined);
  const worked = wls.filter(wl => !['pending', 'disapproved'].includes(wl.approvalStatus)).reduce((acc, x) => acc + x.time, 0);
  const total = end && start && end.diff(start, 'seconds').seconds || 0;

  return {
    start: start?.toJSDate(),
    end: start && end.toJSDate(),
    total,
    worked,
  };
}

export function getUserDetails(user: User): WorklogUserDetails {
  const lt = user.lastTrack;
  let status = TimelineStatusCodes.Offline;
  const duration = lt ? DateTime.fromISO(lt.activeAt || lt.updatedAt).diffNow('seconds').negate() : Duration.fromMillis(0);
  const task = lt?.taskName;
  const taskId = lt?.taskId;
  const projectName = lt?.projectName;
  const projectId = lt?.projectId;

  if (!user.active) {
    status = TimelineStatusCodes.NotActive;
  } else if (!lt) {
    status = TimelineStatusCodes.NeverTracked;
  } else if (lt.online && ['computer', 'mobile'].includes(lt.status)) {
    status = TimelineStatusCodes.Tracking;
  } else if (lt.status === 'paidLeave') {
    status = TimelineStatusCodes.Leave;
  } else if (lt.status === 'unpaidLeave') {
    status = TimelineStatusCodes.UnpaidLeave;
  }

  return { status, duration, task, taskId, projectName, projectId };
}

export function constrainWorklogInDaterange(wl: Worklog, range: DateRange): Worklog {
  const start = DateTime.fromISO(wl.start);
  const end = start.plus({ seconds: wl.time });
  const containsStart = range.contains(start);
  const containsEnd = range.contains(end);

  if (containsStart && containsEnd) { return wl; }

  const newStart = DateTime.max(start, range.start);
  const newEnd = DateTime.min(end, range.end);
  const diff = Math.max(0, newEnd.diff(newStart, 'seconds').seconds);

  return { ...wl, start: newStart.toUTC().toISO(), time: diff };
}

export function getExtendedWorklog(wls: Worklog[], timezone: string, day: DateTime): WorklogExtended[] {
  const starts = wls.map(x => DateTime.fromISO(x.start));
  const ends = wls.map(x => DateTime.fromISO(x.start).plus({ second: x.time }));
  const start = (starts.reduce((acc, x) => x > acc ? acc : x, undefined) || day).setZone(timezone);
  const end = (ends.reduce((acc, x) => x < acc ? acc : x, undefined) || day).setZone(timezone);

  const firstDay = start.startOf('day');
  const nextDay = firstDay.plus({ day: 1 });
  const endDay = (+end.startOf('day') === +end) ? end : TimeService.endOfExclusive(end, 'day');
  const lastDay = DateTime.max(nextDay, endDay);
  const lastDayDiff = lastDay.diff(firstDay, 'seconds').seconds;

  const res: WorklogExtended[] = [];

  const offset = DateTime.utc().setZone(timezone).zone.formatOffset(start.toMillis(), 'techie');

  for (const wl of wls) {
    const startIso = DateTime.fromISO(wl.start);
    const startDiff = startIso.diff(firstDay, 'seconds').seconds;
    const endDiff = startDiff + wl.time;
    const endIso = firstDay.plus({ seconds: endDiff });

    const prevEnd = (res[res.length - 1] || { end: firstDay.toUTC().toISO() }).end;
    const prevEndDiff = DateTime.fromISO(prevEnd).diff(firstDay, 'seconds').seconds;
    const emptyDiff = startDiff - prevEndDiff;

    // prefix integration name to project names
    const projectName = getIntegrationProjectName({ id: wl.projectId, name: wl.projectName });

    if (emptyDiff > 0) {
      const emptyWl: WorklogExtended = {
        ...emptyWlTemplate,
        projectName,
        deviceId: wl.deviceId,
        start: prevEnd,
        end: wl.start,
        time: emptyDiff,
        timezone,
        offset,
      };

      res.push(emptyWl);
    }

    const wlx: WorklogExtended = {
      ...wl,
      projectName,
      end: endIso.toUTC().toISO(),
      timezone,
      offset,
    };
    res.push(wlx);
  }



  const last = res[res.length - 1];
  const lastEnd = last ? last.end : firstDay.toUTC().toISO();
  const lastEndDiff = DateTime.fromISO(lastEnd).diff(firstDay, 'seconds').seconds;
  const lastEmptyDiff = lastDayDiff - lastEndDiff;
  if (lastEmptyDiff > 0) {
    const emptyWl: WorklogExtended = {
      ...emptyWlTemplate,
      deviceId: last?.deviceId || '',
      start: lastEnd,
      end: lastDay.toUTC().toISO(),
      time: lastEmptyDiff,
      timezone,
      offset,
    };

    res.push(emptyWl);
  }


  return res;
}

export function diffSinceDayStart(time: string, zone: string) {
  const dt = DateTime.fromISO(time, { zone });
  return dt.diff(dt.startOf('day'), 'seconds').seconds;
}

/**
 * This function is a workaround of 'exclude-fields=shift' param for the worklog socket update.
 * We are able to use 'exclude-fields=shift' on the API side but it will not work with the socket.
 * Therefore we need to fix the wrong shift related behavior of the socket on the front-end side.
 * The function tries to find if the new worklog is a part of the existing worklog and
 * If so, it will extend it or it will push the new worklog to the list.
 * See STAFF-129610 for more details
 * @param userWorklogs target user worklog list
 * @param newWorklog new worklog to add
 */
export function insertOrUpdateWorklog(userWorklogs: Worklog[], newWorklog: Worklog) {
  const newWorklogStart = DateTime.fromISO(newWorklog.start);
  const newWorklogEnd = newWorklogStart.plus({ seconds: newWorklog.time });

  for (const [index, userWl] of userWorklogs.entries()) {
    const userWlStart = DateTime.fromISO(userWl.start);
    const userWlEnd = userWlStart.plus({ seconds: userWl.time });

    if (newWorklogStart >= userWlStart
      && newWorklogStart <= userWlEnd
      && newWorklog.taskId === userWl.taskId
      && newWorklog.mode === userWl.mode) {
      const extendedTime = Math.abs(userWlStart.diff(newWorklogEnd).as('seconds'));
      const newWl = { ...userWl, time: extendedTime };
      userWorklogs.splice(index, 1, newWl);
      return;
    }
  }

  userWorklogs.push(newWorklog);
}
