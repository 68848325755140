import { Action, State, StateContext } from '@ngxs/store';
import { DismissBanner } from './actions';

export interface BannerStateModel {
  dismissedBanners: { [key: string]: boolean };
}

@State<BannerStateModel>({
  name: 'banner',
  defaults: {
    dismissedBanners: JSON.parse(localStorage.getItem('dismissedBanners')) || {},
  },
})
export class BannerState {
  @Action(DismissBanner)
  dismiss(ctx: StateContext<BannerStateModel>, action: DismissBanner) {
    const state = ctx.getState();
    const updatedDismissedBanners = {
      ...state.dismissedBanners,
      [action.bannerName]: true,
    };

    ctx.patchState({ dismissedBanners: updatedDismissedBanners });

    // Persist to local storage
    localStorage.setItem('dismissedBanners', JSON.stringify(updatedDismissedBanners));
  }
}
