import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { DateTime } from 'luxon';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  lastValueFrom,
  map,
  Observable,
  Subscription
} from 'rxjs';
import { DateRange } from 'src/app/common/daterange';
import { PlanId } from 'src/app/pages/new-billing/types/api.types';
import { CompanyService } from 'src/app/services/company/company.service';
import { GrowthBookService } from 'src/app/services/growthbook/service';
import { ExperimentProps } from 'src/app/services/growthbook/types';
import { UserService, UserTrackingData } from 'src/app/services/user/user.service';
import { CompanySettingsChanged } from 'src/app/store/auth.actions';
import { AuthState } from 'src/app/store/auth/auth.state';
import {
  AuthCompany,
  CompanySettings,
  CustomCompanySettings,
  TimeLimitedPromotionSettings
} from 'src/models';

export type LimitedPromotionSettings = {
  countdownTimerTime: number;
  timeToSeeOffer: number;
  expDiscount?: {
    A: { key: string, value: string };
    B: { key: string, value: string };
  };
};

export interface CountdownStateModel {
  userCount: number;
  usersWhoTrackedTime: number;
  startTime: number;
}

export interface ResponseData {
  data: UserTrackingData[];
}

export const TIME_LIMITED_PROMOTION = 'time-limited-promotion';
export const TIME_LIMITED_PROMOTION_SETTINGS = 'time-limited-promotion-settings';

// URLs where promotions should not be shown
export const noPromoUrls = [
  '/logout',
  '/login',
  'new-billing',
  '',
];

@Injectable({
  providedIn: 'root',
})
export class TrialPromotionOfferService implements OnDestroy {
  private usersWhoTrackedTime = 0;
  private hasInitializedTrackingTime = false;
  private subscriptions: Subscription = new Subscription();

  company: AuthCompany;
  expFeature: ExperimentProps | null = null;
  expFeatureSettings: LimitedPromotionSettings | null = null;
  timeLimitedPromotionSettings: TimeLimitedPromotionSettings;

  // BehaviorSubjects
  private expFeatureSubject = new BehaviorSubject<ExperimentProps | null>(null);
  private expFeatureSettingsSubject = new BehaviorSubject<LimitedPromotionSettings | null>(null);

  expFeatureSubject$ = this.expFeatureSubject.asObservable();
  expFeatureSettings$ = this.expFeatureSettingsSubject.asObservable();

  expFeatureAndSettings$ = combineLatest([
    this.expFeatureSubject$,
    this.expFeatureSettings$,
  ]).pipe(
    filter(([expFeature, expFeatureSettings]) => expFeature !== null && expFeatureSettings !== null),
    debounceTime(300),
    distinctUntilChanged(),
  );

  constructor(
    private store: Store,
    private growthbook: GrowthBookService,
    private userService: UserService,
    private companyService: CompanyService,
  ) {
    this.initializeCompany();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  get hasMetTimeLimitedOfferCriteria(): boolean {
    const metCriteria = this.company?.userCount >= 3 && this.usersWhoTrackedTime >= 2;
    const isOfferStillRunning = this.company.companySettings?.custom?.timeLimitedPromotion?.isTimeLimitedPromotionBannerVisible;
    return metCriteria && this.isTrialPromoEnabled() && isOfferStillRunning;
  }

  async usersTrackingTime(): Promise<number> {
    if (!this.isTrialPromoEnabled()) return 0;
    if (this.usersWhoTrackedTime >= 2) return this.usersWhoTrackedTime;
    const fromDate = DateTime.fromISO(this.company.companyCreatedAt).minus({ days: 3 });
    const params = {
      from: fromDate.toISO(),
      to: DateRange.today().end.toISO(),
      detail: 'settings',
      user: 'all',
      'group-by': 'userId',
    };

    const { data }: ResponseData = await this.userService.checkUserTrackingTime(params) as ResponseData;
    this.usersWhoTrackedTime = data?.length || 0;
    return this.usersWhoTrackedTime;
  }

  async updateTrialPromotionSettings(newValue: unknown, settingKey: string) {
    try {
      const updatedSettings = this.getUpdatedSettingsForTrialPromotion(newValue, settingKey) as CompanySettings;
      await lastValueFrom(this.companyService.putCompanySettings(this.company.id, updatedSettings));

      this.updateStoreCompanySettings(updatedSettings.custom);
      this.timeLimitedPromotionSettings = updatedSettings.custom?.timeLimitedPromotion || null;
    } catch (error) {
      console.error('Error updating trial promotion settings:', error);
    }
  }

  async addTimeLimitedPromotionSettings() {
    const existingSettings = this.company?.companySettings.custom || {};
    const existingPromotionSettings = existingSettings.timeLimitedPromotion;

    if (existingPromotionSettings) return;

    this.timeLimitedPromotionSettings = {
      startTime: DateTime.now(),
      isTimeLimitedPromotionPopUpVisible: true,
      isTimeLimitedPromotionBannerVisible: true,
    };

    const updatedSettings = {
      custom: {
        ...existingSettings,
        timeLimitedPromotion: this.timeLimitedPromotionSettings,
      },
    };

    await lastValueFrom(this.companyService.putCompanySettings(this.company.id, updatedSettings));
    this.updateStoreCompanySettings(updatedSettings.custom);
  }

  isTrialPromoEnabled$: Observable<boolean> = this.expFeatureSubject$.pipe(
    map(expFeature => expFeature?.variationid === 'B'),
  );

  isTrialPromoEnabled(): boolean {
    return this.expFeatureSubject.getValue()?.variationid === 'B';
  }

  shouldShowTimeLimitedPromotionOffer(settingsKey: string): Observable<boolean> {
    return this.isTrialPromoEnabled$.pipe(
      map(isTrialPromoEnabled => {
        if (!isTrialPromoEnabled) return false;

        const creationDate = DateTime.fromISO(this.company.companyCreatedAt);
        const now = DateTime.now();

        const isWithin7DaysOfCreation = this.checkIfWithin7DaysOfCreation(creationDate, now);
        const { isPromotionVisible } = this.getTimeLimitedPromotionInfo(settingsKey);

        return isWithin7DaysOfCreation && isPromotionVisible;
      }),
    );
  }

  get expDiscountValue(): string {
    return this.expFeatureSettings?.expDiscount?.[this.expFeature?.variationid]?.value || '';
  }

  getDiscountId(transactionType: string, planId: PlanId): string | null {
    const isPremiumPlan = planId.startsWith('premium');
    const metCriteria = this.hasMetTimeLimitedOfferCriteria;
    const isCheckOut = transactionType === 'checkout';
    return metCriteria && isCheckOut && isPremiumPlan
      ? 'TDFIFTYDOLLAR'
      : null;
  }

  private initializeCompany() {
    const companySubscription = this.store
      .select(AuthState.company)
      .pipe(
        filter(company => !!company),
        distinctUntilChanged(),
      )
      .subscribe(company => {
        if (!company?.id) return;
        this.company = company;
        this.timeLimitedPromotionSettings = company?.companySettings.custom?.timeLimitedPromotion || null;
        this.initializeTrackingTimeIfNeeded();
      });

    const expFeatureSubscription = this.growthbook.getFeature<ExperimentProps>(TIME_LIMITED_PROMOTION)
      .subscribe({
        next: expFeature => {
          this.expFeature = expFeature;
          this.expFeatureSubject.next(this.expFeature);
        },
        error: err => console.error('Error fetching experiment feature:', err),
      });

    const expFeatureSettingsSubscription = this.growthbook.getFeature<LimitedPromotionSettings>(TIME_LIMITED_PROMOTION_SETTINGS)
      .subscribe({
        next: expFeatureSettings => {
          this.expFeatureSettings = expFeatureSettings;
          this.expFeatureSettingsSubject.next(this.expFeatureSettings);
        },
        error: err => console.error('Error fetching experiment feature settings:', err),
      });

    // Add subscriptions to the Subscription object
    this.subscriptions.add(companySubscription);
    this.subscriptions.add(expFeatureSubscription);
    this.subscriptions.add(expFeatureSettingsSubscription);
  }

  private async initializeTrackingTimeIfNeeded() {
    if (this.hasInitializedTrackingTime) return;
    await this.usersTrackingTime();
    this.hasInitializedTrackingTime = true;
  }

  private checkIfWithin7DaysOfCreation(creationDate: DateTime, now: DateTime): boolean {
    return now.diff(creationDate, 'hours').hours <= 168; // 7 days
  }

  private getTimeLimitedPromotionInfo(settingsKey: string): { startTime: DateTime, isPromotionVisible: boolean } {
    const startTime = typeof this.timeLimitedPromotionSettings?.startTime === 'string'
      ? DateTime.fromISO(this.timeLimitedPromotionSettings.startTime)
      : null;
    const isPromotionVisible = this.timeLimitedPromotionSettings?.[settingsKey] ?? false;

    return { startTime, isPromotionVisible };
  }

  private getUpdatedSettingsForTrialPromotion(newValue: unknown, settingKey: string) {
    const existingCustomSettings = this.company?.companySettings.custom || {};
    const existingTimeLimitedPromotion = existingCustomSettings.timeLimitedPromotion || {};

    return {
      custom: {
        ...existingCustomSettings,
        timeLimitedPromotion: {
          ...existingTimeLimitedPromotion,
          [settingKey]: newValue,
        },
      },
    };
  }

  private updateStoreCompanySettings(settings: Partial<CustomCompanySettings>) {
    const existingSettings = this.company?.companySettings.custom || {};
    const updatedSettings = this.mergeSettings(existingSettings, settings);
    this.store.dispatch(new CompanySettingsChanged(null, null, updatedSettings));
  }

  private mergeSettings(existingSettings: any, newSettings: Partial<CustomCompanySettings>) {
    return {
      ...existingSettings,
      ...newSettings,
      timeLimitedPromotion: {
        ...(existingSettings.timeLimitedPromotion || {}),
        ...(newSettings.timeLimitedPromotion || {}),
      },
    };
  }
}
