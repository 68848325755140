import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { LegacyPricingPlans } from 'src/app/pages/new-billing/types/plan.types';
import { AuthState } from 'src/app/store/auth/auth.state';
import { AuthCompany, Company, CompanySettings, SsoProviderParams, SsoProviderType } from 'src/models';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(
    private api: ApiService,
    private store: Store,
  ) { }

  editCompanySettings(company: AuthCompany, properties: (keyof CompanySettings)[]) {
    const data = {};
    for (const prop of properties) {
      data[prop] = company.companySettings[prop];
    }

    return this.api.request('put', `companies/${company.id}`, data);
  }

  putCompanySettings(company: string, data: Partial<CompanySettings & AuthCompany>) {
    return this.api.request('put', `companies/${company}`, data);
  }

  getCompany(id: string) {
    return this.api.request<{ data: Company }>('get', `companies/${id}`).pipe(map(x => x.data));
  }

  getTimezones(id: string) {
    return lastValueFrom(this.api.request<{ data: { timezones: string[] } }>('get', `companies/${id}/timezones`)
      .pipe(map(x => x?.data?.timezones?.filter(t => t))));
  }

  getSsoProviders(id: string) {
    return lastValueFrom(this.api.request<{ data: (SsoProviderParams & { provider: SsoProviderType })[] }>('get', `companies/${id}/sso`)
      .pipe(map(x => x?.data)));
  }

  getSsoProvider(id: string, provider: SsoProviderType) {
    return lastValueFrom(
      this.api.request<{ data: (SsoProviderParams & { provider: SsoProviderType }) }>('get', `companies/${id}/sso/${provider}`)
        .pipe(map(x => x?.data)));
  }

  deleteSsoProvider(id: string, provider: SsoProviderType) {
    return lastValueFrom(this.api.request('delete', `companies/${id}/sso/${provider}`));
  }

  setSsoProvider(id: string, provider: SsoProviderType, { ...params }: SsoProviderParams) {
    return lastValueFrom(this.api.request<{ data: { redirect?: string } }>('put', `companies/${id}/sso/${provider}`, params));
  }

  getDirectorySync(id: string) {
    return lastValueFrom(this.api.request<{ data: { orgId?: string } }>('get', `companies/${id}/directory-sync`));
  }

  setDirectorySync(id: string, redirectURI: string) {
    return lastValueFrom(this.api.request<{ data: { redirect?: string } }>('put', `companies/${id}/directory-sync`, { redirectURI }));
  }

  switchCurrentCompanyPlan(pricingPlan: unknown) {
    const { id: companyId } = this.store.selectSnapshot(AuthState.company);
    return lastValueFrom(this.putCompanySettings(companyId, { pricingPlan: pricingPlan as LegacyPricingPlans }));
  }
}
