import { Inject, Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { ERROR_URL } from 'src/app/app/app.constants';
import { isInMenu, isMenuAllowed } from 'src/app/common/menu-def.provider';
import { AuthState } from 'src/app/store/auth/auth.state';

@Injectable({
  providedIn: 'root',
})
export class MenuGuard implements CanActivate, CanActivateChild {
  constructor(
    private store: Store,
    private router: Router,
    private injector: Injector,
    @Inject(ERROR_URL) private errorUrl,
  ) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const urlTree = this.router.parseUrl(state.url);
    const urlWithoutParams = urlTree.root.children?.primary?.segments?.map(it => it.path).join('/') || '';
    const company = this.store.selectSnapshot(AuthState.company);
    const isManager = this.store.selectSnapshot(AuthState.isManager);
    let error = 'unauthorized';
    if (!isMenuAllowed(company, this.injector, '/' + urlWithoutParams)) {
      if (state.url === '/' && company.role === 'admin' && company?.userSettings?.disableReportsAccess) {
        return this.router.createUrlTree(['manage-users']);
      }
      if (urlWithoutParams && isInMenu('/' + urlWithoutParams)) {
        if (isManager && this.store.selectSnapshot(AuthState.webAppTrackingMode) === 'off') {
          if (state.url === '/productivity-ratings') {
            error = 'productivityRatings';
          }
          if (state.url === '/web-app-usage') {
            error = 'webAppUsage';
          }
        }
        if (isManager && state.url === '/notifications' && company.pricingPlan?.includes('basic')) {
          error = 'notifications';
        }
        return this.router.createUrlTree([this.errorUrl, error]);
      }
    }
    return true;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(route, state);
  }

}
