<div class="steps">
  <label class="step">
    <mat-checkbox [checked]="(userCount$ | async) >= 3" class="offer-checkbox" disabled></mat-checkbox>
    <span [ngClass]="{'completed-step': (userCount$ | async) >= 3 }" class="step-text">
      <strong class="main-text">Invite team members:</strong> <br>
      <span class="sub-text">
        <a [href]="inviteLink"
          [trackClick]="{
            page: 'Link Click',
            linklocation: 'footer',
            pagename: 'Discount Popup',
            linkname:'Add atleast 2 users',
            linktype: 'text',
            linkurl: inviteLink}"
        >Add at least 2 users</a>
        to your Time Doctor account
      </span>
    </span>
  </label>

  <label class="step">
    <mat-checkbox [checked]="usersTrackingTimeCount$ | async" class="offer-checkbox" disabled></mat-checkbox>
    <span [ngClass]="{'completed-step': (usersTrackingTimeCount$ | async) }" class="step-text">
      <strong class="main-text">Ensure time tracking:</strong> <br>
      <span class="sub-text">Make sure these users track their time within 72 hours</span>
    </span>
  </label>
</div>
