import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router } from '@angular/router';
import { GrowthBookService } from 'src/app/services/growthbook/service';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceGuard implements CanActivate, CanActivateChild {

  constructor(private growthBookService: GrowthBookService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const urlPath = `/${route.url.length > 0 ? route.url[0].path : ''}`;

    /* Set the 'url_path' attribute to configure the GrowthBook
     * feature flag for maintenance mode activation */
    this.growthBookService.setAttribute('url_path', urlPath);

    const feature = this.growthBookService.getFeatureValue<boolean>('maintenance-mode');

    if (feature) return this.router.createUrlTree(['/maintenance-mode']);

    return true;
  }

  canActivateChild(route: ActivatedRouteSnapshot) {
    return this.canActivate(route);
  }
}
