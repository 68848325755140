// https://stackoverflow.com/a/52458069/2346893
// https://github.com/angular/angular/issues/18261

import { HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest, HttpUrlEncodingCodec, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class EncodeHttpParamsInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const params = new HttpParams({ encoder: new CustomEncoder(), fromString: req.params.toString() });
    return next.handle(req.clone({ params }));
  }
}

@Injectable({ providedIn: 'root' })
export default class CustomEncoder extends HttpUrlEncodingCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }
}

export const httpEncoderInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: EncodeHttpParamsInterceptor,
  multi: true,
};
