import { ContentChildren, Directive, ElementRef, OnDestroy, QueryList } from '@angular/core';
import { MatError, MatHint } from '@angular/material/form-field';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';

@Directive({
  selector: 'mat-form-field',
})
export class HasHintDirective implements OnDestroy {

  hasHintSubscription: Subscription;
  hasHints$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  hasErrors$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  @ContentChildren(MatHint, { descendants: true }) set hints(value: QueryList<MatHint>) {
    this.hasHints$.next(!!value.length);
  }

  @ContentChildren(MatError, { descendants: true }) set errors(value: QueryList<MatError>) {
    this.hasErrors$.next(!!value.length);
  }

  constructor(private el: ElementRef) {

    this.hasHintSubscription = combineLatest([this.hasHints$, this.hasErrors$])
      .subscribe(([hasHints, hasErrors]) => {
        this.toggleClass(hasHints || hasErrors);
      });
  }

  toggleClass(isActive: boolean) {
    this.el.nativeElement.classList.toggle('mat-form-field-has-hint', isActive);
  }

  ngOnDestroy() {
    this.hasHintSubscription.unsubscribe();
  }
}
