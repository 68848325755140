import { NgModule } from '@angular/core';
import { MatRadioModule } from '@angular/material/radio';
import { SharedModule } from 'src/app/modules/shared.module';
import {
  CountdownTimerComponent
} from 'src/app/components/trial-promotion-offer/countdown-timer/countdown-timer.component';
import { TrialOfferBannerComponent } from 'src/app/components/trial-promotion-offer/banner/banner.component';
import { DiscountOfferModalComponent } from 'src/app/components/trial-promotion-offer/offer-popup/offer-popup.component';
import {
  ModalHeaderComponent
} from 'src/app/components/trial-promotion-offer/offer-popup/modal-header/modal-header.component';

import {
  ModalFooterComponent
} from 'src/app/components/trial-promotion-offer/offer-popup/modal-footer/modal-footer.component';
import {
  ModalStepComponent
} from 'src/app/components/trial-promotion-offer/offer-popup/modal-steps/modal-step.component';

const modules = [
];

const components = [
  CountdownTimerComponent,
  TrialOfferBannerComponent,
  DiscountOfferModalComponent,
  ModalHeaderComponent,
  ModalStepComponent,
  ModalFooterComponent,
];

@NgModule({
  declarations: [
    ...components,
  ],
  imports: [
    SharedModule,
    MatRadioModule,
    ...modules,
  ],
  exports: [
    ...components,
  ],
})
export class TrialOfferPromotionModule { }
