import { ChangeDetectorRef, Injectable, Pipe, PipeTransform } from '@angular/core';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';

export type Translatable = string | {
  key: string;
  params?: {
    [key: string]: any;
  };
  skip?: boolean;
};

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'translatex' })
export class TranslatexPipe extends TranslatePipe implements PipeTransform {
  constructor(translateService: TranslateService, ref: ChangeDetectorRef) {
    super(translateService, ref);
  }

  transform(value: Translatable, skipIfString: boolean = false, args?: any): any {
    if (!value) {
      return '';
    } else if (typeof value === 'string') {
      if (skipIfString) { return value; }
      return super.transform(value, args);
    } else {
      if (value.skip) { return value.key; }
      return super.transform(value.key, { ...value.params, ...args });
    }
  }
}
