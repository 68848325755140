
export function shallowEquals(object1: any, object2: any) {
  // eslint-disable-next-line eqeqeq
  if (object1 == object2) { return true; }
  if (typeof object1 !== typeof object2) { return false; }

  if (object1 == null) { return object2 == null; }
  if (object2 == null) { return false; }

  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }

  return true;
}

export function splitUserName(fullName: string, splitFirstWord: boolean = false): [firstName: string, lastName: string] {
  if (splitFirstWord) {
    const splits = fullName.split(/ (.+)/);
    const firstName = splits[0];
    const lastName = splits[1];
    return [firstName, lastName];
  }
  else {
    const splits = fullName.split(' ');
    const firstName = splits.length > 1 ? splits.slice(0, -1).join(' ') : splits[0] || '';
    const lastName = splits.length > 1 ? splits.slice(-1).join(' ') : '';
    return [firstName, lastName];
  }
}
