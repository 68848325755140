import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthState } from 'src/app/store/auth/auth.state';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent {
  error: string;
  errorDetails: string;
  icon = 'error';
  cssClass = '';
  showUpgradeButton = false;
  billingRoute = '/billing';

  constructor(activatedRoute: ActivatedRoute, private store: Store) {
    const error = activatedRoute.snapshot.params['error'] || activatedRoute.snapshot.data.error;
    this.error = `errors.${error}`;
    if (error === 'upgrade') {
      this.error = 'pricingLimit.upgrade';
      this.icon = 'announcement';
      this.cssClass = 'white-bg';
      const company = this.store.selectSnapshot(AuthState.company);
      if (company.role === 'owner') {
        this.showUpgradeButton = true;
      } else {
        this.error = 'pricingLimit.upgradeNotOwner';
        this.errorDetails = 'pricingLimit.upgradeAskOwner';
      }
    }
  }
}
