import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AuthUser, OAuthCompany, SsoProviderType, User } from 'src/models';
import { ApiService } from './api.service';
import { GoogleAuthService } from './google-auth.service';

export interface TokenResult {
  token: string;
  expires: string;
  createdAt: string;
}

export interface SsoOtpResponse {
    SSO_OTP: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private api: ApiService, private googleAuthService: GoogleAuthService) { }

  login(email: string, password: string, totpCode?: string) {
    return this.api
      .request<{ data: (AuthUser & { token: string }) | { status: 'totpNeeded' } }>('post', `authorization/login`, {
        email,
        password,
        totpCode,
      }, { params: { company: '', 'no-workspaces': '1', 'has-managed-screencasts': '1' } })
      .pipe(map(x => x.data));
  }

  logout() {
    this.googleAuthService.logout();
    return this.api.request('get', `logout`, {}, {}, true).toPromise();
  }

  auth() {
    return this.api
      .request<{ data: AuthUser }>('get', `authorization`, { 'no-workspaces': '1', company: '', 'has-managed-screencasts': true })
      .pipe(map(x => x.data));
  }

  setTimeZone(timezone: string) {
    return this.api
      .request('put', `profile`, { timezone })
      .toPromise();
  }

  editProfile(properties: Partial<User> & { password?: string }) {
    return this.api.request<{ data: TokenResult }>('put', `profile`, properties).pipe(map(x => x.data)).toPromise();
  }

  register(email: string, name: string, password: string, params: any = null) {
    return this.api.request<{ data: TokenResult }>('post', 'register', { name, email, password, ...(params || {}) })
      .pipe(map(x => x.data)).toPromise();
  }

  forgotPassword(email) {
    return this.api.request<{ data: {} }>('get', `profile/restore-password`, { email }).pipe(map(x => x.data));
  }

  resetPassword(token, password) {
    return this.api.request('put', `profile`, { password }, { params: { company: '' }, headers: { Authorization: `JWT ${token}` } }).toPromise();
  }

  findCompanies(email: string, issuer: string) {
    return this.api.request<{ data: OAuthCompany[] }>
      ('post', `oauth2/find-my-company`, email ? { email } : { issuer }, { params: { company: '' } }).toPromise();
  }

  sendOtpRequest(email: string) {
    return this.api.request<{ data: {} }>('post', `oauth2/one-time-login`, { email }).toPromise();
  }

  isSsoOtpEnabled(email: string) {
    return this.api.request<SsoOtpResponse>('post', `oauth2/check-sso-otp`, { email }).toPromise();
  }


  findCompaniesByOTP(email: string, issuer: string, otp: string) {
    return this.api.request<{ data: OAuthCompany[] }>
      ('post', `oauth2/find-my-company/${otp}`, email ? { email } : { issuer }, { params: { company: '' } }).toPromise();
  }

  oauthLogin(provider: SsoProviderType, token: string) {
    return this.api.request<{ data: { token: string } }>
      ('post', `oauth2/${provider}/login`, {}, { params: { company: '' }, headers: { Authorization: `JWT ${token}` } }).toPromise();
  }
}
