
import { PlatformModule } from '@angular/cdk/platform';
import { Inject, Injectable, NgModule, Optional } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Store } from '@ngxs/store';
import { AuthState } from '../store/auth/auth.state';
import { LUXON_DATE_FORMATS, LuxonDateAdapter } from './luxon-date-adapter/luxon-date-adapter';

@Injectable()
export class CompanyDateAdapter extends LuxonDateAdapter {
  constructor(
    private store: Store,
    @Optional() @Inject(MAT_DATE_LOCALE) matDateLocale: string,
  ) {
    super(matDateLocale);
  }

  getFirstDayOfWeek(): number {
    return this.store.selectSnapshot(AuthState.firstDayOfWeek);
  }
}

@NgModule({
  imports: [PlatformModule],
  providers: [
    { provide: DateAdapter, useClass: CompanyDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: LUXON_DATE_FORMATS },
  ],
})
export class CompanyDateAdapterModule { }
