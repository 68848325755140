import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-shared-banner',
  exportAs: '',
  templateUrl: './component.html',
  styleUrls: ['./component.scss'],
  animations: [
    trigger('dismissed', [
      state('1', style({
        height: '0',
        'padding-top': '0',
        'padding-bottom': '0',
      })),
      state('void', style({
        height: '0',
        'padding-top': '0',
        'padding-bottom': '0',
      })),
      state('0', style({
        height: '*',
      })),
      state('in', style({
        height: '*',
      })),
      transition('0 => 1, :leave', [
        animate('300ms ease'),
      ]),
      transition('1 => 0, :enter', [
        animate('100ms ease'),
      ]),
    ]),
  ],
})
export class SharedBannerComponent {
  @HostBinding('@dismissed') @Input() dismissed = false;

  @Input() icon: string;
  @Input() iconOutlined = false;
  @Input() iconColor = 'accent';
  @Input() svgIcon: string;
  @HostBinding('class') @Input() theme: 'default' | 'important' | 'warning' | 'green' = 'default';

  dismiss() {
    this.dismissed = true;
  }
}
