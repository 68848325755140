import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateTime, DurationUnit } from 'luxon';
import { DateLike, fromDateLike } from '../../util';

const units: DurationUnit[] = ['year', 'month', 'week', 'day', 'hour', 'minute', 'second'];

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'humanize' })
export class HumanizePipe implements PipeTransform {
  constructor(private translate: TranslateService) { }

  transform(
    value: DateLike,
    now?: DateLike,
    translationPrefix = 'time.ago',
  ) {
    const date = fromDateLike(value);
    const nowDate = fromDateLike(now) ?? DateTime.utc();
    const diff = date > nowDate ? date.diff(nowDate) : nowDate.diff(date);

    for (const unit of units) {
      const ud = diff.as(unit);
      if (ud >= 1) {
        return this.translate.instant(`${translationPrefix}.#${unit}`, { count: Math.floor(ud) });
      }
    }
    return this.translate.instant(`${translationPrefix}.0`);
  }
}
