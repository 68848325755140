import { Project } from 'src/models';
import integrations from '../pages/integrations/integrations-list';

export function getIntegrationNameByProjectId(projectId: string) {
  if (projectId && /^[a-z0-9_]+-.*/.test(projectId)) {
    const integrationId = projectId.split('-')[0];

    const capitalizedId = integrationId.split('_').map(str => str.substr(0, 1).toUpperCase() + str.substr(1, str.length)).join(' ');
    const integration = integrations.find(i => i.id === integrationId.replace(' ', '_'));
    return integration ? integration.shortName || integration.title : capitalizedId;
  }
  return null;
}

export function getIntegrationName(project: Partial<Project>) {
  return getIntegrationNameInternal(project.id, project.integration?.data);
}

function getIntegrationNameInternal(projectId, integrationData) {
  if (integrationData && integrationData.integrationName) {
    return integrationData.integrationName;
  }
  return getIntegrationNameByProjectId(projectId);
}

export function getIntegrationProjectNameRaw(projectId, integrationData, projectName) {
  const intName = getIntegrationNameInternal(projectId, integrationData);
  return getIntegrationProjectNameInternal(intName, projectName);
}

export function getIntegrationProjectName(project: Partial<Project>) {
  const intName = getIntegrationNameInternal(project.id, project.integration?.data);
  return getIntegrationProjectNameInternal(intName, project.name);
}

function getIntegrationProjectNameInternal(intName, projectName) {
  return intName && !projectName?.startsWith(`${intName}:`) ? `${intName}: ${projectName}` : projectName;
}
