<ul>
  <ng-container *ngFor="let item of items">
    <li *ngIf="item.isVisible">
      <a class="link" [routerLink]="item.routerLink" routerLinkActive="active"
         [routerLinkActiveOptions]="{ exact: true }">
      <span class="icon-wrapper">
        <mat-icon *ngIf="item.isParent" class="material-icons-outlined">{{ item.icon }}</mat-icon>
      </span>

        <span>{{ item.label | translate }}</span>
      </a>
    </li>
  </ng-container>
</ul>
