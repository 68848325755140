let swipeCoord = [0, 0];
let swipeTime = new Date().getTime();
const SWIPE_DURATION = 1000;
const SWIPE_THRESHOLD = 30;
const SWIPE_DIRECTION_RATIO = 3;
type SwipeDirection = 'next' | 'previous' | 'up' | 'down';

export function swipe(e: TouchEvent, when: string, callback: (swipeDirection: SwipeDirection) => void): void {
  const coord: [number, number] = [e.changedTouches[0].clientX, e.changedTouches[0].clientY];
  const time = new Date().getTime();

  if (when === 'start') {
    swipeCoord = coord;
    swipeTime = time;
  } else if (when === 'end') {
    const direction = [coord[0] - swipeCoord[0], coord[1] - swipeCoord[1]];
    const duration = time - swipeTime;

    if (duration < SWIPE_DURATION) {
      if (Math.abs(direction[0]) > SWIPE_THRESHOLD && Math.abs(direction[0]) > Math.abs(direction[1] * SWIPE_DIRECTION_RATIO)) {
        const swipeDir = direction[0] < 0 ? 'next' : 'previous';
        callback(swipeDir);
      } else if (Math.abs(direction[1]) > SWIPE_THRESHOLD && Math.abs(direction[1]) > Math.abs(direction[0] * SWIPE_DIRECTION_RATIO)) {
        const swipeDir = direction[1] < 0 ? 'up' : 'down';
        callback(swipeDir);
      }
    }
  }
}

export function scroll(swipeDir: SwipeDirection) {
  const container = document.querySelector('.starter-sidebar-top');
  if (!container) {
    return;
  }

  const scrollPosition = swipeDir === 'down' ? 0 : document.body.scrollHeight;
  scrollToSmoothly(container, scrollPosition);
}

function scrollToSmoothly(container: Element, position: number) {
  container.scrollTo({ top: position, behavior: 'smooth' });
}


