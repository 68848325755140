import { Component, EventEmitter, Inject, Injector, Input, Output } from '@angular/core';
import { IsActiveMatchOptions } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { lastValueFrom, Observable } from 'rxjs';
import { hasMandatoryCall, MENU_DEF, MenuDef, MenuParent } from 'src/app/common/menu-def.provider';
import { BrandingService } from 'src/app/services/branding.service';
import { GrowthBookService } from 'src/app/services/growthbook/service';
import { Logout, SelectCompany } from 'src/app/store/auth/auth.actions';
import { AuthState } from 'src/app/store/auth/auth.state';
import { environment } from 'src/environments/environment';
import { AuthCompany, AuthUser } from 'src/models';
import { helpLinks } from './help-links';

@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  company: AuthCompany;
  @Output() navToggle = new EventEmitter<boolean>();
  @Output() sidebarMenuClick = new EventEmitter<void>();

  @Select(AuthState.authorized) authorized$: Observable<string>;
  @Select(AuthState.user) user$: Observable<AuthUser>;
  @Select(AuthState.isManager) isManager$: Observable<boolean>;
  @Select(AuthState.company) company$: Observable<AuthCompany>;
  @Select(AuthState.companies) companies$: Observable<AuthCompany[]>;
  hasMultipleCompanies: boolean;
  hasMandatoryCall: boolean;
  employeePrivacyDisclaimerUrl = environment.env.TD_APP_EMPLOYEE_PRIVACY_DISCLAIMER;
  contractorPrivacyDisclaimerUrl = environment.env.TD_APP_CONTRACTOR_PRIVACY_DISCLAIMER;
  trainingGuideUrl = environment.env.TD_APP_TRAINING_GUIDE_URL;

  customRouterLinkActiveOptions: IsActiveMatchOptions = { paths: 'exact', fragment: 'exact', queryParams: 'ignored', matrixParams: 'ignored' };

  @Input() activeUrl: string;
  @Input() withNavSidebar = false;

  constructor(
    private store: Store,
    @Inject(MENU_DEF) public menuDef: MenuDef,
    public branding: BrandingService,
    public injector: Injector,
    private growthBook: GrowthBookService,
  ) {
    this.companies$.subscribe((companiesList) => {
      if (companiesList) {
        this.hasMultipleCompanies = companiesList.length > 1;
      } else {
        this.hasMultipleCompanies = false;
      }
    });
    this.company$
      .pipe(untilDestroyed(this))
      .subscribe((company) => {
        this.hasMandatoryCall = hasMandatoryCall(company);
      });
  }

  navOpen() {
    this.navToggle.emit(true);
  }

  logout() {
    this.store.dispatch(new Logout(true));
  }

  async selectCompany(company: AuthCompany) {
    await lastValueFrom(this.store.dispatch(new SelectCompany(company)));
  }

  anyChildActive(item: MenuParent) {
    return item.children.some(x => x['routerLink'] === '/' + this.activeUrl);
  }

  getHelpLinks() {
    return this.branding.isDefault
      ? helpLinks[this.activeUrl] || []
      : [];
  }

  contactUsFormEnabled(company: AuthCompany) {
    return ['admin', 'owner'].some(role => role === company.role) && !!this.growthBook.getFeatureValue<string>('contact-us-form-url', '');
  }

  helpButtonEnabled(company: AuthCompany, defaultBranding: boolean) {
    return this.employeePrivacyDisclaimerUrl
      || this.contractorPrivacyDisclaimerUrl
      || this.trainingGuideUrl
      || this.helpCenterEnabled(company, defaultBranding)
      || this.contactUsFormEnabled(company);
  }

  helpCenterEnabled(company: AuthCompany, defaultBranding: boolean) {
    return this.contactUsEnabled(company) && defaultBranding;
  }

  contactUsEnabled(company: AuthCompany) {
    return company.role !== 'user' && company.role !== 'guest' && company.role !== 'manager';
  }

  openBubbleMessage() {
    const formUrl = this.growthBook.getFeatureValue<string>('contact-us-form-url', '');
    if (formUrl) {
      window.open(formUrl, '_blank');
      return;
    }
    window.HubSpotConversations?.widget?.open();
  }
}
