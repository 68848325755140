import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import * as Sentry from '@sentry/angular-ivy';
import { lastValueFrom, of } from 'rxjs';
import { catchError, distinctUntilChanged, tap } from 'rxjs/operators';
import { pricingPlanToPlanId } from 'src/app/pages/new-billing/utils/plan-maps';
import { onboardingPages } from 'src/app/pages/onboarding/onboarding-pages';
import { BillingService } from 'src/app/services/billing/billing.service';
import { PaymentService } from 'src/app/services/payment/payment.service';
import { TrackingContext } from 'src/app/services/segment/types';
import { TraitsService } from 'src/app/services/traits/traits.service';
import { UserService } from 'src/app/services/user/user.service';
import { ExtCustomCompanySettings, TokenLogin, UserSettingsChanged } from 'src/app/store/auth/auth.actions';
import { AuthSelectors } from 'src/app/store/auth/auth.selectors';
import { SetOnboardingWizardConfig } from 'src/app/store/onboarding/onboarding.actions';
import type { AppState } from 'src/app/store/state';
import { environment } from 'src/environments/environment';
import { AuthCompany, AuthUser } from 'src/models';
import { LocalStorageHelper } from '../../util/local-storage-helper';
import { BrandingService } from '../branding.service';
import { ExperimentProps } from '../growthbook/types';
import { TrackingContextType } from '../tracking/context';
import countryNamesJson from './country-names.json';
import { DeviceDetector } from './device-detector';
import freeEmailDomains from './free-email-domains.json';

import { ClockPipe } from 'src/app/pipes/clock';
import {
  transformActivitySummary,
  transformEditTime,
  transformEntityFilter,
  transformHighestIdleWidget,
  transformNewValue,
  transformPeriodPickerSelection,
  transformPeriodTabsSelection,
  transformRadioUserSelection,
  transformSortColumnsSelection,
  transformStackChartSelection,
  transformTabsSelection,
  transformTimeTubeSelection,
  transformTimezoneSelection,
  transformTreeReport,
  transformUserLazySelection
} from './parse-data';

export interface SegmentOptions {
  integrations?: {
    'All': boolean;
    'Intercom': boolean;
    'Google Analytics': boolean;
    [key: string]: boolean;
  };
  allowNonLoggedIn?: boolean;

  [key: string]: unknown;
}

export interface DataForExternalTracking {
  name?: string;
  email?: string;
  userId?: string;
  companyId?: string;
}

export interface GeoInfo {
  ip?: string;
  geo?: {
    country?: string;
  };
}

type UserTraitsType = {
  [key: string]: string;
};

type TrackEventProps = {
  [key: string]: unknown;
};

@Injectable({ providedIn: 'root' })
export class SegmentService {
  user: AuthUser;
  company: AuthCompany;
  adminLogin: boolean;

  prevStep = null;
  geoInfo: Promise<GeoInfo>;
  constructor(
    private store: Store,
    private httpClient: HttpClient,
    private actions$: Actions,
    private branding: BrandingService,
    private clockPipe: ClockPipe,
    private userService: UserService,
    private deviceDetector: DeviceDetector,
    private paymentService: PaymentService,
    private billingService: BillingService,
    public traitsService: TraitsService,
  ) {
    this.prepareData();
    this.actions$
      .pipe(
        ofActionSuccessful(TokenLogin, SetOnboardingWizardConfig),
      )
      .subscribe(action => {
        if (
          typeof window.analytics?.user === 'function' &&
          !window.analytics?.user()?.id()
        ) {
          this.identify();
        }
        switch (true) {
          case action instanceof SetOnboardingWizardConfig:
            if (action?.config?.currentStep) {
              const currStep = action.config.currentStep;
              if (this.prevStep !== currStep) {
                this.pageOnboarding('Onboarding', onboardingPages[currStep]);
                this.prevStep = currStep;
              }
            }
            break;
        }
      });
  }

  prepareData() {
    this.user = null;
    this.company = null;
    this.store.select((st: AppState) => st.auth.user).pipe(
      distinctUntilChanged(),
    ).subscribe(user => {
      this.user = user;
    });
    this.store.select((st: AppState) => st.auth.company).pipe(
      distinctUntilChanged(),
    ).subscribe(company => {
      this.company = company || {} as AuthCompany;
    });
  }

  trackSideBarActivity(stepId: string, dismissedStep: boolean = false) {
    this.prepareData();
    const { user } = this;
    if (!user) {
      return false;
    }
    const completedSteps = user?.companies[0]?.userSettings?.custom?.completedStep;
    if (completedSteps) {
      if (completedSteps[stepId] || completedSteps['trackedAll']) {
        return false;
      }
    }

    if (stepId === 'trackedAll') {
      this.trackWithUTMS('Onboard Complete 2');
      return true;
    }
    if (stepId === 'trackedAll') {
      this.trackWithUTMS('Onboard Complete 2');
      return true;
    }
    const sideBarProps = {
      createAcc: {
        stepNumber: 1,
        description: 'Create Account',
      },
      confirmEmail: {
        stepNumber: 2,
        description: 'Confirm your email',
      },
      addEmployee: {
        stepNumber: 3,
        description: 'Add your first employee',
      },
      employeeTrack: {
        stepNumber: 4,
        description: 'Get an employee to track time',
      },
      addProjectTask: {
        stepNumber: 5,
        description: 'Add poject and tasks',
      },
      setupGroups: {
        stepNumber: 6,
        description: 'Set up employee groups',
      },
      companySettings: {
        stepNumber: 7,
        description: 'Review & adjust company settings',
      },
      selectProductive: {
        stepNumber: 8,
        description: 'Select which apps and websites are productive',
      },
      addCreditCard: {
        stepNumber: 9,
        description: 'Add your credit card',
      },
      setupPassword: {
        stepNumber: 10,
        description: 'Set up your password',
      },
    };
    this.trackWithUTMS(
      'SideBar Activity',
      {
        completedStep: sideBarProps[stepId]?.stepNumber,
        completedStepDescription: sideBarProps[stepId]?.description,
        dismissedStep,
      },
    );
    return true;
  }

  trackAccountCreated() {
    this.trackOnboardingEvent('Account Created');
  }

  async trackLockedUser() {
    const companyTraits = await lastValueFrom(this.traitsService.companyTraits$);
    const { company } = this;
    const props = {
      accesslevel: (company?.role === 'user') ? 'regular user' : company?.role,
      planname: company?.pricingPlan,
      companyname: companyTraits?.name || company?.name,
      numbersofseats: company?.userCount,
      planprice: null,
      reason: companyTraits?.status ?? null,
      level: (company?.role === 'owner' || company?.role === 'admin') ? 'company' : 'user',
    };
    if (
      !this.branding.billingDisabled()
      && (company?.userSettings?.billingAccess || company?.role === 'owner')
      && company.subscription?.status === 'free'
    ) {
      const paddlePlan = company.subscription.provider === 'stripe'
        ? pricingPlanToPlanId[company.pricingPlan]
        : company.pricingPlan;
      const plans = await lastValueFrom(this.billingService.getPlans().pipe(
        catchError(() => of([])),
      ));
      const foundPlan = plans.find(plan => plan.planId === paddlePlan);
      props.planprice = +foundPlan?.unitPrice || null;
    }

    this.track('Locked Out Expired Account', props);
  }

  trackOnboardingEvent(name?: any, extraProps: any = {}) {
    this.prepareData();
    const { user, company } = this;
    const companyStatus = company.subscription?.status === 'free' ? 'trial expired' : company.subscription?.status;
    let props = {
      name: user?.name,
      email: user?.email,
      createdAt: company?.companyCreatedAt,
      home_cta: this.getExperimentDataByName('popup-signup-trigger'),
      companyName: company?.name,
      companyStatus,
      trialStartDate: company?.companyCreatedAt,
      trialEndDate: company?.subscription?.expires,
      planName: company?.pricingPlan,
    };
    props = { ...props, ...extraProps };
    const context = {
      campaign: this.getUTMs(),
    };
    props = { ...props, ...extraProps };
    this.track(name, props, context, true);
  }

  trackCreditCardAdded() {
    this.prepareData();
    const { company } = this;
    const amount = this.getSubscriptionAmountByPlan(company.pricingPlan);
    const props = {
      currency: 'USD',
      value: amount,
    };
    this.trackOnboardingEvent('CreditCard Offer Accepted', props);
  }

  trackCompleteOnBoarding() {
    this.prepareData();
    const { company } = this;
    const groupStatus = company.subscription.status === 'free' ? 'trial expired' : company.subscription.status;
    this.trackOnboardingEvent('Onboard Complete');
    if (this.analyticsEnabled() && typeof window?.analytics?.user === 'function') {
      window.analytics?.user()?.traits({});
    }
  }

  async identify(extraTraits?: any, options?: SegmentOptions, callback?: () => any) {
    try {
      if (this.analyticsEnabled()) {
        let userTraits = await lastValueFrom(this.traitsService.userTraits$);
        if (!userTraits?.tduserid) return false;
        userTraits = this.cleanObject(userTraits);
        const traits = {
          email: userTraits.email,
          tduserid: userTraits.tduserid,
          tdcompanyid: userTraits.tdcompanyid,
          firstname: userTraits?.firstname,
          lastname: userTraits?.lastname,
          access_level: userTraits?.access_level,
          isAdminLogin: this.adminLogin,
        };
        window.analytics?.identify(userTraits.tduserid, traits || {}, options, callback);
        if (typeof window?.analytics?.user === 'function') {
          await window.analytics?.user()?.traits(traits);
        }
      }
    } catch (e) { }
  }

  async initIdentify(options?: SegmentOptions, callback?: () => any) {
    try {
      if (this.analyticsEnabled()) {
        this.prepareData();
        const { user, company } = this;
        const traits = {
          firstname: user.name,
          email: user.email,
          tduserid: user.id,
          tdcompanyid: company.id,
          access_level: company.role ? (company.role === 'user' ? 'regular user' : company.role) : null,
          isAdminLogin: this.adminLogin,
        };
        window.analytics?.identify(user.id, traits || {}, options, callback);
      }
    } catch (e) { }
  }

  async trackInAppEvents(pageName?: string, extraProps: any = {}) {
    this.prepareData();
    if (!this.user || !this.company) {
      return false;
    }

    const { user, company } = this;
    const companyStatus = company?.subscription?.status || '';

    const defaultProps = {
      companyName: company.name,
      companyStatus,
      planName: company.pricingPlan,
      access_level: (company.role === 'user') ? 'regular user' : company.role,
      trackingType: company.companySettings?.trackingMode || '',
      tdcompanyid: company.id,
      tduserid: user.id,
    };

    extraProps = this.formatProperties(extraProps);
    if (extraProps.hasOwnProperty('selectedoption') && typeof extraProps.selectedoption !== 'object') {
      extraProps.selectedoption = { value: extraProps.selectedoption || '' };
    }

    const props = { ...defaultProps, ...extraProps };

    this.track(pageName, props, {}, false);
  }

  async trackWithUTMS(name?: any, extraProps: any = {}) {
    this.prepareData();
    if (!this.user || !this.company) {
      return false;
    }

    const { user, company } = this;
    const traits = await lastValueFrom(this.traitsService.allTraits$);

    const companyStatus =
      traits?.companyTraits?.status || company?.subscription?.status || '';

    const accessLevel = company.role === 'user' ? 'regular user' : company.role;

    const props = {
      name: user.name,
      email: user.email,
      access_level: accessLevel,
      planName: company.pricingPlan,
      trialStartDate: company.companyCreatedAt,
      trialEndDate: company.subscription?.expires,
      workspace_tracking_type:
        traits?.userTraits?.workspace_tracking_type ||
        company.companySettings?.trackingMode ||
        '',
      ...extraProps,
    };

    const context = {
      companyName: company.name,
      companyStatus,
      campaign:
        traits?.companyTraits?.utmCampaign || this.getDataLayer('utm_campaign'),
      source: traits?.companyTraits?.utmSource || this.getDataLayer('utm_source'),
      medium: traits?.companyTraits?.utmMedium || this.getDataLayer('utm_medium'),
      content: traits?.companyTraits?.utmContent || this.getDataLayer('utm_content'),
      keyword: this.getDataLayer('utm_keyword'),
      adgroup: this.getDataLayer('adgroup'),
    };

    this.track(name, props, context, false);
  }

  /**
   * Tracks events with common properties, including access level and optional custom properties.
   * @param event The name of the event to track.
   * @param [properties] Optional properties to include with the event.
   * @param [isOnboarding=false] Flag indicating if the event is part of the onboarding process.
   */
  trackWithCommonProps(event: string, properties: Record<string, any> = {}, isOnboarding: boolean = false): void {
    this.prepareData();
    const accesslevel = this?.company?.role === 'user'
      ? 'regular user'
      : (this.company?.role || 'unknown');
    const eventProperties = { accesslevel, ...properties };

    this.track(event, eventProperties, {}, isOnboarding);
  }


  async track(event: string, properties?: any, context?: any, isOnboarding?: boolean, options?: SegmentOptions, callback?: () => any) {
    this.prepareData();
    const { user, company } = this;
    if (user) {
      const props = { email: user.email, isAdminLogin: this.adminLogin };
      properties = properties ? { ...properties, ...props } : props;
    }
    context = { ...context, groupId: company.id };
    if (!isOnboarding) {
      await this.updateLocalCacheFromServer();
    }
    properties = this.formatProperties(properties);
    if (this.analyticsEnabled() && window?.analytics) {
      window.analytics?.track(event, properties, context, options, callback);
    }
    this.serverSideTracking(event, properties, context);
  }

  trackExperiment(expProps: ExperimentProps) {
    const trackedCookie = this.getCookie('tracked_' + expProps.experimentid);
    if (trackedCookie === 'true') return;

    this.trackInAppEvents('Experiment Viewed', expProps);
    this.setCookie('tracked_' + expProps.experimentid, 'true', 365);
  }

  formatProperties(props) {
    const result = {};
    for (const [key, value] of Object.entries(props)) {
      if (typeof value === 'object' && value !== null) {
        result[key.toLocaleLowerCase().replace(/_/g, '')] = this.formatProperties(value);
      } else {
        result[key.toLowerCase().replace(/_/g, '')] = value;
      }
    }
    return result;
  }

  async pageOnboarding(category?: any, name?: any, properties?: any, options?: SegmentOptions, callback?: () => any) {
    if (this.getParameter('from2a')) {
      return;
    }
    if (this.analyticsEnabled()) {
      this.prepareData();
      const { user, company } = this;
      if (user) {
        const props = { email: user.email };
        properties = properties ? { ...properties, ...props } : props;
      }
      const context = { options, context: { campaign: this.getUTMs() } };
      window.analytics?.page(category, name, properties, context, callback);
    }
  }

  async page(category?: any, name?: any, properties?: any, referrer?: any, options?: SegmentOptions, callback?: () => any) {
    const { user, company } = this;

    const props = { email: user?.email, isAdminLogin: this.adminLogin };
    properties = properties ? { ...properties, ...props } : props;

    if (name !== 'Time Doctor 2') {
      await this.updateLocalCacheFromServer();
    }
    const context = { ...options, campaign: this.getUTMs(), referrer, groupId: company?.id || null };
    properties = this.formatProperties(properties);
    if (this.analyticsEnabled() && window?.analytics) {
      if (category !== null) {
        window.analytics?.page(category, name, properties, context, callback);
      } else {
        window.analytics?.page(name, properties, context, callback);
      }
    }
    this.serverSidePageCall(category, name, properties, context);
  }

  loginPageCall() {
    const context: TrackingContext = {
      context: {
        campaign: this.getUTMs(),
        referrer: encodeURIComponent(window.document.referrer),
      },
      allowNonLoggedIn: true,
    };
    if (this.analyticsEnabled() && window?.analytics) {
      window.analytics?.page('Login Page', 'Login Page', {}, context);
    }
    this.serverSidePageCall('Login Page', 'Login Page', {}, context);
  }

  getUTMs() {
    return {
      name: this.getCookie('utm_campaign'),
      source: this.getCookie('utm_source'),
      medium: this.getCookie('utm_medium'),
      terms: this.getCookie('utm_terms'),
      content: this.getCookie('utm_content'),
    };
  }

  cleanObject(obj) {
    const propNames = Object.getOwnPropertyNames(obj);
    for (const propName of propNames) {
      if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
        delete obj[propName];
      }
    }
    return obj;
  }
  async updateLocalCacheFromServer() {
    if (this.analyticsEnabled() && typeof window?.analytics?.user === 'function') {
      let userTraits = await lastValueFrom(this.traitsService.userTraits$);
      if (!userTraits?.tduserid) return false;
      userTraits = this.cleanObject(userTraits);
      const traits = {
        email: userTraits.email,
        tduserid: userTraits.tduserid,
        tdcompanyid: userTraits.tdcompanyid,
        firstname: userTraits.firstname,
        lastname: userTraits?.lastname,
        access_level: userTraits.access_level,
        isAdminLogin: this.adminLogin,
      };
      const localTraits = await window.analytics?.user()?.traits();
      await window.analytics?.user()?.traits(traits);
      if (JSON.stringify(localTraits) !== JSON.stringify(traits) && this.analyticsEnabled()) {
        await window.analytics?.identify(userTraits.tduserid, traits);
      }
    }
  }
  getSubscriptionAmountByPlan(plan) {
    const plans = {
      basic: '7.00',
      standard_new: '10.00',
      premium: '20.00',
      business: '12.00',
      enterpirse: '24.00',
    };
    return (plan in plans) ? plans[plan] : '10.00';
  }

  async firstOnboardingCalls() {
    try {
      const res = await fetch('https://ipinfo.io/json');
      const data = await res.json();
      const countryCode = data.country ?? 'US';
      const countryName = countryNamesJson[countryCode] ?? '';
      this.serverSideCall({}, { contact_country: countryName });
    } catch (e) { }
  }
  clearLocalTraits() {
    if (this.analyticsEnabled() && typeof window?.analytics?.user === 'function') {
      window.analytics?.user()?.traits({});
    }
  }

  isFreeEmail(domain) {
    return freeEmailDomains.includes(domain);
  }
  cleanUrl(url: string, withWWW: boolean) {
    if (withWWW) {
      return `https://www.${url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0]}`;
    }
    return `https://${url.replace(/^(?:https?:\/\/)?(?:)?/i, '').split('/')[0]}`;
  }

  getGeoInfo(): Promise<GeoInfo> {
    const allowedDomains = ['timedoctor.com', 'timedoctortest.com', 'timedoctordev.com'];
    if (!allowedDomains.includes(this.getDomain()) || this.geoInfo) return this.geoInfo;

    const geoInfo: GeoInfo = LocalStorageHelper.getItemWithExpiry('geo-info');
    if (geoInfo?.ip) return this.geoInfo = Promise.resolve(geoInfo);

    return this.geoInfo = lastValueFrom(
      this.httpClient.get<GeoInfo>(
        environment.geoInfoApiUrl,
        { params: { token: undefined } },
      ).pipe(
        tap(data => LocalStorageHelper.setItemWithExpiry('geo-info', data, 7)),
        catchError(() => of({})),
      ),
    );
  }

  async serverSidePageCall(categoryName: string, pageName: string, properties = {}, ctx: TrackingContext = {}) {
    const { allowNonLoggedIn, ...allCtx } = ctx;
    if (!this.analyticsEnabled(false, allowNonLoggedIn)) return false;

    const geoInfo = await this.getGeoInfo();
    const context = {
      ...allCtx,
      page: {
        path: window.location.pathname,
        referrer: window.document.referrer,
        search: window.location.search,
        title: window.document.title,
        url: window.location.href,
      },
    };
    const deviceInfo = this.deviceDetector.initialize();
    properties = {
      path: window.location.pathname,
      referrer: window.document.referrer,
      search: window.location.search,
      title: window.document.title,
      url: window.location.href,
      ipaddress: geoInfo?.ip,
      country: geoInfo?.geo?.country,
      ...properties,
      ...deviceInfo,
    };
    const { user } = this;
    const anonymousId = await this.uuid();
    const segmentanonymousid = await this.getAnonymousId();
    const payload = {
      category: categoryName,
      name: pageName,
      userId: user?.id,
      anonymousId,
      segmentanonymousid,
      properties,
      context,
      type: 'page',
    };

    const requestOptions = { headers: { 'x-api-key': environment.dataIntegrityApiKey } };
    this.httpClient.post(
      this.getGoogleCloudFunctionUrl() + '/tracking',
      payload,
      requestOptions,
    ).subscribe();
  }

  async serverSideTracking(eventName: string, props: TrackEventProps = {}, ctx: TrackingContext = {}) {
    const { allowNonLoggedIn, ...allCtx } = ctx;
    if (!this.analyticsEnabled(false, allowNonLoggedIn)
      && props.category !== 'Login Screen' && eventName !== 'Login Error'
    ) {
      console.log('Track event', '/ Event Name: ', eventName, '/ Properties: ', props, '/ Context: ', allCtx);
      return;
    }

    const excludedEvent = ['Account Created', 'Onboard Complete', 'Signed Out', 'Time Doctor 2'];
    if (excludedEvent.includes(eventName)) {
      return false;
    }
    const geoInfo = await this.getGeoInfo();
    const context = {
      ...allCtx,
      ...{ campaign: this.getUTMs() },
      page: {
        path: window.location.pathname,
        referrer: window.document.referrer,
        search: window.location.search,
        title: window.document.title,
        url: window.location.href,
      },
    };
    this.prepareData();
    const { user } = this;
    const anonymousId = await this.uuid();
    const segmentanonymousid = await this.getAnonymousId();
    const deviceInfo = this.deviceDetector.initialize();
    const { category, ...allProps } = props;
    const properties = {
      ipaddress: geoInfo?.ip,
      country: geoInfo?.geo?.country,
      ...allProps,
      ...deviceInfo,
    };

    const payload = {
      event: eventName,
      category,
      userId: user?.id,
      anonymousId,
      segmentanonymousid,
      properties,
      context,
      type: 'track',
    };
    const requestOptions = { headers: { 'x-api-key': environment.dataIntegrityApiKey } };
    this.httpClient.post(
      this.getGoogleCloudFunctionUrl() + '/tracking',
      payload,
      requestOptions,
    ).subscribe();
  }

  async serverSideCall(
    extraCompanyTraits?: any,
    extraUserTraits?: any,
    sendSegment: boolean = false,
    isAccountCreated: boolean = false,
  ) {
    this.prepareData();
    if (!this.user || !this.company) return false;
    const { company, user } = this;
    const companyTraits = {
      tdcompanyid: company.id,
      ...extraCompanyTraits,
    };
    const userTraits = {
      tdcompanyid: company.id,
      tduserid: user.id,
      email: user.email,
      ...extraUserTraits,
    };

    const traits = {
      userTraits,
      companyTraits,
      send_segment: sendSegment,
      isAccountCreated,
    };

    const isAutomation = this.getParameter('automation') ?? window?.localStorage?.getItem('automation');
    const automationParam = isAutomation ? '?automation=1' : '';
    this.httpClient.post(
      this.getGoogleCloudFunctionUrl() + '/traits' + automationParam,
      traits,
    ).subscribe();
  }

  isEmptyObject(obj: Object): boolean {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }
  async uuid() {
    this.prepareData();
    const { company } = this;
    const customUserSettings = company.userSettings?.custom as ExtCustomCompanySettings;
    const tdAnonymousIdFromCookie = this.getCookie('td_anonymousId');
    let uuid = company.role === 'owner' ? customUserSettings?.tdAnonymousId : tdAnonymousIdFromCookie;
    if (!uuid) {
      let dt = new Date().getTime();
      uuid = 'TD-xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        // eslint-disable-next-line no-bitwise
        const r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        // eslint-disable-next-line no-bitwise
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
      });
      this.setCookie('td_anonymousId', uuid, 365);
      if (!this.isEmptyObject(company) && company.subscription.status !== 'free' && company.role === 'owner') {
        try {
          await lastValueFrom(this.userService.editProperties('me', { 'custom.tdAnonymousId': uuid } as any));
          await lastValueFrom(this.store.dispatch(new UserSettingsChanged(null, { tdAnonymousId: uuid })));
        } catch (e) {
          Sentry.captureException(e);
        }
      }
    }
    return uuid;
  }

  getCookie(name) {
    const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) { return decodeURI(match[2]); }
  }

  getGoogleCloudFunctionUrl() {
    let firebaseFunctionUrl = 'https://data-integrity.timedoctor.com/api/v1';
    if (this.getDomain() === 'timedoctortest.com' || this.getDomain() === 'timedoctordev.com') {
      firebaseFunctionUrl = 'https://data-integrity.timedoctortest.com/api/v1';
    }

    return environment.dataIntegrityApiUrl || firebaseFunctionUrl;
  }

  dataForExternalTracking() {
    const { user, company } = this;
    const userData = (user && company) ? {
      email: user?.email,
      name: user?.name,
      userId: user?.id,
      companyId: company?.id,
    } : null;

    let tdudft = null;
    if (userData) {
      try {
        const jsonString = JSON.stringify(userData);
        const utf8Bytes = new TextEncoder().encode(jsonString);
        tdudft = btoa(String.fromCharCode(...utf8Bytes));
      } catch (error) {
        Sentry.captureMessage('Error encoding user data', {
          level: 'warning',
          extra: { error },
        });
      }
    }

    const daysToExpire = tdudft ? 7 : 0;
    this.setCookie('__tdudft', tdudft, daysToExpire);
  }

  getDomain() {
    const domain = window.location.hostname;
    if (domain.includes('localhost') || domain.includes('timedoctor.vercel.app')) return 'timedoctortest.com';

    const subdomains = domain.split('.').reverse();
    return subdomains[1]?.includes('timedoctor') ? `${subdomains[1]}.${subdomains[0]}` : '';
  }

  setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    const expires = 'expires=' + d.toUTCString();
    const domain = window.location.hostname;
    const subdomains = domain.split('.').reverse();
    const subdomain = subdomains[1]?.includes('timedoctor') ? `${subdomains[1]}.${subdomains[0]}` : domain;
    if (subdomain !== '') {
      document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/;domain=' + subdomain;
    }
  }

  removeCookie(cname) {
    if (this.getCookie(cname)) {
      const domain = window.location.hostname;
      const expires = 'expires=Thu, 01 Jan 1970 00:00:00 UTC';
      const subdomains = domain.split('.').reverse();
      const subdomain = `${subdomains[1]}.${subdomains[0]}`;
      document.cookie = cname + '=' + ';' + expires + ';path=/;domain=' + subdomain;
    }
  }

  getDataLayer(prop) {
    const dataLayer = (window as any).dataLayer;
    if (!dataLayer) {
      return '';
    }
    for (const index in dataLayer) {
      if (dataLayer[index][prop]) {
        return dataLayer[index][prop] ?? '';
      }
    }
    return '';
  }

  getExperimentDataByName(exprName: string): any {
    this.prepareData();
    const { company } = this;
    const experiments = company?.splitTest || [];
    for (const expr of experiments) {
      if (expr.name === exprName) {
        return expr.value || '';
      }
    }
    return '';
  }

  trackReactivationRequest(company: AuthCompany) {
    const validHomecta = ['Reactivation20', 'Reactivation50'];
    const homecta = this.getParameter('homecta');
    if (
      !this.branding.billingDisabled()
      && (company?.userSettings?.billingAccess || company?.role === 'owner')
      && company.subscription?.status === 'free'
      && validHomecta.includes(homecta)
    ) {
      this.track('Coupon Requested', {
        type: 'Reactivation',
        code: homecta,
        companyname: company.name,
        planname: company.pricingPlan,
        accesslevel: (company.role === 'user') ? 'regular user' : company.role,
      });
      this.paymentService.applyDiscount(company.id, { coupon: homecta }).subscribe(
        (response: { message: string }) => {
          if (response.message === 'Reactivation applied coupon') {
            this.track('Coupon Applied', {
              type: 'Reactivation',
              code: homecta,
              companyname: company.name,
              planname: company.pricingPlan,
              accesslevel: (company.role === 'user') ? 'regular user' : company.role,
            });
          }
        },
        (error: { message: string }) => {
          this.track('Coupon Application Fails', {
            type: 'Reactivation',
            code: homecta,
            companyname: company.name,
            planname: company.pricingPlan,
            accesslevel: (company.role === 'user') ? 'regular user' : company.role,
            errormessage: error.message,
          });
        },
      );
    }
  }

  getParameter(parameterName) {
    let result = null;
    let tmp = [];
    location.search
      .substr(1)
      .split('&')
      .forEach(item => {
        tmp = item.split('=');
        if (tmp[0] === parameterName) { result = decodeURIComponent(tmp[1]); }
      });
    return result;
  }

  analyticsEnabled(enableForAutomation: boolean = false, allowNonLoggedTrackCall: boolean = false): boolean {
    if (window?.localStorage?.getItem('automation') && !enableForAutomation) {
      return false;
    }
    if (this.getParameter('automation')) {
      window.localStorage.setItem('automation', this.getParameter('automation'));
      if (!enableForAutomation) {
        return false;
      }
    }

    if (!(environment.enableAnalytics && this.branding.isDefault)) {
      return false;
    }

    this.adminLogin = this.store.selectSnapshot((x: AppState) => x.auth.adminLogin) || false;

    if (!this.user) {
      this.prepareData();
    }

    if (allowNonLoggedTrackCall) return true;

    if (!this.user) return false;

    if (/@timedoctor(dev|test)\.com$/i.test(this.user.email)) {
      // do not allow timedoctordev.com and timedoctortest.com emails
      return false;
    }

    if (this.getDomain() === 'timedoctor.com') {
      return /^(?!.*@(timedoctor|staff)\.com$).+@.*\.(dev|.+)$/i.test(this.user.email);
    }

    return true;
  }

  async updateUserTraits(userTraits?: any, sendSegment?: boolean) {
    const company = this.store.selectSnapshot(AuthSelectors.company);
    const isAutomation = this.getParameter('automation') ?? window?.localStorage?.getItem('automation');
    const companyTraits = { tdcompanyid: company.id };

    const traits = { userTraits, companyTraits, send_segment: !!sendSegment };
    const automationParam = isAutomation ? '?automation=1' : '';
    this.httpClient.post(
      this.getGoogleCloudFunctionUrl() + '/traits' + automationParam,
      traits,
    ).subscribe();
  }

  async directivesData(type: string, context: Partial<TrackingContextType>) {
    let props = {};
    const pageEvent = context.page;
    delete context.page;
    const basicOptions = ['tabs', 'columns'];
    if (pageEvent === undefined) return false;
    if (context.hasOwnProperty('option')) {
      switch (context.option) {
        case 'timezone':
          delete context.option;
          props = transformTimezoneSelection(context);
          this.trackInAppEvents(pageEvent, props);
          break;
        case 'userpulldown':
          if (!context.userData) break;
          delete context.option;
          if (!context?.userData) break;
          props = transformUserLazySelection(context);
          this.trackInAppEvents(pageEvent, props);
          break;
        case 'periodpicker':
          delete context.option;
          props = transformPeriodPickerSelection(context);
          this.trackInAppEvents(pageEvent, props);
          break;
        case basicOptions.find((x) => x === context.option):
          delete context.option;
          delete Object.assign(context, { ['selectedoption']: context['newValue'] })['newValue'];
          context['selectedoption'] = { value: context['selectedoption'] };
          this.trackInAppEvents(pageEvent, context);
          break;
        case 'periodTabs':
          delete context.option;
          if (context.newValue !== 'days') {
            props = transformPeriodTabsSelection(context);
            this.trackInAppEvents(pageEvent, props);
          }
          break;
        case 'sortColumns':
          delete context.option;
          if (context.sort.direction !== '') {
            props = transformSortColumnsSelection(context);
            this.trackInAppEvents(pageEvent, props);
          }
          break;
        case 'stackChart':
          delete context.option;
          if (context?.newValue !== null) {
            props = transformStackChartSelection(context);
            this.trackInAppEvents(pageEvent, props);
          }
          break;
        case 'entityFilter':
          delete context.option;
          props = transformEntityFilter(context);
          this.trackInAppEvents(pageEvent, props);
          break;
        case 'userlazy':
          delete context.option;
          props = await transformRadioUserSelection(context);
          if (props !== null) this.trackInAppEvents(pageEvent, props);
          break;
        case 'timetube':
          const selectedWorklog = context?.selectedWorklog;
          delete context.option;
          delete context.page;
          delete context.selectedWorklog;
          context.selectedOption = transformTimeTubeSelection(selectedWorklog);
          this.trackInAppEvents(pageEvent, context);
          break;
        case 'editTimeTransform':
          delete context.option;
          props = transformEditTime(context, this.clockPipe);
          if (props['selectedOption'].value !== null) this.trackInAppEvents(pageEvent, props);
          break;
        case 'selectedTab':
          delete context.option;
          props = transformTabsSelection(context);
          if (props['selectedOption'].value !== null) {
            this.trackInAppEvents(pageEvent, props);
          }
          break;
        case 'ratioMinHours':
          delete context.option;
          context.newValue = context.newValue / 3600;
          props = transformNewValue(context);
          this.trackInAppEvents(pageEvent, props);
          break;
        case 'highestidle':
          delete context.option;
          props = transformHighestIdleWidget(context);
          this.trackInAppEvents(pageEvent, props);
          break;
        case 'selectedtabtoname':
          delete context.option;
          context.name = 'Clicked ' + context.selectedTab.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
          delete context.selectedTab;
          this.trackInAppEvents(pageEvent, context);
          break;
        case 'activitysummary':
          if (!context?.newValue) break;
          context.selectedOption = transformActivitySummary(context);
          delete context.option;
          delete context.newValue;
          this.trackInAppEvents(pageEvent, context);
          break;
        case 'exportbutton':
          delete context.option;
          const organization = context.selectedOption?.organization?.toLowerCase();
          const userIdentification = context.selectedOption?.userFields;
          const newObject = {
            ...context.selectedOption,
            content: undefined,
            userFields: undefined,
            userAdditionalColumns: undefined,
            organization: (context?.sidepanel) ? undefined : organization,
            userIdentification,
          };

          this.trackInAppEvents(pageEvent, { name: context?.name, location: context?.location, selectedOption: newObject });
          break;
        case 'treeReport':
          context.selectedOption = transformTreeReport(context);
          delete context.option;
          delete context.selectedView;
          delete context.timesListEnabled;
          this.trackInAppEvents(pageEvent, context);
          break;
      }
    } else {
      props = transformNewValue(context);
      this.trackInAppEvents(pageEvent, props);
    }
  }

  async getAnonymousId() {
    if (
      typeof window.analytics?.user === 'function' &&
      window.analytics?.user()?.anonymousId()
    ) {
      return window?.analytics?.user()?.anonymousId();
    }
    return null;
  }

  trackLinkClick(linkname: string, linkurl = '#', linklocation = 'header') {
    const deploymentId = ['uspublic', 'staging'];
    const isPublicDeployment = deploymentId.includes(environment.deployment);

    if (!isPublicDeployment) return;

    const trackObj = {
      category: 'Login Screen',
      linkname,
      linklocation,
      linktype: 'text',
      linkurl,
      currenturl: window.location.href,
      pagename: document.title,
    };

    this.track('Link Click', trackObj);
  }

  trackLink(anchorElm: HTMLAnchorElement, context: Partial<TrackingContextType> = {}) {
    const linkname = anchorElm.innerText;
    const linkurl = anchorElm.href;

    // Delete 'page' property if it exists
    delete context.page;

    const trackProps = {
      linkname,
      linkurl,
      ...context,
    };

    this.trackWithCommonProps('Link Click', trackProps);
  }
}
