import { APP_BASE_HREF } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { GrowthBookService } from 'src/app/services/growthbook/service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent {
  newLoadingGifEnabled: boolean;

  constructor(@Inject(APP_BASE_HREF) public baseHref: string,
    private growthBook: GrowthBookService) {
    this.newLoadingGifEnabled = this.growthBook.getFeatureValue<boolean>('new-loading-gif', false);
  }
}
