import { Directive } from '@angular/core';
import { BREAKPOINT, ShowHideDirective } from '@angular/flex-layout';

const HandsetBreakPoints = [{
  alias: 'handset',
  suffix: 'Handset',
  mediaQuery: '(max-width: 599.99px) and (orientation: portrait), (max-width: 959.99px) and (orientation: landscape)',
  overlapping: false,
  priority: 1001,
}, {
  alias: 'desktop',
  suffix: 'Desktop',
  mediaQuery: '(min-width: 599.99px) and (orientation: portrait), (min-width: 959.99px) and (orientation: landscape)',
  overlapping: false,
  priority: 1001,
}];

export const CustomBreakPointsProvider = {
  provide: BREAKPOINT,
  useValue: HandsetBreakPoints,
  multi: true,
};


const selector = `[fxHide.handset], [fxHide.desktop]`;
const inputs = ['fxHide.handset', 'fxHide.desktop'];

/* eslint-disable-next-line @angular-eslint/no-inputs-metadata-property */
@Directive({ selector, inputs })
export class CustomShowHideDirective extends ShowHideDirective {
  protected inputs = inputs;
}
