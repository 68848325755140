import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-td-logo',
  templateUrl: './td-logo.component.html',
  styleUrls: ['./td-logo.component.scss'],
})
export class TDLogoComponent {
  @Input() logoUrl = '/assets/images/brand/timedoctor/logo-full.svg'; // default value
}
