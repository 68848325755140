import { InjectionToken } from '@angular/core';
import { Handler } from '@salte-auth/salte-auth';

export type AppType = 'web' | 'interactive' | 'silent' | 'other';

export interface LoginParameters {
  appName: string;
  logoPath: string;
  hideCreateAccount: boolean;
  ssoEnabled?: boolean;
  ssoHandlers?: Handler[];
}

export const LOGIN_PARAMETERS = new InjectionToken<LoginParameters>('Login Parameters', {
  providedIn: 'root',
  factory: () => ({
    appName: 'Time Doctor',
    logoPath: '/assets/images/brand/timedoctor/logo.svg',
    hideCreateAccount: false,
    ssOEnabled: false,
  }),
});


export const APP_URL = new InjectionToken<string>('App Url');

export const LOGIN_URL = new InjectionToken<string>('Login Url', { providedIn: 'root', factory: () => '/login/select' });
export const LOGIN_NEW_URL = new InjectionToken<string>('Login New Url', { providedIn: 'root', factory: () => '/login' });
export const FORGOT_PASSWORD_URL = new InjectionToken<string>('Forgot password Url',
  { providedIn: 'root', factory: () => '/login/forgot-password' });

export const SSO_CALLBACK_URL = new InjectionToken<string>('SSO Callback Url', {
  providedIn: 'root',
  factory: () => '{origin}/login/oauth2/{provider}/callback',
});


export const COMPANY_SELECT_URL =
  new InjectionToken<string>('Company Select Url', { providedIn: 'root', factory: () => '/login/company-select' });

export const HOME_URL = new InjectionToken<string>('Home Url', { providedIn: 'root', factory: () => '/' });

export const USE_WEB_SOCKET = new InjectionToken<boolean>('Use Web Socket', { providedIn: 'root', factory: () => true });
export const HTTP_TIMEOUT = new InjectionToken<number>('Http Timeout', { providedIn: 'root', factory: () => 3 * 60 * 1000 });
export const API_URL = new InjectionToken<string>('Api URL');
export const SOCKET_API_ROOT = new InjectionToken<string>('Socket API Root');
export const SOCKET_HOST = new InjectionToken<string>('Socket Host');
export const DEFAULT_API_VERSION = new InjectionToken<string>('Default Api Version', { providedIn: 'root', factory: () => '1.0' });
export const APP_NAME = new InjectionToken<string>('App Name');
export const APP_TYPE = new InjectionToken<string>('App Type');
export const LOGIN_SHOW_REMEMBER_ME = new InjectionToken<boolean>('Use Web Socket', { providedIn: 'root', factory: () => true });
export const GET_CLOCK_FORMAT = new InjectionToken<() => (12 | 24)>('Get Clock Format', { providedIn: 'root', factory: () => () => 12 });
export const GOOGLE_CLIENT_ID = new InjectionToken<string>('GOOGLE CLIENT ID', { providedIn: 'root', factory: () => undefined });
export const GOOGLE_CALLBACK_URI = new InjectionToken<string>('GOOGLE CALLBACK URI', { providedIn: 'root', factory: () => undefined });
export const DATA_INTEGRITY_APIURL = new InjectionToken<string>('DATA INTEGRITY API URL', { providedIn: 'root', factory: () => undefined });
export const DATA_INTEGRITY_APIKEY = new InjectionToken<string>('DATA INTEGRITY API KEY', { providedIn: 'root', factory: () => undefined });
export const TDC_CHECK_EMAIL_END_POINT = new InjectionToken<string>('TDC CHECK EMAIL END POINT', { providedIn: 'root', factory: () => undefined });
