import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { BillingService } from 'src/app/services/billing/billing.service';
import { map, tap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { AuthState } from 'src/app/store/auth/auth.state';
import { SetBillingData } from 'src/app/pages/new-billing/store/actions';
import { of } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class NewEndOfTrialGuard implements CanActivate {
  constructor(
    private store: Store,
    private router: Router,
    private billingService: BillingService,
  ) { }


  canActivate() {
    const company = this.store.selectSnapshot(AuthState.company);

    if (company.subscription?.provider === 'paddle') {
      return this.billingService.getBillingData().pipe(
        tap(billingPlans => this.store.dispatch(new SetBillingData(billingPlans))),
        map(billingDetails => {
          const { customerStatus, cardLastFourDigits } = billingDetails.billingDetails;
          if (customerStatus === 'past_due' && !cardLastFourDigits) return true;
          return this.router.createUrlTree(['/new-billing']);
        }),
      );
    }
    return of(false);
  }
}
