import { Selector } from '@ngxs/store';
import { AuthStateModel, AUTH_STATE_TOKEN } from 'src/app/store/auth/auth.model';

export class AuthSelectors {
  @Selector([AUTH_STATE_TOKEN])
  static company(state: AuthStateModel) {
    return state.company;
  }

  @Selector([AUTH_STATE_TOKEN])
  static user(state: AuthStateModel) {
    return state.user;
  }

  @Selector([AUTH_STATE_TOKEN])
  static inviteLink(state: AuthStateModel) {
    return state.company?.inviteLink;
  }

  @Selector([AUTH_STATE_TOKEN])
  static ownerOrAdmin(state: AuthStateModel) {
    return state.company?.role === 'admin' || state.company?.role === 'owner';
  }

  @Selector([AUTH_STATE_TOKEN])
  static hasTrackedTime(state: AuthStateModel) {
    return state.user.lastTrackGlobal !== undefined;
  }

  @Selector([AUTH_STATE_TOKEN])
  static role(state: AuthStateModel) {
    return state.company?.role;
  }

  @Selector([AUTH_STATE_TOKEN])
  static isUserActive(state: AuthStateModel) {
    return state.user?.active;
  }

  @Selector([AUTH_STATE_TOKEN])
  static homeCta(state: AuthStateModel) {
    return state.company?.splitTest?.find(test => test.name === 'popup-signup-trigger')?.value ?? '';
  }

  @Selector([AUTH_STATE_TOKEN])
  static isUserOnline(state: AuthStateModel) {
    return state.user?.lastSeenGlobal?.online || false;
  }

  @Selector([AUTH_STATE_TOKEN])
  static isTrackingTime(state: AuthStateModel) {
    return state.user?.lastTrackGlobal?.online || false;
  }

  @Selector([AUTH_STATE_TOKEN])
  static userCount(state: AuthStateModel) {
    return state.company?.userCount || 1;
  }

  @Selector([AUTH_STATE_TOKEN])
  static isTrackingWidgetVisible(state: AuthStateModel) {
    return !!state.company.userSettings.custom?.dismissedTrackingWidget;
  }
}
