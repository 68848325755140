<div class="countdown-timer" [ngClass]="fontSizeClass">
  <!-- Display the countdown if remainingTime is defined -->
  <h1 *ngIf="remainingTime !== null">
    <!-- Conditionally render the countdown based on timerLocation -->
    <ng-container *ngIf="timerLocation === 'popup'; else banner">
      {{ getFormattedTime(remainingTime) }}
    </ng-container>

    <!-- Render different content for the banner -->
    <ng-template #banner>
      {{ 'trialOfferExperiment.banner.text' | translate: { countdown: getFormattedTime(remainingTime) } }}
    </ng-template>
  </h1>
</div>
