import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-td-task-progress',
  templateUrl: './td-task-progress.component.html',
  styleUrls: ['./td-task-progress.component.scss'],
})
export class TDTaskProgressComponent implements OnInit {
  @Input() completedTasks = 0;
  @Input() totalTasks = 1;

  get progress(): number[] {
    return Array.from({ length: this.totalTasks }, (_, i) => i < this.completedTasks ? 100 : 0);
  }

  ngOnInit(): void {
    if (this.completedTasks > this.totalTasks) {
      console.error('Completed tasks cannot exceed total tasks');
    }
  }
}
