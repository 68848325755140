
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { GrowthBookService } from './service';

@Injectable({ providedIn: 'root' })
export class GrowthBookResolver implements Resolve<boolean> {
  constructor(private service: GrowthBookService) { }

  resolve() {
    return this.service.initializedPromise;
  }
}
