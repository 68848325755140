<a *ngIf="isAbsoluteUrl" [href]="url" [target]="target || '_blank'" [rel]="rel">
  <ng-container [ngTemplateOutlet]="contentTemplate"></ng-container>
</a>
<a *ngIf="!isAbsoluteUrl" [routerLink]="url" [target]="target || '_self'">
  <ng-container [ngTemplateOutlet]="contentTemplate"></ng-container>
</a>

<ng-template #contentTemplate>
  <ng-content></ng-content>
</ng-template>
