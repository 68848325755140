import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-info',
  templateUrl: './info-tooltip.component.html',
  styleUrls: ['./info-tooltip.component.scss'],
})
export class InfoTooltipComponent {
  @Input() tooltip: string;
}
