import { ChangeDetectorRef, Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, distinctUntilChanged, map, switchMap } from 'rxjs';
import { GrowthBookService } from './service';


/**
 * Used in Angular templates to show/hide certain parts of UI depending on GrowthBook feature flags.
 * `match` can be used to match the value to a given value instead of the defautl true/false.
 * Exclamation mark (!) in front of key name signifies that the condition is inverted.
 * `default` can be used to evaluate a value to a default value before GB is initialized.
 * If no `default` is given, value will evaluate to `null`. The element will be hidden when value evaluates to `null`.
 *
 * @example
 *
 * ```
 * <div *ngIfFeature="'schedules'">
 *   Schedules are enabled
 * </div>
 *
 * <div *ngIfFeature="'!download'">
 *   Downloads are disabled
 * </div>
 *
 * <div *ngIfFeature="'billing'; match='new'">
 *   New billing page is enabled
 * </div>
 * ```
 */
@Directive({
  selector: '[ngIfFeature]',
})
@UntilDestroy()
export class NgIfFeatureDirective implements OnInit {
  private featureName = new BehaviorSubject('');
  private featureMatch = new BehaviorSubject<any>(true);
  private featureDefaultValue = new BehaviorSubject<any>(null);
  private conditionInverted = new BehaviorSubject(false);
  private elseTemplateRef: TemplateRef<unknown>;

  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<object>,
    private cd: ChangeDetectorRef,
    private growthbook: GrowthBookService,
  ) { }

  @Input() set ngIfFeature(newFeatureName: string) {
    this.conditionInverted.next(!!newFeatureName.match(/^!/));
    this.featureName.next(newFeatureName.replace(/^!/, ''));
  }

  @Input() set ngIfFeatureMatch(val: any) {
    this.featureMatch.next(val);
  }

  @Input() set ngIfFeatureDefault(val: any) {
    this.featureDefaultValue.next(val);
  }

  @Input() set ngIfFeatureElse(template: TemplateRef<unknown>) {
    this.elseTemplateRef = template;
  }

  ngOnInit() {
    combineLatest([
      this.featureName.pipe(distinctUntilChanged()),
      this.featureMatch.pipe(distinctUntilChanged()),
      this.featureDefaultValue.pipe(distinctUntilChanged()),
      this.conditionInverted.pipe(distinctUntilChanged()),
    ])
      .pipe(
        untilDestroyed(this),
        switchMap(([name, match, defaultValue, inverted]) =>
          this.growthbook.getFeature(name, defaultValue).pipe(
            map(val =>
              ((val === match) !== inverted)
              && (val !== null || match === null)))),
        distinctUntilChanged(),
      ).subscribe((condition) => {
        this.viewContainer.clear();

        if (condition) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else if (this.elseTemplateRef) {
          this.viewContainer.createEmbeddedView(this.elseTemplateRef);
        }

        this.cd.detectChanges();
      });
  }
}
