<ng-container *ngIf="!singleColor">
  <div class="worklog-part computer" [style.width.%]="computerWidth * 100"
    [matTooltip]="summary?.computer | durationHms">
  </div>
  <div class="worklog-part manual" [style.width.%]="manualWidth * 100" [matTooltip]="summary?.manual | durationHms">
  </div>
  <div class="worklog-part mobile" [style.width.%]="mobileWidth * 100" [matTooltip]="summary?.mobile | durationHms">
  </div>
  <div class="worklog-part break" [style.width.%]="paidBreakWidth * 100"
    [matTooltip]="(summary?.paidBreak + summary?.unpaidBreak)| durationHms"></div>
  <div class="worklog-part paid-leave" [style.width.%]="paidLeaveWidth * 100" [matTooltip]="(timelineReport && unpaidLeavesEnabled) ?
    (('editTime.paidLeave'| translate) +  ' - ' + (summary?.paidLeave | durationHms)) : (summary?.paidLeave | durationHms)">
  </div>
  <div *ngIf="timelineReport && unpaidLeavesEnabled" class="worklog-part unpaid-leave" [style.width.%]="unpaidLeaveWidth * 100"
    [matTooltip]="('editTime.unpaidLeave'| translate) +  ' - ' + (summary?.unpaidLeave | durationHms)">
    <div class="unpaidleave-mark"></div>
  </div>
</ng-container>

<ng-container *ngIf="singleColor">
  <div class="worklog-part computer" [style.background]="singleColorValue" [style.width.%]="totalWidth * 100" [matTooltip]="summary?.[totalFieldName] | durationHms">
  </div>
</ng-container>
