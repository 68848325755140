import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { PopProgress, PushProgress } from './progress.actions';


export interface ProgressStateModel {
  queued: number;
  done: number;
  diff: number;
}

@State<ProgressStateModel>({
  name: 'progress',
  defaults: {
    queued: 0,
    done: 0,
    diff: 0,
  },
})
@Injectable()
export class ProgressState {
  @Selector()
  static show(state: ProgressStateModel) { return state && state.queued !== state.done; }

  @Selector()
  static indeterminate(state: ProgressStateModel) { return state && state.diff <= 1; }

  @Selector()
  static percentage(state: ProgressStateModel) {
    if (!state || ProgressState.indeterminate(state)) {
      return 0;
    }
    return 100 - (100 * (state.queued - state.done) / state.diff);
  }

  @Action(PushProgress)
  pushProgress({ patchState, getState }: StateContext<ProgressStateModel>, { amount }: PopProgress) {
    const state = getState();
    const newAmount = (state.queued || 0) + amount;
    const newDiff = (state.diff || 0) + amount;
    patchState({ queued: newAmount, diff: newDiff });
  }

  @Action(PopProgress)
  PopProgress({ patchState, getState }: StateContext<ProgressStateModel>, { amount }: PopProgress) {
    const state = getState();
    const newAmount = (state.done || 0) + amount;
    const newDiff = (state.queued === newAmount) ? 0 : state.diff;
    patchState({ done: newAmount, diff: newDiff });
  }
}
