import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { lastValueFrom } from 'rxjs';
import { SegmentService } from 'src/app/services/segment/segment.service';
import { ShowTrialWidgetType } from 'src/app/store/trial-widget/trial-widget.actions';

export interface TrialWidgetStateModel {
    lastUpdated: number | null;
    hubspotOwnerId: string;
    trialWidgetType: 'Regular';
    companySize: string;
}

@State<TrialWidgetStateModel>({
    name: 'trialWidget',
    defaults: {
        lastUpdated: 0,
        hubspotOwnerId: null,
        trialWidgetType: 'Regular',
        companySize: null,
    },
})

@Injectable()
export class TrialWidgetState {
    constructor(
      private segment: SegmentService,
    ) {
    }

    @Selector()
    static getHubspotOwnerId(state: TrialWidgetStateModel) { return state.hubspotOwnerId; }

    @Selector()
    static lastUpdated(state: TrialWidgetStateModel) { return state.lastUpdated; }

    @Selector()
    static getTrialWidgetType(state: TrialWidgetStateModel) { return state.trialWidgetType; }

    @Action(ShowTrialWidgetType)
    async ShowTrialWidgetType(ctx: StateContext<TrialWidgetStateModel>) {
      let companySize = ctx.getState().companySize;

      if (!companySize) {
        const companyTraits = await lastValueFrom(this.segment.traitsService.companyTraits$);
        companySize = companyTraits.tdpeopleinthecompany;
        ctx.patchState({ companySize });
      }
    }
}
