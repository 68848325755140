export class UpdateOnlineStatus {
  static type = '[Shared] Update Online Status';
}

export class UpdateVisibleStatus {
  static type = '[Shared] Update Visible Status';
}

export class SetFocusedStatus {
  static type = '[Shared] Set Focused Status';

  constructor(public focused: boolean) { }
}
