import { Injectable } from '@angular/core';
import { from, lastValueFrom } from 'rxjs';
import { UserApiDetail, UserApiParams, UserService } from 'src/app/services/user/user.service';
import { Approval, Break, Project, Task, User, UserGroup } from 'src/models';
import { ApprovalsService } from './approvals/approvals.service';
import { BreakService } from './break/break.service';
import { GroupService } from './group/group.service';
import { PagingService } from './paging/paging.service';
import { GetProjectsOptions, ProjectService } from './project/project.service';

export type Modification<TModel> = (item: TModel) => TModel;

@Injectable({ providedIn: 'root' })
export class ResolveService {
  private resolvedUsers: Record<string, Map<string, User>> = {};
  private waitingUsers: Record<string, Map<string, Promise<any>>> = {};

  private projectBuffer: Set<string> = new Set();
  private resolvedProjects: Map<string, Project> = new Map();
  private waitingProjects: Map<string, Promise<any>> = new Map();

  private resolvedTasks: Map<string, Task> = new Map();
  private waitingTasks: Map<string, Promise<any>> = new Map();

  private groupBuffer: Set<string> = new Set();
  private resolvedGroups: Map<string, UserGroup> = new Map();
  private waitingGroups: Map<string, Promise<any>> = new Map();

  private breakBuffer: Set<string> = new Set();
  private resolvedBreaks: Map<string, Break> = new Map();
  private waitingBreaks: Map<string, Promise<any>> = new Map();

  private approvalsBuffer: Set<string> = new Set();
  private resolvedApprovals: Map<string, Approval> = new Map();
  private waitingApprovals: Map<string, Promise<any>> = new Map();

  private bufferWait: Promise<any>;

  constructor(
    private userService: UserService,
    private projectService: ProjectService,
    private groupService: GroupService,
    private breakService: BreakService,
    private approvalsService: ApprovalsService,
    private paging: PagingService,
  ) { }


  resolveUser(id: string, params?: UserApiParams) {
    return this.resolveUsers([id], params).then(x => x.get(id));
  }

  resolveUsers(ids: string[], params?: UserApiParams) {
    const detail = params?.detail || 'alias';
    const callback = (cbIds: string[]) => this.userService.users({
      user: cbIds.join(','),
      detail,
      'include-archived-users': 1,
      ...params,
    }).page(0);

    const resolved = this.resolvedUsers[detail] ??= new Map<string, User>();
    const waiting = this.waitingUsers[detail] ??= new Map<string, Promise<any>>();

    return this.resolve(ids, resolved, waiting, callback);
  }

  modifyUser(id: string, action: Modification<User>, detail?: UserApiDetail) {
    detail = detail || 'alias';

    const resolved = this.resolvedUsers[detail] ??= new Map<string, User>();
    const waiting = this.waitingUsers[detail] ??= new Map<string, Promise<any>>();

    return this.modify(resolved, waiting, id, action);
  }

  resolveGroup(id: string, buffer = 200) {
    return this.resolveSingle(id, this.resolvedGroups, this.waitingGroups, this.groupBuffer, this.resolveGroups.bind(this), buffer);
  }

  resolveGroups(ids: string[]) {
    const callback = (cbIds: string[]) => this.groupService.groups({
      'filter[id]': cbIds.join(','),
    }).page(0);
    return this.resolve(ids, this.resolvedGroups, this.waitingGroups, callback);
  }

  resolveProject(id: string, buffer = 200) {
    return this.resolveSingle(id, this.resolvedProjects, this.waitingProjects, this.projectBuffer, this.resolveProjects.bind(this), buffer);
  }

  resolveProjects(ids: string[], params?: GetProjectsOptions) {
    const callback = (cbIds: string[]) => this.projectService.getProjects({
      projects: cbIds.join(','),
      'show-integration': true,
      user: 'all-self',
      deleted: 'include',
      detail: 'users',
      ...params,
    }).page(0);
    return this.resolve(ids, this.resolvedProjects, this.waitingProjects, callback);
  }

  resetProject(id: string) {
    this.reset(this.resolvedProjects, this.waitingProjects, id);
  }

  resolveTask(id: string) {
    return this.resolveTasks([id]).then(x => x.get(id));
  }

  resolveTasks(ids: string[]) {
    const callback = (cbIds: string[]) => this.projectService.getTasks({
      tasks: cbIds.join(','),
      'show-integration': true,
      user: 'all-self',
      deleted: 'include',
    }).page(0);
    return this.resolve(ids, this.resolvedTasks, this.waitingTasks, callback);
  }

  resolveBreak(id: string, buffer = 200) {
    return this.resolveSingle(id, this.resolvedBreaks, this.waitingBreaks, this.breakBuffer, this.resolveBreaks.bind(this), buffer);
  }

  resolveBreaks(ids: string[]) {
    const callback = (cbIds: string[]) => this.breakService.getBreaks({
      'filter[id]': cbIds.join(','),
      noDeleted: 1,
    }).page(0);
    return this.resolve(ids, this.resolvedBreaks, this.waitingBreaks, callback);
  }

  resetBreak(id: string) {
    this.reset(this.resolvedBreaks, this.waitingBreaks, id);
  }

  resolveApproval(id: string, buffer = 200) {
    return this.resolveSingle(
      id, this.resolvedApprovals, this.waitingApprovals, this.approvalsBuffer, this.resolveApprovals.bind(this), buffer);
  }

  resolveApprovals(ids: string[]) {
    const callback = (cbIds: string[]) => this.approvalsService.getApprovals().page(0);
    return this.resolve(ids, this.resolvedApprovals, this.waitingApprovals, callback);
  }

  resetApproval(id: string) {
    this.reset(this.resolvedApprovals, this.waitingApprovals, id);
  }


  private async resolveSingle<T extends { id: string }>(
    id: string, resolveMap: Map<string, T>, waitMap: Map<string, Promise<any>>, buffer: Set<string>,
    callback: (id: string[]) => Promise<Map<string, T>>, bufferTime = 200) {
    const resolved = resolveMap.get(id);
    if (resolved) { return resolved; }

    const wait = waitMap.get(id);
    if (wait) {
      await wait;
      return resolveMap.get(id);
    }

    buffer.add(id);
    const currentWait = this.bufferWait = new Promise((resolvePromise) => {
      setTimeout(() => resolvePromise(this.bufferWait === currentWait ? null : this.bufferWait), bufferTime);
    });

    await currentWait;

    const res = await callback(Array.from(buffer));

    return res.get(id);
  }

  private async resolve<T extends { id: string }>(
    ids: string[], resolveMap: Map<string, T>, waitMap: Map<string, Promise<any>>, callback: (ids: string[]) => Promise<T[]>) {

    const uniqueIds = Array.from(new Set(ids));
    const itemMap = uniqueIds.map(id => ({ id, resolved: resolveMap.get(id), wait: waitMap.get(id) }));

    const needToWait = Promise.all(itemMap.filter(x => !x.resolved && x.wait).map(x => x.wait));
    await needToWait;

    const needToResolve = itemMap.filter(x => !x.resolved && !x.wait).map(x => x.id);

    if (needToResolve.length) {
      const resolvingPromise = lastValueFrom(this.paging.getPagedResult(
        (page) => from(callback(needToResolve.slice(page, page + 100)).then(data => ({ data }))),
        100,
        needToResolve.length,
      ).all().accumulated).then(items => {
        for (const item of items) {
          if (item) {
            resolveMap.set(item.id, item);
          }
        }
      });

      for (const itemId of needToResolve) {
        waitMap.set(itemId, resolvingPromise);
      }

      await resolvingPromise;
    }

    return resolveMap;
  }

  private modify<T extends { id: string }>(
    resolveMap: Map<string, T>,
    waitMap: Map<string, Promise<any>>,
    id: string,
    action: Modification<T>,
  ) {
    const resolved = resolveMap.get(id);
    if (resolved) resolveMap.set(id, action(resolved));

    const wait = waitMap.get(id);
    if (wait) waitMap.delete(id);

    return !!(resolved || wait);
  }

  private reset<T extends { id: string }>(
    resolveMap: Map<string, T>,
    waitMap: Map<string, Promise<any>>,
    id: string,
  ) {
    resolveMap?.delete(id);
    waitMap?.delete(id);
  }
}
