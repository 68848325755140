import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { lastValueFrom } from 'rxjs';
import { isTimeDoctor } from 'src/app/app/app.constants';
import { TraitsService } from 'src/app/services/traits/traits.service';
import { AuthSelectors } from 'src/app/store/auth/auth.selectors';

@Injectable({
  providedIn: 'root',
})
export class SubscribePlansGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private store: Store,
    private traitsService: TraitsService,
    private router: Router,
  ) { }

  async canActivate() {
    const currentCompany = this.store.selectSnapshot(AuthSelectors.company);
    const companyRdbmsStatus = await lastValueFrom(this.traitsService.companyStatus$);
    const hasBillingAccess = currentCompany?.userSettings.billingAccess;
    const companyStatus = currentCompany?.subscription.status;
    const isOwner = currentCompany.role === 'owner';
    const companyId = currentCompany?.id;
    const isCardAdded = currentCompany?.userSettings?.custom?.completedStep?.['addCreditCard'] || false;
    if (companyStatus === 'trial' && companyRdbmsStatus === 'trial' && !isCardAdded && !isTimeDoctor(companyId) && (isOwner || hasBillingAccess)) {
      return true;
    } else {
      this.router.navigate(['/billing']);
      return false;
    }
  }

  canActivateChild() {
    return this.canActivate();
  }

  canLoad() {
    return this.canActivate().then(x => x === true);
  }
}
