import { Injectable, Provider } from '@angular/core';
import type { DailyTimetubeComponent } from './daily-timetube.component';

/**
 * This is used to share the maximum value of a group of timetubes between all instances.
 * This way, we can show all timetubes in a group with a relative width to the maximum value.
 */
@Injectable()
export class TimetubeContext {
  public maxVal = 0;

  private items: DailyTimetubeComponent[] = [];

  constructor(public fieldName: string, public defaultVal: number = 0) {
    this.maxVal = defaultVal;
  }

  reset() {
    this.maxVal = this.defaultVal;
    this.items.length = 0;
  }

  check() {
    const maxVal = this.items.reduce((acc, cur) => cur.useContext && (!acc || cur.summary?.[this.fieldName] > acc) ?
      cur.summary?.[this.fieldName] || 0 : acc, this.maxVal);

    if (maxVal !== this.maxVal) {
      this.maxVal = maxVal;

      for (const item of this.items) item.rerender();
    }
  }

  register(cmp: DailyTimetubeComponent) {
    this.items.push(cmp);
    this.check();

    return () => {
      const idx = this.items.indexOf(cmp);
      if (idx >= 0) this.items.splice(idx, 1);
    };
  }
}

export function provideTimetubeCtx(fieldName: string = 'total', defaultVal: number = 0): Provider {
  return {
    provide: TimetubeContext,
    useFactory: () => new TimetubeContext(fieldName, defaultVal),
    deps: [],
  };
}
