import { Component, HostBinding } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { BehaviorSubject } from 'rxjs';
import { GrowthBookService } from 'src/app/services/growthbook/service';
import { AuthState } from 'src/app/store/auth/auth.state';
import { environment } from 'src/environments/environment';

@UntilDestroy()
@Component({
  selector: 'app-announce-button',
  templateUrl: './announce-button.component.html',
  styleUrls: ['./announce-button.component.scss'],
})
export class AnnounceButtonComponent {
  announceKitUrl = environment.announceKitUrl;
  @HostBinding('class.announce-kit-disabled') announceKitDisabled = !this.announceKitUrl;
  // Note: Following type conversion is because of imporper type def in announcekit-angular
  labels = ['fix', 'Feature Announcement', 'announcement', 'improvement', 'minor update'] as unknown as [string];

  userData$ = new BehaviorSubject<{ id: string, [x: string]: any }>(null);
  segmentData$ = new BehaviorSubject<{ [x: string]: any }>(null);

  constructor(private growthBook: GrowthBookService, private store: Store) {
      // We are excluding object values because AnnounceKit only supports string values
      this.growthBook.getAllFeaturesWithValues(true).pipe(untilDestroyed(this))
      .subscribe(featuresWithValues => {
        const attributes = this.growthBook.instance.getAttributes();
        const userRole = this.store.selectSnapshot(AuthState.role);

        this.userData$.next({ id: attributes.user_id });
        this.segmentData$.next({
          environment: environment.env.NODE_ENV,
          pricing_plan: attributes.pricing_plan,
          company_id: attributes.company_id,
          user_role: userRole,
          ...featuresWithValues,
        });
      });
  }
}
