import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, Optional } from '@angular/core';
import { GrowthBookService } from 'src/app/services/growthbook/service';
import { DailySummary } from 'src/models';
import { TimetubeContext } from './context';


type PartialDailySummary = Partial<DailySummary>;

@Component({
  selector: 'app-daily-timetube',
  templateUrl: './daily-timetube.component.html',
  styleUrls: ['./daily-timetube.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DailyTimetubeComponent implements OnDestroy {
  @HostBinding('class.single-color')
  @Input() singleColor: boolean;
  @Input() singleColorValue: string;
  @Input() timelineReport = false;

  unpaidLeavesEnabled: boolean;

  private _useContext: boolean;
  @Input() set useContext(value: boolean) {
    if (value === this._useContext) return;
    this._useContext = value;
    if (this.ctx) this.ctx.check();
  }
  get useContext() { return this._useContext; }

  private _summary: PartialDailySummary;
  get summary() { return this._summary; }
  @Input() set summary(value: PartialDailySummary) {
    this._summary = value;
    if (this.ctx) this.ctx.check();
  }

  get totalFieldName() {
    return this.ctx?.fieldName || 'total';
  }

  get fullSeconds() {
    if (this.useContext && this.ctx) return this.ctx.maxVal;
    return 60 * 60 * 12;
  }

  @HostBinding('class.disabled') get disabled() {
    return !this.summary || !this.fullSeconds;
  }

  private cleanup: () => void;

  constructor(
    @Optional() public ctx: TimetubeContext,
    private cd: ChangeDetectorRef,
    private growthBook: GrowthBookService,
  ) {
    this.cleanup = ctx?.register(this);
    this.unpaidLeavesEnabled = this.growthBook.getFeatureValue<boolean>('unpaid-leaves', false);
  }

  ngOnDestroy() {
    if (this.cleanup) this.cleanup();
  }

  rerender() {
    this.cd.markForCheck();
  }

  get totalWidth() {
    return (this.summary?.[this.totalFieldName] || 0) / this.fullSeconds;
  }

  get computerWidth() {
    return (this.summary?.computer || 0) / this.fullSeconds;
  }

  get manualWidth() {
    return (this.summary?.manual || 0) / this.fullSeconds;
  }

  get mobileWidth() {
    return (this.summary?.mobile || 0) / this.fullSeconds;
  }

  get paidBreakWidth() {
    return (this.summary && ((this.summary.paidBreak || 0) + (this.summary.unpaidBreak || 0))) / this.fullSeconds;
  }

  get paidLeaveWidth() {
    return (this.summary?.paidLeave || 0) / this.fullSeconds;
  }

  get unpaidLeaveWidth() {
    return (this.summary?.unpaidLeave || 0) / this.fullSeconds;
  }

}
