import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Experiment, GrowthBook, JSONValue, Result, WidenPrimitives } from '@growthbook/growthbook';
import { Store } from '@ngxs/store';
import { BehaviorSubject, combineLatest, filter, firstValueFrom, of, switchMap } from 'rxjs';
import { catchError, distinctUntilChanged, map } from 'rxjs/operators';
import { ExperimentalReportType } from 'src/app/pages/experimental-reports/reports';
import { BillingService } from 'src/app/services/billing/billing.service';
import { DownloadService } from 'src/app/services/download/download.service';
import { ExperimentProps } from 'src/app/services/growthbook/types';
import { DeviceDetector } from 'src/app/services/segment/device-detector';
import { SegmentService } from 'src/app/services/segment/segment.service';
import { AuthState } from 'src/app/store/auth/auth.state';
import { shallowEquals } from 'src/app/util/object-helpers';
import { environment } from 'src/environments/environment';

export type ExperimentalReportFeature = `experimental-${ExperimentalReportType}` | 'all-experimental-reports';
export type DashboardFeature = 'executive-dashboard' | 'executive-dashboard-banner';
export type GrowthBookFeature =
  'alpha'
  | 'beta'
  | 'test'
  | 'new-billing-page'
  | 'user-created-tasks'
  | 'sso-workos'
  | 'gt2-2924'
  | 'tokens-manager'
  | 'new-hours-tracked-report'
  | 'edit-time-snackbar'
  | ExperimentalReportFeature
  | DashboardFeature
  | (string & {});

const clientKey = environment.growthbookClientKey;

@Injectable({
  providedIn: 'root',
})
export class GrowthBookService {
  trackExperiment = (_exp: Experiment<any>, { value }: Result<ExperimentProps>) => {
    this.segmentService.trackExperiment(value);
  };

  readonly instance = new GrowthBook({
    enabled: !!environment.growthbookClientKey,
    enableDevMode: !environment.production,
    apiHost: environment.growthbookApiHost,
    clientKey,
    trackingCallback: this.trackExperiment,
  });
  deployment = environment.deployment;
  initialized = new BehaviorSubject<boolean>(false);
  initializedPromise = firstValueFrom(this.initialized.pipe(filter(x => x)));

  private rerenderCount = new BehaviorSubject<number>(0);

  attributes: Record<string, any> = {};

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private store: Store,
    private segmentService: SegmentService,
    private downloadService: DownloadService,
    private billingService: BillingService,
    private deviceDetector: DeviceDetector,
  ) {
    this.initialize();
    router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe((event: NavigationEnd) => {
      this.setAttribute('url_path', event.urlAfterRedirects);
      this.setAttribute('td_internal', this.activatedRoute.snapshot.queryParams.td_internal);
      this.setAttribute('automation', this.activatedRoute.snapshot.queryParams.automation);
      this.setAttribute('mobile_user', this.deviceDetector.isMobile());
      this.setAttribute('browser', this.deviceDetector.browserDetectionName);
    });
  }

  private async initialize() {
    const company$ = this.store.select(AuthState.company).pipe(distinctUntilChanged());
    this.store.select(AuthState.user).pipe(distinctUntilChanged()).subscribe(user => {
      this.setAttribute('user_id', user?.id);
    });

    company$.subscribe(company => {
      this.setAttribute('company_id', company?.id || '');
      this.setAttribute('pricing_plan', company?.pricingPlan || '');
      this.setAttribute('role', company?.role || '');
      this.setAttribute('company_created_date', company?.companyCreatedAt);
      this.setAttribute('tracking_mode', company?.companySettings?.trackingMode);
      this.setAttribute('billing_provider', company?.subscription?.provider);
      this.setAttribute('user_count', company?.userCount || 0);
      this.setAttribute('billing_access', company?.userSettings?.billingAccess);
      this.setAttribute('company_subscription_status', company?.subscription?.status);
      this.setAttribute('promoteBrowserApp', company?.companySettings?.custom?.splitTest?.promoteBrowserApp);
    });

    company$.pipe(
      filter(company => company?.subscription?.provider === 'paddle'),
      switchMap(() => this.billingService.getBillingDetails().pipe(
        catchError(() => of(null)),
      )),
    ).subscribe(billing => {
      this.setAttribute('subscription_status', billing?.customerStatus || '');
    });

    this.setAttribute('deployment', this.deployment);
    this.setAttribute('operating_system', this.downloadService.determineOS());

    this.segmentService.traitsService.companyTraits$.pipe(distinctUntilChanged())
      .subscribe(traits => {
        this.setAttribute('estimated_company_size', traits?.tdpeopleinthecompany);
        this.setAttribute('rdbms_status', traits?.status || '');
      });

    this.instance.setRenderer(() => {
      this.rerenderCount.next(this.rerenderCount.value + 1);
    });

    if (clientKey) {
      await this.instance.loadFeatures({ autoRefresh: true });
    }

    this.initialized.next(true);
  }

  /**
   * Get the value of a feature-flag in the current moment
   *
   * @example
   *
   * ```
   *   const result = this.growthbook.getFeatureValue('featureKey') === true
   * ```
   */
  getFeatureValue<T extends JSONValue>(feature: GrowthBookFeature, defaultValue?: T) {
    return this.instance.getFeatureValue<T>(feature, defaultValue);
  }

  /**
   * Get the reactive value of a feature-flag
   *
   * @example
   *
   * ```
   *   this.growthbook.getFeature('featureKey').subscribe((value) => console.log(value))
   * ```
   */
  getFeature<T extends JSONValue>(feature: GrowthBookFeature, defaultValue?: T) {
    return combineLatest([this.initialized, this.rerenderCount]).pipe(
      map(() => this.getFeatureValue<T>(feature, defaultValue)),
      distinctUntilChanged(),
    );
  }

  /**
   * Get the all features with their values
   * @param excludeObjectValues Exclude object values from the result
   */
  getAllFeaturesWithValues(excludeObjectValues = false) {
    return combineLatest([this.initialized, this.rerenderCount]).pipe(
      map(() => {
        const features = this.instance.getFeatures();

        return Object.keys(features).reduce((acc, key) => {
          const value = this.getFeatureValue(key);
          if (typeof value === 'object' && value !== null && excludeObjectValues) return acc;

          acc[key] = value;
          return acc;
        }, {} as { [x: string]: WidenPrimitives<JSONValue> });
      }),
      distinctUntilChanged(shallowEquals),
    );
  }

  setAttribute(key: string, value: any) {
    if (this.attributes[key] !== value) {
      this.attributes[key] = value;
      this.instance.setAttributes(this.attributes);
    }
  }
}
