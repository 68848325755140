<div *ngIf="isModalOpen" class="modal-overlay">
  <div class="modal">
    <mat-icon
      (click)="closeModal()"
      class="close-btn"
      [trackClick]="{
        page: 'Link Click',
        linklocation: 'header',
        pagename: 'Discount Popup',
        linkname:'Closed $50 Discount Popup',
        linktype: 'button',
        linkurl: ''}"
      >close</mat-icon>
    <app-modal-header [modalSource]="modalSource"></app-modal-header>
    <app-modal-steps
      [userCount$]="userCount$"
      [usersTrackingTimeCount$]="usersTrackingTimeCount$"
      [inviteLink]="inviteLink"
    ></app-modal-steps>
    <app-modal-footer
      [modalSource]="modalSource"
      [userCount$]="userCount$"
      (addUsersEvent)="addUsers()"
      (clickLinkEvent)="closeModal()"
      (gotoBillingPageEvent)="gotoBillingPage()"
      ></app-modal-footer>
  </div>
</div>
