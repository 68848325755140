import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { splitUserName } from 'src/app/util/object-helpers';

@Injectable()
@Pipe({ name: 'firstName', pure: true })
export class FirstNamePipe implements PipeTransform {
  transform(fullName: string, splitFirstWord: boolean = true): string {
    return fullName && splitUserName(fullName, splitFirstWord)[0];
  }
}
