import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad } from '@angular/router';
import { Store } from '@ngxs/store';
import { LoadManagedUsers } from '../store/manager/manager.actions';

@Injectable({
  providedIn: 'root',
})
export class LoadAllUsersGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private store: Store,
  ) { }

  async canActivate() {
    this.store.dispatch(new LoadManagedUsers(true));
    return true;
  }

  canActivateChild() {
    return this.canActivate();
  }

  canLoad() {
    return this.canActivate().then(x => x === true);
  }
}
