import { Directive, ElementRef } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

/**
 * Restores the scroll position of a container with overflow to the top (0,0) when navigating to a new route.
 *
 * Before the navigation starts, the directive will remove the `smooth-scroll` class from the element
 * and scroll to the top of the page. After a `0s` delay, the `smooth-scroll` class will be added back. So, if
 * you need smooth scrolling, you should add the `smooth-scroll` class to the element.
 */
@UntilDestroy()
@Directive({
  selector: '[restoreScroll]',
})
export class RestoreScrollDirective {

  constructor(
    elRef: ElementRef<HTMLElement>,
    router: Router,
  ) {
    router.events.pipe(
      filter(event => event instanceof NavigationStart),
      untilDestroyed(this),
    ).subscribe(() => {
      const htmlEl = elRef.nativeElement;

      htmlEl.classList.remove('smooth-scroll');
      htmlEl.scroll(0, 0);
      setTimeout(() => {
        htmlEl.classList.add('smooth-scroll');
      });
    });
  }
}
