export interface IntegrationTableItem {
  id: string;
  logoFileName: string;
  title: string;
  shortName?: string;
  configurable?: boolean;
  categories?: string[];
}

const integrations: IntegrationTableItem[] = [
  { id: 'adp', logoFileName: 'adp.svg', title: 'ADP', categories: ['payments'] },
  { id: 'asana', logoFileName: 'asana.svg', title: 'Asana', categories: ['pm', 'communication'] },
  { id: 'avaza', logoFileName: 'avaza.svg', title: 'Avaza', categories: ['pm'] },
  { id: 'visualstudio', logoFileName: 'visualstudio.svg', title: 'Azure DevOps', categories: ['pm'] },
  { id: 'basecamp', logoFileName: 'basecamp3.svg', title: 'Basecamp 3', categories: ['pm'] },
  { id: 'bitbucket', logoFileName: 'bitbucket.svg', title: 'Bitbucket', categories: ['pm'] },
  { id: 'bitrix24', logoFileName: 'bitrix24.svg', title: 'Bitrix24', categories: ['pm'] },
  { id: 'breeze', logoFileName: 'breeze.svg', title: 'Breeze', categories: ['pm'] },
  { id: 'bugherd', logoFileName: 'bugherd.svg', title: 'BugHerd', categories: ['pm'] },
  { id: 'clickup', logoFileName: 'clickup.svg', title: 'ClickUp', categories: ['pm'] },
  { id: 'evernote', logoFileName: 'evernote.svg', title: 'Evernote', categories: ['pm'] },
  { id: 'freshdesk', logoFileName: 'freshdesk.svg', title: 'Freshdesk', categories: ['pm', 'helpdesk'] },
  { id: 'freshservice', logoFileName: 'freshservice.svg', title: 'Freshservice', categories: ['pm', 'helpdesk'] },
  { id: 'flow', logoFileName: 'flow.svg', title: 'Flow', categories: ['pm'] },
  { id: 'github', logoFileName: 'github.svg', title: 'GitHub', categories: ['pm'] },
  { id: 'gitlab', logoFileName: 'gitlab.svg', title: 'GitLab', categories: ['pm'] },
  { id: 'google_apps', logoFileName: 'google-apps.svg', title: 'Google Apps', categories: ['pm'] },
  { id: 'gusto', logoFileName: 'gusto.svg', title: 'Gusto', categories: ['payments'] },
  { id: 'helpscout', logoFileName: 'helpscout.svg', title: 'Help Scout', categories: ['helpdesk'] },
  { id: 'hubspot', logoFileName: 'hubspot.svg', title: 'HubSpot', categories: ['crm'] },
  { id: 'intercom', logoFileName: 'intercom.svg', title: 'Intercom', categories: ['helpdesk'] },
  { id: 'intervals', logoFileName: 'intervals.svg', title: 'Intervals', categories: ['pm'] },
  { id: 'jira', logoFileName: 'jira.svg', title: 'Jira', categories: ['pm'] },
  { id: 'liquidplanner', logoFileName: 'liquidplanner.svg', title: 'LiquidPlanner', categories: ['pm'] },
  { id: 'meistertask', logoFileName: 'meistertask.svg', title: 'MeisterTask', categories: ['pm'] },
  { id: 'microsoft_todo', logoFileName: 'microsoft_todo.png', title: 'Microsoft To Do', categories: ['pm'] },
  { id: 'monday_com', logoFileName: 'monday.svg', title: 'monday.com', configurable: true, categories: ['pm'] },
  { id: 'notion', logoFileName: 'notion.svg', title: 'Notion', categories: ['pm'] },
  { id: 'odoo', logoFileName: 'odoo.svg', title: 'Odoo', categories: ['pm'] },
  { id: 'office365', logoFileName: 'office365.svg', title: 'Office 365', categories: ['pm'] },
  { id: 'orapm', logoFileName: 'orapm.svg', title: 'Ora', categories: ['pm'] },
  { id: 'phacility', logoFileName: 'phacility.svg', title: 'Phacility', categories: ['pm', 'helpdesk'] },
  { id: 'pipedrive', logoFileName: 'pipedrive.svg', title: 'Pipedrive', categories: ['crm'] },
  { id: 'pivotal_tracker', logoFileName: 'pivotal-tracker.svg', title: 'Pivotal Tracker', categories: ['pm'] },
  { id: 'plutio', logoFileName: 'plutio.svg', title: 'Plutio', categories: ['pm'] },
  { id: 'podio', logoFileName: 'podio.svg', title: 'Podio', categories: ['pm', 'communication'] },
  { id: 'paypal', logoFileName: 'paypal.png', title: 'Paypal Payouts', categories: ['payments'] },
  { id: 'process_st', logoFileName: 'process_st.svg', title: 'Process Street', categories: ['pm'] },
  { id: 'redbooth', logoFileName: 'redbooth.svg', title: 'Redbooth', categories: ['pm'] },
  { id: 'redmine', logoFileName: 'redmine.svg', title: 'Redmine', categories: ['pm'] },
  { id: 'glip', logoFileName: 'glip.svg', title: 'RingCentral Glip', shortName: 'Glip', categories: ['pm'] },
  { id: 'salesforce', logoFileName: 'salesforce.svg', title: 'Salesforce', categories: ['crm'] },
  { id: 'sentry', logoFileName: 'sentry.svg', title: 'Sentry', categories: ['pm'] },
  { id: 'spiceworks', logoFileName: 'spiceworks.svg', title: 'Spiceworks', categories: ['helpdesk'] },
  { id: 'taiga', logoFileName: 'taiga.svg', title: 'Taiga', categories: ['pm'] },
  { id: 'teamwave', logoFileName: 'teamwave.svg', title: 'TeamWave', categories: ['pm'] },
  { id: 'teamwork', logoFileName: 'teamwork-projects.svg', title: 'Teamwork Projects', shortName: 'Teamwork', categories: ['pm'] },
  { id: 'todoist', logoFileName: 'todoist.svg', title: 'Todoist', categories: ['pm'] },
  { id: 'trainual', logoFileName: 'trainual.svg', title: 'Trainual', categories: ['pm'] },
  { id: 'transferwise', logoFileName: 'transferwise.png', title: 'Wise', categories: ['payments'] },
  { id: 'trello', logoFileName: 'trello.svg', title: 'Trello', categories: ['pm'] },
  { id: 'wordpress', logoFileName: 'wordpress.svg', title: 'WordPress', categories: ['pm'] },
  { id: 'worksection', logoFileName: 'worksection.svg', title: 'Worksection', categories: ['pm'] },
  { id: 'wrike', logoFileName: 'wrike.svg', title: 'Wrike', categories: ['pm'] },
  { id: 'zendesk', logoFileName: 'zendesk.svg', title: 'Zendesk', categories: ['helpdesk'] },
  { id: 'zoho_desk', logoFileName: 'zoho-desk.svg', title: 'Zoho Desk', categories: ['helpdesk'] },
  { id: 'zoho_projects', logoFileName: 'zoho-projects.svg', title: 'Zoho Projects', categories: ['pm', 'communication', 'crm'] },
  { id: 'zoho_sprints', logoFileName: 'zoho-sprints.svg', title: 'Zoho Sprints', categories: ['pm'] },
];

export default integrations;
