import { Injectable, Injector } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/services/user/user.service';
import { AuthState } from 'src/app/store/auth/auth.state';
import { CompleteWelcomeStep } from 'src/app/store/onboarding/onboarding.actions';
import { SidebarItem } from '../sidebar-item';

@Injectable()
export class ConfirmEmailStep implements SidebarItem {
  id = 'confirmEmail';
  completed = false;
  link = null;
  action = 'common.resend';
  preventDismiss = true;

  private store: Store;
  private userService: UserService;
  private translate: TranslateService;
  private snackbar: MatSnackBar;
  private subscription: Subscription;

  constructor(injector: Injector) {
    this.store = injector.get(Store);
    const user = this.store.selectSnapshot(AuthState.user);
    this.completed = user?.emailConfirmed ?? false;
    this.userService = injector.get(UserService);
    this.snackbar = injector.get(MatSnackBar);
    this.translate = injector.get(TranslateService);

    this.action = this.translate.instant('common.resend');
  }

  clear() {
    this.subscription?.unsubscribe();
    this.subscription = null;
  }

  async start() {
    this.clear();
    this.subscription = this.store.select(AuthState.user).subscribe(user => {
      this.completed = user?.emailConfirmed ?? false;

      if (user?.emailConfirmed) {
        this.store.dispatch(new CompleteWelcomeStep(this.id));
        return;
      }
    });
  }

  async onActionClick() {
    await this.userService.resendConfirmationEmail();
    this.snackbar.open(this.translate.instant('sidebar.confirmationSent'), null, { duration: 3000 });
  }
}
