import { AfterContentInit, Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

export type AutofocusType = 'all' | 'end' | '' | boolean;

export function autofocus(input: HTMLElement, type?: AutofocusType) {
  if (type === false) { return; }
  input.focus();

  if (input instanceof HTMLInputElement && input.type === 'text') {
    const length = (input.value || '').length || 0;
    if (type === 'all') {
      input.setSelectionRange(0, length);
    } else if (type === 'end') {
      input.setSelectionRange(length, length);
    }
  }
}

@Directive({
  selector: '[autofocus]',
})
export class AutofocusDirective implements AfterContentInit, OnChanges {
  @Input() autofocus: AutofocusType;

  public constructor(private el: ElementRef) { }

  public ngAfterContentInit() {
    this.triggerAutofocus();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.autofocus) {
      this.triggerAutofocus();
    }
  }

  triggerAutofocus() {
    setTimeout(() => {
      const el: HTMLElement = this.el.nativeElement;
      autofocus(el, this.autofocus);
    }, 100);
  }
}
