import { DateTime } from 'luxon';
import {
  CancellationRequest,
  ScheduledChange,
  SubscriptionApiData,
  SubscriptionStatus
} from 'src/app/pages/new-billing/types/api.types';

export interface SubscriptionModel {
  hasRestrictedSub(): boolean;
  cancellationRequest: CancellationRequest;
  status: SubscriptionStatus;
  expiry: string;
  isTrialExpired: boolean;
  trialEndDate: string;
  trialStartDate: string;
  gracePeriodDays: number;
  willBeCanceled: boolean;
  scheduledChange?: ScheduledChange;
  hasLessThan30DaysSub?: boolean;
}

const RESTRICTED_TIME_IN_HOURS = 3;

export class SubscriptionModelImp implements SubscriptionModel {
  private cardLastFourDigits: string;
  private _gracePeriodEndDate: string | null;

  readonly cancellationRequest: SubscriptionModel['cancellationRequest'];
  readonly expiry: SubscriptionModel['expiry'];
  readonly status: SubscriptionModel['status'];
  readonly trialEndDate: SubscriptionModel['trialEndDate'];
  readonly trialStartDate: SubscriptionModel['trialStartDate'];
  readonly scheduledChange: SubscriptionModel['scheduledChange'];

  get gracePeriodDays(): number {
    return SubscriptionModelImp.calculateGracePeriod(this._gracePeriodEndDate);
  }

  get willBeCanceled(): boolean {
    return this.scheduledChange?.action === 'cancel';
  }

  get hasLessThan30DaysSub(): boolean {
    return DateTime.now().diff(DateTime.fromISO(this.trialEndDate), 'days').days < 30;
  }

  get isTrialExpired(): boolean {
    return DateTime.now() > DateTime.fromISO(this.trialEndDate);
  }

  constructor(data: SubscriptionApiData) {
    this.cancellationRequest = data.cancellationRequest;
    this.status = data.customerStatus;
    this.expiry = data.expiry;
    this.trialEndDate = data.trialEndDate;
    this.trialStartDate = data.trialStartDate;
    this.cardLastFourDigits = data.cardLastFourDigits;
    this._gracePeriodEndDate = data.gracePeriodEndDate;
    this.scheduledChange = data.scheduledChange;
  }

  static calculateGracePeriod(gracePeriodEndDate: string): number {
    if (!gracePeriodEndDate) return 0;

    const gracePeriodEndDateTime = DateTime.fromISO(gracePeriodEndDate);
    const today = DateTime.now();
    const days = gracePeriodEndDateTime.diff(today, 'days').days;

    return days > 0 ? days : 0;
  }

  hasRestrictedSub(): boolean {
    const expiryTime = new Date(this.expiry).getTime();
    const currentTimeInUTC = new Date().toISOString();
    const currentTime = new Date(currentTimeInUTC).getTime();
    const timeDifferenceInHours = (expiryTime - currentTime) / (1000 * 60 * 60);
    return this.cardLastFourDigits && timeDifferenceInHours <= RESTRICTED_TIME_IN_HOURS;
  }
}
