import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import * as Sentry from '@sentry/angular-ivy';
import { combineLatest, exhaustMap, Observable, of, share } from 'rxjs';
import { catchError, filter, map, take, tap } from 'rxjs/operators';
import { Traits, TraitsResponse } from 'src/app/services/traits/types';
import { AuthSelectors } from 'src/app/store/auth/auth.selectors';
import { environment } from 'src/environments/environment';
import { AuthCompany, AuthUser } from 'src/models';

const REQUEST_URL = `${environment.dataIntegrityApiUrl}/traits`;

type ErrorResponse = { message?: string, currentTarget?: XMLHttpRequest };

@Injectable({
  providedIn: 'root',
})
export class TraitsService {
  @Select(AuthSelectors.company) company$: Observable<AuthCompany>;
  @Select(AuthSelectors.user) user$: Observable<AuthUser>;

  private _pendingRequest: Observable<TraitsResponse> = null;
  private _cachedResponse: TraitsResponse = null;
  private _allTraits: Traits = null;
  private _exemptedErrorMessage = ['Invalid Token'];

  get allTraits$(): Observable<Traits> {
    return this._allTraits ? of(this._allTraits) : this.fetchTraits().pipe(take(1));
  }

  get companyTraits$(): Observable<Traits['companyTraits']> {
    return this.allTraits$.pipe(
      take(1),
      map(({ companyTraits }) => companyTraits),
    );
  }

  get companyStatus$(): Observable<string> {
    return this.companyTraits$.pipe(
      map(traits => traits.status),
    );
  }

  get userTraits$(): Observable<Traits['userTraits']> {
    return this.allTraits$.pipe(
      take(1),
      map(({ userTraits }) => userTraits),
    );
  }

  constructor(
    private httpClient: HttpClient,
  ) { }

  public fetchTraits(): Observable<Traits> {
    // If the API URL is not set, the traits are irrelevant and an empty object may be returned.
    if (!environment.dataIntegrityApiUrl) {
      return of({
        companyTraits: {},
        userTraits: {},
      });
    }

    return combineLatest([this.company$, this.user$]).pipe(
      filter(([company, user]) => !!company?.id && !!user?.id),
      exhaustMap(([company, user]) => this.traitsRequest(company.id, user.id)),
      map(this.mapTraitsResponse),
      tap(traits => this._allTraits = traits),
      catchError((error) => {
        this.handleErrorLogging(error);
        return of({
          companyTraits: {},
          userTraits: {},
        });
      }),
    );
  }

  private shouldCaptureError(error: ErrorResponse): boolean {
    const isExemptedMessage = error?.message && this._exemptedErrorMessage.includes(error.message);
    const isExemptedStatus = error?.currentTarget?.status !== 0;
    return !isExemptedMessage && !isExemptedStatus;
  }

  private handleErrorLogging(error: ErrorResponse): void {
    if (this.shouldCaptureError(error)) {
      Sentry.captureMessage('Error fetching traits', {
        level: 'warning',
        extra: { error },
      });
    }
  }

  private mapTraitsResponse({ data }: TraitsResponse): Traits {
    const companyTraits = data.companyTraits[0] || {};
    const userTraits = data.userTraits[0] || {};
    delete companyTraits.id;
    delete userTraits.id;

    return {
      companyTraits,
      userTraits,
    };
  }

  private traitsRequest(companyId: string, userId: string) {
    if (this._cachedResponse) return of(this._cachedResponse);
    if (this._pendingRequest) return this._pendingRequest;

    this._pendingRequest = this.httpClient.get<TraitsResponse>(REQUEST_URL, {
      params: {
        tdcompanyid: companyId,
        tduserid: userId,
      },
    }).pipe(
      tap(response => {
        this._pendingRequest = null;
        this._cachedResponse = response;
      }),
      share(),
    );

    return this._pendingRequest;
  }
}
