import { APP_INITIALIZER, Injectable, Provider } from '@angular/core';
import { RouterDataResolved } from '@ngxs/router-plugin';
import { actionMatcher, Actions, getValue, setValue, Store } from '@ngxs/store';
import { DateTime } from 'luxon';
import { filter, map } from 'rxjs/operators';

export function getExposeStoreInitializer(expose: ExposeStore) {
  return () => expose.init();
}

@Injectable({
  providedIn: 'root',
})
export class ExposeStore {
  private actionArray: { time: DateTime, action: any }[] = [];

  constructor(
    private store: Store,
    private actions: Actions,
  ) { }

  async init() {
    this.actions
      .pipe(filter(x => x.status === 'SUCCESSFUL'), map(x => x.action))
      .subscribe(action => {
        const matches = actionMatcher(action);

        if (!matches(RouterDataResolved)) {
          this.actionArray.push({ action, time: DateTime.utc() });
        }
      });

    window['getState'] = (path) => {
      if (path) {
        return getValue(this.store.snapshot(), path);
      } else {
        return this.store.snapshot();
      }
    };

    window['copyState'] = (path = null) => {
      let toCopy = this.store.snapshot();
      if (path) {
        toCopy = getValue(toCopy, path);
      }
      const str = JSON.stringify(toCopy, null, 2);
      this.copyToClipboard(str);
    };

    window['setValue'] = setValue;

    window['setState'] = (state) => {
      this.store.reset(state);
    };

    window['patchState'] = (prop, value) => {
      this.store.reset(setValue(this.store.snapshot(), prop, value));
    };

    window['actionLog'] = () => {
      const str = this.actionArray.map(({ time, action }) => {
        let actionName = action.type || '';
        if (action.constructor) {
          actionName = action.constructor.type || action.constructor.name || actionName;
        }

        let payload = '';
        try {
          payload = JSON.stringify(action, null, 2);
        } catch (err) { }

        return `---- ${time.toISO()} - ${actionName} ----\n${payload}\n`;
      }).join('\n');

      return str;
    };

    window['copyActions'] = () => {
      const str = window['actionLog']();
      this.copyToClipboard(str);
    };


    window['store'] = this.store;
    window['actions'] = this.actionArray;
  }

  copyToClipboard(str: string) {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }
}

export const exposeStoreInitProvider: Provider = {
  provide: APP_INITIALIZER,
  useFactory: getExposeStoreInitializer,
  deps: [ExposeStore],
  multi: true,
};
