import { MeetingBookedEvent, RevenueHeroData, RevenueHeroMessageData, RevenueHeroModelOptions } from './types';


export class RevenueHeroModel {
  private user: { email?: string } = {};
  private readonly routerId: string | null;
  private readonly embedTarget: string | null;

  // LIST OF RH EVENTS
  public onMeetingBooked: (e: MeetingBookedEvent | MessageEvent<RevenueHeroMessageData>) => void = null;
  public onRescheduleClick: (() => void) | null = null;
  public onAddGuest: (() => void) | null = null;
  public onError: ((error: Error) => void) | null = null;

  constructor(options: RevenueHeroModelOptions = {}) {
    this.user = options.user || {};
    this.routerId = options.routerId || null;
    this.embedTarget = options.embedTarget || null;
  }

  public initialize(): void {

    try {
      const hero = new RevenueHero({ routerId: this.routerId });
      hero.schedule('hsForm_b906627a-9b84-4111-9f73-1654245cdef4');
      hero
        .submit({ email: 'justfortest@cnn.com' })
        .then((data: RevenueHeroData) => {
          if (this.embedTarget !== null) {
            hero.dialog.setEmbedTarget(this.embedTarget);
          }
          hero.dialog.open(data);
        })
        .catch(this.handleError);

      window.addEventListener('message', this.listenToWidget);
    } catch (error) {
      console.error(error);
      this.handleError(error);
    }
  }

  private handleError = (error: Error): void => {
    if (this.onError) {
      this.onError(error);
    }
  };

  // listen to revenue hero widget events
  public listenToWidget = (e: MessageEvent<RevenueHeroMessageData>): void => {
    const bookStatus = ['MEETING_BOOKED', 'MEETING_RESCHEDULED', 'ADDED_GUEST'];
    if (bookStatus.includes(e.data.type) && this.onMeetingBooked) {
      this.onMeetingBooked(e);
    }

    if (e.data.type === 'RESCHEDULE_CLICKED' && this.onRescheduleClick) {
      this.onRescheduleClick();
    }

    if (e.data.type === 'ADDING_GUEST' && this.onAddGuest) {
      this.onAddGuest();
    }
  };

  // remove the listener
  public destroy(): void {
    window.removeEventListener('message', this.listenToWidget);
  }
}
