import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Subscription } from 'rxjs';
import { isTrialOfferPromotionOpen } from 'src/app/components/trial-promotion-offer/offer-popup/offer-popup.component';
import { CheckTrackedTimeUser } from 'src/app/components/trial-promotion-offer/store/trial-offer-promotion.actions';
import { TrialPromotionOfferService } from '../service/trial-promotion-offer.service';

export const isTrialOfferBannerVisible = new BehaviorSubject(false);

@Component({
  selector: 'app-trial-offer-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})

export class TrialOfferBannerComponent implements OnDestroy {
  showBanner = false;
  private subscription: Subscription;
  constructor(
    private store: Store,
    private trialPromotionOfferService: TrialPromotionOfferService,
  ) {
    this.subscription = this.trialPromotionOfferService.shouldShowTimeLimitedPromotionOffer('isTimeLimitedPromotionBannerVisible')
      .subscribe(isEligible => {
        this.showBanner = isEligible;
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  checkTrialPromotionOffer() {
    if (!this.showBanner) return false;

    isTrialOfferPromotionOpen.next({ isOpen: true, source: 'banner' });
    this.store.dispatch(new CheckTrackedTimeUser());
  }

  shouldShowTrialPromotionBanner(): boolean {
    return this.showBanner;
  }
}
