import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

export interface AppMouseMoveEvent {
  localX: number;
  localY: number;
  globalX: number;
  globalY: number;
}

@Directive({
  selector: '[appMouseMove]',
})
export class MouseMoveDirective {
  @Input() appMouseMoveScaleTo: number;
  @Output() appMouseMove = new EventEmitter<AppMouseMoveEvent>();

  constructor(private el: ElementRef) {}

  @HostListener('document:mousemove', ['$event']) onMove(event: MouseEvent) {
    this.appMouseMove.emit(this.getMousePos(event));
  }

  getMousePos({ clientX: globalX, clientY: globalY }: MouseEvent): AppMouseMoveEvent {
    const scale = this.appMouseMoveScaleTo;
    const { x: rectX, y: rectY, width, height } = this.el.nativeElement.getBoundingClientRect();
    const globalWidth = window.innerWidth;
    const globalHeight = window.innerHeight;

    const localX = Math.max(globalX - rectX);
    const localY = Math.max(globalY - rectY);

    return {
      localX: scale ? localX / width * scale : localX,
      localY: scale ? localY / height * scale : localY,
      globalX: scale ? globalX / globalWidth * scale : globalX,
      globalY: scale ? globalY / globalHeight * scale : globalY,
    };
  }
}
