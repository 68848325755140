import { Inject, Injectable, InjectionToken } from '@angular/core';
import { NgxsStoragePluginOptions, StorageEngine, STORAGE_ENGINE } from '@ngxs/storage-plugin';
import { actionMatcher, getValue, InitState, NgxsPlugin, setValue, UpdateState } from '@ngxs/store';
import { NgxsNextPluginFn } from '@ngxs/store/src/symbols';
import { tap } from 'rxjs/operators';
import { getCombinedId } from '../../util/internal';
import { AfterLogout, SelectCompany } from '../auth.actions';
import { AppStateRootModel } from '../models';


export const NGXS_SYNC_STORAGE_SWITCH_PLUGIN_OPTIONS = new InjectionToken<string>('NGXS_STORAGE_SWITCH_PLUGIN_OPTIONS');

@Injectable()
export class NgxsSyncStorageSwitchPlugin implements NgxsPlugin {
  constructor(
    @Inject(NGXS_SYNC_STORAGE_SWITCH_PLUGIN_OPTIONS) private userOptions: NgxsStoragePluginOptions,
    @Inject(STORAGE_ENGINE) private engine: StorageEngine,
  ) { }

  handle(state: AppStateRootModel, event: any, next: NgxsNextPluginFn) {
    const options = this.userOptions || {};
    const matches = actionMatcher(event);
    const isInitAction = matches(InitState) || matches(UpdateState);
    const isSelectCompany = matches(SelectCompany);
    const isLogout = matches(AfterLogout);
    const keys: string[] = (Array.isArray(options.key) ? options.key : [options.key]) as string[];
    let hasMigration = false;

    let combinedId: string = null;

    if (isInitAction) {
      combinedId = getCombinedId(state && state.auth);
    } else if (isSelectCompany) {
      const selectCompany = event as SelectCompany;
      if (selectCompany.company && state.auth && state.auth.user) {
        combinedId = `${state.auth.user && state.auth.user.id}__${selectCompany.company.id}`;
      }
    }

    if (isInitAction || isSelectCompany || isLogout) {
      for (const key of keys) {
        const isMaster = key === '@@STATE';
        let combinedKey = key;
        if (combinedId) {
          combinedKey = `[${combinedId}] ${key}`;
        }

        let val: any = this.engine.getItem(combinedKey);

        if (val !== 'undefined' && typeof val !== 'undefined' && val !== null) {
          try {
            val = options.deserialize(val);
          } catch (e) {
            console.error(
              'Error ocurred while deserializing the store value, falling back to empty object.',
            );
            val = {};
          }

          if (options.migrations) {
            options.migrations.forEach(strategy => {
              const versionMatch =
                strategy.version === getValue(val, strategy.versionKey || 'version');
              const keyMatch = (!strategy.key && isMaster) || strategy.key === key;
              if (versionMatch && keyMatch) {
                val = strategy.migrate(val);
                hasMigration = true;
              }
            });
          }

          if (!isMaster) {
            state = setValue(state, key, val);
          } else {
            state = { ...state, ...val };
          }
        }
      }
    }



    return next(state, event).pipe(
      tap((nextState: AppStateRootModel) => {
        if (!isInitAction || (isInitAction && hasMigration)) {
          const newCombinedId = getCombinedId(nextState && nextState.auth);

          for (const key of keys) {
            let val = nextState;
            let prevVal = nextState;

            if (key !== '@@STATE') {
              val = getValue(nextState, key);
              prevVal = getValue(state, key);
            }

            let combinedKey = key;
            if (newCombinedId) {
              combinedKey = `[${newCombinedId}] ${key}`;
            }

            if (prevVal !== val && newCombinedId) {
              try {
                this.engine.setItem(combinedKey, options.serialize(val));
              } catch (e) {
                console.error(
                  'Error ocurred while serializing the store value, value not updated.',
                );
              }
            }
          }
        }
      }),
    );
  }
}
