import { Directive, Injectable, Input, Optional, Provider, SkipSelf } from '@angular/core';


@Injectable()
export class TrackingDisabled {
  get disabled() { return this.isDisabled; }
  constructor(private isDisabled = true) { }
}

@Injectable()
@Directive({
  selector: '[trackingDisabled]',
  providers: [{ provide: TrackingDisabled, useExisting: TrackingDisabledDirective }],
})
export class TrackingDisabledDirective extends TrackingDisabled {
  @Input() trackingDisabled: boolean | '';
  get disabled() { return this.trackingDisabled === '' || !!this.trackingDisabled; }
  constructor() { super(); }
}

@Injectable()
@Directive({
  selector: '[trackingEnabled]',
  providers: [{ provide: TrackingDisabled, useExisting: TrackingEnabledDirective }],
})
export class TrackingEnabledDirective extends TrackingDisabled {
  @Input() trackingEnabled: boolean | '';
  get disabled() { return this.trackingEnabled !== '' && !this.trackingEnabled; }
  constructor() { super(); }
}


export function provideTrackingDisabled(disabled = true): Provider {
  return {
    provide: TrackingContext,
    useFactory: () => new TrackingDisabled(!!disabled),
  };
}



export type TrackingContextType = {
  page: string;
  location: string;
  newValue?: any;
  cause?: any;
  [key: string]: any;
};

export type PartialCtx = Partial<TrackingContextType>;

@Injectable()
export abstract class TrackingContext {
  abstract get context(): PartialCtx;
  abstract modify(val: PartialCtx);
}

@Injectable()
export class StaticTrackingContext extends TrackingContext {
  get context() {
    return {
      ...this.root ? {} : this.parent?.context,
      ...this.value,
    };
  }

  constructor(
    private value: PartialCtx,
    private root = false,
    private parent?: TrackingContext,
  ) {
    super();
  }

  modify(val: PartialCtx) {
    Object.assign(this.value, val);
  }
}

export function provideTrackingCtx(val: PartialCtx, root = false): Provider {
  return {
    provide: TrackingContext,
    useFactory: (ctx: TrackingContext) => new StaticTrackingContext(val, root, ctx),
    deps: [[new Optional(), new SkipSelf(), TrackingContext]],
  };
}

@Injectable()
@Directive({
  selector: '[trackingCtx]',
  providers: [
    { provide: TrackingContext, useExisting: TrackingContextDirective },
  ],
})
export class TrackingContextDirective extends TrackingContext {
  @Input() trackingCtx: PartialCtx | ((ctx: PartialCtx) => PartialCtx);
  @Input() trackingCtxRoot: boolean | '';
  @Input() trackingCtxPage: string;
  @Input() trackingCtxLocation: string;

  get isRoot() {
    return !!this.trackingCtxRoot || this.trackingCtxRoot === '';
  }

  get context() {
    const parentValue = this.isRoot ? {} : this.parent?.context;
    let baseValue = {};

    if (typeof this.trackingCtx === 'function') {
      baseValue = this.trackingCtx(parentValue);
    }
    else {
      baseValue = {
        ...parentValue,
        ...this.trackingCtx,
      };
    }

    return {
      ...baseValue,
      ...this.trackingCtxPage && { page: this.trackingCtxPage },
      ...this.trackingCtxLocation && { location: this.trackingCtxLocation },
    };
  }

  constructor(
    @Optional() @SkipSelf() private parent?: TrackingContext,
  ) {
    super();
  }

  modify(val: PartialCtx) {
    Object.assign(this.trackingCtx, val);
  }
}
