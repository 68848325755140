import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { UserGroup } from 'src/models';
import { ApiService } from '../api.service';
import { PagedApiResult, PagingService } from '../paging/paging.service';

export interface GroupApiParams {
  sort?: string;
  limit?: number;
  page?: number;
  deleted?: boolean;
  'filter[keyphrase]'?: string;
  'filter[id]'?: string | string[];
}

@Injectable({ providedIn: 'root' })
export class GroupService {
  constructor(private api: ApiService, private paging: PagingService) { }

  groups(params: GroupApiParams = {}) {
    const limit = params?.limit || 200;

    return this.paging.getPagedResult((page) =>
      this.api.request<PagedApiResult<UserGroup>>('get', `tags`, { limit, sort: 'name', ...params, page }), limit);
  }

  create(params: Partial<UserGroup>) {
    return lastValueFrom(this.api.request<{ data: UserGroup }>('post', `tags`, params)).then(x => x.data);
  }

  edit(groupId: string, params: Partial<UserGroup>) {
    return lastValueFrom(this.api.request('put', `tags/${groupId}`, params));
  }

  delete(groupId: string) {
    return lastValueFrom(this.api.request('delete', `tags/${groupId}`));
  }

  restore(groupId: string) {
    return lastValueFrom(this.api.request('put', `tags/${groupId}`, { deleted: false }));
  }

  addGroupManager(groupId: string, userId: string) {
    return lastValueFrom(this.api.request('post', `tags/${groupId}/managers`, { userId }));
  }

  removeGroupManager(groupId: string, userId: string) {
    return lastValueFrom(this.api.request('delete', `tags/${groupId}/managers/${userId}`));
  }

  addGroupMember(groupId: string, userId: string) {
    return lastValueFrom(this.api.request('post', `users/${userId}/tags`, { tagId: groupId }));
  }

  removeGroupMember(groupId: string, userId: string) {
    return lastValueFrom(this.api.request('delete', `users/${userId}/tags/${groupId}`));
  }
}
