import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

interface PercentageItem {
  percent: number;
  color: string;
}
@Component({
  selector: 'app-percentage-bar',
  templateUrl: './percentage-bar.component.html',
  styleUrls: ['./percentage-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PercentageBarComponent {
  @Input() percentage: number;
  @Input() percentageList: PercentageItem[] = null;
  @Input() type = 'green-bar';
  @Input() color: string;
  @Input() hideLabel = false;

  get percentageWidth() {
    return this.percentage || 0;
  }
}
