import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { Duration, DurationUnit } from 'luxon';

const regex = /[123456789]\d*\w/g;
const defaultFormat = `h'h' m'm'`;
const defaultValue = '0m';

const numberFormatter = (window['Intl'] && Intl.NumberFormat) ? new Intl.NumberFormat('en-US', {}) : null;

function formatNumber(value: number) {
  return numberFormatter ? numberFormatter.format(value) : value;
}

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'durationHms' })
export class DurationHmsPipe implements PipeTransform {
  transform(value: number, unit: DurationUnit = 'seconds', defaultText: string = null): string {
    unit = unit || 'seconds';
    if (!Number.isFinite(value)) {
      return '';
    }

    const duration = Duration.fromObject({ [unit]: value });
    const durationSeconds = duration.as('seconds');

    const formatted = duration.toFormat(defaultFormat);
    const split = formatted && formatted.match(regex);

    if (durationSeconds <= 0) {
      return defaultText || defaultValue;
    }
    if (durationSeconds < 60) {
      return '<1m';
    }

    // if no time tracked then “0m”
    // if <1 min tracked then “<1m”
    // if <1 hour and <10 minutes then "9m"
    // if <1 hour and >10 minutes then "11m"
    // if >1 hour and <1h 10m then "1h 09m" (so the minutes get a "0")
    if (split && split.length > 1) {
      const h = parseInt(split[0], 0);
      const m = parseInt(split[1], 0);
      const hh = formatNumber(h);
      split[0] = `${hh}h`;
      split[1] = m < 10 ? `0${m}m` : split[1];
    } else if (split && durationSeconds > 3600 && !split[1]) {
      split[1] = '00m';
    }
    const text = split?.join(' ');
    return text || defaultText || defaultValue;
  }
}
