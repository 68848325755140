import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { interval } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';

@UntilDestroy()
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'truncated-text',
  templateUrl: './truncated-text.component.html',
  styleUrls: ['./truncated-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TruncatedTextComponent {
  @ViewChild('showTooltip', { static: true }) showTooltip: ElementRef<HTMLSpanElement>;

  readonly shouldDisableTooltip = interval(1000).pipe(
    untilDestroyed(this),
    map(() => !this.showTooltip || this.showTooltip.nativeElement.offsetWidth > 1),
    distinctUntilChanged(),
    startWith(true),
  );

  @Input() text = '';
  @Input() tooltip = '';
  @Input() disabled = false;
  @Input() position: TooltipPosition = 'below';
}
