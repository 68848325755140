import { Injectable, Injector } from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthState } from 'src/app/store/auth/auth.state';
import { CompleteWelcomeStep } from 'src/app/store/onboarding/onboarding.actions';
import { SidebarItem } from '../sidebar-item';


@Injectable()
export class AddCreditCardStep implements SidebarItem {
  static stepId = 'addCreditCard';

  id = AddCreditCardStep.stepId;
  completed = undefined;
  link = '/billing';

  private store: Store;

  constructor(injector: Injector) {
    this.store = injector.get(Store);
  }

  async start() {
    const company = this.store.selectSnapshot(AuthState.company);
    const hasCardAdded = company?.companySettings?.custom?.completedStep?.[AddCreditCardStep.stepId] ?? false;

    this.completed = hasCardAdded;
    if (hasCardAdded) this.store.dispatch(new CompleteWelcomeStep(AddCreditCardStep.stepId));
  }
}
