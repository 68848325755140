// Temporary fix defined in: https://github.com/angular/components/issues/10117#issuecomment-447373986
import { ActiveDescendantKeyManager, Highlightable } from '@angular/cdk/a11y';
import { DOWN_ARROW, END, ENTER, HOME, PAGE_DOWN, PAGE_UP, UP_ARROW } from '@angular/cdk/keycodes';
import { Directive, HostListener, Input } from '@angular/core';

export type AutoKeyHandlerItem = { handle: () => void } & Highlightable;

@Directive({
  selector: '[autoKeyHandler]',
})
export class AutoKeyHandlerDirective {
  @Input() autoKeyHandler: ActiveDescendantKeyManager<AutoKeyHandlerItem>;

  @HostListener('keydown', ['$event', '$event.keyCode'])
  handleKeyUp(event: KeyboardEvent, key: number) {
    event.stopImmediatePropagation();

    if (this.autoKeyHandler) {
      if (key === DOWN_ARROW || key === UP_ARROW) {
        this.autoKeyHandler.onKeydown(event);
        return false;
      } else if (key === PAGE_UP) {
        this.autoKeyHandler.setActiveItem(Math.max(0, this.autoKeyHandler.activeItemIndex - 5));
        return false;
      } else if (key === PAGE_DOWN) {
        const activeInd = this.autoKeyHandler.activeItemIndex;
        this.autoKeyHandler.setLastItemActive();
        const lastInd = this.autoKeyHandler.activeItemIndex;
        this.autoKeyHandler.setActiveItem(Math.min(lastInd, activeInd + 5));
        return false;
      } else if (key === HOME) {
        this.autoKeyHandler.setFirstItemActive();
        return false;
      } else if (key === END) {
        this.autoKeyHandler.setLastItemActive();
        return false;
      } else if (key === ENTER) {
        if (this.autoKeyHandler.activeItem) {
          this.autoKeyHandler.activeItem.handle();
        }
      }
    }
  }
}
