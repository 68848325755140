import { AfterViewInit, Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[cdkFixedHeader]',
})

export class FixedHeaderDirective implements AfterViewInit {
  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngAfterViewInit() {
    const cdkViewport: HTMLElement = this.el.nativeElement.closest('cdk-virtual-scroll-viewport');
    const wrapper: HTMLElement = cdkViewport.querySelector('.cdk-virtual-scroll-content-wrapper');

    const ntv = this.el.nativeElement;
    this.renderer.setStyle(ntv, 'top', '0px');
    this.renderer.setStyle(ntv, 'position', 'sticky');
    this.renderer.setStyle(ntv, 'position', '-webkit-sticky');
    this.renderer.setStyle(ntv, 'z-index', '1000');

    cdkViewport.prepend(ntv);

    this.renderer.setStyle(wrapper, 'position', 'relative');
    this.renderer.setStyle(wrapper, 'contain', 'initial');
  }
}
