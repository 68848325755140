import { InvoiceStatus, ReceiptApiData, ReceiptRetryType } from 'src/app/pages/new-billing/types/api.types';


export interface InvoiceModel {
  amount: number;
  date: number;
  paymentMethod: string;
  transactionId: string;
  status: InvoiceStatus;
  loadingPdf?: boolean;
  loadingRetryPayment?: boolean;
  errorCode?: string;
  errorDescription?: string;
  type: string;
  retry?: ReceiptRetryType;
}

export class InvoiceModelImp implements InvoiceModel {
  readonly amount: number;
  readonly date: number;
  readonly paymentMethod: string;
  readonly transactionId: string;
  readonly status: InvoiceStatus;
  readonly type: string;
  readonly errorCode?: string;
  readonly errorDescription?: string;
  readonly retry?: ReceiptRetryType;

  loadingPdf = false;

  constructor(data: ReceiptApiData) {
    this.amount = data.amount;
    this.date = Date.parse(data.date);
    this.paymentMethod = data.paymentMethod;
    this.transactionId = data.id;
    this.status = data.status;
    this.type = data.type;
    this.errorCode = data?.errorCode;
    this.errorDescription = data?.errorDescription;
    this.retry = data?.retry;
  }
}
