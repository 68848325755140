<button mat-button class="export-doc-button" [matMenuTriggerFor]="buttonMenu">
  <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
</button>

<ng-template #buttonContent>
  <mat-icon>cloud_download</mat-icon>
  <span fxHide.lt-sm style="margin-left: 12px;" fxHide.sm class="export-text">
    Export
  </span>
</ng-template>

<mat-menu #buttonMenu="matMenu" xPosition="before">
  <button class="export-doc-link" mat-menu-item (click)="export.next('png')" [trackClick]="{newValue: 'PNG'}">
    <mat-icon>image</mat-icon>
    <span>{{ 'common.exportPNG' | translate }}</span>
  </button>

  <button class="export-doc-link" mat-menu-item (click)="export.next('pdf')" [trackClick]="{newValue: 'PDF'}">
    <mat-icon>description</mat-icon>
    <span>{{ 'common.exportPDF' | translate }}</span>
  </button>
</mat-menu>
