import { Injectable } from '@angular/core';
import { SidebarItem } from '../sidebar-item';


@Injectable()
export class AddProjectTaskStep implements SidebarItem {
  id = 'addProjectTask';
  completed = false;
  link = '/projects-and-tasks';
}
