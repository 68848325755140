import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpParams,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AuthState } from 'src/app/store/auth/auth.state';
import { environment } from 'src/environments/environment';

@Injectable()
export class HttpBillingInterceptor implements HttpInterceptor {

  constructor(
    private store: Store,
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.includes(environment.billingApiUrl) || request.url.includes(environment.integrationsApiUrl)) {
      const token = this.store.selectSnapshot(AuthState.token);
      const { id } = this.store.selectSnapshot(AuthState.company);
      const params = new HttpParams({ fromString: request.params.toString() })
        .append('tdCompanyId', id)
        .append('deploymentId', environment.deployment);

      const clonedRequest = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
        params,
      });

      return next.handle(clonedRequest);
    }

    return next.handle(request);
  }
}

export const httpBillingInterceptorProvider = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpBillingInterceptor,
    multi: true,
  },
];
