import { APP_INITIALIZER } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export function initializeLanguage(translate: TranslateService) {
  return () => new Promise<any>((resolve) => {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('en').subscribe({
      next: () => {
        console.log(`Successfully initialized 'en' language.`);
      },
      error: err => {
        console.error(`Problem with 'en' language initialization.`, err);
        throw err;
      },
      complete: () => {
        resolve(null);
      },
    });

  });
}

export const translateInitProvider = {
  provide: APP_INITIALIZER,
  useFactory: initializeLanguage,
  deps: [TranslateService],
  multi: true,
};
