import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { PaymentUser } from 'src/models';
import { PaymentApiService } from './payment-api.service';


@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(private api: PaymentApiService) { }

  getUser(userId: string, data?: {}) {
    return this.api.request<{ data: PaymentUser }>('get', `users/${userId}`, data).pipe(map(x => x.data));
  }

  getCompany(data?: {}) {
    return this.api.request<{ data: PaymentUser }>('get', `company`, data).pipe(map(x => x.data));
  }

  updateUser(userId: string, data: {}) {
    return this.api.request('put', `users/${userId}`, data);
  }

  getInfo(userId: string) {
    return this.api.request<{ stripePubKey: string }>('get', `info/${userId}`).pipe(map(x => x));
  }

  cancelAccount(companyId: string, data) {
    return this.api.request('delete', `subscriptions/${companyId}`, data).pipe(map(x => x));
  }

  applyDiscount(companyId, data) {
    return this.api.request('put', `discount?company=${companyId}`, data);
  }
}
