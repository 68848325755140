import { NgModule } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TrackingContextDirective, TrackingDisabledDirective, TrackingEnabledDirective } from './context';
import {
  TrackChangeDirective,
  TrackClickDirective,
  TrackExportDirective,
  TrackHoverDirective,
  TrackScreenshotDirective,
  TrackSortDirective,
  TrackTabChangeDirective
} from './directive';
import { LogTrackingService, SegmentTrackingService, TrackingService } from './service';

const directives = [
  TrackClickDirective,
  TrackChangeDirective,
  TrackExportDirective,
  TrackSortDirective,
  TrackTabChangeDirective,
  TrackingContextDirective,
  TrackingDisabledDirective,
  TrackingEnabledDirective,
  TrackSortDirective,
  TrackScreenshotDirective,
  TrackTabChangeDirective,
  TrackHoverDirective,
];

@NgModule({
  declarations: [
    ...directives,
  ],
  exports: [
    ...directives,
  ],
  imports: [
  ],
  providers: [
    {
      provide: TrackingService,
      useClass: (!environment.production && !environment.enableAnalytics)
      ? LogTrackingService
      : SegmentTrackingService,
    },
  ],
})
export class TrackingModule { }
