import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-modal-footer',
  templateUrl: './modal-footer.component.html',
  styleUrls: ['./modal-footer.component.scss'],
})
export class ModalFooterComponent {
  @Input() modalSource: string;
  @Input() userCount$: Observable<number>;
  @Output() addUsersEvent = new EventEmitter<void>(); // Emit an event when add users button is clicked
  @Output() clickLinkEvent = new EventEmitter<void>(); // Emit an event when add users click a link
  @Output() gotoBillingPageEvent = new EventEmitter<void>(); // Emit an event when add users click a link

  onAddUsersClick() {
    this.addUsersEvent.emit();
  }

  gotoBillingPage() {
    this.gotoBillingPageEvent.emit();
  }

  onLinkClick() {
    this.clickLinkEvent.emit();
  }
}
