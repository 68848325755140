type Flatten<T> = Exclude<T, any[]> | {
  0: never;
  1: T extends (infer L)[] ? Flatten<L> : never;
}[T extends any[] ? 1 : 0];

export function flatten<T>(arr: T[]): Flatten<T>[] {
  return arr.reduce((flat, toFlatten) => {
    return flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten);
  }, []);
}

export function setEquals<T>(a: T[], b: T[]) {
  const aSet = new Set(a);
  const bSet = new Set(b);

  if (aSet.size !== bSet.size) { return false; }

  for (const item of aSet) {
    if (!bSet.has(item)) {
      return false;
    }
  }

  return true;
}
