<div *ngIf="shouldShowTrialPromotionBanner()" class="trial-offer-banner-wrapper"
     fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center"
     fxLayoutAlign.lt-md="center center">
  <!-- Icon and content in one row for desktop and mobile -->
  <div fxLayout="row" fxLayoutAlign="center center">
    <div class="banner-icon" fxFlex="none">
      <mat-icon class="material-icons-outlined">sell</mat-icon>
    </div>
    <div class="banner-content">
      <app-countdown-timer [timerLocation]="'banner'" [fontSizeClass]="'small-font'"></app-countdown-timer>
    </div>
  </div>
  <!-- Button aligned to the center for mobile and desktop -->
  <div class="banner-button" fxFlex="none" fxLayoutAlign="center center">
    <button
      (click)="checkTrialPromotionOffer()"
      mat-button mat-stroked-button
      [trackClick]="{
        page: 'Link Click',
        linklocation: 'banner',
        pagename: 'Billing Page',
        linkname:'Get Discount',
        linktype: 'button',
        linkurl: ''}"
    >
      {{ 'trialOfferExperiment.banner.getDiscount' | translate | uppercase }}
    </button>
  </div>
</div>
