import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

const colors = [
  {
    bgColor: '#B1B7F0',
    fgColor: '#000b7c',
  },
  {
    bgColor: '#BEECD2',
    fgColor: '#008039',
  },
  {
    bgColor: '#DCDDE1',
    fgColor: '#585A6E',
  },
  {
    bgColor: '#FAACB3',
    fgColor: '#78000C',
  },
  {
    bgColor: '#FCE3AC',
    fgColor: '#865C00',
  },
  {
    bgColor: '#B3FEFF',
    fgColor: '#1D7791',
  },
  {
    bgColor: '#FFDAFF',
    fgColor: '#B400B4',
  },
];

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedAvatarComponent {
  @Input() public size = 50;
  @Input() public name = '';
  @Input() public textSizeRatio = 3;
  @Input() public bgColor: string | undefined;
  @Input() public fgColor: string | undefined;
  @Input() public borderColor: string | undefined;
  @Input() public round = true;
  @Input() public src: string | null;
  @Input() public initialsSize: number;
  @Input() public value: string | null;
  @Input() public cornerRadius: string | number;

  getShortName() {
    const nameSplit = this.name?.split(' ') || [];
    return nameSplit.length > 2 ? `${nameSplit[0]} ${nameSplit[nameSplit.length - 1]}` : this.name;
  }

  getAvatarVariant() {
    const colorIdx = this.name?.split('').map(e => e.charCodeAt(0)).reduce((a, b) => a + b, 0) % colors.length;
    return colors[colorIdx || 0];
  }
}
