import { inject } from '@angular/core';
import { CanMatchFn } from '@angular/router';
import { JSONValue } from '@growthbook/growthbook';
import { filter, firstValueFrom } from 'rxjs';
import { GrowthBookFeature, GrowthBookService } from './service';

export function growthbookMatcher(flag: GrowthBookFeature, value: JSONValue = true, defaultValue?: JSONValue): CanMatchFn {
  return async function growthbookMatchFn() {
    const service = inject(GrowthBookService);
    await firstValueFrom(service.initialized.pipe(filter(x => x)));

    return service.getFeatureValue(flag, defaultValue) === value;
  };
}
