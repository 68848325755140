import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Select, Selector, State, StateContext } from '@ngxs/store';
import { Observable, filter } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import {
  SetBillingData,
  SetTransactionIdCacheOption,
  UpdateBillingDetails
} from 'src/app/pages/new-billing/store/actions';
import {
  BillingApiData,
  BillingDetailsApiData,
  DiscountApiData,
  PlanApiData
} from 'src/app/pages/new-billing/types/api.types';
import type { CacheOption } from 'src/app/services/billing/billing.service';
import { AuthSelectors } from 'src/app/store/auth/auth.selectors';
import { AuthCompany } from 'src/models';

export interface NewBillingStateModel {
  updatedBillingDetails: BillingDetailsApiData;
  plans: PlanApiData[];
  discounts: DiscountApiData[];
  discountCode: string;
  transactionIdCacheOption: CacheOption;
}

@Injectable()
@State<NewBillingStateModel>({
  name: 'newBilling',
  defaults: {
    updatedBillingDetails: null,
    plans: [],
    discounts: [],
    discountCode: '',
    transactionIdCacheOption: 'no-cache',
  },
})
export class NewBillingState implements NgxsOnInit {
  @Select(AuthSelectors.company) company$!: Observable<AuthCompany>;

  ngxsOnInit({ setState }: StateContext<NewBillingStateModel>) {
    this.company$.pipe(
      distinctUntilChanged(),
      filter(company => !company),
    ).subscribe(() => {
      setState({} as NewBillingStateModel);
    });
  }

  @Selector()
  static updatedBillingDetails(state: NewBillingStateModel) {
    return state.updatedBillingDetails;
  }

  @Selector()
  static billingData(state: NewBillingStateModel): BillingApiData {
    return {
      billingDetails: state.updatedBillingDetails,
      ...state,
    };
  }

  @Selector()
  static hasScheduledCancellation(state: NewBillingStateModel) {
    return state.updatedBillingDetails?.scheduledChange?.action === 'cancel';
  }

  @Selector()
  static billingMode({ updatedBillingDetails }: NewBillingStateModel) {
    return updatedBillingDetails?.billingMode;
  }

  @Selector()
  static transactionIdCacheOption(state: NewBillingStateModel) {
    return state.transactionIdCacheOption;
  }

  @Action(UpdateBillingDetails)
  updateBillingDetails({ patchState }: StateContext<NewBillingStateModel>, { payload }: UpdateBillingDetails) {
    patchState({ updatedBillingDetails: payload });
  }

  @Action(SetBillingData)
  billingData(
    { patchState }: StateContext<NewBillingStateModel>,
    { payload }: SetBillingData,
  ) {
    patchState({ updatedBillingDetails: payload.billingDetails, ...payload });
  }

  @Action(SetTransactionIdCacheOption)
  setTransactionIdCacheOption(
    { patchState }: StateContext<NewBillingStateModel>,
    { payload }: SetTransactionIdCacheOption,
  ) {
    patchState({ transactionIdCacheOption: payload });
  }
}
