<div fxLayout fxLayoutAlign="space-between ">
  <h2 mat-dialog-title>{{ 'export.title' | translate }}</h2>
  <mat-icon mat-dialog-close>close</mat-icon>
</div>

<mat-dialog-content fxLayout.xs="column" fxLayout.sm="column" fxLayout="row" [class.small]="small" fxLayoutGap="10px">

  <div fxLayout="column" class="export-column file-type">
    <strong>{{ 'export.fileType' | translate }}</strong>
    <mat-radio-group aria-labelledby="radio-group-label" class="radio-group" color="primary"
      (change)="setOption('fileType', $event.value)" [value]="exportOptions.getValue().fileType">
      <mat-radio-button class="radio-button" *ngFor="let option of options.fileType" [value]="option">
        {{ ('export.' + option) | translate }}
      </mat-radio-button>

    </mat-radio-group>
  </div>

  <ng-container *ngIf="exportOptions.getValue().fileType !== 'pdf'">
    <div fxLayout="column" class="export-column time-format" *ngIf="exportOptions.getValue().organization !== 'timelineDetailed' &&
      page !== 'attendance' && page !== 'manageUsers' && page !== 'internetReport'">
      <strong>{{ 'export.timeFormat' | translate }}</strong>
      <div fxLayout="column" class="checkbox-group">
        <mat-checkbox color="primary" *ngFor="let option of options.timeFormat" [value]="option"
          [checked]="exportOptions.getValue().timeFormat?.includes(option)"
          (change)="setOption('timeFormat', option, $event.checked)">
          {{ ('export.' + option) | translate }}
        </mat-checkbox>
      </div>
    </div>

    <div fxLayout="column" class="export-column time-format" *ngIf="exportOptions.getValue().organization === 'timelineDetailed' ||
      page === 'attendance' || page === 'internetReport'">
      <strong>{{ 'export.timeFormat' | translate }}</strong>

      <mat-radio-group aria-labelledby="radio-group-label" class="radio-group" color="primary"
        (change)="setOption('timeFormat', [$event.value])"
        [value]="exportOptions.getValue().timeFormat[0] || 'digital'">
        <mat-radio-button class="radio-button" *ngFor="let option of options.timeFormat" [value]="option">
          {{ ('export.' + option) | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </ng-container>

  <div fxLayout="column" class="export-column organization"
    *ngIf="showOrganizationColumn && exportOptions.getValue().fileType !== 'pdf'">
    <strong>{{ 'export.organization' | translate }}</strong>
    <mat-radio-group aria-labelledby="radio-group-label" class="radio-group" color="primary"
      (change)="setOption('organization', $event.value)" [value]="exportOptions.getValue().organization">
      <mat-radio-button class="radio-button" *ngFor="let option of options.organization" [value]="option">
        {{ ('export.' + option) | translate }}
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <div fxLayout="column" class="export-column content" *ngIf="showContentColumn">
    <strong>{{ 'export.content' | translate }}</strong>
    <mat-radio-group aria-labelledby="radio-group-label" class="radio-group" color="primary"
      (change)="setOption('content', $event.value)" [value]="exportOptions.getValue().content">
      <mat-radio-button class="radio-button" *ngFor="let option of options.content" [value]="option">
        {{ ('export.' + option) | translate }}
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <div fxLayout="column" class="export-column user-identification" *ngIf="showUserFieldsColumn">
    <strong>{{ 'export.userIdentification' | translate }}</strong>
    <div fxLayout="column" class="checkbox-group">
      <mat-checkbox color="primary" *ngFor="let option of options.userFields" [value]="option"
        [disabled]="option === 'name' || ['onlyProjectTotal', 'onlyTaskTotal'].includes(exportOptions.getValue().organization)"
        [checked]="option === 'name' || exportOptions.getValue().userFields?.includes(option)"
        (change)="setOption('userFields', option, $event.checked)">
        {{ ('export.' + option) | translate }}
      </mat-checkbox>
    </div>
  </div>

  <div fxLayout="column" class="export-column user-additional-columns" *ngIf="showUserAdditionalColumns">
    <strong>{{ 'export.additionalColumns.title' | translate }}</strong>
    <div fxLayout="column" class="checkbox-group">
      <mat-checkbox color="primary" *ngFor="let option of options.userAdditionalColumns" [value]="option"
        [checked]="exportOptions.getValue().userAdditionalColumns?.includes(option)"
        (change)="setOption('userAdditionalColumns', option, $event.checked)">
        {{ ('export.additionalColumns.' + option) | translate }}
      </mat-checkbox>
    </div>
  </div>
</mat-dialog-content>

<div class="message" *ngIf="customFootnote?.length > 0">
  <mat-icon class="message-icon" fontSet="material-icons-two-tone">report_problem</mat-icon>
  <span class="message-text">{{ customFootnote }}</span>
</div>

<div *ngIf="showColumnRenameNote" class="footnote">
  {{ 'export.columnRenameNote' | translate }}
</div>

<div *ngIf="showCustomExportFootnote && !isRegularUser" class="footnote">More export options, including exporting data
  from multiple
  reports,
  are available in <a mat-dialog-close routerLink="/csv">Reports /Custom Export</a>
</div>

<mat-dialog-actions align="end">
  <button mat-stroked-button [mat-dialog-close]="false" class="cancel-btn">
    {{ 'common.cancel' | translate | uppercase }}
  </button>
  <button mat-raised-button color="primary" [mat-dialog-close]="exportOptions.getValue()">
    {{ 'export.export' | translate | uppercase }}
  </button>
</mat-dialog-actions>
