import { Injectable, NgModule } from '@angular/core';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule, State } from '@ngxs/store';
import { NewBillingState } from 'src/app/pages/new-billing/store/state';
import { TrialWidgetState } from 'src/app/store/trial-widget/trial-widget.state';
import { environment } from 'src/environments/environment';
import { storeReadyAppInitializer } from '../services/store-ready.service';
import { AuthState } from './auth/auth.state';
import { LoadingState } from './loading/loading.state';
import { ManagerState } from './manager/manager.state';
import { NgxsSyncStorageSwitchPluginModule } from './ngxs-sync-storage-switch-plugin/sync-storage-switch.module';
import { OnboardingState } from './onboarding/onboarding.state';
import { PreferencesState } from './preferences/preferences.state';
import { ProgressState } from './progress/progress.state';
import { SharedState } from './shared/shared.state';
import { CountdownState } from 'src/app/components/trial-promotion-offer/store/trial-offer-promotion.state';
import { BannerState } from 'src/app/store/banner/state';


export function identity(x) { return x; }

// This dummy state is used to fire an update state event, which is required for app to initialize
@State<Record<string, never>>({ name: 'dummy' })
@Injectable()
export class DummyState { }

@NgModule({
  imports: [
    NgxsModule.forRoot(
      [
        AuthState,
        LoadingState,
        ProgressState,
        SharedState,
        PreferencesState,
        OnboardingState,
        ManagerState,
        TrialWidgetState,
        NewBillingState,
        CountdownState,
        BannerState,
      ],
      { developmentMode: !environment.production },
    ),
    NgxsRouterPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot({
      name: 'Time Doctor 2',
      disabled: environment.production,
    }),
    NgxsStoragePluginModule.forRoot({
      key: [
        'auth.user', 'auth.token', 'auth.company', 'auth.usersToRemember', 'auth.adminLogin',
        'preferences', 'onboarding', 'timelineReport', 'activitySummary', 'unusualActivity',
        'projectsReport', 'projectsReportNew', 'exportOptions', 'invite.params',
        'manageUsers.silentBannerDismissed', 'manageUsers.visibleColumns', 'editTime.timezone',
        'payroll.visibleColumns', 'payroll.timezone', 'payroll.userFilter', 'payroll.bannerDismissed', 'addManagers.params', 'hoursTracked', 'hoursTrackedNew', 'csv', 'connectivity',
        'schedules', 'attendance', 'webAndAppBanner', 'engagementTracking', 'approvals', 'manageUsers.deleteOptionsBannerDismissed',
        'emailNotificationsBanner', 'productivityRatings.selectedRating, trialWidget', 'identicalScreencastAwarenessBanner', 'uarMonetizationAwarenessBanner',
        /**
         * these are temporary definitions in order to show soft/hide the soft delete feature
         * see https://globalworkforce.jira.com/browse/STAFF-128103
         */
        'manageUsersNoSoftDelete.silentBannerDismissed', 'manageUsersNoSoftDelete.visibleColumns',
      ],
    }),
    NgxsSyncStorageSwitchPluginModule.forRoot({
      key: ['dashboard', 'productivity', 'screencasts', 'notifications'],
    }),
    NgxsModule.forFeature([DummyState]),
  ],
  exports: [
    NgxsModule,
  ],
  providers: [
    storeReadyAppInitializer,
  ],
})
export class NgxsStoreModule { }
