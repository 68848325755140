import { Injectable } from '@angular/core';
import { lastValueFrom, map } from 'rxjs';
import { Approval, Worklog } from 'src/models';
import { ApiLimitParams, ApiService } from '../api.service';
import { PagingService } from '../paging/paging.service';

export interface RemoveManualTimeParams {
  start: string;
  end: string;
}

export interface ModifyManualTimeParams extends RemoveManualTimeParams {
  projectId: string;
  taskId: string;
  reason?: string;
}

export interface GetEditTimeParams extends ApiLimitParams {
  from?: string;
  to?: string;
}
export interface EditTimelog {
  id: string;
  start: string;
  time: number;
  createdAt: string;
  approved: string;
  reviewerId?: string;
  reviewedAt?: string;
  operation: string;
  reason: string;
  userId: string;
  reviewerName: string;
}

@Injectable({ providedIn: 'root' })
export class EditTimeService {
  constructor(private api: ApiService, private paging: PagingService) { }

  manualTimelog(minDate: string, maxDate: string, user: string, params: { limit?: number, 'resolve-names'?: boolean } = {}) {
    const limit = params?.limit || 1000;
    return this.paging.getPagedResult<Worklog>(() => {
      return this.api.request<{ data: EditTimelog[][] }>('get', 'activity/edit-time', {
        ...params,
        user,
        from: minDate,
        to: maxDate,
      }).pipe(map(result => ({
        data: (result.data[0] || []).map(val => ({
          deviceId: 'manual',
          mode: 'manual',
          projectId: '',
          projectName: '',
          taskId: '',
          taskName: '',
          approvalStatus: val.approved,
          ...val,
        })),
      })));
    }, limit);
  }

  getManualTimes(params: GetEditTimeParams) {
    return this.api.request<{ data: Approval[][] }>('get', `activity/edit-time`, { ...params, detail: true });
  }

  addManualTime(params: ModifyManualTimeParams, userId: string) {
    return lastValueFrom(this.api.request<{ data: EditTimelog }>('post', 'activity/edit-time', { ...params, operation: 'add', userId }));
  }

  removePendingManualTime(manualTimeId: string) {
    return lastValueFrom(this.api.request<{ data: EditTimelog }>('delete', `activity/edit-time/${manualTimeId}`));
  }

  removeManualTime(params: ModifyManualTimeParams, userId: string) {
    return lastValueFrom(this.api.request<{ data: EditTimelog }>('post', 'activity/edit-time', { ...params, operation: 'remove', userId }));
  }

  modifyPendingManualTime(params: ModifyManualTimeParams, manualTimeId: string) {
    return lastValueFrom(this.api.request<{ data: EditTimelog }>('put', `activity/edit-time/${manualTimeId}`, { ...params }));
  }

  modifyManualTime(params: ModifyManualTimeParams, user: string) {
    return lastValueFrom(this.api.request<{ data: EditTimelog }>('put', 'activity/worklog', { ...params }, user && { params: { user } }));
  }
}
