export class CheckTrackedTimeUser {
  static readonly type = '[Trial Offer] Check Tracked Time User';
}

export class ShowPromoPopup {
  static readonly type = '[Trial Offer] Show Trial Offer';
}

export class HidePromoPopup {
  static readonly type = '[Trial Offer] Hide Trial Offer';
}

export class HidePromoBanner {
  static readonly type = '[Trial Offer] Hide Trial Offer Banner';
}

export class ShowPromoBanner {
  static readonly type = '[Trial Offer] Show Trial Offer Banner';
}
