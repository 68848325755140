import { Injectable } from '@angular/core';
import { lastValueFrom, map } from 'rxjs';
import { Break, BreakType } from 'src/models';
import { ApiLimitParams, ApiService } from '../api.service';
import { PagedApiResult, PagingService } from '../paging/paging.service';

export interface GetBreaksOptions extends ApiLimitParams {
  convertId?: boolean;
  assigned?: boolean;
  filter?: string;
  'filter[name]'?: string;
  'filter[keywords]'?: string;
  'filter[id]'?: string;
  'deleted'?: 1 | 0;
  'noDeleted'?: 1 | 0;
}


@Injectable({ providedIn: 'root' })
export class BreakService {
  constructor(private api: ApiService, private paging: PagingService) { }

  async getBreak(id: string): Promise<Break> {
    const response = await lastValueFrom(this.api.request<{ data: Break }>('get', `breaks/${id}`, null, null, true));
    return response.data;
  }

  getBreaks(breaksOptions?: GetBreaksOptions) {
    const { convertId, ...options } = breaksOptions || {};
    const limit = options?.limit || 1000;
    return this.paging.getPagedResult<Break>(page =>
      this.api.request<PagedApiResult<Break>>('get', `breaks`, { sort: 'name', ...options, page, limit }, null, true).pipe(map(result => {
        if (convertId) {
          return { ...result, data: result.data.map(x => ({ ...x, id: `break:${x.type}:${x.id}` })) };
        }
        return result;
      })), limit);
  }

  createBreak(name: string, type: BreakType, scope, access) {
    return lastValueFrom(
      this.api.request<{ data: Break }>('post', 'breaks', { name, type, scope, access }, null, true)
        .pipe(map(x => x.data)));
  }

  editBreak(id: string, break$: Partial<Break>) {
    return lastValueFrom(this.api.request('put', `breaks/${id}`, break$, null, true));
  }

  deleteBreak(id: string) {
    return lastValueFrom(this.api.request('delete', `breaks/${id}`, null, null, true));
  }

  archiveBreak(id: string) {
    return lastValueFrom(this.api.request('delete', `breaks/${id}`, null, null, true));
  }
}
