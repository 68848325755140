import { Injectable, Injector } from '@angular/core';
import { ChildNavSidebarItem, ParentNavSidebarItem } from 'src/app/components/nav-sidebar/models';
import { Store } from '@ngxs/store';
import { AuthSelectors } from 'src/app/store/auth/auth.selectors';

@Injectable({
  providedIn: 'root',
})
export class GetStartedSidebarService {

  constructor(
    private injector: Injector,
  ) { }

  get parentItem(): ParentNavSidebarItem {
    return new GetStartedParentSidebarItem('get-started', this.injector, {
      label: 'navSidebar.getStartedGroup.gettingStarted',
      icon: 'rocket_launch',
    });
  }
}

class GetStartedParentSidebarItem extends ParentNavSidebarItem {
  get children() {
    return [
      new ChildNavSidebarItem('invite', this.injector, {
        parent: this.routerLink,
        label: 'navSidebar.getStartedGroup.inviteUsers',
      }),
      new ChildNavSidebarItem('tracking', this.injector, {
        parent: this.routerLink,
        label: 'navSidebar.getStartedGroup.setupTracking',
      }),
    ];
  }
}

export const getStartedPathMapping = (injector: Injector) => {
  const role = injector.get(Store).selectSnapshot(AuthSelectors.role);
  const getStartedPage = role === 'owner'
    ? 'Viewed Getting Started Screen'
    : 'Viewed Invitee Getting Started Screen';

  return {
    'get-started': { pageName: getStartedPage, access_level: role },
    'get-started/invite': { pageName: 'Viewed Invite Users Screen' },
    'get-started/tracking': { pageName: 'Viewed Setup Tracking Screen' },
  };
};


