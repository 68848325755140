import { Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'app-copy-to-clipboard',
  templateUrl: './copy-to-clipboard.component.html',
  styleUrls: ['./copy-to-clipboard.component.scss'],
})
export class CopyToClipboardComponent implements OnChanges {
  @Input() text = '';
  @Input() icon = '';
  @Input() buttonText = '';
  @Input() buttonIcon = 'file_copy';
  @ViewChild('inputElement', { static: true }) input: ElementRef<HTMLInputElement>;

  ngOnChanges() {
    this.input.nativeElement.value = this.text || '';
    this.input.nativeElement.setSelectionRange(0, this.input.nativeElement.value.length);
  }

  copyInputMessage(input: HTMLInputElement) {
    input.select();
    document.execCommand('copy');
    input.setSelectionRange(0, input.value.length);
  }
}
