import { Component, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';
import { flyoutAnim, sidepanelSlideInOutAnim } from './common';

@Component({
  selector: 'app-sidepanel',
  templateUrl: './component.html',
  styleUrls: ['./component.scss'],
  animations: [sidepanelSlideInOutAnim, flyoutAnim],
})
export class SidepanelComponent {
  @HostBinding('class.app-sidepanel') protected readonly sidepanel = true;
  @HostBinding('class.visible') protected visible = false;

  @HostBinding('@slideInOut')
  get slideInOut() {
    if (!this.enableAnimation) return this.visible ? 'opened' : 'closed';
    return this.visible;
  }

  @HostBinding('class.animation-enabled')
  @Input() enableAnimation = false;

  @Output() closed = new EventEmitter();
  @Output() moved = new EventEmitter<number>();
  @Output() opened = new EventEmitter<number>();

  close() {
    this.closed.emit();
    this.visible = false;
  }

  open() {
    this.opened.emit();
    this.visible = true;
  }

  toggle(open?: boolean) {
    if (typeof open === 'undefined') {
      if (this.visible) this.close();
      else this.open();
    } else {
      if (open) this.open();
      else this.close();
    }

    return this.visible;
  }

  @HostListener('document:keydown.escape', ['$event'])
  private onEscape(event: KeyboardEvent) {
    if (!this.visible) return;
    this.close();
    event?.preventDefault();
    event?.stopPropagation();
  }

  @HostListener('document:keydown.arrowup', ['$event'])
  private onArrowUp(event: KeyboardEvent) {
    if (!this.visible) return;
    this.moved.emit(-1);
    event?.preventDefault();
    event?.stopPropagation();
  }

  @HostListener('document:keydown.arrowdown', ['$event'])
  private onArrowDown(event: KeyboardEvent) {
    if (!this.visible) return;
    this.moved.emit(1);
    event?.preventDefault();
    event?.stopPropagation();
  }
}
