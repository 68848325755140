import { ControlValueAccessor } from '@angular/forms';
import { BehaviorSubject, of, Subject, timer } from 'rxjs';
import { debounce, distinctUntilChanged, map } from 'rxjs/operators';

export class ValueAccessorBase<T> implements ControlValueAccessor {
  private changed = new Array<(value: T) => void>();
  private touched = new Array<() => void>();

  private readonly valueInner$ = new BehaviorSubject<T>(undefined);
  public readonly value$ = this.valueInner$.asObservable();
  private readonly valueDebounced$ = new Subject<{ value: T, debounce?: number }>();

  constructor() {
    this.valueDebounced$.pipe(
      debounce(x => x.debounce ? timer(x.debounce) : of(0)),
      map(x => x.value),
      distinctUntilChanged(),
    ).subscribe(x => {
      this.changed.forEach(f => f(x));
    });
  }

  get value(): T {
    return this.valueInner$.value;
  }


  set value(value: T) {
    if (this.value !== value) {
      this.valueInner$.next(value);
      this.valueDebounced$.next({ value, debounce: 0 });
    }
  }

  setDebouncedValue(value: T, debounceFor: number) {
    if (this.value !== value) {
      this.valueInner$.next(value);
      this.valueDebounced$.next({ value, debounce: debounceFor });
    }
  }

  touch() {
    this.touched.forEach(f => f());
  }


  writeValue(value: T) {
    this.valueInner$.next(value);
  }


  registerOnChange(fn: (value: T) => void) {
    this.changed = [];
    this.changed.push(fn);
  }


  registerOnTouched(fn: () => void) {
    this.touched = [];
    this.touched.push(fn);
  }
}
