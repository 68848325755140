<button class="ellipsis" mat-button [matMenuTriggerFor]="roleMenu" fxFlex>
  <span fxFlex>
    {{ 'controls.accessLevelSelected' | translate:{count: value?.length || 0} }}
  </span>
  <mat-icon style="margin-left: 6px;">keyboard_arrow_down</mat-icon>
</button>


<mat-menu #roleMenu="matMenu" [trackChange]="{ name: 'Pulldown Access level Selection' }"
  [trackChangeTransform]="roleSelectedFn" trackChangeKey="selectedoption">
  <mat-checkbox class="column-checkbox" (click)="$event.stopPropagation()" color="primary" (change)="masterToggle()"
    [checked]="value?.length && isAllSelected()" [indeterminate]="value?.length && !isAllSelected()"
    [trackingDisabled]="value?.length && isAllSelected()">
    <strong>{{ 'controls.allAccessLevels' | translate }}</strong>
  </mat-checkbox>

  <mat-divider></mat-divider>

  <mat-checkbox *ngFor=" let role of roles" class="column-checkbox" (click)="$event.stopPropagation()" color="primary"
    (change)="setSelected($event.checked, role)" [checked]="value?.includes(role)"
    [trackingDisabled]="value?.includes(role)">
    {{ ('common.roles.' + role) | translate }}
  </mat-checkbox>
</mat-menu>
