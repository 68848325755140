import { AfterContentInit, Directive, ElementRef, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Directive({
  selector: '[scrollIntoView]',
})
export class ScrollIntoViewDirective implements AfterContentInit {
  @Input() scrollIntoView: boolean | ScrollIntoViewOptions;
  @Input() checkUrlFragment = false;
  @Input() scrollDelay = 100;

  public constructor(
    private el: ElementRef,
    private activatedRoute: ActivatedRoute,
  ) { }

  public ngAfterContentInit() {
    setTimeout(() => {
      const el: HTMLElement = this.el.nativeElement;
      const fragment = this.activatedRoute.snapshot.fragment;

      if (this.checkUrlFragment && el?.id !== fragment) return;
      if (this.scrollIntoView === false) { return; }

      let scrollMode: ScrollIntoViewOptions = this.scrollIntoView as any;

      if (this.scrollIntoView === true) {
        scrollMode = { block: 'center' };
      }

      el.scrollIntoView(scrollMode);
    }, this.scrollDelay);
  }
}
