import { InjectionToken, Provider } from '@angular/core';
import { GetStartedSidebarService } from 'src/app/pages/get-started/get-started-sidebar.service';
import { NavSidebarItem } from 'src/app/components/nav-sidebar/models';

export const ITEMS_INJECTION_TOKEN = new InjectionToken<NavSidebarItem[]>('SidebarItems');
export const itemsProvider: Provider = {
  provide: ITEMS_INJECTION_TOKEN,
  useFactory: (getStarted: GetStartedSidebarService) => {
    return [
      getStarted.parentItem,
    ];
  },
  deps: [GetStartedSidebarService],
};
