import { PlanId } from 'src/app/pages/new-billing/types/api.types';
import { LegacyPricingPlans, PricePeriodicity } from 'src/app/pages/new-billing/types/plan.types';

export const planIdToPricingPlan: Record<PlanId, LegacyPricingPlans> = {
  basic_monthly: 'basic_plan',
  basic_yearly: 'basic_plan_annual',
  standard_monthly: 'standard_plan',
  standard_yearly: 'standard_plan_annual',
  premium_monthly: 'premium_plan',
  premium_yearly: 'premium_plan_annual',
  standard_monthly_01: 'standard_plan',
  standard_yearly_01: 'standard_plan_annual',
};

export const pricingPlanToPlanId: Record<LegacyPricingPlans, PlanId> = {
  basic_plan: 'basic_monthly',
  basic_plan_annual: 'basic_yearly',
  standard_plan: 'standard_monthly_01',
  standard_plan_annual: 'standard_yearly_01',
  premium_plan: 'premium_monthly',
  premium_plan_annual: 'premium_yearly',
};

export const tierUnderPlan: Record<PlanId, PlanId> = {
  basic_monthly: 'basic_monthly',
  basic_yearly: 'basic_yearly',
  standard_monthly: 'basic_monthly',
  standard_yearly: 'basic_yearly',
  premium_monthly: 'standard_monthly_01',
  premium_yearly: 'standard_yearly_01',
  standard_monthly_01: 'basic_monthly',
  standard_yearly_01: 'basic_yearly',
};

export const tierOverPlan: Record<PlanId, PlanId> = {
  basic_monthly: 'standard_monthly',
  basic_yearly: 'standard_yearly',
  standard_monthly: 'premium_monthly',
  standard_yearly: 'premium_yearly',
  premium_monthly: null,
  premium_yearly: null,
  standard_monthly_01: 'premium_monthly',
  standard_yearly_01: 'premium_yearly',
};

export const planRecurrenceToApiRecurrence: Record<PricePeriodicity, 'monthly' | 'yearly'> = {
  month: 'monthly',
  year: 'yearly',
};

export const planIdSwitch: Record<PlanId, PlanId> = {
  basic_monthly: 'basic_yearly',
  basic_yearly: 'basic_monthly',
  standard_monthly: 'standard_yearly',
  standard_yearly: 'standard_monthly',
  premium_monthly: 'premium_yearly',
  premium_yearly: 'premium_monthly',
  standard_monthly_01: 'standard_yearly_01',
  standard_yearly_01: 'standard_monthly_01',
};

export const pricingPlanSwitch: Record<LegacyPricingPlans, LegacyPricingPlans> = {
  basic_plan: 'basic_plan_annual',
  basic_plan_annual: 'basic_plan',
  standard_plan: 'standard_plan_annual',
  standard_plan_annual: 'standard_plan',
  premium_plan: 'premium_plan_annual',
  premium_plan_annual: 'premium_plan',
};
