import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { DateTime } from 'luxon';
import { distinctUntilChanged, filter, interval, Observable, Subscription } from 'rxjs';
import { HidePromoBanner, HidePromoPopup } from 'src/app/components/trial-promotion-offer/store/trial-offer-promotion.actions';
import { GrowthBookService } from 'src/app/services/growthbook/service';
import { AuthState } from 'src/app/store/auth/auth.state';
import { TrialPromotionOfferService } from '../service/trial-promotion-offer.service';

const DEFAULT_PROMOTION_DURATION = 259200; //72hrs in seconds

@Component({
  selector: 'app-countdown-timer',
  templateUrl: './countdown-timer.component.html',
  styleUrls: ['./countdown-timer.component.scss'],
})
export class CountdownTimerComponent implements OnInit, OnDestroy {

  @Select(AuthState.company) company$: Observable<any>;
  @Input() fontSizeClass = 'small-font';
  @Input() timerLocation: 'banner' | 'popup';  // Added this back

  remainingTime = 0;
  private countdownSubscription: Subscription;
  private hasHiddenPromoBanner = false;
  private hasHiddenPopupPromo = false;
  private popupPromoSubscription: Subscription;

  constructor(
    private store: Store,
    private growthbook: GrowthBookService,
    private trialPromotionOfferService: TrialPromotionOfferService,
  ) {
    this.popupPromoSubscription = this.trialPromotionOfferService.shouldShowTimeLimitedPromotionOffer('isTimeLimitedPromotionPopUpVisible')
      .subscribe(isEligible => {
        this.hasHiddenPopupPromo = isEligible;
      });
  }

  ngOnInit(): void {
    const timeToSeeOffer = this.trialPromotionOfferService.expFeatureSettings?.timeToSeeOffer || 0;
    const promotionDuration = timeToSeeOffer ? (timeToSeeOffer * 60 * 60) : DEFAULT_PROMOTION_DURATION;

    this.company$.pipe(
      filter(company => !!company?.companySettings?.custom?.['timeLimitedPromotion']),
      distinctUntilChanged(),
    ).subscribe(company => {
        const timeLimitedPromotion = company.companySettings.custom['timeLimitedPromotion'];
        const startTime = timeLimitedPromotion?.startTime;

        if (!startTime) {
            console.warn('Start time is missing; countdown will not start.');
            return;
        }

        const parsedStartTime = DateTime.fromISO(startTime);
        this.startCountdown(parsedStartTime, promotionDuration);
    });
  }

  private startCountdown(startTime: DateTime, promotionDuration: number): void {
      this.countdownSubscription = interval(1000).subscribe(() => {
          this.updateRemainingTime(startTime, promotionDuration);
          if (this.remainingTime <= 0) {
              this.stopCountdown();
          }
      });
  }

  updateRemainingTime(startTime: DateTime, promotionDuration: number): void {
    const now = DateTime.now();
    const elapsedSeconds = now.diff(startTime, 'seconds').seconds;
    this.remainingTime = promotionDuration - elapsedSeconds;
    if (this.remainingTime < 0) {
      this.remainingTime = 0;
    }
  }

  stopCountdown(): void {
    if (this.countdownSubscription) {
      this.countdownSubscription.unsubscribe();
    }

    this.remainingTime = 0;

    if (!this.hasHiddenPromoBanner) {
      this.store.dispatch(new HidePromoBanner());
      this.hasHiddenPromoBanner = true;
    }
    if (this.hasHiddenPopupPromo) {
      this.store.dispatch(new HidePromoPopup());
    }
  }

  getFormattedTime(remainingTime: number): string {
    if (remainingTime === null || remainingTime === undefined) {
      return '00h:00m:00s';
    }
    const hours = Math.floor(remainingTime / 3600);
    const minutes = Math.floor((remainingTime % 3600) / 60);
    const seconds = Math.floor(remainingTime % 60);

    return `${hours.toString()}h:${this.pad(minutes)}m:${this.pad(seconds)}s`;
  }

  pad(num: number): string {
    return num < 10 ? `0${num}` : num.toString();
  }

  ngOnDestroy(): void {
    if (this.countdownSubscription) {
      this.countdownSubscription.unsubscribe();
    }
    if (this.popupPromoSubscription) {
      this.popupPromoSubscription.unsubscribe();
    }
  }
}
