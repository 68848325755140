import { Injectable } from '@angular/core';

export const originalError = Symbol('Original Error');
export const originalRequest = Symbol('HTTP Request');

export enum Severity {
  Fatal = 'fatal',
  Error = 'error',
  Warning = 'warning',
  Log = 'log',
  Info = 'info',
  Debug = 'debug',
  Critical = 'critical',
}

@Injectable({ providedIn: 'root' })
export class ErrorMonitoringService {
  notify(err: Error, data?: any, tags?: { [key: string]: string } | string[], severity?: Severity) {
    console.warn(err, data, tags);
  }
}
