import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'translateCut' })
export class TranslateCutPipe implements PipeTransform {
  transform(value: string, index: number, cutCharacter: string = '|'): string {
    const cutIndex = Number(index);
    return value.split(cutCharacter)[cutIndex];
  }
}
