import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Project, ProjectAccess, Task } from 'src/models';
import { ApiLimitParams, ApiService } from '../api.service';
import { PagedApiResult, PagingService } from '../paging/paging.service';

export interface GetProjectsOptions extends ApiLimitParams {
  // Comma-separated IDs of Projects to filter tasks by.
  projects?: string;

  // Comma-separated IDs of Folders to filter tasks by.
  folders?: string;

  // Filter the tasks by user.
  filter?: string;

  // ID of user to get the tasks for. (caller by default )
  user?: string;

  // If set, integration tasks will be also listed
  'show-integration'?: boolean;

  deleted?: boolean | 'include';

  detail?: 'users' | 'basic';

  'filter[keyphrase]'?: string;

  projectless?: boolean;

  includeBreaks?: boolean;

  'allow-unassigned'?: boolean;

}

export interface GetTasksOptions extends GetProjectsOptions {
  // If specified, comma-separated list of IDs of tasks to resolve.
  tasks?: string;
  'public-only'?: boolean;
}

@Injectable({ providedIn: 'root' })
export class ProjectService {
  constructor(private api: ApiService, private paging: PagingService) { }

  async getProject(id: string): Promise<Project> {
    const response = await lastValueFrom(this.api.request<{ data: Project }>('get', `projects/${id}`));
    return response.data;
  }

  getProjects(options?: GetProjectsOptions) {
    const limit = options?.limit || 1000;

    const includeBreaks = options?.includeBreaks;
    const breaksOptions: Project[] = [
      {
        id: 'break:paid',
        name: 'Paid Break',
      },
    ].filter((item) => {
      return options['filter[keyphrase]']
        ? item.name
          .toLowerCase()
          .includes(options['filter[keyphrase]'].toLowerCase())
        : true;
    }) as Project[];

    return this.paging.getPagedResult<Project>(page => this.api.request('get', `projects`, { sort: 'name', ...options, page, limit }).pipe(tap<PagedApiResult<Project>>(resp => {
      if (includeBreaks && resp.paging.cur === 0) {
        resp.data = [...breaksOptions, ...resp.data];
        resp.paging.nItems += breaksOptions.length;
        resp.paging.totalCount += breaksOptions.length;
      }
    })), limit);

  }

  async getTask(id: string): Promise<Task> {
    const response = await lastValueFrom(this.api.request<{ data: Task }>('get', `tasks/${id}`));
    return response.data;
  }

  getTasks(options?: GetTasksOptions) {
    const limit = options?.limit || 1000;
    return this.paging.getPagedResult<Task>(page =>
      this.api.request('get', `tasks`, { sort: 'name', 'public-only': false, ...options, page, limit }), limit);
  }

  cloneProject(name: string, cloneFromId: string) {
    return lastValueFrom(
      this.api.request<{ data: Project }>('post', 'projects', { name, cloneTasksFromId: cloneFromId, cloneAccessFromId: cloneFromId })
        .pipe(map(x => x.data)));
  }

  createProject(name: string, scope?: string, users?: ProjectAccess[]) {
    return lastValueFrom(
      this.api.request<{ data: Project }>('post', 'projects', { name, scope, users })
        .pipe(map(x => x.data)));
  }

  editProject(id: string, project: Partial<Project>) {
    return lastValueFrom(this.api.request('put', `projects/${id}`, project));
  }

  deleteProject(id: string) {
    return lastValueFrom(this.api.request('delete', `projects/${id}`));
  }

  createTask(name: string, projectId: string) {
    return lastValueFrom(
      this.api.request<{ data: Task }>('post', 'tasks', { name, project: { id: projectId } })
        .pipe(map(x => x.data)));
  }

  editTask(id: string, task: Partial<Task>) {
    return lastValueFrom(this.api.request('put', `tasks/${id}`, task));
  }

  deleteTask(id: string) {
    return lastValueFrom(this.api.request('delete', `tasks/${id}`));
  }

  assign(projectId: string, userId: string, role: 'user' | 'tag') {
    return lastValueFrom(this.api.request('put', `projects/${projectId}/users/${userId}`, { role }));
  }

  unassign(projectId: string, userId: string, role: 'user' | 'tag') {
    return lastValueFrom(this.api.request('delete', `projects/${projectId}/users/${userId}`, { role }));
  }

  unassignAll(projectId: string) {
    return lastValueFrom(this.api.request('delete', `projects/${projectId}/users`));
  }
}
