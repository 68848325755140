import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-sidepanel-header',
  templateUrl: './component.html',
  styleUrls: ['./component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidepanelHeaderComponent {
  @Input() name = '';
  @Input() time: number = undefined;
  @Input() iconType: 'avatar' | 'none' | (string & {}) = 'none';

  @Output() closed = new EventEmitter<void>();
}
