<button mat-button (click)="exportOptions()" [disabled]="disabledForUpsell"
  [ngClass]="{'disabled-for-upsell': disabledForUpsell}">
  <mat-icon>cloud_download</mat-icon>
  <span fxHide.lt-sm style="margin-left: 12px;" *ngIf="!hideLabel" class="export-text">
    <ng-container *ngIf="page">
      {{ 'export.button' | translate }}
    </ng-container>
    <ng-container *ngIf="!page">
      {{ 'export.export' | translate }}
    </ng-container>
  </span>
  <div class="full-cover" [matTooltip]="(disabledForUpsell ? 'common.upsellTooltip': 'export.button') | translate">
  </div>
</button>
