import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { IntegrationsService } from 'src/app/services/integrations/integrations.service';


@Injectable({
  providedIn: 'root',
})
export class PayrollGuard implements CanActivate {

  constructor(
    private router: Router,
    private integrations: IntegrationsService,
  ) { }

  async canActivate(route: ActivatedRouteSnapshot) {
    const { code: authorizationCode, state: appCode, appName, error } = route.queryParams || {};
    if (authorizationCode && appCode) {
      try {
        await lastValueFrom(this.integrations.postToken({ authorizationCode, appCode, appName }));
        return this.router.navigate(['/payroll']);
      }
      catch (err) {
        return this.router.navigate(['/payroll']);
      }
    } else if (error) {
      return this.router.navigate(['/payroll']);
    }
    return true;
  }
}
