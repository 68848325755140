import { Component, Input } from '@angular/core';
import { NavSidebarItem, ParentNavSidebarItem } from 'src/app/components/nav-sidebar/models';

@Component({
  selector: 'app-nav-sidebar-group-item',
  templateUrl: './nav-sidebar-group-item.component.html',
  styleUrls: ['./nav-sidebar-group-item.component.scss'],
})
export class NavSidebarGroupItemComponent {
  @Input() set navItem(item: ParentNavSidebarItem) {
    this.items = [
      item,
      ...item.children,
    ];
  }

  items: NavSidebarItem[] = [];
}
