import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Inject,
  Injectable,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { Sort } from '@angular/material/sort';
import { MatTabGroup } from '@angular/material/tabs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, distinctUntilChanged, filter, skip } from 'rxjs';
import { DateRange } from 'src/app/common/daterange';
import { UserFilterSelection } from 'src/app/components/user-filter-dropdown/user-filter-selection';
import { LazyUserSelection } from 'src/app/components/user-filter-lazy';
import { TrackingContext, TrackingContextType, TrackingDisabled } from './context';
import { TrackingService } from './service';

export abstract class TrackDirective {
  abstract get type(): string;
  abstract get track(): any;
  abstract service: TrackingService;
  abstract context: TrackingContext;
  abstract disabled: TrackingDisabled;

  trackEvent(props?: Partial<TrackingContextType>, type?: string) {
    if (!this.disabled?.disabled && (this.track || this.track === '')) {
      this.service.track(type || this.type, { ...this.context?.context, ...this.track, ...props });
    }
  }
}

@Directive({
  selector: '[track]',
  exportAs: 'track',
})
export class TrackExportDirective extends TrackDirective {
  @Input() track: any;
  @Input('trackType') type = 'track';

  constructor(
    public service: TrackingService,
    @Optional() public context: TrackingContext,
    @Optional() public disabled: TrackingDisabled,
  ) {
    super();
  }
}

@Directive({ selector: '[trackClick]' })
export class TrackClickDirective extends TrackDirective implements OnInit {
  @Input('trackClick') track: any;
  @Input('trackType') type = 'click';

  constructor(
    public service: TrackingService,
    @Optional() public context: TrackingContext,
    @Optional() public disabled: TrackingDisabled,
    public el: ElementRef<HTMLElement>,
  ) {
    super();
  }

  ngOnInit() {
    this.el.nativeElement.addEventListener('click', () => {
      if (this.el.nativeElement instanceof HTMLAnchorElement && (this.context?.context?.linktype || this.track?.linktype)) {
        return this.service.trackLink(
          this.el.nativeElement,
          { ...this.context?.context, ...this.track },
        );
      }
      this.trackEvent();
    }, false);
  }
}

@Directive({ selector: '[trackHover]' })
export class TrackHoverDirective extends TrackDirective implements OnInit, OnDestroy {
  @Input('trackHover') track: any;
  @Input('trackType') type = 'hover';

  constructor(
    public service: TrackingService,
    @Optional() public context: TrackingContext,
    @Optional() public disabled: TrackingDisabled,
    public el: ElementRef<HTMLElement>,
  ) {
    super();
  }

  listenerCallback = () => this.trackEvent();

  ngOnInit() {
    this.el.nativeElement.addEventListener('mouseenter', this.listenerCallback, false);
  }

  ngOnDestroy() {
    this.el.nativeElement.removeEventListener('mouseenter', this.listenerCallback, false);
  }
}



type TrackChangeTransform = (val: any, directiveRef: TrackChangeDirective) => any;

@Injectable()
export abstract class TrackChangeControl<TValue = any, TCause extends string = string> {
  abstract trackValueChanges: Observable<{ value: TValue, cause: TCause }>;
}

@UntilDestroy()
@Directive({ selector: '[trackChange]' })
export class TrackChangeDirective extends TrackDirective implements OnInit {
  @Input('trackChange') track: any;
  @Input('trackType') type = 'change';
  @Input('trackChangeKey') key = 'newValue';
  @Input() excludeCauses: string[] = [];
  @Input() includeCauses: string[] = null;
  @Input() trackChangeTransform: TrackChangeTransform = TrackChangeDirective.defaultTransform;

  static defaultTransform: TrackChangeTransform = (val) => {
    return val;
  };

  constructor(
    public service: TrackingService,
    @Optional() public context: TrackingContext,
    @Optional() public disabled: TrackingDisabled,
    @Optional() public ctrl: NgControl,
    @Optional() @Inject(TrackChangeControl) @Self() public trackCtrl: TrackChangeControl<any>,
  ) {
    super();
  }

  ngOnInit() {
    if (this.trackCtrl) {
      this.trackCtrl.trackValueChanges.pipe(
        untilDestroyed(this),
        filter(x => this.filterCause(x.value, x.cause)),
        distinctUntilChanged((a, b) => this.checkDistinct(a.value, b.value)),
      ).subscribe((ev) => {
        this.trackEvent({ [this.key]: this.transform(ev.value), cause: ev.cause });
      });
    } else {
      if (this.ctrl) {
        this.ctrl.valueChanges.pipe(
          untilDestroyed(this),
          distinctUntilChanged(this.checkDistinct.bind(this)),
          skip(1),
        ).subscribe((ev) => {
          this.trackEvent({ [this.key]: this.transform(ev) });
        });
      }
    }
  }

  checkDistinct(val1: any, val2: any) {
    // eslint-disable-next-line eqeqeq
    if (val1 == val2) return true;
    if (val1 instanceof Array) return JSON.stringify(val1) === JSON.stringify(val2);
    if (val1 instanceof LazyUserSelection) return LazyUserSelection.same(val1, val2);
    if (val1 instanceof UserFilterSelection) return UserFilterSelection.same(val1, val2);
    if (val1 instanceof DateRange) return DateRange.same(val1, val2, true);

    return false;
  }

  filterCause(val: any, cause: string) {
    if (this.includeCauses) {
      return this.includeCauses.includes(cause);
    }
    if (this.excludeCauses) {
      if (this.excludeCauses.includes(cause)) return false;
    }

    if (val instanceof DateRange) {
      if (['init', 'model', 'timezone', 'type'].includes(cause)) return false;
    }

    if (['init'].includes(cause)) return false;

    return true;
  }

  transform(val: any) {
    if (this.trackChangeTransform) return this.trackChangeTransform(val, this);
    return TrackChangeDirective.defaultTransform(val, this);
  }
}


@Directive({ selector: '[trackSort]' })
export class TrackSortDirective extends TrackDirective {
  @Input('trackSort') track: any;
  @Input('trackType') type = 'sort';

  constructor(
    public service: TrackingService,
    @Optional() public context: TrackingContext,
    @Optional() public disabled: TrackingDisabled,
  ) {
    super();
  }
  @HostListener('matSortChange', ['$event'])
  sortChange(e: Sort) {
    if (!e?.active) return false;
    const props = { selectedoption: { value: `${e.active} ${e.direction.toUpperCase()}` } };
    this.trackEvent(props);
  }
}

@Directive({ selector: '[trackScreenshot]' })
export class TrackScreenshotDirective extends TrackDirective implements AfterViewInit {
  @Input('trackScreenshot') track: any;
  @Input('trackType') type = 'click';

  constructor(
    public service: TrackingService,
    @Optional() public context: TrackingContext,
    @Optional() public disabled: TrackingDisabled,
    public el: ElementRef<HTMLElement>,
  ) {
    super();
  }

  ngAfterViewInit() {
    this.el.nativeElement.querySelector('.screens-wrap').addEventListener('click', (e) => {
      this.trackEvent();
    }, true);
  }
}

@UntilDestroy()
@Directive({ selector: '[trackTabChange]' })
export class TrackTabChangeDirective extends TrackDirective implements OnInit {
  @Input('trackTabChange') track: any;
  @Input('trackType') type = 'tabChange';

  constructor(
    public service: TrackingService,
    @Optional() public context: TrackingContext,
    @Optional() public disabled: TrackingDisabled,
    public tabGroup: MatTabGroup,
  ) {
    super();
  }

  ngOnInit() {
    this.tabGroup.selectedTabChange.pipe(untilDestroyed(this)).subscribe(res => {
      const selectedTabText = res.tab.textLabel.toLowerCase().replace(/\b\S/g, (t) => { return t.toUpperCase(); });
      const eventName = this.track?.name
        ? { name: this.track.name, selectedOption: this.uppercaseWordInString(selectedTabText, 'Csv') }
        : { name: 'Clicked ' + this.uppercaseWordInString(selectedTabText, 'Csv') };
      this.trackEvent(eventName);
    });
  }

  private uppercaseWordInString(str: string, word: string): string {
    return str.replace(new RegExp(`\\b${word}\\b`, 'gi'), match => match.toUpperCase());
  }
}

