<div class="sidebar-content">
  <!-- This may be added in the future. Keeping it in here for consistency -->
  <!--<header></header>-->

  <nav class="nav-items">
    <ng-container *ngFor="let item of items">
      <app-nav-sidebar-group-item
        *ngIf="item.isVisible"
        [navItem]="item"
      ></app-nav-sidebar-group-item>
    </ng-container>
  </nav>

  <footer class="cards-section">
    <app-trial-widget
      *ngIf="canShowTrialWidget | async"
      variant="outlined"
      [isCollapsed]="isDesktopCollapsed"
    ></app-trial-widget>
  </footer>
</div>
