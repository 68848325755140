<div class="ruler">
  <ng-container *ngIf="hourFormat24$ | async; else ruler12">
    <div></div>
    <div><span>2</span></div>
    <div><span>4</span></div>
    <div><span>6</span></div>
    <div><span>8</span></div>
    <div><span>10</span></div>
    <div><span>12</span></div>
    <div><span>14</span></div>
    <div><span>16</span></div>
    <div><span>18</span></div>
    <div><span>20</span></div>
    <div><span>22</span></div>
    <div></div>
  </ng-container>

  <ng-template #ruler12>
    <div></div>
    <div><span>2 <span>AM</span></span></div>
    <div><span>4 <span>AM</span></span></div>
    <div><span>6 <span>AM</span></span></div>
    <div><span>8 <span>AM</span></span></div>
    <div><span>10 <span>AM</span></span></div>
    <div><span>12 <span>PM</span></span></div>
    <div><span>2 <span>PM</span></span></div>
    <div><span>4 <span>PM</span></span></div>
    <div><span>6 <span>PM</span></span></div>
    <div><span>8 <span>PM</span></span></div>
    <div><span>10 <span>PM</span></span></div>
    <div></div>
  </ng-template>
</div>

<div class="ruler-half">
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</div>

<div class="tubes">
  <div *ngFor="let wl of (items$ | async); index as i; trackBy: trackBy" class="tube" [ngClass]="[wl.mode, wl?.approvalStatus || '']"
    [style.left.%]="wl.left" [style.width.%]="wl.width" [class.hovered]="hovered === wl.original"
    [class.highlighted]="highlighted === wl.original" [class.editing]="editing === wl.original"
    [class.hidden]="wl.original === editing">
    <div *ngIf="wl.mode === 'unpaidLeave'" aria-label="unpaid leave mark" [class.xs-unpaidleave-mark]="wl.width < 1.1"
      [class.sm-unpaidleave-mark]="wl.width >= 1.1 && wl.width < 4"
      [class.md-unpaidleave-mark]="wl.width >= 4 && wl.width < 10" [class.unpaidleave-mark]="wl.width >= 10">
      <mat-icon class="unpaid-money-off-icon" *ngIf="wl.width >= 4">money_off</mat-icon>
      <span *ngIf="wl.width >= 10">Unpaid</span>
    </div>
  </div>

  <div #tooltip="matTooltip" [matTooltip]="hoveredItem?.tooltip"
    [matTooltipDisabled]="!hoveredItem?.tooltip || hoveredItem?.original === editing" matTooltipPosition="above"
    class="tooltip-base" [matTooltipShowDelay]="0" [matTooltipHideDelay]="0"
    [style.left.%]="(hoveredItem?.left + hoveredItem?.width / 2) || 0">
  </div>

  <div *ngIf="editing && editData" class="tube manual" (click)="clickWorklog.emit(editing)"
    [style.left.%]="editData.start * 100 / secondsInDay"
    [style.width.%]="(editData.end - editData.start) * 100 / secondsInDay"
    (mouseenter)="hovered = editing; hoveredChange.emit(editing)"
    (mouseleave)="hovered = null; hoveredChange.emit(null)" [class.hovered]="hovered === editing" [class.editing]="true"
    matTooltipPosition="above" [matTooltipShowDelay]="0" [matTooltipHideDelay]="0"
    [matTooltip]="getTooltip({taskName: editData.task?.name, projectName: editData?.project?.name, time: editData.end - editData.start, mode:'manual'})">
  </div>
</div>
