import { APP_BASE_HREF } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';

@Component({
  selector: 'app-pdf-report-header',
  templateUrl: './pdf-report-header.component.html',
  styleUrls: ['./pdf-report-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PdfReportHeaderComponent {

  @Input() date: string;
  @Input() timezone: string;

  constructor(@Inject(APP_BASE_HREF) public baseHref: string) { }

}
