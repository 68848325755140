import { Component, Input, OnInit } from '@angular/core';
import { CustomValidators } from 'src/app/common/validators';

type LinkTarget = '_self' | '_blank' | '_parent' | '_top';
type LinkRel = 'alternate' | 'author' | 'bookmark' | 'external' | 'help' | 'license' | 'next' | 'nofollow' | 'noopener' | 'noreferrer' | 'prev' | 'search' | 'tag' | (string & {});

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
})
export class LinkComponent implements OnInit {
  @Input() url: string | any[];
  @Input() target: LinkTarget;
  @Input() rel: LinkRel;
  isAbsoluteUrl: boolean;

  ngOnInit(): void {
    this.isAbsoluteUrl = typeof this.url === 'string' ?  CustomValidators.urlRegex.test(this.url) : false;
  }
}
