import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { take } from 'rxjs';
import { AddCreditCardStep } from 'src/app/components/starter-sidebar/items/add-credit-card';
import { CompanyService } from 'src/app/services/company/company.service';
import { SegmentService } from 'src/app/services/segment/segment.service';
import { UserService } from 'src/app/services/user/user.service';
import { UserSettingsChanged } from '../auth/auth.actions';
import { AuthState } from '../auth/auth.state';
import {
  CompleteWelcomeStep,
  DismissWelcomeBanner,
  DismissWelcomeStep,
  OnboardingWizardConfig,
  ResetOnboardingWizardConfig,
  SetOnboardingWizardConfig,
  ToggleSidebarCollapsed
} from './onboarding.actions';

export interface OnboardingStateModel {
  sidebarCollapsed: boolean;
  showWizard: boolean;
  wizardConfig: OnboardingWizardConfig;
}

@State<OnboardingStateModel>({
  name: 'onboarding',
  defaults: {
    sidebarCollapsed: false,
    showWizard: false,
    wizardConfig: {},
  },
})
@Injectable()
export class OnboardingState {
  @Selector()
  public static sidebarCollapsed(state: OnboardingStateModel) {
    return state.sidebarCollapsed;
  }

  @Selector()
  public static wizardConfig(state: OnboardingStateModel) {
    return state.wizardConfig;
  }

  constructor(
    private companyService: CompanyService,
    private userService: UserService,
    private store: Store,
    private segment: SegmentService,
  ) { }

  @Action(ToggleSidebarCollapsed)
  toggleSidebarCollapsed({ patchState }: StateContext<OnboardingStateModel>, { collapsed }: ToggleSidebarCollapsed) {
    patchState({ sidebarCollapsed: collapsed });
  }

  @Action(CompleteWelcomeStep)
  CompleteWelcomeStep({ dispatch }: StateContext<OnboardingStateModel>, { stepId }: CompleteWelcomeStep) {
    if (stepId === AddCreditCardStep.stepId) {
      const { id, companySettings } = this.store.selectSnapshot(AuthState.company);

      if (!companySettings?.custom?.completedStep?.[stepId]) {
        this.companyService.putCompanySettings(id, { [`custom.completedStep.${stepId}`]: true })
          .pipe(take(1))
          .subscribe();
      }
    }

    if (this.store.selectSnapshot(AuthState.role) !== 'owner') return;

    this.segment.trackSideBarActivity(stepId);
    this.userService.editProperties('me', { [`custom.completedStep.${stepId}`]: true }).subscribe();
    dispatch(new UserSettingsChanged(null, { completedStep: { [stepId]: true } }));
  }

  @Action(DismissWelcomeStep)
  DismissWelcomeStep({ dispatch }: StateContext<OnboardingStateModel>, { stepId }: DismissWelcomeStep) {
    if (this.store.selectSnapshot(AuthState.role) !== 'owner') { return; }
    this.segment.trackSideBarActivity(stepId, true);
    this.userService.editProperties('me', { [`custom.dismissedStep.${stepId}`]: true }).subscribe();
    dispatch(new UserSettingsChanged(null, { dismissedStep: { [stepId]: true } }));
  }

  @Action(DismissWelcomeBanner)
  DismissWelcomeBanner({ dispatch }: StateContext<OnboardingStateModel>) {
    if (this.store.selectSnapshot(AuthState.role) !== 'owner') { return; }
    this.userService.editProperties('me', { 'custom.welcomeSidebarCompleted': true } as any).subscribe();
    dispatch(new UserSettingsChanged(null, { welcomeSidebarCompleted: true }));
  }

  @Action(SetOnboardingWizardConfig)
  SetOnboardingWizardConfig({ patchState, getState }: StateContext<OnboardingStateModel>, { config }: SetOnboardingWizardConfig) {
    patchState({ wizardConfig: { ...getState().wizardConfig, ...config } });
  }

  @Action(ResetOnboardingWizardConfig)
  ResetOnboardingWizardConfig({ patchState }: StateContext<OnboardingStateModel>) {
    patchState({ wizardConfig: {} });
  }
}
