import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { lastValueFrom } from 'rxjs';
import { LOGIN_NEW_URL, LOGIN_URL } from 'src/app/services/app.constants';
import { InvalidateUser, UpdateCurrentUser } from 'src/app/store/auth/auth.actions';
import { AuthState } from 'src/app/store/auth/auth.state';
import { AppState } from 'src/app/store/state';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private store: Store,
    private router: Router,
    @Inject(LOGIN_URL) private loginUrl,
    @Inject(LOGIN_NEW_URL) private loginNewUrl,
  ) { }

  async canActivate(route?: ActivatedRouteSnapshot, state?: RouterStateSnapshot) {
    const token = this.store.selectSnapshot(AuthState.token);
    let redirectUrl = state?.url || undefined;

    if (!token) {
      if (redirectUrl?.startsWith('/activate')) {
        redirectUrl = null;
      }
      return this.router.parseUrl(this.loginUrl + (redirectUrl ? '?redirectUrl=' + encodeURIComponent(redirectUrl) : ''));
    }

    const user = this.store.selectSnapshot(AuthState.user);
    const userRequiresUpdate = this.store.selectSnapshot(((st: AppState) => st.auth.userRequiresUpdate));
    if (userRequiresUpdate || !user) {
      try {
        await lastValueFrom(this.store.dispatch(new UpdateCurrentUser()));
      } catch (err) {
        if (err.error === 'invalidToken' || err.error === 'invalidCredentials' || err.error === 'expiredToken' || err.error === 'denied') {
          await lastValueFrom(this.store.dispatch(new InvalidateUser()));
          return this.router.parseUrl(this.loginNewUrl);
        }
      }
    }

    return true;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(route, state);
  }

  canLoad() {
    return this.canActivate(null, null).then(x => x === true);
  }
}
