import { animate, state, style, transition, trigger } from '@angular/animations';

export const flyoutAnim = trigger('flyout', [
  transition(':enter', [
    style({ transform: 'translateX(70%)', opacity: 0 }),
    animate('300ms ease-out', style({ transform: 'translateX(0%)', opacity: 1 })),
  ]),
  transition(':leave', [
    animate('300ms ease-out', style({ transform: 'translateX(70%)', opacity: 0 })),
  ]),
]);

export const sidepanelSlideInOutAnim = trigger('slideInOut', [
  state('false', style({ display: 'none' })),
  state('true', style({ display: 'flex' })),
  state('closed', style({ display: 'none' })),
  state('opened', style({ display: 'flex' })),
  transition('0 => 1', [
    style({ transform: 'translateX(70%)', opacity: 0, display: 'flex' }),
    animate('300ms ease-out', style({ transform: 'translateX(0%)', opacity: 1 })),
  ]),
  transition('1 => 0', [
    animate('100ms ease-out', style({ transform: 'translateX(70%)', opacity: 0 })),
  ]),
]);
