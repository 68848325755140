import { ListRange } from '@angular/cdk/collections';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatTabGroup } from '@angular/material/tabs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select } from '@ngxs/store';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { ReportLazyUserService } from 'src/app/services/lazy/user/lazy-users.service';
import { AuthState } from 'src/app/store/auth/auth.state';
import { ManagerState } from 'src/app/store/manager/manager.state';
import { advancedStringFilter, ValueAccessorBase } from 'src/app/util';
import { AuthCompany, AuthUser, User, UserGroup } from 'src/models';
import { SearchComponent } from '../search/search.component';
import { LazyUserSelection } from './lazy-user-selection';

export * from './lazy-user-selection';


@UntilDestroy()
@Component({
  selector: 'app-user-filter-lazy',
  templateUrl: './index.html',
  styleUrls: ['./index.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserFilterLazyComponent),
      multi: true,
    },
  ],
})
export class UserFilterLazyComponent extends ValueAccessorBase<LazyUserSelection> {
  @ViewChild(SearchComponent, { static: false }) searchComponent: SearchComponent;
  readonly membersOfNoGroupId = LazyUserSelection.membersOfNoGroupId;
  @Input() fireChangeOnClose = true;
  @Input() showSingleUser = false;
  @Input() showUsers = true;
  @Input() showGroups = true;
  @Input() showMembersOfNoGroup = false;
  @Input() showMembersOfMultipleGroups = false;
  @Input() showArchivedUsers = true;
  @Input() noGroupsTranslate = 'common.noGroupsAvailableAndAsk';
  @Input() disabledForUpsell = false;

  alwaysShow$ = new BehaviorSubject<boolean>(true);
  @Input() set alwaysShow(value: boolean) { this.alwaysShow$.next(value); }

  filter$ = new BehaviorSubject('');

  customUsers$ = new BehaviorSubject<User[]>(undefined);
  @Input() set customUsers(value: User[]) { this.customUsers$.next(value); }
  customUsersFiltered$ = combineLatest([this.customUsers$, this.filter$]).pipe(
    map(([customUsers, searchFilter]) => !searchFilter || !customUsers ? customUsers :
      advancedStringFilter(customUsers, searchFilter, x => [x.name, x.email, x.employeeId])));

  @Select(ManagerState.groups) groups$!: Observable<UserGroup[]>;
  users$: Observable<User[]>;
  @Select(AuthState.company) company$!: Observable<AuthCompany>;
  @Select(AuthState.user) authUser$!: Observable<AuthUser>;

  includeArchivedUsers$: Observable<boolean>;
  searchLoading$: Observable<boolean>;

  usersToShow$: Observable<User[]>;
  get userList$() { return this.usersToShow$; }

  groupsToShow$ = combineLatest([this.groups$, this.authUser$, this.company$, this.filter$]).pipe(
    map(([groups, user, company, searchFilter]) => {
      groups = advancedStringFilter(groups || [], searchFilter, x => x.name);
      if (['manager', 'guest'].includes(company?.role)) {
        return groups.filter(group => group.managers?.includes(user.id));
      }
      return groups;
    }),
  );

  private allUsersCount: number;
  private allGroups: UserGroup[];

  userVs$ = new BehaviorSubject<CdkVirtualScrollViewport>(null);
  @ViewChild('userVs', { static: false }) set userVs(val: CdkVirtualScrollViewport) { this.userVs$.next(val); }
  get userVs() { return this.userVs$.value; }

  @ViewChild('groupVs', { static: false }) groupVs: CdkVirtualScrollViewport;
  @ViewChild(MatTabGroup, { static: false }) tabGroup: MatTabGroup;

  private tempValue$ = new BehaviorSubject<LazyUserSelection>(null);
  set tempValue(value: LazyUserSelection) {
    if (this.fireChangeOnClose) {
      this.tempValue$?.next(value);
    } else {
      this.value = value;
      this.tempValue$?.next(null);
    }
  }
  get tempValue() { return this.tempValue$?.value || this.value; }

  readonly hasSingleUser$ = this.value$.pipe(
    map(x => x?.type === 'users' && x.users.count === 1 ? x.users.list[0] : null),
    switchMap(x => !x ? of(null) : this.service.resolve(x)),
  );

  constructor(private cd: ChangeDetectorRef, private service: ReportLazyUserService) {
    super();

    this.value = LazyUserSelection.none;

    this.includeArchivedUsers$ = combineLatest([this.tempValue$, this.value$]).pipe(
      untilDestroyed(this),
      map(([tempValue, value]) => tempValue?.includeArchivedUsers || value?.includeArchivedUsers),
      distinctUntilChanged(),
    );

    let allUsersCountCache: { allUsersCountWithoutArchived?: number, allUsersCountWithArchived?: number } = {};
    this.includeArchivedUsers$.pipe(untilDestroyed(this), filter(() => this.tempValue.type === 'users'))
      .subscribe(includeArchivedUsers => {
        const { allUsersCountWithArchived, allUsersCountWithoutArchived } = allUsersCountCache;
        if (includeArchivedUsers && allUsersCountWithArchived) {
          this.allUsersCount = allUsersCountWithArchived;
          this.tempValue = this.value = this.tempValue.setAllUsers(null, this.allUsersCount);
          return;
        }

        if (!includeArchivedUsers && allUsersCountWithoutArchived) {
          this.allUsersCount = allUsersCountWithoutArchived;
          this.tempValue = this.value = this.tempValue.setAllUsers(null, this.allUsersCount);
          return;
        }

        service.all(this.getIncludeArchivedUsersApiParam(includeArchivedUsers)).metadata().then(x => {
          this.allUsersCount = x.totalCount;
          this.tempValue = this.value = this.tempValue.setAllUsers(null, this.allUsersCount);
          allUsersCountCache = {
            ...allUsersCountCache,
            ...includeArchivedUsers && { allUsersCountWithArchived: this.allUsersCount },
            ...!includeArchivedUsers && { allUsersCountWithoutArchived: this.allUsersCount },
          };
        });
      });


    this.users$ = combineLatest([
      this.userVs$.pipe(
        switchMap(x => x?.renderedRangeStream ?? of(null as ListRange)),
        distinctUntilChanged((a, b) => a?.start === b?.start && a?.end === b?.end),
        map(x => ({ start: x?.start || 0, end: Math.max(x?.end || 0, (x?.start || 0) + 20) })),
        debounceTime(300),
        shareReplay(1)),
      combineLatest([this.filter$.pipe(distinctUntilChanged()), this.includeArchivedUsers$]).pipe(
        map(([x, includeArchivedUsers]) => x ? service.search(x, this.getIncludeArchivedUsersApiParam(includeArchivedUsers))
          : service.all(this.getIncludeArchivedUsersApiParam(includeArchivedUsers))),
        shareReplay(1)),
    ]).pipe(
      switchMap(([range, pager]) => {
        const preallocated = pager.preallocated();
        preallocated.loadRange(range.start, range.end);
        return preallocated.results;
      }),
      shareReplay(),
      untilDestroyed(this),
    );

    this.usersToShow$ = this.customUsersFiltered$.pipe(switchMap(x => x ? of(x) : this.users$));

    combineLatest([this.groupsToShow$]).pipe(untilDestroyed(this)).subscribe(([groups]) => {
      this.allGroups = groups;
      this.tempValue = this.value = this.tempValue.setAllGroups(this.allGroups, groups?.length + 1);
    });

    this.filter$.pipe(untilDestroyed(this)).subscribe(async searchFilter => {
      if (searchFilter && this.tempValue.all) {
        this.masterToggle(false);
        cd.detectChanges();
      }
    });

    this.searchLoading$ = combineLatest([this.filter$, this.users$]).pipe(
      map(([searchFilter, users]) => searchFilter && !users));
  }

  getIncludeArchivedUsersApiParam(includeArchivedUsers: boolean): { 'include-archived-users': 1 } {
    return includeArchivedUsers ? { 'include-archived-users': 1 } : null;
  }

  writeValue(value: LazyUserSelection) {
    super.writeValue((value || LazyUserSelection.none)
      .setAllGroups(this.allGroups, this.allGroups?.length + 1)
      .setAllUsers(null, this.allUsersCount));
    this.tempValue$.next(null);
    this.cd.detectChanges();
  }

  masterToggle(checked) {
    this.tempValue = checked ? this.tempValue.selectAll() : this.tempValue.clear();

    if (checked) {
      this.filter$.next('');
    }

    this.cd.detectChanges();
  }

  setSelected(selected: boolean, item: string) {
    // This makes sure the `All users` checkbox is unselected When changing selection of a group
    if (this.tempValue.type === 'groups' && this.tempValue.all) this.tempValue = this.tempValue.switchGroupsAll();
    this.tempValue = this.tempValue.set(selected, item);
    this.tempValue = this.tempValue.setNoTag(false);
    this.tempValue = this.tempValue.setMultipleTag(false);
  }

  setNoTag(selected: boolean) {
    this.tempValue = this.tempValue.setNoTag(selected);
  }

  setMultipleTag(selected: boolean) {
    this.tempValue = this.tempValue.setMultipleTag(selected);
  }

  setIncludeArchivedUsers(selected: boolean, users?: User[]) {
    this.tempValue = this.tempValue.setIncludeArchivedUsers(selected, users?.filter(x => x?.archived)?.map(x => x.id));
  }

  typeChanged({ index }) {
    this.filter$.next('');
    this.tempValue = this.tempValue.setType(index === 1 ? 'groups' : 'users');

    this.refreshVs();
  }

  menuClosed() {
    if (this.fireChangeOnClose && this.tempValue && this.tempValue !== this.value) {
      this.value = this.tempValue;
      this.tempValue = null;
    }
  }

  menuOpened() {
    this.filter$.next('');
    this.refreshVs();
    if (this.tabGroup) {
      this.tabGroup.realignInkBar();
    }
    this.searchComponent.focus();
  }

  private refreshVs() {
    // HACK: Scroll position is resetted when the tab changes.
    // This is a workaround to fix items not rendering after reset.
    this.userVs?.checkViewportSize();
    this.groupVs?.checkViewportSize();
  }
}
