import { BillingApiData, BillingDetailsApiData } from 'src/app/pages/new-billing/types/api.types';
import { CacheOption } from 'src/app/services/billing/billing.service';

export class UpdateBillingDetails {
  static readonly type = '[NewBilling] Update billing details';

  constructor(public payload: BillingDetailsApiData) {}
}

export class SetBillingData {
  static readonly type = '[NewBilling] update plans billing details';

  constructor(public payload: BillingApiData) {}
}

export class SetTransactionIdCacheOption {
  static readonly type = '[NewBilling] set transaction id cache option';

  constructor(public payload: CacheOption) {}
}
