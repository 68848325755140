import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-modal-steps',
  templateUrl: './modal-step.component.html',
  styleUrls: ['./modal-step.component.scss'],
})
export class ModalStepComponent {
  @Input() userCount$: Observable<number>;
  @Input() usersTrackingTimeCount$: Observable<boolean>;
  @Input() inviteLink: string;
}
