import { Inject, Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { DATA_INTEGRITY_APIKEY, DATA_INTEGRITY_APIURL } from './app.constants';

@Injectable({
  providedIn: 'root',
})
export class DataIntegrityTrackingService {
  constructor(
    @Inject(DATA_INTEGRITY_APIURL) private dataIntegrityApiUrl: string,
    @Inject(DATA_INTEGRITY_APIKEY) private dataIntegrityApiKey: string,
    private api: ApiService,
  ) { }
  private getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
  private getMainDomain() {
    const hostname = window.location.hostname;
    const domain = hostname.split('.');
    return domain[domain.length - 2] + '.' + domain[domain.length - 1];
  }
  private getCookie(name) {
    const re = new RegExp(name + '=([^;]+)');
    const value = re.exec(document.cookie);
    return (value != null) ? unescape(value[1]) : null;
  }
  private setCookie(name, value) {
    const now = new Date();
    now.setTime(now.getTime() + 6 * 2592000000);
    document.cookie = name + '=' + value + ';domain=.' + this.getMainDomain() + ';path=/;expires=' + now.toUTCString() + (3600 * 1000 * 24 * 365 * 10);
  }
  private getContext() {
    const context = {
      campaign: {
        source: this.getParameterByName('utm_source'),
        medium: this.getParameterByName('utm_medium'),
        name: this.getParameterByName('utm_campaign'),
        terms: this.getParameterByName('utm_terms'),
        content: this.getParameterByName('utm_content'),
      },
      page: {
        path: window.location.pathname,
        referrer: window.document.referrer,
        search: window.location.search,
        title: window.document.title,
        url: window.location.href,
      },
    };
    return context;
  }
  private uuid() {
    let uuid = this.getCookie('td_anonymousId');
    if (!uuid) {
      let dt = new Date().getTime();
      uuid = 'TD-xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (dt + Math.random() * 16) % 16 || 0;
        dt = Math.floor(dt / 16);
        // eslint-disable-next-line no-bitwise
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
      });
      this.setCookie('td_anonymousId', uuid);
    }
    return uuid;
  }

  track(category: string, name: string, props: { [key: string]: string | number }, token: string, trackType: string) {
    if (!this.dataIntegrityApiUrl || !this.dataIntegrityApiKey) return;
    const endpoint = this.dataIntegrityApiUrl + '/tracking';
    const authHeader = token ? { params: { token } } : { headers: { 'x-api-key': this.dataIntegrityApiKey } };
    let userId = this.getCookie('ajs_user_id');
    userId = (userId !== null) ? userId.replace(/['"]+/g, '') : null;
    const anonymousId = this.uuid();
    const stdId = this.getCookie('ajs_anonymous_id');
    const segmentanonymousid = (stdId !== null) ? stdId.replace(/['"]+/g, '') : '';
    const body = {
      event: name,
      userId,
      anonymousId,
      segmentanonymousid,
      category,
      context: this.getContext(),
      properties: props,
      type: trackType,
    };
    if (trackType === 'page') {
      body['name'] = body.event;
      body.properties = this.getContext().page;
      delete body.event;
    }
    this.api.request('post', endpoint, body, authHeader).subscribe();
  }

  trackClick(category: string, name: string, props: { [key: string]: string | number }) {
    this.track(category, name, props, null, 'track');
  }
}
