<div class="header-part" fxLayout="row" fxLayoutAlign="start" fxLayoutGap="16px">
  <ng-container [ngSwitch]="iconType">
    <app-avatar *ngSwitchCase="'avatar'" [name]="name" [size]="32"></app-avatar>
    <ng-container *ngSwitchCase="'none'"></ng-container>
    <mat-icon *ngSwitchDefault class="icon">{{ iconType }}</mat-icon>
  </ng-container>

  <div fxLayout="column" fxFlex style="overflow: hidden;">
    <span class="name">{{ name }}</span>
    <span class="subtitle">{{ time | durationHms }}</span>
  </div>

  <button mat-icon-button (click)="closed.emit()" class="compact">
    <mat-icon>close</mat-icon>
  </button>
</div>
