
export function pick<T, P extends keyof T>(obj: T, props: P[]): Pick<T, P> {
  if (!obj) {
    return null;
  }

  const res: Pick<T, P> = {} as any;

  for (const prop of props) {
    if (obj.hasOwnProperty(prop)) {
      res[prop] = obj[prop];
    }
  }

  return res;
}
