import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss'],

})
export class ModalHeaderComponent {
  @Input()  modalSource: 'banner' | 'timer' | '';
}
