import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { Logout } from 'src/app/store/auth/auth.actions';

@Component({
  selector: 'app-logout',
  template: '',
})
export class LogoutComponent {
  constructor(store: Store) {
    store.dispatch(new Logout(true));
  }
}
