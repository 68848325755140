import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Settings } from 'luxon';
import { AppModule } from './app/app/app.module';
import { environment } from './environments/environment';
import { hmrBootstrap } from './hmr';
import './sentry';

Settings.defaultLocale = 'en-US';

declare const module;

if (environment.production) {
  enableProdMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);
if (environment.hmr) {
  if (module.hot) {
    hmrBootstrap(module, bootstrap);
  } else {
    console.error('HMR is not enabled for webpack-dev-server!');
    console.log('Are you using the --hmr flag for ng serve?');
  }
} else {
  bootstrap().catch(err => console.log(err));
}

if (environment.enableAnalytics) {
  window.analytics?.load(environment.env.TD_APP_SEGMENT_KEY);
}

loadHubspotScript();
loadUseberryScript();

function loadScript(src: string, appendTo: 'head' | 'body') {
  const script = document.createElement('script');
  script.src = src;
  script.type = 'text/javascript';
  script.async = true;
  script.defer = true;
  document[appendTo].appendChild(script);
}

function loadHubspotScript() {
  const hubspotKey = environment.hubspotKey;
  if (!hubspotKey) return;
  const src = `//js.hs-scripts.com/${hubspotKey}.js`;
  loadScript(src, 'head');
}

function loadUseberryScript() {
  const publicDeployments = ['uspublic', 'staging'];
  const isPublicDeployment = publicDeployments.includes(environment.deployment);
  if (!isPublicDeployment) return;
  const src = 'https://api.useberry.com/integrations/liveUrl/scripts/useberryScript.js';
  loadScript(src, 'body');
}
