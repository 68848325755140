import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/modules/shared.module';
import { NavSidebarComponent } from 'src/app/components/nav-sidebar/nav-sidebar.component';
import {
  NavSidebarGroupItemComponent
} from 'src/app/components/nav-sidebar/nav-sidebar-group-item/nav-sidebar-group-item.component';


@NgModule({
  declarations: [
    NavSidebarComponent,
    NavSidebarGroupItemComponent,
  ],
  imports: [
    SharedModule,
  ],
  exports: [
    NavSidebarComponent,
  ],
})
export class NavSidebarModule { }
