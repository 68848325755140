import { APP_INITIALIZER, Injectable } from '@angular/core';
import { Actions, UpdateState, ofActionSuccessful } from '@ngxs/store';
import { take } from 'rxjs/operators';

export function appInitializeWaitForNGXS(storeReady: StoreReadyService) {
  return () => storeReady.ready;
}

@Injectable({ providedIn: 'root' })
export class StoreReadyService {
  public ready: Promise<any>;

  constructor(actions: Actions) {
    this.ready = actions.pipe(ofActionSuccessful(UpdateState), take(1)).toPromise();
  }
}

/**
 * Delays the app initialization until after NGXS is initialized
 * If this is not included, async storage plugin will not work correctly
 */
export const storeReadyAppInitializer = {
  provide: APP_INITIALIZER,
  useFactory: appInitializeWaitForNGXS,
  deps: [StoreReadyService],
  multi: true,
};
