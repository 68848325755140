import { StateToken } from '@ngxs/store';
import { AuthCompany, AuthUser } from 'src/models';

export interface AuthStateModel {
  user?: AuthUser;
  token?: string;
  userRequiresUpdate?: boolean;
  company?: AuthCompany;
  errorMessage?: string;
  totpNeeded?: boolean;
  usersToRemember: { token: string, user: AuthUser }[];
  adminLogin?: boolean;
}

export const AUTH_STATE_TOKEN = new StateToken<AuthStateModel>('auth');
