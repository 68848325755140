import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Select } from '@ngxs/store';
import { exhaustMap, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import {
  BannerButton,
  BannerIconColor,
  BannerType,
  TopBanner
} from 'src/app/pages/new-billing/models/top-banner.model';
import { NewBillingState } from 'src/app/pages/new-billing/store/state';
import { BillingDetailsApiData } from 'src/app/pages/new-billing/types/api.types';
import { BillingService } from 'src/app/services/billing/billing.service';
import { AuthSelectors } from 'src/app/store/auth/auth.selectors';
import { AuthCompany } from 'src/models';

@Injectable({
  providedIn: 'root',
})
export class GracePeriodBanner implements TopBanner {
  @Select(AuthSelectors.company) company$: Observable<AuthCompany>;
  @Select(NewBillingState.updatedBillingDetails) billingDetails$: Observable<BillingDetailsApiData>;

  private _gracePeriodDays = 0;

  public ariaLabel = 'Grace period banner';
  public icon: string;
  public iconColor: BannerIconColor = 'white';
  public svgIcon: string;
  public trackingInfo: Record<string, unknown>;
  public type: BannerType = 'error';

  get primaryButton(): BannerButton {
    return {
      text: 'billing.editCard',
      action: () => {
        this.router.navigate(['/billing']);
      },
    };
  }

  get message(): string {
    const gracePeriodDays = Math.floor(this._gracePeriodDays);
    const messageKey = !gracePeriodDays ? 'billing.gracePeriodBannerLastDay' : 'billing.gracePeriodBanner';

    return this.translateService.instant(messageKey, { remainingDays: gracePeriodDays });
  }

  constructor(
    private billingService: BillingService,
    private translateService: TranslateService,
    private router: Router,
  ) {
  }

  public showCondition() {
    const gracePeriodDays$ = this.billingDetails$.pipe(
      switchMap(({ gracePeriodEndDate, customerStatus }) => customerStatus === 'past_due'
        ? this.billingService.getGracePeriodDays(gracePeriodEndDate)
        : throwError(() => 'Condition not met'),
      ),
      tap(days => this._gracePeriodDays = days),
      map(days => days > 0),
      catchError(() => {
        return of(false);
      }),
    );

    return this.company$.pipe(
      exhaustMap(company => (company?.subscription?.provider === 'paddle' && (company?.userSettings?.billingAccess || company?.role === 'owner'))
        ? gracePeriodDays$
        : of(false),
      ),
    );
  }
}
