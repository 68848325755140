import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Approval } from 'src/models';
import { ApiLimitParams, ApiService } from '../api.service';
import { EditTimeService } from '../edit-time/edit-time.service';
import { PagingService } from '../paging/paging.service';

export interface GetApprovalsOptions extends ApiLimitParams {
  from?: string;
  to?: string;
  'include-auto-approved'?: boolean;
  'resolve-names'?: boolean;
  'page-user'?: string;
  'sort-user'?: string;
}

export interface EditApprovalsPayload extends Array<Approval> { }

@Injectable({ providedIn: 'root' })
export class ApprovalsService {

  constructor(
    private api: ApiService,
    private editTimeService: EditTimeService,
    private paging: PagingService,
  ) { }


  getApprovals(options?: GetApprovalsOptions) {
    let nextOptions: Partial<GetApprovalsOptions> = null;
    return this.paging.getPagedResult<Approval>(page => {
      return this.editTimeService.getManualTimes({
        limit: 40,
        page,
        ...options,
        ...nextOptions,
      }).pipe(
        tap(resp => {
          const lastUserItems = resp.data[resp.data.length - 1] || [];
          const lastUserId = lastUserItems[lastUserItems.length - 1]?.userId;
          const prevItemLength = nextOptions && nextOptions['page-user'] === lastUserId ? nextOptions?.page : 0;
          const itemsLength = prevItemLength + lastUserItems.length;
          nextOptions = { page: itemsLength, ...lastUserId && { 'page-user': lastUserId } };
        }),
        map(resp => ({ data: resp.data.flat() })),
      );
    });
  }

  editApproval(id: string, payload: Partial<Approval>) {
    return lastValueFrom(this.api.request('put', `activity/edit-time/${id}`, { ...payload, approved: payload.approved === 'approved' }));
  }

  editApprovals(payload: EditApprovalsPayload) {
    return lastValueFrom(this.api.request('put', `activity/edit-time/bulk`, payload.map(item => ({ ...item, approved: item.approved === 'approved' }))));
  }
}
