
export enum Branding {
  TimeDoctor = 'timedoctor',
  Staging = 'staging',
  LibertyFinance = 'liberty',
  Vodafone = 'vois',
  HelloRache = 'hellorache2',
  Temark = 'temark',
  Genesis = 'genesis',
}
