<ng-container *ngIf="modalSource === 'timer'; else fromBanner">
  <span class="access-discount-text">
    {{ 'trialOfferExperiment.popup.discountTextTimer' | translate }}
    <a
      href="/new-billing"
      (click)="onLinkClick()"
      [trackClick]="{
        page: 'Link Click',
        linklocation: 'footer',
        pagename: 'Discount Popup',
        linkname:'Settings/Billing',
        linktype: 'text',
        linkurl: '/new-billing'}"
    >
      {{ 'trialOfferExperiment.popup.goToBilling' | translate }}
    </a>
  </span>
</ng-container>

<ng-template #fromBanner>
  <p class="access-discount-text">
    {{ 'trialOfferExperiment.popup.discountTextBanner' | translate }}
  </p>
</ng-template>

<ng-container *ngIf="(userCount$ | async) < 3">
  <button
    (click)="onAddUsersClick()"
    class="cta-btn"
    [trackClick]="{
      page: 'Link Click',
      linklocation: 'footer',
      pagename: 'Discount Popup',
      linkname:'Add users',
      linktype: 'button',
      linkurl: ''}"
  >
    {{ 'trialOfferExperiment.popup.addUsersCta' | translate | uppercase }}
  </button>
</ng-container>

<ng-container *ngIf="modalSource === 'timer' && (userCount$ | async) > 1">
  <button mat-button mat-stroked-button
    class="btn-goto-billing"
    (click)="gotoBillingPage()"
    [trackClick]="{
      page: 'Link Click',
      linklocation: 'footer',
      pagename: 'Discount Popup',
      linkname:'Go to billing page',
      linktype: 'button',
      linkurl: ''}"
  >
    {{ 'trialOfferExperiment.popup.goToBillingCta' | translate | uppercase }}
  </button>
</ng-container>

<ng-container *ngIf="modalSource === 'timer'">
  <p class="popup-footer">
    {{ 'trialOfferExperiment.popup.footer' | translate }}
    <a href="/new-subscribe-plans?url=new-billing" (click)="onLinkClick()">
      {{ 'trialOfferExperiment.popup.footerLink' | translate }}
    </a>
  </p>
</ng-container>
