import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { getStartedPathMapping } from 'src/app/pages/get-started/get-started-sidebar.service';
import { AuthStateModel } from 'src/app/store/auth/auth.model';
import { AuthState } from 'src/app/store/auth/auth.state';

export interface InAppVisitReferrer {
  'type': string;
  'name': string;
  'path': string;
  'url': string;
}

export const enum Subdomains {
  App2 = '2',
  Onboarding = 'onboarding',
}

// 10 seconds should be good enough to wait for the redirection to resolve.
const REDIRECTION_WAIT = 10000;

@Injectable({ providedIn: 'root' })
export class UrlService {
  private previousUrl: BehaviorSubject<InAppVisitReferrer> = new BehaviorSubject<InAppVisitReferrer>(null);
  previousUrl$: Observable<InAppVisitReferrer> = this.previousUrl.asObservable();

  constructor(
    private router: Router,
    private store: Store,
    private injector: Injector,
  ) { }

  setPreviousUrl(inAppVisitReferrer: InAppVisitReferrer) {
    this.previousUrl.next(inAppVisitReferrer);
  }

  getPageNameByPath(path: string, pageName: string) {
    const reportCategories = [
      'activity-summary', 'hours-tracked', 'projects-report',
      'timeline', 'web-app-usage', 'attendance', 'internet-report', 'csv',
      'experimental-reports',
    ];

    const settingsCategories = [
      'billing', 'new-billing', 'projects-and-tasks', 'productivity-ratings',
      'manage-users', 'manage-user-groups', 'integrations', 'company/edit',
      'notifications', 'schedules', 'breaks',
    ];

    const dynamicCategories = ['payroll', 'downloads', 'screencasts'];

    if (reportCategories.includes(path)) {
      return { category: 'Webapp Reports', pageName };
    }

    if (settingsCategories.includes(path)) {
      return { category: 'Webapp Settings', pageName };
    }

    if (dynamicCategories.includes(path)) {
      return { category: `Webapp ${pageName}`, pageName };
    }

    const pathMapping = {
      dashboard: { category: 'Webapp Dashboard', pageName: 'Team Dashboard' },
      'dashboard-individual': { category: 'Webapp Dashboard', pageName: 'Individual Dashboard' },
      'dashboard-executive': { category: 'Webapp Dashboard', pageName: 'Executive Dashboard' },
      'edit-time': { category: 'Webapp Edit Time', pageName },
      'end-of-trial': { category: 'End Of Trial', pageName: 'End Of Trial Screen' },
      'new-end-of-trial': { category: 'End Of Trial', pageName: 'Viewed End Of Trial Screen' },
      'eot-subscribe-plans': { category: 'End Of Trial', pageName: 'Plan Screen' },
      'subscribe-plans': { pageName: 'Plan Screen', category: 'Webapp Subscribe Plans' },
      'new-subscribe-plans': { pageName: 'Plan Screen', category: 'Webapp Subscribe Plans' },
      invite: { category: 'Webapp Invite Staff', pageName },
      'profile-edit': { category: 'Webapp Profile Edit', pageName },
      approvals: { category: 'Webapp Manual Time Approvals', pageName },
      ...getStartedPathMapping(this.injector),
    };
    return pathMapping[path] || { category: null, pageName };
  }

  redirectToContainer(containerSubDomain: string) {
    const { token } = this.store.selectSnapshot<AuthStateModel>(AuthState);
    const domain = window.location.hostname;
    const subdomains = domain.split('.').reverse();
    const reqHostname = subdomains[1]?.includes('timedoctor') ? `${subdomains[1]}.${subdomains[0]}` : '';
    let redirectURI = `https://${containerSubDomain}.timedoctor.com/?from2a=true&token=${token}`;

    if (reqHostname === 'timedoctortest.com' || domain === 'staging.timedoctor.com' || domain.includes('vercel.app')) {
      redirectURI = `https://${containerSubDomain}.timedoctortest.com/?from2a=true&token=${token}`;
    } else if (reqHostname.includes('timedoctordev.com')) {
      redirectURI = `http://${containerSubDomain}.timedoctordev.com:3000/?from2a=true&token=${token}`;
    }

    return this.redirectToExternalUrl(this.getNewURLWithParams(redirectURI));
  }

  redirectToOnboarding() {
    return this.redirectToContainer(Subdomains.Onboarding);
  }

  redirectToExternalUrl(url: string) {
    window.open(url, '_self');

    /*
     * Wait for redirection to happen in order to prevent displaying the dashboard.
     */
    return new Promise<boolean>(res => {
      setTimeout(() => res(false), REDIRECTION_WAIT);
    });
  }

  // Function to rewrite the redirection url with parameters
  private getNewURLWithParams(baseURL: string): string {
    const currentURL = new URL(window.location.href);
    const newURL = new URL(baseURL);
    currentURL.searchParams.forEach((value, key) => newURL.searchParams.append(key, value));
    return newURL.toString();
  }
}
