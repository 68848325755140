import { Injectable } from '@angular/core';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import { lastValueFrom } from 'rxjs';
import { UserApiParams, UserService } from 'src/app/services/user/user.service';
import { Logout } from 'src/app/store/auth/auth.actions';
import { AuthStateModel } from 'src/app/store/auth/auth.model';
import { AuthState } from 'src/app/store/auth/auth.state';
import { AuthCompany, AuthUser, User } from 'src/models';
import { BaseLazyService } from '..';
import { PagingService } from '../../paging/paging.service';
import { Modification, ResolveService } from '../../resolve.service';

@Injectable({ providedIn: 'root' })
export class DefaultLazyUserService extends BaseLazyService<User, UserApiParams> {
  constructor(
    protected store: Store,
    protected service: UserService,
    protected actions: Actions,
    protected resolver: ResolveService,
    protected paging: PagingService,
  ) {
    super();
    this.actions?.pipe(ofActionDispatched(Logout)).subscribe(() => this.reset());
  }

  protected normalizeOptions(options?: UserApiParams) {
    if (options && options.tag === undefined) {
      delete options.tag;
    }
    return options;
  }

  shouldIncludeSelf(company: AuthCompany, user: AuthUser) {
    return false;
  }

  getItems = (options?: UserApiParams, removeShowonReportsFilter: boolean = false) => {
    if (options?.['filter[id]']?.length === 0 || options?.tag?.length === 0) {
      return this.paging.empty<User>();
    }

    const auth: AuthStateModel = this.store.selectSnapshot(AuthState);
    const includeSelf = this.resolveSelfInclude(auth?.user, auth?.company, options, null);

    const filteredDefaultOptions = { ...this.defaultOptions };
    if (removeShowonReportsFilter) {
      delete filteredDefaultOptions['filter[show-on-reports]'];
    }

    return this.service.users(this.normalizeOptions({
      detail: 'alias',
      limit: 40,
      ...includeSelf && { self: 'include' },
      ...filteredDefaultOptions,
      ...options,
    }));
  };

  getSearchedItems = (search: string, options?: UserApiParams) => {
    if (options?.tag === '') { return this.paging.empty<User>(); }

    const auth: AuthStateModel = this.store.selectSnapshot(AuthState);
    const includeSelf = this.resolveSelfInclude(auth?.user, auth?.company, options, search);

    return this.service.users(this.normalizeOptions({
      detail: 'alias',
      limit: 40,
      sort: 'keywords',
      'filter[keyphrase]': search,
      ...includeSelf && { self: 'include' },
      ...this.defaultOptions,
      ...options,
    }));
  };

  getDefault = () => {
    const company = this.store.selectSnapshot(AuthState.company);
    const user = this.store.selectSnapshot(AuthState.user);
    if (this.shouldIncludeSelf(company, user)) return Promise.resolve(user);
    return lastValueFrom(this.all().range(0, 1).accumulated).then(x => x?.[0]);
  };

  resolveInternal = async (id: string, options?: UserApiParams) => {
    if (!id) { return null as User; }
    return this.loadedItems?.find(x => x?.id === id) ?? this.resolver.resolveUser(id, options);
  };

  private resolveSelfInclude(user: AuthUser, company: AuthCompany, options?: UserApiParams, search?: string) {
    return !options?.user && user && company &&
      (!options?.tag || company.tagIds?.some(x => options.tag.includes(x))) &&
      (!options?.['filter[id]'] || options['filter[id]'].includes(user.id)) &&
      (!search || ([user.email, user.name, user.id].some(x => x?.includes(search)))) &&
      this.shouldIncludeSelf(company, user);
  }

  override modify(id: string, action: Modification<User>) {
    this.resolver.modifyUser(id, action, this.defaultOptions?.detail || 'alias');
    return super.modify(id, action);
  }
}

@Injectable({ providedIn: 'root' })
export class NonGuestUserService extends DefaultLazyUserService {
  get defaultOptions() {
    return {
      ...super.defaultOptions,
      'filter[!role]': 'guest',
    };
  }
}

@Injectable({ providedIn: 'root' })
export class AdminLazyUserService extends DefaultLazyUserService {
  get defaultOptions() {
    return {
      ...super.defaultOptions,
      'filter[role]': 'admin',
    };
  }
}

@Injectable({ providedIn: 'root' })
export class ManagerLazyUserService extends DefaultLazyUserService {
  get defaultOptions() {
    return {
      ...super.defaultOptions,
      'filter[role]': 'manager',
    };
  }
}

@Injectable({ providedIn: 'root' })
export class ReportLazyUserService extends DefaultLazyUserService {
  get defaultOptions() {
    return {
      ...super.defaultOptions,
      'filter[show-on-reports]': 1 as const,
    };
  }

  override shouldIncludeSelf(company: AuthCompany) {
    if (!company) return false;
    return company.role === 'user' ||
      (company.userSettings?.showOnReports && company.role !== 'guest');
  }
}

@Injectable({ providedIn: 'root' })
export class SettingLazyUserService extends DefaultLazyUserService {
  get defaultOptions() {
    return {
      ...super.defaultOptions,
      'filter[!role]': ['owner', 'guest'],
      self: 'exclude' as const,
    };
  }
}

@Injectable({ providedIn: 'root' })
export class ScreencastLazyUserService extends DefaultLazyUserService {
  get defaultOptions() {
    return {
      ...super.defaultOptions,
      'filter[0][!screenshots]': '0',
      'filter[1][!videos]': 'off',
      'filter[0][show-on-reports]': '1',
      'filter[1][show-on-reports]': '1',
    };
  }

  getSearchedItems = (search: string, options?: UserApiParams) => {
    if (options?.tag === '') { return this.paging.empty<User>(); }

    return this.service.users(this.normalizeOptions({
      detail: 'alias',
      limit: 40,
      sort: 'keywords',
      'filter[0][keyphrase]': search,
      'filter[1][keyphrase]': search,
      ...this.defaultOptions,
      ...options,
    }));
  };

  override shouldIncludeSelf(company: AuthCompany) {
    if (!company) return false;
    return company.role === 'user' ||
      (company.userSettings?.showOnReports && company.role !== 'guest' &&
        (company.userSettings?.screenshots + '' !== '0' || company.userSettings?.videos !== 'off'));
  }
}
