import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { UserSettingsChanged } from '../store/auth.actions';
import { AuthState } from '../store/auth/auth.state';
import { UserService } from './user/user.service';

export interface INotificationVisitService {
  visitedNotification(notif: string): boolean;
  setNotificationVisit(notif: string): void;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationVisitService implements INotificationVisitService {
  constructor(
    @Inject(Store) private readonly store: Store,
    @Inject(UserService) private readonly userService: UserService,
  ) {}

  visitedNotification(notif: string): boolean {
    const isVisited = this.isVisitedInLocalStorage(notif) || this.isVisitedInUserSettings(notif);
    if (isVisited) {
      this.updateLocalStorage(notif, true);
    }
    return isVisited;
  }

  setNotificationVisit(notif: string): void {
    if (this.visitedNotification(notif)) return;

    this.updateLocalStorage(notif, true);
    this.updateUserSettings(notif);
  }

  private isVisitedInLocalStorage(notif: string): boolean {
    return localStorage.getItem(`visited-${notif}`) === 'true';
  }

  private isVisitedInUserSettings(notif: string): boolean {
    const userSettings = this.store.selectSnapshot(AuthState.company)?.userSettings;
    return userSettings?.custom?.visitedNotification?.[notif] || false;
  }

  private updateLocalStorage(notif: string, value: boolean): void {
    localStorage.setItem(`visited-${notif}`, String(value));
  }

  private updateUserSettings(notif: string): void {
    this.userService.editProperties('me', { [`custom.visitedNotification.${notif}`]: true }).subscribe();
    this.store.dispatch(new UserSettingsChanged({ custom: { visitedNotification: { [notif]: true} }}));
  }
}
