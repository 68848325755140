import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { isOwner } from 'src/app/common/menu-def.provider';
import { AuthState } from 'src/app/store/auth/auth.state';

@Injectable({
  providedIn: 'root',
})
export class FirstLoginGenesysGuard implements CanActivate, CanActivateChild {
  constructor(
    private store: Store,
    private router: Router,
  ) { }

  canActivate(route: ActivatedRouteSnapshot) {
    const company = this.store.selectSnapshot(AuthState.company);
    const user = this.store.selectSnapshot(AuthState.user);
    const isFromGenesys = company.subscription?.provider === 'genesys';
    const hasPassword = user?.hasPassword;
    if (isOwner(company) && isFromGenesys && !hasPassword) {
      return this.router.createUrlTree(['/genesys-onboarding'], { queryParams: route.queryParams });
    }

    return true;
  }

  canActivateChild(route: ActivatedRouteSnapshot) {
    return this.canActivate(route);
  }
}
