<app-top-banner [banner]="gracePeriodBanner"></app-top-banner>
<app-top-banner [banner]="retryPaymentBanner"></app-top-banner>
<app-trial-promotion-offer-modal></app-trial-promotion-offer-modal>
<app-top-banner [banner]="inviteWebinarBanner"></app-top-banner>

<div fxLayout="row" style="min-height: 100%;">
  <div fxLayout="column" *ngIf="(hasNavSidebar$ | async) === false && (hasGettingStarted$ | async) === false">
    <app-starter-sidebar (sidebarChange)="onChangeStarterSideBar"></app-starter-sidebar>
  </div>
  <div fxLayout="column" style="flex-grow: 1; min-width: 0;" class="page-container">
    <app-shared-banner #banner icon="check" iconColor="primary"
      *ngIf="isOwner && !welcomeSidebarCompleted && allCompleted">
      <div banner-header style="max-width: 550px; display: inline-block;"
        [innerHtml]="'sidebar.banner' | translate:bannerParams"></div>

      <button banner-buttons mat-button color="accent" (click)="dismissBanner(); banner.dismiss();">
        {{'common.gotIt' | translate | uppercase}}
      </button>
    </app-shared-banner>

    <mat-sidenav-container *ngLet="hasNavSidebar$ | async as hasNavSidebar">
      <mat-sidenav #sidenav role="navigation" style="width: 320px; left: 0;" [fixedInViewport]="true">
        <app-sidenav [activeUrl]="activeUrl"></app-sidenav>
      </mat-sidenav>
      <mat-sidenav-content cdkScrollable fxLayout="column" [class.with-sidebar]="hasNavSidebar">
        <app-header [fxHide]="headerPortalOutlet.hasAttached() || (headerHidden$ | async)" [activeUrl]="activeUrl"
          [withNavSidebar]="hasNavSidebar" (sidebarMenuClick)="navSidebar.toggleCollapse()"
          (navToggle)="sidenav.toggle()">
        </app-header>
        <ng-template cdkPortalOutlet #headerPortalOutlet="cdkPortalOutlet"></ng-template>

        <ng-container *ngIf="(hasNavSidebar$ | async) === false; else withNavSidebar">
          <div fxFlex="1 0 auto" fxLayout="column">
            <mat-progress-bar class="app-progress" color="primary" *ngIf="showProgress$ | async"
              [mode]="(progressIndeterminate$ | async) ? 'indeterminate' : 'determinate'"
              [value]="progressPercentage$ | async">
            </mat-progress-bar>

            <router-outlet class="primary-outlet"></router-outlet>
          </div>
        </ng-container>
      </mat-sidenav-content>
    </mat-sidenav-container>

    <router-outlet name="modal"></router-outlet>

    <app-loading *ngIf="loadingDebounced$ | async" @loading></app-loading>
  </div>
</div>

<ng-template #withNavSidebar>
  <div class="main-content-wrapper">
    <app-nav-sidebar #navSidebar *ngIf="company$ | async"></app-nav-sidebar>

    <div class="content smooth-scroll" restoreScroll>
      <mat-progress-bar class="app-progress" color="primary" *ngIf="showProgress$ | async"
        [mode]="(progressIndeterminate$ | async) ? 'indeterminate' : 'determinate'"
        [value]="progressPercentage$ | async">
      </mat-progress-bar>

      <router-outlet class="primary-outlet"></router-outlet>
    </div>
  </div>
</ng-template>
