import { DateTime, Zone } from 'luxon';
import timezoneNames from './timezones.json';


export interface TimezoneDetails {
  id: string;
  name: string;
  offset: number;
  offsetFormat: string;
  display: string;
  search: string;
}

export const timezones: TimezoneDetails[] = timezoneNames
  .map(x => DateTime.utc().setZone(x))
  .filter(x => x.zone?.isValid)
  .map(x => [x.zone, x.zoneName || x.zone.name || ''] as [Zone, string])
  .map(([zone, zoneName]) => {
    const name = zoneName.split('/').reverse().join(', ').replace('_', ' ');
    const offsetFormat = zone.formatOffset(Date.now(), 'short');
    const display = `(UTC ${offsetFormat}) ${name}`;
    const search = `${display}__${zoneName}`;
    return {
      id: zoneName,
      name,
      offset: zone.offset(Date.now()),
      offsetFormat,
      display,
      search,
    };
  }).sort((a, b) => a.offset - b.offset);

export const timezoneMap = new Map(timezones.map(x => [x.id, x]));
