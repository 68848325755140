import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Store } from '@ngxs/store';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { SetBillingData } from 'src/app/pages/new-billing/store/actions';
import { BillingApiData } from 'src/app/pages/new-billing/types/api.types';
import { BillingService } from 'src/app/services/billing/billing.service';
import { AuthSelectors } from 'src/app/store/auth/auth.selectors';

@Injectable({
  providedIn: 'root',
})
export class BillingResolver implements Resolve<BillingApiData | null> {
  constructor(
    private billingService: BillingService,
    private store: Store,
  ) {
  }

  resolve() {
    const company = this.store.selectSnapshot(AuthSelectors.company);
    if (company?.subscription?.provider !== 'paddle') return null;

    return this.billingService.getBillingData().pipe(
      tap((billingData) => this.store.dispatch(new SetBillingData(billingData))),
      catchError(() => of(null)),
    );
  }
}
