import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { NewSubscribePlansState } from 'src/app/pages/new-subscribe-plans/store/state';

@Injectable({
  providedIn: 'root',
})
export class CheckoutSuccessGuard implements CanActivate {
  constructor(
    private store: Store,
    private router: Router,
  ) {}

  canActivate() {
    if (this.store.selectSnapshot(NewSubscribePlansState.checkoutSuccessInfo)) return true;

    return this.router.createUrlTree(['/dashboard']);
  }
}
