import { Injectable } from '@angular/core';
import { SidebarItem } from '../sidebar-item';


@Injectable()
export class SelectProductiveStep implements SidebarItem {
  id = 'selectProductive';
  completed = false;
  link = '/productivity-ratings';
}
