import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { NgxsStoragePluginOptions } from '@ngxs/storage-plugin';
import { NGXS_PLUGINS } from '@ngxs/store';
import { NgxsSyncStorageSwitchPlugin, NGXS_SYNC_STORAGE_SWITCH_PLUGIN_OPTIONS } from './sync-storage-switch.plugin';


export function storageOptionsFactory(options: NgxsStoragePluginOptions) {
  return {
    key: '@@STATE',
    serialize: JSON.stringify,
    deserialize: JSON.parse,
    ...options,
  };
}

export const USER_OPTIONS = new InjectionToken('USER_OPTIONS_NGXS_SYNC_STORAGE_SWITCH');

@NgModule()
export class NgxsSyncStorageSwitchPluginModule {
  static forRoot(options?: NgxsStoragePluginOptions): ModuleWithProviders<NgxsSyncStorageSwitchPluginModule> {
    return {
      ngModule: NgxsSyncStorageSwitchPluginModule,
      providers: [
        {
          provide: NGXS_PLUGINS,
          useClass: NgxsSyncStorageSwitchPlugin,
          multi: true,
        },
        {
          provide: USER_OPTIONS,
          useValue: options,
        },
        {
          provide: NGXS_SYNC_STORAGE_SWITCH_PLUGIN_OPTIONS,
          useFactory: storageOptionsFactory,
          deps: [USER_OPTIONS],
        },
      ],
    };
  }
}
