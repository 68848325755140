import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { lastValueFrom } from 'rxjs';
import { HOME_URL, LOGIN_NEW_URL } from 'src/app/services/app.constants';
import { AuthService } from 'src/app/services/auth.service';
import { InvalidateUser, TokenLogin } from 'src/app/store/auth/auth.actions';
import { CompleteWelcomeStep } from 'src/app/store/onboarding/onboarding.actions';

interface ConfirmSpec {
  token: string;
}

@Injectable({
  providedIn: 'root',
})
export class ConfirmGuard implements CanActivate, CanActivateChild {
  id = 'confirmEmail';

  constructor(
    private store: Store,
    private router: Router,
    @Inject(HOME_URL) private homeUrl,
    @Inject(LOGIN_NEW_URL) private loginNewUrl,
    private auth: AuthService,
  ) { }

  async canActivate(childRoute: ActivatedRouteSnapshot) {
    const query = childRoute.queryParams as ConfirmSpec;

    try {
      await lastValueFrom(this.store.dispatch(new TokenLogin(query.token, null)));
    } catch (err) {
      if (err.error === 'invalidToken' || err.error === 'invalidCredentials' || err.error === 'expiredToken' || err.error === 'denied') {
        await lastValueFrom(this.store.dispatch(new InvalidateUser()));
        return this.router.parseUrl(this.loginNewUrl);
      }
    }

    if (query?.token) {
      await this.auth.editProfile({ emailConfirmed: true });
      this.store.dispatch(new CompleteWelcomeStep(this.id));
    }

    return this.router.createUrlTree([this.homeUrl]);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot) {
    return this.canActivate(childRoute);
  }
}
