import { APP_BASE_HREF } from '@angular/common';
import { Injectable, Injector } from '@angular/core';
import { SidebarItem } from '../sidebar-item';


@Injectable()
export class CompanySettingsStep implements SidebarItem {
  id = 'companySettings';
  completed = false;
  link = '/company/edit';

  constructor(injector: Injector) {
    const baseHref: string = injector.get(APP_BASE_HREF);
    this.link = baseHref + this.link;
  }
}
