export class PushProgress {
  static readonly type = '[Progress] Push';

  constructor(public amount: number = 1) { }
}

export class PopProgress {
  static readonly type = '[Progress] Pop';

  constructor(public amount: number = 1) { }
}
