export type BreakType = 'paid' | 'unpaid';
export declare type BreakScope = 'user' | 'tag' | 'company';
export const scopeMap: Map<BreakScope, string> = new Map([
  ['company', 'Everyone'],
  ['tag', 'Specific Groups'],
  ['user', 'Specifics Users'],
]);

export interface Break {
  id: string;
  name: string;
  type: BreakType;
  active: boolean;
  public: boolean;
  archivedAt: string;
  scope: BreakScope;
  access: string[];
}
