import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import {
  HidePromoPopup
} from 'src/app/components/trial-promotion-offer/store/trial-offer-promotion.actions';
import { CountdownState } from 'src/app/components/trial-promotion-offer/store/trial-offer-promotion.state';
import { SegmentService } from 'src/app/services/segment/segment.service';
import { provideTrackingCtx } from 'src/app/services/tracking/context';
import { AuthSelectors } from 'src/app/store/auth/auth.selectors';
import { AuthState } from 'src/app/store/auth/auth.state';

const defaultInviteUrl = '/invite';

export interface OfferProps {
  isOpen: boolean;
  source: 'banner' | 'timer' | '';
}
export const isTrialOfferPromotionOpen = new BehaviorSubject<OfferProps>({ isOpen: false, source: '' });

@Component({
  selector: 'app-trial-promotion-offer-modal',
  templateUrl: './offer-popup.component.html',
  styleUrls: ['./offer-popup.component.scss'],
  providers: [
    provideTrackingCtx({ page: 'Discount Popup' }),
  ],
})

export class DiscountOfferModalComponent implements OnInit {
  isModalOpen = false;
  modalSource: 'banner' | 'timer' | '';
  private cachedInviteLink: string | null = null;
  private trackingModeUnsubscribe$ = new Subject<void>();

  @Select(AuthSelectors.userCount) userCount$: Observable<number>;

  usersTrackingTimeCount$: Observable<boolean>;

  constructor(
    private router: Router,
    private store: Store,
    private segment: SegmentService,
  ) {}

  ngOnInit(): void {
    this.usersTrackingTimeCount$ = this.store.select(CountdownState.usersTrackingTime);
    isTrialOfferPromotionOpen.subscribe((modal) => {
      if (modal.source === '') return;
      this.isModalOpen =  modal.isOpen;
      this.modalSource = modal.source;
      if (this.isModalOpen) {
        this.segment.page('Discount Popup', 'Discount Popup');
      }
    });
    this.subscribeToTrackingMode();
  }

  closeModal() {
    isTrialOfferPromotionOpen.next({ isOpen: false, source: '' });
    this.isModalOpen = false;
    if (this.modalSource === 'timer') {
      this.store.dispatch(new HidePromoPopup());
    }
  }

  addUsers() {
    this.closeModal();
    this.router.navigate([this.inviteLink]);
  }

  gotoBillingPage() {
    this.closeModal();
    this.router.navigate(['/billing']);
  }

  private subscribeToTrackingMode() {
    // Subscribe to tracking mode changes
    this.store.select(AuthState.trackingMode).pipe(
      takeUntil(this.trackingModeUnsubscribe$),
    ).subscribe(mode => {
      this.cachedInviteLink = mode === 'silent' ? '/add-managers' : defaultInviteUrl;
    });
  }

  get inviteLink(): string {
    return this.cachedInviteLink || defaultInviteUrl;
  }
}
