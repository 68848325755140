import { AuthCompany, AuthUser, CompanySettings, CustomCompanySettings } from 'src/models';

export class Login {
  static readonly type = '[Auth] Login';
  constructor(public email: string, public password: string, public rememberMe: boolean, public totpCode?: string) { }
}

export class LoginAs {
  static readonly type = '[Auth] Login As';
  constructor(public user: AuthUser, public token: string) { }
}

export class UpdateCurrentUser {
  static readonly type = '[Auth] Update Current User';
}

export class Logout {
  static readonly type = '[Auth] Logout';
  constructor(public forget: boolean, public errorMessage?: string) { }
}

export class BeforeLogout {
  static readonly type = '[Auth] Before Logout';
}

export class AfterLogout {
  static readonly type = '[Auth] After Logout';
}

export class ForgotPassword {
  static readonly type = '[Auth] Forgot Password';
  constructor(public email: string) { }
}

export class SelectCompany {
  static readonly type = '[Auth] Select Company';
  constructor(public company: AuthCompany) { }
}

export class UserSettingsChanged {
  static readonly type = '[Auth] User Settings Changed';
  constructor(public changes: Partial<CompanySettings>) { }
}

export class CompanySettingsChanged {
  static readonly type = '[Auth] Company Settings Changed';
  constructor(
    public rootChanges: Pick<AuthCompany, 'name' | 'timezone'>,
    public settingChanges: Pick<CompanySettings, 'name' | 'blurScreenshots'>,
    public custom: CustomCompanySettings,
  ) { }
}

export class HasInvalidToken {
  static readonly type = '[Auth] Has Invalid Token';
}

export class TokenLogin {
  static readonly type = '[Auth] Token Login';
  constructor(public token: string, public company: string, public adminLogin?: boolean) { }
}
