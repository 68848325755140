import { LazySelectionModel } from 'src/app/util';
import { UserRole } from 'src/models';

export interface UserFilterSerializable {
  all?: boolean;
  list?: string[];
  type?: 'users' | 'groups';
}

export class UserFilterSelection {
  static readonly membersOfNoGroupId = '@membersOfNoGroup';

  static none = new UserFilterSelection('users', LazySelectionModel.none, LazySelectionModel.none);
  static all = new UserFilterSelection('users', LazySelectionModel.all, LazySelectionModel.all);
  static default = new UserFilterSelection('groups', LazySelectionModel.all, LazySelectionModel.all);

  static fromSerializable(val: UserFilterSerializable) {
    if (!val) {
      return this.default;
    }

    const sel = new LazySelectionModel(val.list || [], !!val.all);
    const type = val.type || this.default.type;
    const users = type === 'users' ? sel : LazySelectionModel.all;
    const groups = type === 'groups' ? sel : LazySelectionModel.all;

    return new UserFilterSelection(type, users, groups);
  }

  static same(oldSelection: UserFilterSelection, newSelection: UserFilterSelection) {
    if (!(oldSelection && newSelection)) { return false; }
    if (oldSelection.type !== newSelection.type) { return false; }
    if (oldSelection.type === 'users') { return LazySelectionModel.same(oldSelection.users, newSelection.users); }
    if (oldSelection.type === 'groups') { return LazySelectionModel.same(oldSelection.groups, newSelection.groups); }
    return false;
  }

  constructor(
    public readonly type: 'users' | 'groups',
    public readonly users: LazySelectionModel,
    public readonly groups: LazySelectionModel,
  ) { }

  setType(type: 'users' | 'groups') {
    return new UserFilterSelection(type, this.users, this.groups);
  }

  setAllItems(users: string[] | Set<string>, groups: string[] | Set<string>): UserFilterSelection {
    return new UserFilterSelection(this.type, this.users.setAllItems(users), this.groups.setAllItems(groups));
  }

  select(...ids: string[]): UserFilterSelection {
    if (this.type === 'users') {
      return new UserFilterSelection(this.type, this.users.select(...ids), this.groups);
    } else {
      return new UserFilterSelection(this.type, this.users, this.groups.select(...ids));
    }
  }

  deselect(...ids: string[]): UserFilterSelection {
    if (this.type === 'users') {
      return new UserFilterSelection(this.type, this.users.deselect(...ids), this.groups);
    } else {
      return new UserFilterSelection(this.type, this.users, this.groups.deselect(...ids));
    }
  }

  set(selected: boolean, ...ids: string[]): UserFilterSelection {
    if (this.type === 'users') {
      return new UserFilterSelection(this.type, this.users.set(selected, ...ids), this.groups);
    } else {
      return new UserFilterSelection(this.type, this.users, this.groups.set(selected, ...ids));
    }
  }

  isSelected(id: string): boolean {
    if (this.type === 'users') {
      return this.users.isSelected(id);
    } else {
      return this.groups.isSelected(id);
    }
  }

  clear(): UserFilterSelection {
    if (this.type === 'users') {
      return new UserFilterSelection(this.type, this.users.clear(), this.groups);
    } else {
      return new UserFilterSelection(this.type, this.users, this.groups.clear());
    }
  }

  selectAll(): UserFilterSelection {
    if (this.type === 'users') {
      return new UserFilterSelection(this.type, this.users.selectAll(), this.groups);
    } else {
      return new UserFilterSelection(this.type, this.users, this.groups.selectAll());
    }
  }


  get count(): number {
    return this.type === 'users' ? this.users.count : this.groups.count;
  }

  get any(): boolean {
    return this.type === 'users' ? this.users.any : this.groups.any;
  }

  get all(): boolean {
    return this.type === 'users' ? this.users.all : this.groups.all;
  }

  get indeterminate(): boolean {
    return this.type === 'users' ? this.users.indeterminate : this.groups.indeterminate;
  }

  isUserSelected = (user: { id: string, tagIds?: string[], role?: UserRole }) => {
    if (!user) {
      return false;
    }
    if (this.type === 'users') {
      if (this.users.all) {
        return true;
      }
      return this.users.isSelected(user.id);
    } else {
      if (this.groups.all) {
        return true;
      }
      if (!user.tagIds) {
        return false;
      }
      return this.groups.list.some(x => {
        const membersOfNoGroupIdCondition = user.role === 'user' ? user.tagIds.length < 2 : user.tagIds.length < 1;
        return x === UserFilterSelection.membersOfNoGroupId ? membersOfNoGroupIdCondition : user.tagIds.includes(x);
      });
    }
  };

  toSerializable() {
    const all = this.all;
    const type = this.type;
    const notAllCondition = type === 'users' ? this.users.list : this.groups.list;
    const list = all ? [] : notAllCondition;

    return { all, list, type } as UserFilterSerializable;
  }

  asApiParams(allUsersKey: string = 'all-on-reports') {
    if (!this.any) { return { user: [] }; }
    if (this.all) { return { user: allUsersKey }; }

    return this.type === 'users'
      ? { user: this.users.list }
      : { tag: this.groups.list, user: allUsersKey };
  }
}
