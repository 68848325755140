import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { isTimeDoctor } from 'src/app/app/app.constants';
import { isOwner } from 'src/app/common/menu-def.provider';
import { BillingService } from 'src/app/services/billing/billing.service';
import { BrandingService } from 'src/app/services/branding.service';
import { TraitsService } from 'src/app/services/traits/traits.service';

@Injectable({
  providedIn: 'root',
})

export class EndOfTrialService {
  prevCompanyId: string;
  subscriptionStatus: string;

  constructor(
    private traitService: TraitsService,
    private branding: BrandingService,
    private billingService: BillingService,
  ) {

  }

  async isTrialler(company) {
    const billingDisabled = this.branding.billingDisabled();
    const hasBillingAccess = company?.userSettings?.billingAccess;
    const endOfTrialStatuses = ['trial past due', 'trial canceled'];
    const companyRdbmsStatus = await lastValueFrom(this.traitService.companyStatus$);
    const gracePeriod = await lastValueFrom(this.billingService.getGracePeriodDays().pipe(take(1)));
    const isCardAdded = company?.userSettings?.custom?.completedStep?.['addCreditCard'] || false;
    const isFreeSubscription = company.subscription.status === 'free' && !gracePeriod;
    return (
      !billingDisabled
      && endOfTrialStatuses.includes(companyRdbmsStatus)
      && !isCardAdded
      && !isTimeDoctor(company?.id)
      && (isOwner(company) || hasBillingAccess)
      && isFreeSubscription
    );
  }
}
