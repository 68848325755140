import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthState } from 'src/app/store/auth/auth.state';

@Injectable({
  providedIn: 'root',
})
export class BillingGuard implements CanActivate {
  constructor(
    private store: Store,
    private router: Router,
  ) {}

  public canActivate() {
    const {subscription} = this.store.selectSnapshot(AuthState.company);

    if (subscription?.provider === 'paddle') {
      return this.router.createUrlTree(['/new-billing']);
    }

    return true;
  }
}
