<div class="task-progress-container">
  <span class="typography-body2 progress-text">
    {{ completedTasks }} of {{ totalTasks }} task{{ totalTasks > 1 ? 's' : '' }} complete
  </span>
  <div class="progress-bars">
    <ng-container *ngFor="let barValue of progress; let i = index">
      <mat-progress-bar [value]="barValue" mode="determinate"
        [ngClass]="{'rounded-start': i === 0, 'rounded-end': i === progress.length - 1}"
        class="custom-progress-bar">
      </mat-progress-bar>
    </ng-container>
  </div>
</div>
