import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { lastValueFrom } from 'rxjs';
import { COMPANY_SELECT_URL, LOGIN_URL } from 'src/app/services/app.constants';
import { InvalidateUser, Logout, SelectCompany } from 'src/app/store/auth/auth.actions';
import { AuthState } from 'src/app/store/auth/auth.state';

@Injectable({
  providedIn: 'root',
})
export class CompanySelectedGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private store: Store,
    private router: Router,
    @Inject(LOGIN_URL) private loginUrl,
    @Inject(COMPANY_SELECT_URL) private companySelectUrl,
  ) { }

  async canActivate(route?: ActivatedRouteSnapshot, state?: RouterStateSnapshot) {
    const redirectUrl = state?.url || undefined;
    const redirectSuffix = (redirectUrl ? '?redirectUrl=' + redirectUrl : '');

    const company = this.store.selectSnapshot(AuthState.company);
    if (!company) {
      const companies = this.store.selectSnapshot(AuthState.companies);

      if (!companies?.length) {
        await lastValueFrom(this.store.dispatch(new Logout(true, 'login.noCompany')));
        return this.router.parseUrl(this.loginUrl + redirectSuffix);
      }

      if (companies.length === 1) {
        await lastValueFrom(this.store.dispatch(new SelectCompany(companies[0])));
      } else {
        return this.router.parseUrl(this.companySelectUrl + redirectSuffix);
      }
    }

    if (company?.locked) {
      await lastValueFrom(this.store.dispatch(new InvalidateUser('login.contactSupport')));
      return this.router.parseUrl(this.loginUrl);
    }

    return true;
  }

  canActivateChild(route?: ActivatedRouteSnapshot, state?: RouterStateSnapshot) {
    return this.canActivate(route, state);
  }

  canLoad() {
    return this.canActivate().then(x => x === true);
  }
}
