import { AbstractControl, UntypedFormArray, UntypedFormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class CustomValidators {
  static domainRegex = /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/;
  static urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-\.][a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

  static cannotContainSpace(control: AbstractControl): ValidationErrors | null {
    if ((control.value as string)?.indexOf(' ') >= 0) {
      return { cannotContainSpace: true };
    }

    return null;
  }

  static requiredArray(control: AbstractControl): ValidationErrors | null {
    if (Array.isArray(control.value) && control.value.length > 0) {
      return null;
    }

    return { requiredArray: 'empty' };
  }

  static arrayItemsValidator(validator: ValidatorFn): ValidatorFn {
    return (control: AbstractControl) => {
      const results = Array.from(control.value).map(x => validator({ value: x } as UntypedFormControl));
      if (results.some(x => x)) {
        const faultyMap = {};
        for (let index = 0; index < results.length; index++) {
          const element = results[index];
          if (element) {
            faultyMap[index] = element;
          }
        }

        return Object.assign({ faultyMap, faultyItems: results.map((x, i) => x ? i : null).filter(x => x) }, ...results);
      }

      return null;
    };
  }
}

export function selectionRequiredValidator(fieldName: string): ValidatorFn {
  return (formArray: UntypedFormArray) => {
    const selected = formArray.controls?.some(item => item.get(fieldName).value);
    return selected ? null : { selectionRequired: true };
  };
}
