export function removeNonAlphanumericAndDiacritics(str: string) {
  if (typeof str !== 'string') {
    return '';
  }

  str = str.normalize ? str.normalize('NFD') : str;
  // Replace Combining Diacritical Marks - https://stackoverflow.com/a/37511463/2346893
  return str.replace(/[\u0300-\u036f]/g, '').toLowerCase();
}

export function advancedStringFilter(array: string[], search: string): string[];
export function advancedStringFilter<T>(array: T[], search: string, propertySelector: string | ((val: T) => string | string[])): T[];


export function advancedStringFilter(array: any[], search: string, propertySelector?: string | ((val) => string | string[])): any[] {
  const sd = removeNonAlphanumericAndDiacritics(search);
  if (!sd) { return array; }
  const propFun = propertySelector && (typeof propertySelector === 'string' ? (x => x[propertySelector] as string) : propertySelector);
  return array.filter(item => advancedContainsInternal(propFun ? propFun(item) : item, sd));
}

function advancedContainsInternal(data: string | string[], search: string): boolean {
  if (Array.isArray(data)) {
    return data.some(x => removeNonAlphanumericAndDiacritics(x).includes(search));
  }
  return removeNonAlphanumericAndDiacritics(data).includes(search);
}

export function advancedContains(data: string | string[], search: string): boolean {
  return advancedContainsInternal(data, removeNonAlphanumericAndDiacritics(search));
}

export function sortBy<T>(property?: (keyof T) | ((dt: T) => any)) {
  const propAcc = (typeof property === 'function') ? (property || ((x: T) => x)) : ((x: T) => x[property]);
  return (a: T, b: T) => {
    const aProp = propAcc(a);
    const bProp = propAcc(b);

    if (typeof aProp === 'number' && typeof bProp === 'number') {
      return aProp - bProp;
    } else {
      return (String(aProp || '')).localeCompare(bProp || '', undefined, { sensitivity: 'base' });
    }
  };
}

export function sortByPriority<T>(value: any, property?: (keyof T) | ((dt: T) => any)) {
  const propAcc = (typeof property === 'function') ? (property || ((x: T) => x)) : ((x: T) => x[property]);
  return (a: T, b: T) => {
    const aProp = propAcc(a);
    const bProp = propAcc(b);

    if (aProp === value) { return -1; }
    if (bProp === value) { return 1; }
    return 0;
  };
}
