import { Provider } from '@angular/core';
import { APP_NAME } from '../../services/app.constants';
import { ErrorMonitoringService } from '../../services/error-monitoring.service';


export interface Schema {
  name: string;
  indexes?: string[];
  seeds?: string[];
}

export interface DbOject<T = any> {
  key: string;
  data: T;
}

export interface DbService<T = any> {
  put(object: DbOject<T>): Promise<any>;
  post(object: DbOject<T>): Promise<any>;
  get(key: string): Promise<DbOject<T>>;
  all(): Promise<DbOject<T>[]>;
  remove(key: string): Promise<any>;
  count(): Promise<number>;
  clear(): Promise<any>;
}

export class IndexedDbService<T = any> implements DbService<T> {
  private indexedDB: IDBFactory;

  private storeName = 'ngxs';
  private version = 2;
  private connection: Promise<IDBDatabase>;

  private schemas: Schema[] = [
    { name: this.storeName },
  ];

  constructor(private readonly dbName: string, private errorMonitoring: ErrorMonitoringService) {
    this.indexedDB = indexedDB;
  }

  put(object: DbOject<T>): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getConnection().then((db) => {
        const tx = db.transaction(this.storeName, 'readwrite');
        const store = tx.objectStore(this.storeName);
        store.put(object);

        tx.oncomplete = () => {
          resolve(object);
        };
        db.onerror = (ev: ErrorEvent) => {
          const error = this.handleError('IndexedDB error in put:', ev);
          reject(error);
        };
      });
    });
  }

  post(object: DbOject<T>): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getConnection().then((db) => {
        const tx = db.transaction(this.storeName, 'readwrite');
        const store = tx.objectStore(this.storeName);
        const request = store.add(object);

        request.onsuccess = (e: any) => {
          resolve(e.target.result);
        };
        db.onerror = (ev: ErrorEvent) => {
          const error = this.handleError('IndexedDB error in post:', ev);
          reject(error);
        };
      });
    });
  }

  get(key: string): Promise<DbOject<T>> {
    return new Promise((resolve, reject) => {
      this.getConnection().then((db) => {
        const tx = db.transaction(this.storeName, 'readonly');
        const store = tx.objectStore(this.storeName);
        const index = store.index('key_idx');
        const request = index.get(key);

        request.onsuccess = () => {
          resolve(request.result);
        };
        db.onerror = (ev: ErrorEvent) => {
          const error = this.handleError('IndexedDB error in get: ', ev);
          reject(error);
        };
      });
    });
  }

  all(): Promise<DbOject<T>[]> {
    return new Promise((resolve, reject) => {
      const indexName = 'key_idx';

      this.getConnection().then((db) => {
        const tx = db.transaction(this.storeName, 'readonly');
        const store = tx.objectStore(this.storeName);
        const index = store.index(indexName);
        const request = index.openCursor();
        const results: any[] = [];

        request.onsuccess = () => {
          const cursor = request.result;
          if (cursor) {
            results.push(cursor.value);
            cursor.continue();
          } else {
            resolve(results);
          }
        };
        db.onerror = (ev: ErrorEvent) => {
          const error = this.handleError('IndexedDB error in all:', ev);
          reject(error);
        };
      });
    });
  }

  remove(key: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getConnection().then((db) => {
        const tx = db.transaction(this.storeName, 'readwrite');
        const store = tx.objectStore(this.storeName);

        store.delete(key);

        tx.oncomplete = () => {
          resolve(key);
        };
        db.onerror = (ev: ErrorEvent) => {
          const error = this.handleError('IndexedDB error in remove:', ev);
          reject(error);
        };
      });
    });
  }

  count(): Promise<number> {
    return new Promise((resolve, reject) => {
      this.getConnection().then((db) => {
        const indexName = 'key_idx';
        const tx = db.transaction(this.storeName, 'readonly');
        const store = tx.objectStore(this.storeName);
        const index = store.index(indexName);
        const request = index.count();

        request.onsuccess = () => {
          resolve(request.result);
        };
        db.onerror = (ev: ErrorEvent) => {
          const error = this.handleError('IndexedDB error in count:', ev);
          reject(error);
        };
      });
    });
  }

  clear(): Promise<any> {
    return new Promise((resolve, reject) => {
      const request = this.indexedDB.deleteDatabase(this.dbName);

      request.onsuccess = () => {
        resolve(true);
      };
      request.onerror = (ev) => {
        const error = this.handleError('Could not delete indexed db', ev);
        reject(error);
      };
      request.onblocked = (ev) => {
        const error = this.handleError('Couldn not delete database due to the operation being blocked', ev);
        reject(error);
      };
    });
  }

  private handleError(msg: string, ev: Event) {
    const db: IDBDatabase = ev && ev.target as any;
    const error = db && db['error'] || {};
    const message = `${msg} - Code: ${error.code} - Name:${error.name}`;
    console.error(message);
    const res = new Error(message);
    res['customMessage'] = error.message;

    return res;
  }

  private async getConnection(): Promise<IDBDatabase> {
    if (this.connection) {
      return this.connection;
    }

    this.connection = this.openConnection();
    this.connection.then(con => {
      con.onclose = () => {
        this.connection = null;
      };
    });
    return this.connection;
  }

  private openConnection(): Promise<IDBDatabase> {
    return new Promise((resolve, reject) => {
      const request = this.indexedDB.open(this.dbName, this.version);

      request.onupgradeneeded = (event) => {
        if (event.oldVersion < 2) {
          // The database did not previously exist, so create object stores and indexes.
          this.createStoreAndIndexes(request.result);
        }
      };
      request.onsuccess = () => resolve(request.result);
      request.onerror = (ev: ErrorEvent) => reject(this.handleError('IndexedDB error in open:', ev));
      request.onblocked = () => console.warn('IndexedDb is pending till unblocked.');
    });
  }

  private createStoreAndIndexes(db: IDBDatabase) {
    for (const schema of this.schemas) {
      if (db.objectStoreNames.contains(schema.name)) {
        continue;
      }

      const store = db.createObjectStore(schema.name, { keyPath: 'key' });
      store.createIndex('key_idx', 'key', { unique: true });

      if (schema.indexes !== undefined) {
        for (const index of schema.indexes) {
          store.createIndex(`${index}_idx`, index);
        }
      }

      if (schema.seeds !== undefined) {
        for (const seed of schema.seeds) {
          store.put(seed);
        }
      }
    }
  }
}

export function createDefaultIndexedDbService(appName: string, errorMonitoring: ErrorMonitoringService) {
  return new IndexedDbService(appName, errorMonitoring);
}

export const defaultIndexedDbServiceProvider: Provider = {
  provide: IndexedDbService,
  useFactory: createDefaultIndexedDbService,
  deps: [APP_NAME, ErrorMonitoringService],
};
