import { ElementRef, Injectable } from '@angular/core';
import { ResizeSensor } from 'css-element-queries';
import { debounceTime, Observable, shareReplay } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ResizeService {

  watch(el: ElementRef) {
    return new Observable<HTMLElement>((observer) => {
      const sensor = new ResizeSensor(el.nativeElement,
        () => observer.next(el.nativeElement));
      return () => sensor.detach();
    }).pipe(debounceTime(10), shareReplay());
  }
}
