import { BillingErrorCode, GenericError } from 'src/app/pages/new-billing/types/api.types';

export class BillingError extends Error {
  public readonly errorCode: BillingErrorCode;
  public readonly statusCode: number;

  constructor({ errorCode, message, statusCode }: GenericError) {
    super(message);

    this.errorCode = errorCode;
    this.statusCode = statusCode;
  }
}
