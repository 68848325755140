import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatTabGroup } from '@angular/material/tabs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select } from '@ngxs/store';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthState } from 'src/app/store/auth/auth.state';
import { ManagerState } from 'src/app/store/manager/manager.state';
import { advancedStringFilter, ValueAccessorBase } from 'src/app/util';
import { AuthCompany, AuthUser, User, UserGroup } from 'src/models';
import { SearchComponent } from '../search/search.component';
import { UserFilterSelection } from './user-filter-selection';


@UntilDestroy()
@Component({
  selector: 'app-user-filter-dropdown',
  templateUrl: './user-filter-dropdown.component.html',
  styleUrls: ['./user-filter-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserFilterDropdownComponent),
      multi: true,
    },
  ],
})
export class UserFilterDropdownComponent extends ValueAccessorBase<UserFilterSelection> {
  @ViewChild(SearchComponent, { static: false }) searchComponent: SearchComponent;
  readonly membersOfNoGroupId = UserFilterSelection.membersOfNoGroupId;
  @Input() fireChangeOnClose = true;
  @Input() showUsers = true;
  @Input() showGroups = true;

  alwaysShow$ = new BehaviorSubject<boolean>(false);
  @Input() set alwaysShow(value: boolean) { this.alwaysShow$.next(value); }

  customUsers$ = new BehaviorSubject<User[]>(undefined);
  @Input() set customUsers(value: User[]) { this.customUsers$.next(value); }

  @Select(ManagerState.groups) groups$!: Observable<UserGroup[]>;
  @Select(ManagerState.users.onReports) users$!: Observable<User[]>;
  @Select(AuthState.company) company$!: Observable<AuthCompany>;
  @Select(AuthState.user) authUser$!: Observable<AuthUser>;

  filter$ = new BehaviorSubject('');

  userList$ = combineLatest([this.customUsers$, this.users$]).pipe(map(([customUsers, users]) => customUsers || users));

  showButton$ = combineLatest([this.company$.pipe(map(x => x && x.role !== 'user')), this.alwaysShow$, this.userList$])
    .pipe(map(([show, alwaysShow, userList]) => (alwaysShow || show) && userList));

  usersToShow$ = combineLatest([this.userList$, this.filter$])
    .pipe(map(([users, filter]) => advancedStringFilter(users || [], filter, x => [x.name, x.email, x.employeeId])));

  groupsToShow$ = combineLatest([this.groups$, this.authUser$, this.company$, this.filter$]).pipe(
    map(([groups, user, company, filter]) => {
      groups = advancedStringFilter(groups || [], filter, x => x.name);
      if (['manager', 'guest'].includes(company?.role)) {
        return groups.filter(group => group.managers?.includes(user.id));
      }
      return groups;
    }),
  );

  private allUsers: string[];
  private allGroups: string[];

  @ViewChild('userVs', { static: false }) userVs: CdkVirtualScrollViewport;
  @ViewChild('groupVs', { static: false }) groupVs: CdkVirtualScrollViewport;
  @ViewChild(MatTabGroup, { static: false }) tabGroup: MatTabGroup;

  private tempValueField: UserFilterSelection = null;
  set tempValue(value: UserFilterSelection) {
    if (this.fireChangeOnClose) {
      this.tempValueField = value;
    } else {
      this.value = value;
      this.tempValueField = null;
    }
  }
  get tempValue() { return this.tempValueField || this.value; }

  constructor(private cd: ChangeDetectorRef) {
    super();

    this.value = UserFilterSelection.none;

    combineLatest([this.userList$, this.groupsToShow$]).pipe(untilDestroyed(this)).subscribe(([users, groups]) => {
      this.allUsers = users?.map(y => y.id);
      this.allGroups = groups && [this.membersOfNoGroupId, ...groups.map(y => y.id)];
      this.tempValue = this.tempValue.setAllItems(this.allUsers, this.allGroups);
    });

    this.filter$.pipe(untilDestroyed(this)).subscribe(async filter => {
      if (filter && this.tempValue.all) {
        this.masterToggle(false);
        cd.detectChanges();
      }
    });
  }

  writeValue(value: UserFilterSelection) {
    super.writeValue((value || UserFilterSelection.none).setAllItems(this.allUsers, this.allGroups));
    this.tempValueField = null;
    this.cd.detectChanges();
  }

  masterToggle(checked) {
    this.tempValue = checked ? this.tempValue.selectAll() : this.tempValue.clear();

    if (checked) {
      this.filter$.next('');
    }

    this.cd.detectChanges();
  }

  setSelected(selected: boolean, item: string) {
    this.tempValue = this.tempValue.set(selected, item);
  }

  typeChanged({ index }) {
    this.filter$.next('');
    this.tempValue = this.tempValue.setType(index === 1 ? 'groups' : 'users');

    this.refreshVs();
  }

  menuClosed() {
    if (this.fireChangeOnClose && this.tempValue && this.tempValue !== this.value) {
      this.value = this.tempValue;
      this.tempValue = null;
    }
  }

  menuOpened() {
    this.filter$.next('');
    this.refreshVs();
    if (this.tabGroup) {
      this.tabGroup.realignInkBar();
    }
    this.searchComponent.focus();
  }

  private refreshVs() {
    // HACK: Scroll position is resetted when the tab changes.
    // This is a workaround to fix items not rendering after reset.
    this.userVs?.checkViewportSize();
    this.groupVs?.checkViewportSize();
  }
}
