import { AfterViewInit, Directive, ElementRef, EventEmitter, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ResizeService } from '../services/resize.service';


@UntilDestroy()
@Directive({
  selector: '[watchSize]',
  exportAs: 'watchSize',
})
export class WatchSizeDirective implements AfterViewInit {
  @Output() resized = new EventEmitter<HTMLElement>();

  constructor(
    private hostElement: ElementRef,
    private resizeService: ResizeService,
  ) { }

  ngAfterViewInit() {
    this.resizeService.watch(this.hostElement).pipe(untilDestroyed(this))
      .subscribe(x => this.resized.next(x));
  }
}
