import { APP_INITIALIZER, Inject, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { connect } from 'socket.io-client';
import { AppStateRootModel } from '../store/models';
import { getCombinedId } from '../util/internal';
import { APP_TYPE, AppType, SOCKET_HOST } from './app.constants';
import { StoreReadyService } from './store-ready.service';

export type SocketChunkParam = string | number | { pause: boolean, items: number };

export interface SocketApiParams {
  id: number;
  path: string;
  method: 'get' | 'post' | 'put' | 'delete';
  body: any;
  headers: object;
  query: object;
  chunk?: SocketChunkParam;
}

export interface SocketApiResult<T = any> {
  id: number;
  more?: boolean;
  paused?: boolean;
  status: string;
  data: T;
}

export interface SocketData<T = any> {
  id: string;
  method: 'post' | 'put' | 'delete';
  payload: T;
  resource: string;
}

export interface SocketAdminMessage {
  from: string;
  to: string;
  subject: 'refresh-ui' | 'diagnostic/info/request';
}

export function getSocketServiceInitializer(service: SocketService) {
  /** Factory method for APP_INITIALIZER */
  return () => service.initialized;
}

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  private socket: ReturnType<typeof connect>;
  private notifySubject: Subject<SocketData> = new Subject<SocketData>();
  private socketResultSubject = new Subject<SocketApiResult>();
  private msgSubject = new Subject<SocketAdminMessage>();
  private disconnectSubject = new Subject();
  private connectedUserSubject = new BehaviorSubject<string>(null);

  public initialized: Promise<void>;
  public readonly socketApiResult = this.socketResultSubject.asObservable();
  public readonly notify = this.notifySubject.asObservable();
  public readonly msg = this.msgSubject.asObservable();
  public readonly disconnect = this.disconnectSubject.asObservable();
  public readonly onConnection = this.connectedUserSubject.asObservable();

  private subscribeList: string[];

  public get connected() {
    return this.socket?.connected;
  }

  constructor(
    private store: Store,
    private storeReady: StoreReadyService,
    @Inject(SOCKET_HOST) private socketHost: string,
    @Inject(APP_TYPE) private appType: AppType,
  ) {
    this.initialized = this.init();
  }

  private async init() {
    await this.storeReady.ready;

    let connectedId: string = null;
    let connectedToken: string = null;

    this.store.select((st: AppStateRootModel) => st.auth).subscribe(state => {
      const shouldNotConnect = !state || !state.user || !state.company || !state.token || state.userRequiresUpdate;

      if (shouldNotConnect) {
        this.disconnectSocket();
        connectedId = null;
        connectedToken = null;
        return;
      }

      // Credentials changed, lets reconnect
      const combinedId = getCombinedId(state);
      if (connectedId !== combinedId || connectedToken !== state.token) {
        this.disconnectSocket();
      }

      connectedId = combinedId;
      connectedToken = state.token;

      // Already connected
      if (this.socket) {
        return;
      }
      const appType: AppType = this.appType || 'other';

      this.socket = connect(this.socketHost, {
        path: '/api/1.0/socket/',
        transports: ['websocket'],
        transportOptions: {
          websocket: {
            extraHeaders: {
              Authorization: `JWT ${state.token}`,
              'x-company-id': state.company?.id,
              'x-client-app': appType,
            },
          },
        },
        auth: {
          token: state.token,
        },
        reconnectionDelay: 1000 * 30,
        reconnectionDelayMax: 1000 * 30,
        query: { company: state.company?.id, app: appType },
      });

      this.socket.on('connectReady', () => {
        this.emitSubscribe([state.user.id], state.company.id, this.subscribeList || []);
        this.connectedUserSubject.next(combinedId);
      });

      this.socket.on('notify', data => this.notifySubject.next(data));
      this.socket.on('api', res => this.socketResultSubject.next(res));
      this.socket.on('msg', msg => this.msgSubject.next(msg));
    });
  }

  private disconnectSocket() {
    if (!this.socket) { return; }
    this.connectedUserSubject.next(null);
    this.socket.off('connectReady');
    this.socket.off('api');
    this.socket.off('msg');
    this.socket.off('notify');
    this.socket.disconnect();
    this.socket = null;
    this.subscribeList = null;
    this.disconnectSubject.next(null);
  }

  emit(event: string, ...args: any[]) {
    this.socket.emit(event, ...args);
  }

  subscribe(userIds: string[], companyId: string, detail?: string | string[]) {
    detail = detail || [];
    const detailList = Array.isArray(detail) ? detail : [detail];
    this.subscribeList = detailList;

    this.onceConnected().subscribe(() => {
      this.emitSubscribe(userIds, companyId, detailList);
    });
  }

  private emitSubscribe(users: string[], company: string, detail: string[]) {
    this.emit('subscribe', { users, company, detail });
  }

  resource<T = any>(resource: string, method?: 'post' | 'put' | 'delete'): Observable<SocketData<T>> {
    return this.notify.pipe(
      filter(x => x.resource === resource && (!method || method === x.method)),
    );
  }

  onceConnected(combinedId?: string) {
    if (!combinedId) {
      const auth = this.store.selectSnapshot((st: AppStateRootModel) => st.auth);
      combinedId = getCombinedId(auth);
    }

    if (this.connected && this.connectedUserSubject.value === combinedId) {
      return of(combinedId);
    }

    return this.onConnection.pipe(filter(x => x === combinedId), take(1), filter(() => !!this.socket));
  }
}

export const socketInitProvider = {
  provide: APP_INITIALIZER,
  useFactory: getSocketServiceInitializer,
  deps: [SocketService],
  multi: true,
};
