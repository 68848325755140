import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

export class SelectionModel {
  private selectionMap: { [key: string]: true } = {};
  private selectionObsMap: { [key: string]: BehaviorSubject<boolean> } = {};
  private selectionCount = 0;

  private changeObs = new Subject();
  readonly current = this.changeObs.pipe(map(() => this), startWith(this));

  private selectSingle(id: string) {
    if (this.isSelected(id) !== true) {
      this.selectionMap[id] = true;

      this.selectionCount++;

      const obs = this.selectionObsMap[id];
      if (obs) {
        obs.next(true);
      }
    }
  }

  private deselectSingle(id: string) {
    if (this.isSelected(id) !== false) {
      delete this.selectionMap[id];

      this.selectionCount--;

      const obs = this.selectionObsMap[id];
      if (obs) {
        obs.next(false);
      }
    }
  }

  private toggleSingle(id: string) {
    if (this.isSelected(id)) {
      this.deselectSingle(id);
    } else {
      this.selectSingle(id);
    }
  }

  clear() {
    const oldSelectionMap = this.selectionMap;
    this.selectionMap = {};

    for (const key in this.selectionObsMap) {
      if (this.selectionObsMap.hasOwnProperty(key)) {
        const obs = this.selectionObsMap[key];
        if (oldSelectionMap[key]) {
          obs.next(false);
        }
      }
    }

    this.selectionCount = 0;
    this.changeObs.next(null);
  }

  select(...ids: string[]) {
    for (const id of ids) {
      this.selectSingle(id);
    }
    this.changeObs.next(null);
  }

  deselect(...ids: string[]) {
    for (const id of ids) {
      this.deselectSingle(id);
    }
    this.changeObs.next(null);
  }

  toggle(...ids: string[]) {
    for (const id of ids) {
      this.toggleSingle(id);
    }
    this.changeObs.next(null);
  }

  set(selected: boolean, ...ids: string[]) {
    if (selected) {
      this.select(...ids);
    } else {
      this.deselect(...ids);
    }
  }

  isSelected = (id: string) => {
    return this.selectionMap[id] || false;
  };

  selected(id: string): Observable<boolean> {
    if (!this.selectionObsMap[id]) {
      this.selectionObsMap[id] = new BehaviorSubject(this.isSelected(id));
    }
    return this.selectionObsMap[id];
  }

  list(): string[] {
    return Object.keys(this.selectionMap);
  }

  get count() {
    return this.selectionCount;
  }

  get any() {
    return this.selectionCount > 0;
  }
}
