import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { PopLoading, PushLoading } from './loading.actions';


@State<number>({
  name: 'loading',
  defaults: 0,
})
@Injectable()
export class LoadingState {
  @Selector()
  static loading(state: number) { return state > 0; }

  @Action(PushLoading)
  pushLoading({ setState, getState }: StateContext<number>) {
    setState((getState() || 0) + 1);
  }

  @Action(PopLoading)
  PopLoading({ setState, getState }: StateContext<number>) {
    setState((getState() - 1) || 0);
  }
}
