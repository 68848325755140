import { StringLiteral } from 'src/models';
import envTyped from './env-replaced.json';

export type DeploymentType = StringLiteral | 'uspublic' | 'vois' | 'hellorache' | 'genesis';

/**
 * IMPORTANT: This list of envirornment variables should be synced with Dockerfile
 */
interface KnownEnvironmentVariables {
  NODE_ENV: 'production' | 'development' | 'test';
  TD_APP_VERSION?: string;
  TD_APP_DEPLOYMENT?: DeploymentType;
  TD_APP_SEGMENT_KEY?: string;
  TD_APP_HOME?: string;
  TD_APP_API_HOST?: string;
  TD_APP_INTEGRATIONS_API_URL?: string;
  TD_APP_PAYMENT_HOST?: string;
  TD_APP_SENTRY_DSN?: string;
  TD_APP_SENTRY_ENVIRONMENT?: string;
  TD_APP_SENTRY_DEBUG?: string;
  TD_APP_SENTRY_TRACES_SAMPLE_RATE?: string;
  TD_APP_DISABLE_SOCKET?: 'true' | StringLiteral;
  TD_APP_DISABLE_ALL_SOCKET?: 'true' | StringLiteral;
  TD_APP_DISABLE_BILLING?: 'true' | StringLiteral;
  TD_APP_DISABLE_ONBOARDING?: 'true' | StringLiteral;
  TD_APP_DISABLE_INVITE?: 'true' | StringLiteral;
  TD_APP_DISABLE_DOWNLOAD?: 'true' | StringLiteral;
  TD_APP_DISABLE_SCREENCASTS?: 'true' | StringLiteral;
  TD_APP_ENABLE_SPLIT_TESTS?: 'true' | StringLiteral;
  TD_APP_ENABLE_ANALYTICS?: 'true' | StringLiteral;
  TD_APP_HTTP_TIMEOUT?: string;
  TD_APP_GOOGLE_CLIENT_ID?: string;
  TD_APP_DATA_INTEGRITY_APIURL?: string;
  TD_APP_DATA_INTEGRITY_APIKEY?: string;
  TD_APP_PWA_URL?: string;
  TD_APP_ANNOUNCE_KIT?: string;
  TD_APP_GROWTHBOOK_API_HOST?: string;
  TD_APP_GROWTHBOOK_CLIENT_KEY?: string;
  TD_APP_PADDLE_SANDBOX?: 'true' | StringLiteral;
  TD_APP_PADDLE_VENDOR?: string;
  TD_APP_PADDLE_CLIENT_TOKEN?: string;
  TD_APP_BILLING_API_URL?: string;
  TD_APP_GEO_INFO_API_URL?: string;
  TD_APP_HUBSPOT_KEY?: string;
  TD_APP_EMPLOYEE_PRIVACY_DISCLAIMER?: string;
  TD_APP_CONTRACTOR_PRIVACY_DISCLAIMER?: string;
  TD_APP_TDC_CHECK_EMAIL_END_POINT?: string;
  TD_APP_TRAINING_GUIDE_URL?: string;
  TD_APP_GOOGLE_IMPORT_CLIENT_ID?: string;
  TD_APP_HUBSPOT_FLOW_ID?: string;
  TD_APP_HUBSPOT_TOKEN_GENERATE?: string;
}

const env = envTyped as unknown as KnownEnvironmentVariables;

export interface Environment {
  version: string;
  deployment: string;
  production: boolean;
  hmr: boolean;
  test: boolean;
  baseHref: string;
  appName: string;
  appUrl: string;
  apiUrl: string;
  httpTimeout: number;
  announceKitUrl: string | null;
  integrationsApiUrl: string;
  billingApiUrl: string;
  pwaAppUrl: string;
  paymentApiUrl: string;
  socketApiRoot: string;
  socketHost: string;
  defaultApiVersion: string;
  useSocketApi: boolean;
  allowSocket: boolean;
  enableAnalytics: boolean;
  hubspotKey: string;
  sentryDsn: string;
  sentryEnvironment: string;
  sentryDebug: boolean;
  sentryTracesSampleRate: number;
  sentryRelease: string;
  googleClientId: string;
  tdcCheckEmailEndPoint: string;
  dataIntegrityApiUrl: string;
  dataIntegrityApiKey: string;
  enableSplitTest: boolean;
  growthbookApiHost: string;
  growthbookClientKey: string;
  geoInfoApiUrl: string;
  env: KnownEnvironmentVariables;
}

const version = env.TD_APP_VERSION || '0.0.0';
const production = env.NODE_ENV === 'production';
const hmr = env.NODE_ENV === 'development';
const test = env.NODE_ENV === 'test';
const deployment = env.TD_APP_DEPLOYMENT || 'uspublic';

const appUrl = env.TD_APP_HOME || 'https://2.timedoctor.com';
const apiHost = env.TD_APP_API_HOST || 'https://api2.timedoctor.com';
const paymentApiUrl = env.TD_APP_PAYMENT_HOST || 'https://staff-api-stripe.herokuapp.com';
const integrationsApiUrl = env.TD_APP_INTEGRATIONS_API_URL || `https://integrations.timedoctor.com`;
const pwaAppUrl = env.TD_APP_PWA_URL || 'https://desktop.timedoctor.com';
const sentryDsn = env.TD_APP_SENTRY_DSN;
const sentryEnvironment = env.TD_APP_SENTRY_ENVIRONMENT;
const sentryDebug = env.TD_APP_SENTRY_DEBUG === 'true';
const sentryTracesSampleRate = env.TD_APP_SENTRY_TRACES_SAMPLE_RATE ? parseFloat(env.TD_APP_SENTRY_TRACES_SAMPLE_RATE) : 0.01;
const googleClientId = env.TD_APP_GOOGLE_CLIENT_ID;
const tdcCheckEmailEndPoint = env.TD_APP_TDC_CHECK_EMAIL_END_POINT;
const dataIntegrityApiUrl = env.TD_APP_DATA_INTEGRITY_APIURL;
const dataIntegrityApiKey = env.TD_APP_DATA_INTEGRITY_APIKEY;
const allowSocket = env.TD_APP_DISABLE_ALL_SOCKET !== 'true';
const enableSplitTest = env.TD_APP_ENABLE_SPLIT_TESTS === 'true';
const announceKitUrl = env.TD_APP_ANNOUNCE_KIT ? `https://announcekit.co/widgets/v2/${env.TD_APP_ANNOUNCE_KIT}` : null;
const geoInfoApiUrl = env.TD_APP_GEO_INFO_API_URL || 'https://td-website-mu.timedoctor.com/api/geo-info';
const hubspotKey = env.TD_APP_HUBSPOT_KEY;
const httpTimeout = env.TD_APP_HTTP_TIMEOUT ? parseInt(env.TD_APP_HTTP_TIMEOUT) * 1000 : 3 * 60 * 1000;

// ToDo: Define the proper billing API URL once it is available
const billingApiUrl = env.TD_APP_BILLING_API_URL || `https://billing.timedoctor.com/v1`;

export const environment: Environment = {
  version,
  deployment,
  hmr,
  production,
  test,
  httpTimeout,
  enableAnalytics: env.TD_APP_ENABLE_ANALYTICS === 'true' || (!!env.TD_APP_SEGMENT_KEY && production),
  hubspotKey,
  useSocketApi: allowSocket && production && env.TD_APP_DISABLE_SOCKET !== 'true',
  allowSocket,
  baseHref: '/',
  appName: 'timedoctor2-web',
  appUrl,
  apiUrl: `${apiHost}/api/{version}`,
  billingApiUrl,
  pwaAppUrl,
  announceKitUrl,
  paymentApiUrl,
  integrationsApiUrl,
  socketApiRoot: '/api/{version}',
  defaultApiVersion: '1.0',
  socketHost: apiHost,
  sentryDsn,
  sentryEnvironment,
  sentryDebug,
  sentryTracesSampleRate,
  sentryRelease: version,
  env,
  googleClientId,
  tdcCheckEmailEndPoint,
  dataIntegrityApiUrl,
  dataIntegrityApiKey,
  enableSplitTest,
  growthbookApiHost: env.TD_APP_GROWTHBOOK_API_HOST,
  growthbookClientKey: env.TD_APP_GROWTHBOOK_CLIENT_KEY,
  geoInfoApiUrl,
};
