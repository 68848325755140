import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { ERROR_URL } from 'src/app/app/app.constants';
import { GrowthBookService } from 'src/app/services/growthbook/service';
import { AuthState } from 'src/app/store/auth/auth.state';

@Injectable({
  providedIn: 'root',
})
export class BasicPricingGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private store: Store,
    private router: Router,
    private growthBookService: GrowthBookService,
    @Inject(ERROR_URL) private errorUrl,
  ) { }

  async canActivate(route?: ActivatedRouteSnapshot, state?: RouterStateSnapshot) {
    const company = this.store.selectSnapshot(AuthState.company);
    const payrollUpsellFeatureEnabled = this.growthBookService.getFeatureValue<boolean>('payroll:upsell');

    if (company.pricingPlan?.includes('basic')) {
      if (state.url === '/payroll' && payrollUpsellFeatureEnabled) {
        return true;
      }
      return this.router.createUrlTree([this.errorUrl, 'upgrade']);
    }

    return true;
  }

  canActivateChild() {
    return this.canActivate();
  }

  canLoad() {
    return this.canActivate().then(x => x === true);
  }
}
