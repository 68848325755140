import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-status-avatar',
  templateUrl: './status-avatar.component.html',
  styleUrls: ['./status-avatar.component.scss'],
})
export class StatusAvatarComponent {
  @Input() @HostBinding('style.width.px') @HostBinding('style.height.px') size = 32;
  @Input() name = '';
  @Input() status: string = undefined;
  @Input() statusTooltip = false;
  @Input() statusBackground = 'transparent';
  @Input() hideStatus = false;
}
