<span [ngClass]="['input-menu-trigger', triggerClass]" mat-button [matMenuTriggerFor]="menu" #trigger="matMenuTrigger">
  <ng-container *ngTemplateOutlet="triggerTemplate || defaultTriggerTemplate; context: { triggerText }"></ng-container>
</span>

<mat-menu class="input-menu" #menu="matMenu" xPosition="after">
  <ng-template matMenuContent>
    <div class="input-dialog" (click)="$event.stopPropagation()">
      <mat-form-field appearance="fill">
        <mat-label>{{ label }}</mat-label>
        <input #inputEl matInput [type]="type" [ngModel]="value" autofocus="all" autocomplete="off" autofill="off"
          [min]="min" [max]="max" [step]="step" (keyup.enter)="setValue(inputEl.value); trigger.closeMenu()" />
      </mat-form-field>

      <div fxLayout="row" fxLayoutAlign="flex-end center">
        <button mat-stroked-button class="cancel-button compact" style="margin-right: 8px;"
          (click)="trigger.closeMenu()">
          {{ 'common.cancel' | translate | uppercase }}
        </button>
        <button mat-raised-button color="primary" class="compact"
          (click)="setValue(inputEl.value); trigger.closeMenu()">
          {{ 'common.save' | translate | uppercase }}
        </button>
      </div>
    </div>
  </ng-template>
</mat-menu>

<ng-template #defaultTriggerTemplate let-text="triggerText">
  <span class="input-menu-trigger-text">{{ text }}</span>
</ng-template>
