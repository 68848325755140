import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Select } from '@ngxs/store';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, startWith, switchMap, take, takeUntil } from 'rxjs/operators';
import {
  BannerButton,
  BannerIconColor,
  BannerType,
  TopBanner
} from 'src/app/pages/new-billing/models/top-banner.model';
import { NewBillingState } from 'src/app/pages/new-billing/store/state';
import { BillingDetailsApiData } from 'src/app/pages/new-billing/types/api.types';
import { BillingService } from 'src/app/services/billing/billing.service';
import { AuthSelectors } from 'src/app/store/auth/auth.selectors';
import { AuthCompany } from 'src/models';

@Injectable({
  providedIn: 'root',
})
export class RetryPaymentBanner implements TopBanner, OnDestroy {
  @Select(AuthSelectors.company) company$: Observable<AuthCompany>;
  @Select(NewBillingState.updatedBillingDetails) billingDetails$: Observable<BillingDetailsApiData>;

  public ariaLabel = 'Retry payment banner';
  public icon: string;
  public iconColor: BannerIconColor = 'white';
  public svgIcon: string;
  public trackingInfo: Record<string, unknown>;
  public type: BannerType = 'error';

  private readonly destroy$ = new Subject<void>();

  private _primaryButton: BannerButton;
  private _message: string;

  get primaryButton(): BannerButton {
    if (!this._primaryButton) {
      this._primaryButton = {
        text: 'billing.viewInvoices',
        action: () => {
          this.router.navigate(['/new-billing'], { fragment: 'invoices' });
        },
      };
    }
    return this._primaryButton;
  }

  get message(): string {
    if (!this._message) {
      const messageKey = 'billing.failingPaymentMessage';
      this._message = this.translateService.instant(messageKey);
    }
    return this._message;
  }

  constructor(
    private billingService: BillingService,
    private translateService: TranslateService,
    private router: Router,
  ) {
  }

  public showCondition(): Observable<boolean> {
    const currentFragment$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.router.routerState.snapshot.root.fragment),
      startWith(this.router.routerState.snapshot.root.fragment),
      distinctUntilChanged(),
    );

    const hasPastDueInvoices$ = this.billingService.getPastDueInvoices().pipe(
      map(pastDueInvoices => pastDueInvoices.length > 0),
      catchError(() => of(false)),
      take(1), // Ensure only one emission
    );

    return this.company$.pipe(
      switchMap(company => {
        if (company?.subscription?.provider === 'paddle' && (company?.userSettings?.billingAccess || company?.role === 'owner')) {
          return combineLatest([hasPastDueInvoices$, currentFragment$]).pipe(
            map(([hasPastDueInvoices, fragment]) => hasPastDueInvoices && fragment !== 'invoices'),
          );
        }
        return of(false);
      }),
      takeUntil(this.destroy$),
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
