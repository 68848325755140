import { Injectable, OnDestroy } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { lastValueFrom, Observable, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  BannerButton,
  BannerIconColor,
  BannerType,
  TopBanner
} from 'src/app/pages/new-billing/models/top-banner.model';
import { NewBillingState } from 'src/app/pages/new-billing/store/state';
import { BillingDetailsApiData } from 'src/app/pages/new-billing/types/api.types';
import { AuthSelectors } from 'src/app/store/auth/auth.selectors';
import { AuthCompany } from 'src/models';
import { SharedBannerComponent } from 'src/app/components/shared-banner/component';
import { GrowthBookService } from 'src/app/services/growthbook/service';
import { DismissBanner } from 'src/app/store/banner/actions';
import { CompanyService } from 'src/app/services/company/company.service';

type InfoBannerData = {
  showBanner: boolean;
  link: string;
  message: string;
};

@Injectable({ providedIn: 'root' })
export class InviteWebinarBanner implements TopBanner, OnDestroy {
  @Select(AuthSelectors.company) company$: Observable<AuthCompany>;
  @Select(NewBillingState.updatedBillingDetails) billingDetails$: Observable<BillingDetailsApiData>;
  @Select(state => state.banner.dismissedBanners['inviteWebinarBanner'])

  dismissed$: Observable<boolean>;
  currentCompany: AuthCompany;

  private readonly destroy$ = new Subject<void>();
  private subscription: Subscription;

  public ariaLabel = 'Webinar banner';
  public icon: string;
  public iconColor: BannerIconColor = 'white';
  public svgIcon: string;
  public trackingInfo: Record<string, unknown>;
  public type: BannerType = 'info';
  public bannerData: InfoBannerData = { showBanner: false, link: '', message: '' };

  constructor(
    private store: Store,
    public growthBook: GrowthBookService,
    public companyService: CompanyService,
  ) {
    this.initializeBannerInfo();
  }

  private async updateCompanySettings(settings) {
    if (this.currentCompany && this.currentCompany.id) {
      await lastValueFrom(this.companyService.putCompanySettings(this.currentCompany.id, settings));
    }
  }

  private initializeBannerInfo() {
    this.subscription = this.growthBook.getFeature('webinar-info-banner').pipe(
      map((info: InfoBannerData) => {
        return info;
      }),
    ).subscribe(info => {
      this.bannerData = info;
    });
  }

  get primaryButton(): BannerButton {
    return {
      text: 'Dismiss',
      action: async (banner: SharedBannerComponent) => {
        banner.dismiss();
        this.store.dispatch(new DismissBanner('inviteWebinarBanner'));
        await this.updateCompanySettings({ 'custom.dismissedBanners': { inviteWebinarBanner: true } });
      },
    };
  }

  get message(): string {
    if (this.bannerData && this.bannerData.link && this.bannerData.message) {
      const { link, message } = this.bannerData;
      return `<a target="_blank" href="${link}">${message}</a>`;
    }
    return;
  }

  get showBanner(): boolean {
    return this.bannerData ? this.bannerData.showBanner : false;
  }

  public showCondition(): Observable<boolean> {
    return this.company$.pipe(
      map((company) => {
        this.currentCompany = company;

        const storedDismissedBanners = JSON.parse(localStorage.getItem('dismissedBanners') || '{}');
        const isDismissed = storedDismissedBanners['inviteWebinarBanner'] === true;
        const isDismissedInCompanySettings = company?.companySettings?.custom?.dismissedBanners?.inviteWebinarBanner === true;

        const isOwnerAndTrial = company?.role === 'owner' && company?.subscription?.status === 'trial';
        return isOwnerAndTrial && this.bannerData?.showBanner && !isDismissed && !isDismissedInCompanySettings;
      }),
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
