import { StateOperator } from '@ngxs/store';
import { iif, updateItem as originalUpdateItem } from '@ngxs/store/operators';
import { sortBy, sortByPriority } from './string.util';

export type RepairType<T> = T extends true ? boolean : (T extends false ? boolean : T);
export type Predicate<T = any> = (value?: RepairType<T>) => boolean;

/**
 * A replacement for NGXS's original updateItem but does not error on null values
 * @param selector - Index of item in the array or a predicate function
 * that can be provided in `Array.prototype.findIndex`
 * @param operatorOrValue - New value under the `selector` index or a
 * function that can be applied to an existing value
 */
export function updateItem<T>(
  selector: number | Predicate<T>,
  operatorOrValue: T | StateOperator<T>,
): StateOperator<RepairType<T>[]> {

  return iif(x => x != null, originalUpdateItem(selector, operatorOrValue));
}

/**
 * @param selector - predicate to remove an items from an array by
 */
export function removeItems<T>(selector: Predicate<T>) {
  return function removeItemsOperator(existing: Readonly<RepairType<T>[]>): RepairType<T>[] {
    return existing.filter(x => !selector(x));
  };
}

export function noop<T>() {
  return function noopOperator(existing: Readonly<RepairType<T>[]>): RepairType<T>[] {
    return existing as RepairType<T>[];
  };
}

/**
 * @param items - Specific items to merge to array
 */
export function appendOrUpdate<T>(items: T[], equality: (a: T, b: T) => boolean, updateOperator?: (prev: Readonly<T>, next: T) => T) {
  return function appendOrUpdateOperator(existing: Readonly<RepairType<T>[]>): RepairType<T>[] {
    // If `items` is `undefined` or `null` or `[]` but `existing` is provided
    // just return `existing`
    const itemsNotProvidedButExistingIs = (!items || !items.length) && existing;
    if (itemsNotProvidedButExistingIs) {
      return existing as RepairType<T>[];
    }

    if (Array.isArray(existing)) {
      const newArr = [...existing];

      for (const item of items) {
        const ind = newArr.findIndex(x => equality(x, item));

        if (ind >= 0) {
          newArr[ind] = updateOperator ? updateOperator(newArr[ind], item) : { ...newArr[ind], ...item };
        } else {
          newArr.push(item);
        }
      }

      return newArr;
    }

    // For example if some property is added dynamically
    // and didn't exist before thus it's not `ArrayLike`
    return items as RepairType<T>[];
  };
}

export function byId<T extends { id: string }>(a: T, b: T): boolean {
  return a && b && a.id === b.id;
}

/**
 * @param selector - predicate to pick the property to sort on
 */
export function sort<T>(property?: keyof RepairType<T>) {
  return function sortOperator(existing: Readonly<RepairType<T>[]>): RepairType<T>[] {
    return [...existing].sort(sortBy(property));
  };
}

/**
 * Puts an item on top by excluding it from sort, other items stay in the same order
 * @param selector - predicate to pick the property
 * @param value - the property value
 */
export function sortPriority<T>(value: any, property?: keyof RepairType<T>) {
  return function sortPriorityOperator(existing: Readonly<RepairType<T>[]>): RepairType<T>[] {
    return [...existing].sort(sortByPriority(value, property));
  };
}
