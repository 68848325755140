import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { DateTime, DateTimeUnit, DurationUnit } from 'luxon';
import { AuthState } from 'src/app/store/auth/auth.state';

@Injectable({ providedIn: 'root' })
export class TimeService {
  static readonly days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

  static instance: TimeService;

  static periodStart(period: DurationUnit = 'day') {
    const startTime = '00:00:00';

    if (period === 'year' || period === 'years') return `01-01T${startTime}`;
    if (period === 'month' || period === 'months') return `01T${startTime}`;
    if (period === 'week' || period === 'weeks') return `${this.instance.firstDayOfWeek || 7}T${startTime}`;
    if (period === 'day' || period === 'days') return startTime;
    if (period === 'hour' || period === 'hours') return '00:00';
    if (period === 'minute' || period === 'minutes') return '00';
    return '';
  }

  static startOf(time: DateTime, unit: DurationUnit) {
    if ((unit === 'week' || unit === 'weeks') && this.instance) {
      return this.instance.startOfWeek(time);
    } else {
      return time.startOf(unit as DateTimeUnit);
    }
  }

  static endOf(time: DateTime, unit: DurationUnit) {
    if ((unit === 'week' || unit === 'weeks') && this.instance) {
      return this.instance.startOfWeek(time).plus({ week: 1 }).minus({ millisecond: 1 });
    } else {
      return time.endOf(unit as DateTimeUnit);
    }
  }

  static endOfExclusive(time: DateTime, unit: DurationUnit, skipIfAlreadyEnd = false) {
    if (skipIfAlreadyEnd) time = time.minus({ millisecond: 1 });

    if ((unit === 'week' || unit === 'weeks') && this.instance) {
      return this.instance.startOfWeek(time).plus({ week: 1 });
    } else {
      return time.startOf(unit as DateTimeUnit).plus({ [unit]: 1 });
    }
  }

  constructor(
    private store: Store,
  ) {
    TimeService.instance = this;
  }

  isWeekend(day: number) {
    const normalWeekends = [0, 6];
    const weekStart = this.store.selectSnapshot(AuthState.firstDayOfWeek);

    return normalWeekends.includes((day - weekStart + 1 + 7) % 7);
  }

  getSortedDays(): string[] {
    const weekStart = this.store.selectSnapshot(AuthState.firstDayOfWeek);
    return [...TimeService.days.slice(weekStart - 1), ...TimeService.days.slice(0, weekStart - 1)];
  }

  startOfWeek(time: DateTime) {
    const firstDayOfWeek = this.store.selectSnapshot(AuthState.firstDayOfWeek);

    const weekStart = time.startOf('week');
    const dayDiff = time.diff(weekStart, 'days').days + 1;
    const adjusted = weekStart.plus({ days: firstDayOfWeek - 1 });
    if (dayDiff < firstDayOfWeek) { return adjusted.plus({ days: -7 }); }
    return adjusted;
  }

  get firstDayOfWeek() {
    return this.store.selectSnapshot(AuthState.firstDayOfWeek);
  }
}
