import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpRequestOptions } from '../api.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentApiService {
  apiUrl = environment.paymentApiUrl;

  constructor(
    private http: HttpClient,
  ) { }

  request<T>(
    method: 'get' | 'post' | 'put' | 'delete',
    endpoint,
    bodyOrParams = {},
    extraOptions: HttpRequestOptions = {},
  ): Observable<T> {
    let params = {};
    let body = {};

    if (method === 'get') {
      params = bodyOrParams || {};
    } else {
      body = bodyOrParams || {};
    }

    const options = { body, params };
    const httpEndpoint = `${this.apiUrl}/${endpoint}`;

    const requestOptions = { ...options, ...extraOptions };
    return this.http.request<T>(method, httpEndpoint, requestOptions);
  }
}
