import { Directive, Host, HostBinding } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';

@Directive({
  selector: '[verticalTabs]',
})
export class VerticalTabsDirective {
  @HostBinding('class.vertical-tabs-group') verticalTabs = true;

  constructor(
    @Host() private hostComponent: MatTabGroup,
  ) {
    this.hostComponent.animationDuration = '0ms';
  }
}
