import { DateTime, DateTimeUnit } from 'luxon';

export type DateLike = Date | DateTime | string | number;

// Normally, luxon compares dates according to their UTC timestamp
// This function tricks the luxon to compare them in current timezone
export function hasSameWithTz(t1: DateTime, t2: DateTime, unit: DateTimeUnit) {
  return t1.setZone('utc', { keepLocalTime: true }).hasSame(t2.setZone(t1.zone).setZone('utc', { keepLocalTime: true }), unit);
}


export function getFollowing5am() {
  const diff = (DateTime.local().endOf('day').diffNow('seconds').seconds + 5 * 60 * 60) % (24 * 60 * 60);
  return DateTime.utc().plus({ seconds: diff });
}

export function fromDateLike(value: DateLike) {
  if (!value) {
    return null;
  }

  if (value instanceof DateTime) {
    return value;
  }

  return DateTime.fromJSDate(new Date(value));
}
