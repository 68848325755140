import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { guestReports } from 'src/app/common/menu-def.provider';
import { GrowthBookService } from 'src/app/services/growthbook/service';
import { ExperimentProps } from 'src/app/services/growthbook/types';
import { AuthState } from 'src/app/store/auth/auth.state';

@Injectable({
  providedIn: 'root',
})
export class HomeGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private store: Store,
    private router: Router,
    private injector: Injector,
    private growthBookService: GrowthBookService,
  ) { }

  async canActivate(route?: ActivatedRouteSnapshot) {
    await this.growthBookService.initializedPromise;

    const exp = this.growthBookService.getFeatureValue<ExperimentProps>('welcome-screen-trial');
    const feat = this.growthBookService.getFeatureValue<boolean>('get-started-admins-managers');

    if (exp?.variationid === 'B' || feat) return this.router.createUrlTree(['/get-started']);
    return this.canActivateBasedOnRole(route);
  }

  private canActivateBasedOnRole(route?: ActivatedRouteSnapshot) {
    const company = this.store.selectSnapshot(AuthState.company);

    if (company.role === 'user') {
      return this.router.parseUrl('/dashboard-individual');
    } else if (company.role === 'guest') {
      const matchingReport = guestReports.find(x => x.condition(company, this.injector));
      const href = (matchingReport as { href: string })?.href;
      const link = (matchingReport as { routerLink: string })?.routerLink;

      if (href) {
        window.location.href = href;
        return false;
      } else if (link) {
        return this.router.parseUrl(link);
      } else {
        return this.router.parseUrl('/error/guest');
      }
    } else {
      const queryParams = route?.queryParams;
      return this.router.createUrlTree(['/dashboard'], { queryParams });
    }
  }

  canActivateChild(route: ActivatedRouteSnapshot) {
    return this.canActivate(route);
  }

  canLoad() {
    return true;
  }
}
