import { InjectionToken } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BrandingService } from '../services/branding.service';

export const ERROR_URL = new InjectionToken<string>('Error Url', { providedIn: 'root', factory: () => '/error' });

export const EXTENSION_URL = 'https://chrome.google.com/webstore/detail/time-doctor-2/hhchghdnpepogpigkpalpljaoocjhadj';
export const CHROMEOS_EXTENSION_URL = 'https://chrome.google.com/webstore/detail/time-doctor-2/fpfkmegplkpabeceoljjeajajeoiimim';
export const FIREFOX_ADDON_URL = 'https://addons.mozilla.org/en-US/firefox/addon/time-doctor-2/';

export const IOS_APP_URL = 'https://apps.apple.com/us/app/time-doctor-2/id6447000777';
export const ANDROID_APP_URL = 'https://play.google.com/store/apps/details?id=com.timedoctor2.mobile';

const splitTestUrl = 'https://api2-test.timedoctor.com';
const splitTestRatio = 0;
const apiSplitTest = (environment.production && (Math.random() * 100) < splitTestRatio) ? splitTestUrl : null;

export function getFromQuery(param: string) {
  const urlParams = typeof URLSearchParams === 'function' ? new URLSearchParams(window.location.search?.toLowerCase()) : null;
  const fromQuery = urlParams?.get(param?.toLowerCase());

  if (fromQuery === 'true') { return true; }
  if (fromQuery === 'false') { return false; }
  if (fromQuery === 'null') { return null; }
  if (fromQuery === 'undefined') { return undefined; }
  if (fromQuery && fromQuery.match(/^[+-]?([0-9]*[.])?[0-9]+$/)) { return parseFloat(fromQuery); }

  return fromQuery;
}

export function getSocketUrl(branding: BrandingService): string {
  const fromQuery = getFromQuery('api') || branding.getApiUrl() || apiSplitTest;
  if (typeof fromQuery !== 'string') { return environment.socketHost; }
  return fromQuery.replace(/^https?:\/\//, '');
}

export function getApiUrl(branding: BrandingService): string {
  const fromQuery = getFromQuery('api') || branding.getApiUrl() || apiSplitTest;
  if (typeof fromQuery !== 'string') { return environment.apiUrl; }
  const protocol = fromQuery.startsWith('http') ? '' : 'https://';
  const url = new URL('/api/{version}', `${protocol}${fromQuery}`);
  return decodeURI(url.href);
}

export function getDisableBilling(): boolean {
  const disable = getFromQuery('disablebilling');
  if (typeof disable === 'boolean') { return disable; }
  return environment.env.TD_APP_DISABLE_BILLING === 'true';
}

export function getDisableScreencasts(): boolean {
  const disable = getFromQuery('disablescreencasts');
  if (typeof disable === 'boolean') { return disable; }
  return environment.env.TD_APP_DISABLE_SCREENCASTS === 'true';
}

export function getDisableOnboarding(): boolean {
  const disable = getFromQuery('disableonboarding');
  if (typeof disable === 'boolean') { return disable; }
  return environment.env.TD_APP_DISABLE_ONBOARDING === 'true';
}

export function getDisableInvite(): boolean {
  const disable = getFromQuery('disableinvite');
  if (typeof disable === 'boolean') { return disable; }
  return environment.env.TD_APP_DISABLE_INVITE === 'true';
}

export function getDisableDownload(): boolean {
  const disable = getFromQuery('disabledownload');
  if (typeof disable === 'boolean') { return disable; }
  return environment.env.TD_APP_DISABLE_DOWNLOAD === 'true';
}

export function getAllowSocket(): boolean {
  const allow = getFromQuery('allowsocket');
  if (typeof allow === 'boolean') { return allow; }
  return environment.allowSocket;
}

export function getUseSocketApi(): boolean {
  if (!getAllowSocket()) return false;
  const dev = getFromQuery('dev');
  const socket = getFromQuery('socket');
  if (typeof socket === 'boolean') { return socket; }
  if (typeof dev === 'boolean') { return !dev; }
  return environment.useSocketApi;
}

export function getHttpTimeout(): number {
  const httpTimeout = getFromQuery('httpTimeout');
  if (typeof httpTimeout === 'number') { return httpTimeout * 1000; }
  return environment.httpTimeout;
}

export const defualtUserApiLimit = 400;
export const defualtWorkscheduleApiLimit = 500;

const isAlphaQuery = !!getFromQuery('alpha');

export const isBeta = !!getFromQuery('beta');

export const isExecutive = !!getFromQuery('executive');

const alphaCustomers = [
  'VcKxVBrmYBMArp5C', // Time Doctor
  'YI7NjZfhhwAE4wUn', // Swipewire
];

const betaCustomers = [
  'VcKxVBrmYBMArp5C', // Time Doctor
  'W0QAPz_26wB9ZPhg', // AMN Distribution
  'W61eIzIpSAE6uTdL', // Lava Seats
  // Removed from Beta Customers for release of Breaks.
  // More info https://globalworkforce.jira.com/browse/STAFF-125146?focusedCommentId=353751
  // 'W-yCxK1X6AB2qTMY', // Ascend BPO
  'XKT9nqR01AAg22SD', // TempDev
  'XYTcA_XjXgAg-5w8', // Task Drive (TD-LTV+)
  'XadAqZSuEAAZHarB', // AGM Agency (Manuel Suarez)
  'XjebAjMENwAEFwCx', // Freedom.tm (Freedom! Family Limited)
  'XnDiG-h3PgAEOqMA', // Tada
  'XtUc0lplEQAEurs4', // Gogotech ll LLC
  'XLWcZ99nJgAfoEWi', // Beepo Australia
  'YEjDYSgiyQAElHP4', // QUIVER DESENVOLVIMENTO E TECNOLOGIA
  'X46_S6jwzwAEF9-f', // Carecone pty Ltd
  'YLjCupnoyQAE0q46', // Peak Support
  'XoqCfpppyAAExYnN', // Filta
  'YGz0i0lxuwAEfupt', // FiltaGlobal
  'Xnga_m5ElAAEOnmK', // Personiv
  'XsLcnhaFzQAE8Tsm', // Yeetiq
  'X6hUPaXf8AAEogSU', // RepStack
  'YUS-Vwb4KicD1RUB', // eXp Realty
  'XLS4h1qc_wAdgKL-', // Dentistry Support
  'Yk86evgmqCsEKmqx', // Greek House
  'YFlizoFDbAAEv6g8', // Circles.Life
  'Yl6n1RSjVRibUAse', //Sipuni RUS
  'YqBSLKqW9XtoBzYe', //GymBeam
  'YvdDiMJklFi5Y76r', //AppCake
  'Y1m9bTk9L3JBCmaE', //Simpress
  'Yk-uDH8ErvTkUJem',// Stafflink
];

const timeDoctorSalesCompanyId = 'X4DHsJcjcgAE2QHW';

export function isAlphaCustomer(companyId: string) {
  return alphaCustomers.includes(companyId) || isAlphaQuery;
}

export function isBetaCustomer(companyId: string) {
  return betaCustomers.includes(companyId);
}

export function isTimeDoctor(companyId: string) {
  return companyId === betaCustomers[0];
}

export function isTimeDoctorSales(companyId: string) {
  return companyId === timeDoctorSalesCompanyId;
}
