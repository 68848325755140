<ng-container *ngIf="mode !== 'instructions'; else instructionsMode">
  <mat-form-field appearance="fill" class="invite-link-field">
    <mat-icon matPrefix class="material-icons-outline">{{ icon }}</mat-icon>
    <input matInput disabled [value]="value">
  </mat-form-field>
  <button trackClick
          mat-raised-button color="primary"
          (click)="handleCopyLink()"
  >
    {{ btnLabel | translate | uppercase }}
  </button>
</ng-container>

<ng-template #instructionsMode>
  <div class="instructions-mode">
    <div class="instructions-container" [innerHTML]="value" #instructionsList></div>

    <button
      mat-stroked-button color="primary"
      class="instructions-btn"
      (click)="handleCopyInstructions()"
      trackClick
    >
      {{ 'invite.inviteByLink.copyInstructionsBtn' | translate | uppercase }}
    </button>
  </div>
</ng-template>
