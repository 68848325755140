import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AuthState } from 'src/app/store/auth/auth.state';
import { User, UserRole } from 'src/models';

type FlatUser = {
  userId: string;
  userName?: string;
};

@UntilDestroy()
@Component({
  selector: 'app-user',
  templateUrl: './component.html',
  styleUrls: ['./component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserComponent {
  @Input() user: User | FlatUser;
  @Input() hideAvatar = false;
  @Input() hideLink = false;
  @Input() size = 24;
  @Input() textSizeRatio = 32 / 14;

  @Select(AuthState.role) role$!: Observable<UserRole>;

  get userId(): string {
    if (!this.user) return null;
    if ('id' in this.user) return this.user.id;
    if ('userId' in this.user) return this.user.userId;
    return null;
  }

  get userName(): string {
    if (!this.user) return null;
    if ('name' in this.user) return this.user.name;
    if ('userName' in this.user) return this.user.userName;
    return null;
  }
}
