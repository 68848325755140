import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ClipboardService {

  constructor() { }

  public async copyToClipboard(data: string | Record<string, string | Blob | PromiseLike<string | Blob>>): Promise<{
    success: boolean;
  }> {
    if (!navigator.clipboard) return { success: false };

    try {
      const writePromise = typeof data === 'string'
        ? navigator.clipboard.writeText(data)
        : navigator.clipboard.write([
          new ClipboardItem(data),
        ]);
      await writePromise;
      return { success: true };
    } catch (err) {
      return { success: false };
    }
  }
}
