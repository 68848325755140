<img class="pdf-logo" alt="" src="{{baseHref}}assets/images/brand/timedoctor/logo-full.png" />

<div class="pdf-name-section">
  <div class="report-title">
    <ng-content></ng-content>
  </div>

  <div class="pdf-time-section">
    <div class="pdf-date">{{ date }}</div>
    <div class="pdf-timezone">{{ timezone }}</div>
  </div>
</div>
